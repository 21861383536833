import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import "react-image-gallery/styles/css/image-gallery.css"

import { Slide, ToastContainer } from 'react-toastify';
import { useEffect } from 'react';
import {
    Route,
    Routes,
} from "react-router-dom";
import { lazy, Suspense } from 'react';

import useLoader from 'hooks/useLoader'
import useAuth from 'hooks/useAuth';

import BigLoader from 'components/atoms/Loader/BigLoader';
import LoginRequired from 'components/molecules/LoginRequired';
import TrainRequestListPage from 'pages/TrainRequestListPage';
import Intro from 'components/organisms/Intro';

import MapContextProvider from 'contexts/MapContext';
import { useIntroContext } from 'contexts/IntroContext';
import AuctionPage from 'pages/AuctionPage';
import AuctionLawsPage from 'pages/AuctionLaws';
import NewsLetterPage from 'pages/NewsLetterPage';
import AuctionSupply from 'pages/AuctionSupply';
import AuctionNotices from 'pages/AuctionNotices';

const Home = lazy(() => import("pages/Home"))
const Products = lazy(() => import("pages/Products"))
const Demo = lazy(() => import("pages/Products/Demo"))
const NewPassword = lazy(() => import("pages/NewPassword"))
const Profile = lazy(() => import("pages/Profile"))
const ForgetPasswordPage = lazy(() => import("pages/ForgetPassword"))
const MetalPage = lazy(() => import("pages/MetalPage"))
const ContractPage = lazy(() => import("pages/ContractPage"))
const NotFound = lazy(() => import("pages/helper/NotFound"))
const ErrorBoundary = lazy(() => import("pages/helper/ErrorBoundary"))
const Trading = lazy(() => import("pages/Trading"))
const MorePages = lazy(() => import("pages/MorePages"))
const DetailProduct = lazy(() => import("pages/Products/Detail"))
const FAQPage = lazy(() => import("pages/FAQPage"))
const Test = lazy(() => import("pages/Test"))
const NewsDetailPage = lazy(() => import("pages/NewsDetailPage"))
const NewsListPage = lazy(() => import("pages/NewsListPage"))
const ConsultingPage = lazy(() => import("pages/Consulting"))
const ProjectDetailPage = lazy(() => import("pages/ProjectDetailPage"))
const ContactUsPage = lazy(() => import("pages/ContactUsPage"))
const SignUpNewPage = lazy(() => import("pages/SignUpNewPage"))
const LoginNewPage = lazy(() => import("pages/LoginNewPage"))
const LicensePage = lazy(() => import("pages/LicensePage"))
const HanshPage = lazy(() => import("pages/HanshPage"))
const Points = lazy(() => import("pages/Profile/Points"))
const TrainRequestPage = lazy(() => import("pages/TrainRequestPage"))
const TrainPage = lazy(() => import("pages/TrainPage"))

export default function App()
{
    const { isLoading, Loader, fetchData } = useLoader({ initValue: true })
    const { getDetail } = useAuth()
    const { getPaths } = useIntroContext()

    const getData = async () =>
    {
        await getDetail()
        await getPaths()
    }

    useEffect(
        () =>
        {
            fetchData(getData())
        },
        []
    )

    if (isLoading)
    {
        return (
            <BigLoader />
        )
    }

    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                transition={Slide}
            />
            <Suspense fallback={<BigLoader />}>
                <ErrorBoundary>
                    <Routes>
                        <Route path='*' element={<NotFound />} />
                        <Route path='/' element={<Home />}/>
                        <Route path='products' element={<Products />} />
                        <Route path='products/demo/' element={<MapContextProvider><Demo /></MapContextProvider>} />
                        <Route path='consulting' element={<ConsultingPage />} />
                        <Route path='trading' element={<Trading />} />
                        <Route path='new-password/:token/' element={<NewPassword />} />
                        <Route path='register' element={<SignUpNewPage />} />
                        <Route path='login' element={<LoginNewPage />} />
                        <Route path='profile/*' element={<LoginRequired><Profile /></LoginRequired>} />
                        <Route path='my-points/*' element={<LoginRequired><Points /></LoginRequired>} />
                        <Route path='forget-password' element={<ForgetPasswordPage />} />
                        <Route path='forget-password/:token/' element={<ForgetPasswordPage />} />
                        <Route path='m/:name' element={<MetalPage />} />
                        <Route path='c/:name' element={<ContractPage />} />
                        <Route path='p/:name' element={<MorePages />} />
                        <Route path='detail' element={<DetailProduct />} />
                        <Route path='faq' element={<FAQPage />} />
                        <Route path="test" element={<Test />} />
                        <Route path="news/:id" element={<NewsDetailPage />} />
                        <Route path="n/:type" element={<NewsListPage />} />
                        <Route path={"consulting/:id"} element={<ProjectDetailPage />} />
                        <Route path={"contact-us"} element={<ContactUsPage />} />
                        <Route path={"license"} element={<LicensePage />} />
                        <Route path={"hansh"} element={<HanshPage />} />
                        <Route path={"train/*"} element={<LoginRequired config={{ has_train: true }} ><TrainPage /></LoginRequired>} />
                        <Route path={"train/request"} element={<LoginRequired><TrainRequestPage /></LoginRequired>} />
                        <Route path={"train/request-list"} element={<LoginRequired config={{ has_train: true }} ><TrainRequestListPage /></LoginRequired>} />
                        <Route path={"exchange/"} element={<AuctionPage />} />
                        <Route path={"exchange/laws/"} element={<AuctionLawsPage />} />
                        <Route path={"exchange/supply/"} element={<AuctionSupply />} />
                        <Route path={"exchange/notices/"} element={<AuctionNotices />} />
                        <Route path={"newsletter/:id/"} element={<NewsLetterPage />} />
                    </Routes>
                </ErrorBoundary>
            </Suspense>
            <Intro />
        </>
    )
}
