import useLanguage from "hooks/useLanguage"

import language from "utils/language"
import { changeLanguage } from "utils/language/fn"

/**
    @param {} props
*/
const Text = (props) =>
{

    const { lang } = useLanguage()

    const text = props.children

    if (typeof(text) === "string")
    {
        return changeLanguage(text, lang, props.vars)
    }

    return text
}
export default Text
