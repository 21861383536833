import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import useApi from 'hooks/useApi'
import useLanguage from 'hooks/useLanguage'
import useSWR from 'swr'
import Text from 'components/atoms/Text'

import cnLocale from '@fullcalendar/core/locales/zh-cn'
import enLocale from '@fullcalendar/core/locales/en-au'
import { useCallback, useRef } from 'react'
import OnlyBodyModal from 'components/atoms/Modal/OnlyBody'
import CalendarModalBody from './ModalBody'
import { PRODUCT_TYPE } from 'utils/const'
import moment from 'moment'

const CALENDAR_LOCALE = {
    "en": "en-gb",
    "cn": "zh-cn",
    "mn": "mn"
}

const mnLocale = {
    code: 'mn',
    week: {
        dow: 0,
        doy: 4, // The week that contains Jan 4th is the first week of the year.
    },
    buttonHints: {
        prev: 'Өмнөх $0',
        next: 'Дараах $0',
        today: 'Өнөөдөр $0',
    },
    viewHint: '$0 харах',
    navLinkHint: 'Үсрэх $0',
    moreLinkHint(eventCnt) {
        return `Show ${eventCnt} more event${eventCnt === 1 ? '' : 's'}`;
    },
    buttonText: {
        prev: 'Өмнөх',
        next: 'Дараах',
        today: 'Өнөөдөр',
        year: 'Жил',
        month: 'Сар',
        week: 'Долоо хоног',
        day: 'Өдөр',
        list: 'Жагсаалт',
    },
    allDayText: 'Heeldag',
    moreLinkText: 'Addisionele',
    dayHeaderContent: function(arg) {
        return ['Дав', "Мяг", "Лха", "Пүр", "Баа", "Бям", "Ням"][arg.date.getDay()]
    },
}

const NoticeCalendar = () => {

    const modalRef = useRef()
    const api = useApi().auctions
    const { lang } = useLanguage()

    const { data, isLoading, isValidating } = useSWR(
        ["notices-all", lang],
        ([key, language]) => api.noticeCalendar(language),
        {
            revalidateOnFocus: false,
        }
    )

    return (
        <>
        <div>
            <FullCalendar
                plugins={[dayGridPlugin]}
                initialView="dayGridMonth"
                events={isLoading ? [] : data.data}
                height={600}
                locale={CALENDAR_LOCALE[lang]}
                locales={[cnLocale, enLocale, mnLocale]}
                eventContent={(eventInfo) => renderEventContent(eventInfo, modalRef)}
                dayCellClassNames={({ date }) =>
                {
                    const today = new Date()
                    const todayDay = moment(today).format("YYYY-MM-DD")
                    const cellDay = moment(date).format("YYYY-MM-DD")
                    if (todayDay === cellDay)
                    {
                        return "fc-day-today"
                    }
                    else {
                        return todayDay > cellDay ? "fc-day-before" : ""
                    }
                }}
            />
            <OnlyBodyModal ref={modalRef} header={<Text>Жагсаалт</Text>} body={<CalendarModalBody />} backdrop={true} />
        </div>
        </>
    )
}

export default NoticeCalendar

function renderEventContent({
    event,
    timeText,
    backgroundColor,
    borderColor,
    textColor,
    isDraggable,
    isStartResizable,
    isEndResizable,
    isMirror,
    isStart,
    isEnd,
    isPast,
    isFuture,
    isToday,
    isSelected,
    isDragging,
    isResizing,
    view,
}, modalRef)
{

    const handleClick = () =>
    {
        modalRef.current.setShow(true, {
            timeText,
            event,
        })
    }

    return (
        <div className={`${event.title === "1" ? "bg-primary" : "bg-info text-dark"} text-center`} role='button' onClick={handleClick}>
            <Text>{PRODUCT_TYPE[event.title]?.charAt(0)}</Text> - {event.extendedProps.count}
        </div>
    )
}
