import Text from "components/atoms/Text"
import useApi from "hooks/useApi"
import useLanguage from "hooks/useLanguage"
import useSWR from "swr"
import NoticeMiniListCard from "./NoticeMiniListCard"
import { PRODUCT_TYPE } from "utils/const"

/**
    @param {} props
*/
const Notices = (props) =>
{

    const { productType=1 } = props

    const api = useApi().auctions
    const { lang } = useLanguage()

    const { data, isLoading, isValidating } = useSWR(
        ["notices", lang, productType],
        ([key, language, productType]) => api.notice(language, productType),
        {
            revalidateOnFocus: false,
        }
    )

    return (
        <div>
            <div className="news-title-bar">
                <span><Text>{PRODUCT_TYPE[productType]}</Text></span>
            </div>

            <div
                style={{
                    maxHeight: "200px",
                    overflowY: "auto"
                }}
            >
                {
                    isLoading
                    ? <div>loading</div>
                    : (
                        data.data.map(
                            (item, idx) =>
                            {
                                return (
                                    <NoticeMiniListCard
                                        key={idx}
                                        {...item}
                                    />
                                )
                            }
                        )
                    )
                }
            </div>
        </div>
    )
}
export default Notices
