import React, { useState } from 'react';

import StepWay from 'components/molecules/StepWay';

import MainForm from './MainForm';
import Finish from './Finish';
import SeparatedInput from './SeperatedInput';
import { NavLink } from 'react-router-dom';

const Register = (props) => {

	const [ email, setEmail ] = useState("")
    const { setRegister, token } = props

    const [ activeStep, setActiveStep ] = useState(token ? 2 : 0)

    const steps = [
		{
			name: "Register",
			icon: "fa-solid fa-user",
			component: <MainForm setRegister={setRegister} setActiveStep={setActiveStep} setEmail={setEmail} />,
		},
		{
			name: "Confirm",
			icon: "fas fa-shield-alt",
			component: <SeparatedInput setActiveStep={setActiveStep} email={email} />,
		},
		{
			name: "Finish",
			icon: "fa-solid fa-key",
			component: <Finish token={token} setActiveStep={setActiveStep}/>,
		},
	]

    return (
        <div className='p-4'>
            <div className="text-center pb-2">
                <NavLink to={"/"}>
					<img src={`${process.env.PUBLIC_URL}/comex.png`} alt="logo" width={"120"} />
				</NavLink>
            </div>

			<StepWay activeStep={activeStep} steps={steps}/>
		</div>
	)
}

export default Register;
