import { NavLink as RouterNavLink, useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { LuUser, LuSearch } from 'react-icons/lu';
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from 'react-bootstrap';

import CButton from 'components/atoms/Button';
import CustomMenu from 'components/atoms/CustomMenu';
import Login from 'components/organisms/Login';
import OnlyBodyModal from 'components/atoms/Modal/OnlyBody';

import useAuth from 'hooks/useAuth';
import useLanguage from 'hooks/useLanguage';

import { HEADER_NAV_ANALYSIS, HEADER_NAV_TRADING, LANGUAGES } from 'utils/const';
import Text from 'components/atoms/Text';
import useMobile from 'hooks/useMobile';

function Headers() {

    const { isLogged, signOut } = useAuth()
    const navigate = useNavigate()
    const { lang, setLang } = useLanguage()
    const location = useLocation()

    const modalRef = useRef()
    const { isMobile } = useMobile()

    const handleLogin = useCallback(
        () => {
            if (!isLogged)
            {
                // utsaar orj bwal huudas usergeh
                if (isMobile)
                {
                    navigate("/login")
                }
                else {
                    modalRef.current.setShow(prev => !prev)
                }
            }
            else {
                navigate("/profile")
            }
        },
        [isLogged, modalRef, isMobile]
    )

    const handleLang = useCallback(
        (value) =>
        {
            if (value !== lang)
            {
                window.localStorage.setItem("lang", value)
                setLang(value)
            }
        },
        [lang]
    )

    return (
        <header id='header'>
            {["md"].map((expand) => (
                <Navbar key={expand} expand={expand} className="bg-body-tertiary bg-dark">
                    <Container fluid className='c-nav-container'>
                        <Navbar.Brand as={RouterNavLink} to={"/"}>
                            <img src={`${process.env.PUBLIC_URL}/comex.png`} alt="logo" height={"50px"} />
                        </Navbar.Brand>
                        <Navbar.Toggle className="text-light" aria-controls={`offcanvasNavbar-expand-${expand}`} />
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-${expand}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                            placement="end"
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                    <Text>
                                        Navigations
                                    </Text>
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Nav className="justify-content-end flex-grow-1 pe-3">
                                    {
                                        location.pathname !== "/"
                                        ? (
                                            <div className='d-flex align-items-center mx-3'>
                                                <CButton to={"/"} as={RouterNavLink} end={true} className='cnav bg-transparent' type="button">
                                                    <Text>Home</Text>
                                                </CButton>
                                            </div>
                                        )
                                        : ""
                                    }
                                    <Dropdown className='d-flex align-items-center mx-3'>
                                        <Dropdown.Toggle  as={NavLink} className='cnav bg-transparent' type="button" id='dsadassda'>
                                            <Text>Data and Analysis</Text>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu as={CustomMenu} renderOnMount>
                                            {
                                                HEADER_NAV_ANALYSIS.map(
                                                    (nav, idx) =>
                                                    {
                                                        return (
                                                            <Dropdown.Item key={idx} as={RouterNavLink} to={nav.link} eventKey={nav.id}><Text>{nav.name}</Text></Dropdown.Item>
                                                        )
                                                    }
                                                )
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    {/* <Dropdown className='d-flex align-items-center mx-3'>
                                        <Dropdown.Toggle as={NavLink} className='cnav bg-transparent' type="button" id='ksdajcalkjd'>
                                            <Text>Partner and Consultancy</Text>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu as={CustomMenu} renderOnMount>
                                            {
                                                HEADER_NAV_TRADING.map(
                                                    (nav, idx) =>
                                                    {
                                                        return (
                                                            <Dropdown.Item key={idx} as={RouterNavLink} to={nav.link} eventKey={nav.id}><Text>{nav.name}</Text></Dropdown.Item>
                                                        )
                                                    }
                                                )
                                            }
                                        </Dropdown.Menu>

                                    </Dropdown> */}
                                    <div className='d-flex align-items-center mx-3'>
                                        <CButton as={RouterNavLink} to={"/faq"} end className='cnav bg-transparent'>
                                            <Text>FAQ</Text>
                                        </CButton>
                                    </div>
                                    <div className='nav-line me-2 d-none d-md-block'>

                                    </div>
                                    <div className='d-flex align-items-center mx-2 me-0 ms-3 ms-md-0'>
                                        {
                                            isLogged
                                            ? (
                                                <Dropdown className='right'>
                                                    <Dropdown.Toggle className='cnav bg-transparent px-0 pe-0 pe-md-2 d-flex align-items-center' type="button" id='dsadassda'>
                                                        <LuUser size={20} /> <span className='d-block d-md-none ms-2 ms-md-0'><Text>User</Text></span>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu as={CustomMenu} renderOnMount>
                                                        <Dropdown.Item as={RouterNavLink} to={"/profile"} eventKey="2001"><Text>Profile</Text></Dropdown.Item>
                                                        <Dropdown.Item onClick={signOut}><Text>Log out</Text></Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            )
                                            : (
                                                <CButton onClick={handleLogin} className='cnav bg-transparent me-2 d-flex d-md-block'>
                                                    <LuUser size={20} /> <span className='d-block d-md-none ms-2 ms-md-0'><Text>User</Text></span>
                                                </CButton>
                                            )
                                        }
                                    </div>
                                    <div className='d-flex align-items-center ms-3 ms-md-0'>
                                        <Dropdown className='d-flex align-items-center right' onSelect={handleLang}>
                                            <Dropdown.Toggle as={NavLink} className='cnav bg-transparent px-0 d-flex d-md-block' type="button" id='dsadassda'>
                                                <img src={process.env.PUBLIC_URL + LANGUAGES[lang].flag} height={18} style={{ borderRadius: "2px" }} /> <span className='d-block d-md-none ms-2 ms-md-0'>{LANGUAGES[lang].name}</span>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu as={CustomMenu}>
                                                {
                                                    Object.keys(LANGUAGES).map(
                                                        (key, idx) =>
                                                        {
                                                            const item = LANGUAGES[key]
                                                            return (
                                                                <Dropdown.Item key={idx} active={key === lang} eventKey={key}><img src={process.env.PUBLIC_URL + item.flag} height={18} style={{ borderRadius: "2px" }} /> {item.name}</Dropdown.Item>
                                                            )
                                                        }
                                                    )
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
            ))}
    		<OnlyBodyModal ref={modalRef} body={<Login />}/>
        </header>
    );
}

export default Headers;
