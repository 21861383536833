import Text from "../Text"

/**
    @param {} props
*/
const Title = (props) =>
{

    const { text, as="h1", className="", sub } = props

    const Compoennt = as

    return (
        <div>
            <Compoennt style={{ fontWeight: "bold" }} className={`text-center ${className} ${sub ? "mb-0" : ""}`}>
                <Text>{text}</Text>
            </Compoennt>
            {
                sub
                ? <h5 className="text-center"><Text>/{sub}/</Text></h5>
                : null
            }
        </div>
    )
}
export default Title
