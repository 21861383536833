import useLanguage from "hooks/useLanguage"
import { forwardRef, useCallback } from "react"
import { Controller } from "react-hook-form"
import { HL_LANG } from "utils/const"
import HCaptcha from '@hcaptcha/react-hcaptcha';


const HCaptchaComp = forwardRef((props, ref) =>
{

    const { lang } = useLanguage()
    const hl = HL_LANG[lang]

    return (
        <Controller
            control={props.control}
            name={"captcha"}
            render={({ field: { name, onChange }, formState: { errors } }) =>
            {
                const errorMessage = errors[name]
                return (
                    <div className="d-flex flex-column">
                        <HCaptcha
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                            onVerify={(token, key) =>
                            {
                                onChange(Boolean(token))
                            }}
                            languageOverride={hl}
                            onExpired={() =>
                            {
                                onChange(false)
                            }}
                            onError={() =>
                            {
                                onChange(false)
                            }}
                            size="normal"
                        />
                        {
                            errorMessage
                            ? <div className="custom-error"><span>{errorMessage.message}</span></div>
                            : null
                        }
                    </div>
                )
            }}
        />
    )
})

export default HCaptchaComp
