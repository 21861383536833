import Footer from "components/molecules/Footer"
import Headers from "components/molecules/Headers"
import SecondaryHeader from "components/molecules/SecondaryHeader"
import TrainRequestList from "components/organisms/Train/TrainRequestList"
import useAuth from "hooks/useAuth"
import { useMemo } from "react"

/**
    @param {} props
*/
const TrainRequestListPage = (props) =>
{

    const { userDetail } = useAuth()

    const links = useMemo(
        () =>
        {
            const links = [
                {
                    name: "Order",
                    to: "/train/request"
                },
            ]

            if (userDetail.has_train)
            {
                links.unshift({
                    name: "List",
                    to: "/train",
                    exact: true,
                })
                links.push({
                    name: "Order list",
                    to: "/train/request-list"
                },)
            }

            return links
        },
        [userDetail.has_train]
    )

    return (
        <>
            <Headers />
            <SecondaryHeader
                title={"Train"}
                buttons={[
                    {
                        text: "Need help? Read our FAQ",
                        as: "a",
                        href: "#faq",
                        className: "smooth-goto",
                    }
                ]}
                links={links}
            />
            <div className="container my-5">
                <TrainRequestList />
            </div>
            <Footer />
        </>
    )
}
export default TrainRequestListPage
