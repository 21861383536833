import { useCallback } from "react"
import { toast, ToastOptions } from "react-toastify"

export default function useToast()
{

    const displayToast = useCallback(
        /**
         * @param {string} msg
         * @param {ToastOptions} option
         */
        (msg, option) =>
        {
            toast(msg, option)
        },
        []
    )

    return { displayToast }
}
