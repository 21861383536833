import React, { useEffect, useState } from 'react';

import CButton from 'components/atoms/Button';

import useLoader from 'hooks/useLoader';
import useApi from 'hooks/useApi';
import useToast from 'hooks/useToast';

import css from 'utils/css/separatedInput/style.module.css'
import { NavLink } from 'react-bootstrap';
import Text from 'components/atoms/Text';

const SeparatedInput = (props) => {

	const { setActiveStep, email } = props

	const { isLoading, Loader, fetchData } = useLoader({ isSmall: true })
	const userApi = useApi().user

    const onSubmit = async () =>
	{
		const body = {
			email: email,
			username: email,
		}
		const { success, data } = await fetchData(userApi.register(body)).catch(err => err)
		if (success)
		{
			setActiveStep(prev=>prev+1)
		}
	}

    return (
		<div className={css.form}>
			{isLoading && Loader}
			<div className='text-center'>
				<Text>We sent email to your email address. Please confirm your email address.</Text>
				<NavLink onClick={onSubmit}>
					<Text>Didn't get a email?</Text>
				</NavLink>
			</div>
		</div>
	)
}

export default SeparatedInput;
