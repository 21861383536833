import Skeleton from 'react-loading-skeleton'

/**
    @param {} props
*/
const CMPageLoader = (props) =>
{

    return (
        <div className='row'>
            <div className='col-12 mb-3'>
                <Skeleton height={100} enableAnimation  duration={5}/>
            </div>
            <div className='col-12 col-md-4'>
                <Skeleton height={250} enableAnimation  duration={5}/>
            </div>
            <div className='col-12 col-md-8'>
                <Skeleton count={2} height={20} enableAnimation  duration={5}/>
                <div className='row mt-3'>
                    <div className='col-6 col-md-3'>
                        <Skeleton count={4} height={40} enableAnimation  duration={5}/>
                    </div>
                    <div className='col-6 col-md-3'>
                        <Skeleton count={4} height={40} enableAnimation  duration={5}/>
                    </div>
                    <div className='col-6 col-md-3'>
                        <Skeleton count={4} height={40} enableAnimation  duration={5}/>
                    </div>
                    <div className='col-6 col-md-3'>
                        <Skeleton count={4} height={40} enableAnimation  duration={5}/>
                    </div>
                </div>
            </div>
            <div className='col-12 mt-4'>
                <Skeleton count={5} height={40} enableAnimation  duration={5}/>
            </div>
        </div>
    )
}
export default CMPageLoader
