import CButton from "components/atoms/Button"
import Text from "components/atoms/Text"
import { Fragment, useCallback } from "react"
import { Dropdown } from "react-bootstrap"
import { NavLink } from "react-router-dom"

/**
    @param {} props
*/
const SecondaryHeader = (props) =>
{

    const { buttons, mobileTitle, title, links=[], hasFirstDisplay=true, onlyTitle=false } = props

    const child = useCallback(
        (options={}) =>
        {
            const Element = options.as ?? "li"

            return (
                <>
                    {
                        links === false
                        ? null
                        :
                        links.length
                        ? (
                            links.map(
                                (link, idx) =>
                                {
                                    const isLink = link.isLink === false ? false : true
                                    return <Fragment key={idx}>
                                            {
                                                isLink
                                                ? (
                                                    <Element as={NavLink} to={link.to}>
                                                        <NavLink className="" to={link.to} end={link.end ?? true}>
                                                            <Text>{link.name}</Text>
                                                        </NavLink>
                                                    </Element>
                                                )
                                                : <Element className={link.className ?? ""} onClick={link.onClick} role="button"><Text>{link.name}</Text></Element>
                                            }
                                            {
                                                link.badge
                                                ? <span className="badge badge-primary"><Text>{link.badge}</Text></span>
                                                : null
                                            }
                                        </Fragment>
                                }
                            )
                        )
                        : (
                            <>
                                <Element as={NavLink} to={"/"}>
                                    <NavLink className="" end to={"/"}>
                                        <Text>Overview</Text>
                                    </NavLink>
                                </Element>
                                <Element as={NavLink} to={"/products/demo"}>
                                    <NavLink className={""} to={"/products/demo"}>
                                        <Text>Products</Text>
                                    </NavLink>
                                </Element>
                                <Element as={NavLink} to={"/consulting/"}>
                                    <NavLink className={""} to={"/consulting/"}>
                                        <Text>Consulting</Text>
                                    </NavLink>
                                </Element>
                                <Element as={NavLink} to={"/trading/"}>
                                    <NavLink className={""} to={"/trading/"}>
                                        <Text>Trading</Text>
                                    </NavLink>
                                </Element>
                            </>
                        )
                    }
                </>
            )
        },
        []
    )

    return (
        <div id="c-secondary-nav" className={`secondary-nav-wrapper position-relative myshadow ${hasFirstDisplay ? "" : "d-none"}`}>
            <div className="c-secondary-nav">
                <div className="flex-column flex-md-row d-none d-md-flex">
                    <div className="c-nav-title">
                        <Text>{title}</Text>
                    </div>
                    <ul className="c-nav-list">
                        {child()}
                    </ul>
                </div>
                <div className="d-flex mobile-nav d-md-none d-block">
                    {
                        !onlyTitle && links !== false
                        ? (
                            <Dropdown>
                                <Dropdown.Toggle className="bg-transparent border-0 text-dark" style={{ fontSize: "16px" }}>
                                    {title}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {child({ as: Dropdown.Item })}
                                </Dropdown.Menu>
                            </Dropdown>
                        )
                        : links === false
                        ? (
                            <div className="c-nav-title" style={{ padding: "5px" }}>
                                <Text>{mobileTitle}</Text>
                            </div>
                        )
                        : <div className="c-nav-title">
                            <Text>{title}</Text>
                        </div>
                    }
                </div>
                <div className="d-flex align-items-center justify-content-center second-btns">
                    {
                        buttons && buttons.length
                        ?
                            buttons.map(
                                (button, idx) =>
                                {
                                    if (!button)
                                    {
                                        return
                                    }
                                    return (
                                        <CButton key={idx} onClick={button.onClick} {...button} className={`${button.className} me-3`}>
                                            {button.text}
                                        </CButton>
                                    )
                                }
                            )
                        : null
                    }
                </div>
            </div>
        </div>
    )
}
export default SecondaryHeader
