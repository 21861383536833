import useApi from "hooks/useApi"
import { createContext, useCallback, useContext, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

export const IntroContext = createContext(
    {
        introCode: "",
        setInroCode: () => {},
        getPaths: () => {},
        option: {
            continueFn: () => {}
        },
        setOption: () => {},
    }
)

export default function IntoContextProvider(props)
{

    const [ paths, setPaths ] = useState({})

    const [ introCode, setCode ] = useState("")
    const [ option, setOption ] = useState({})
    const location = useLocation()

    const api = useApi().intro

    const getPaths = useCallback(
        async () =>
        {
            const { success, data } = await api.getPaths().catch(err => err)
            if (success)
            {
                setPaths(data)
            }
        }
    )

    const setInroCode = useCallback(
        (code, continueFn) =>
        {
            setCode(code)
            setOption({ continueFn })
        },
        []
    )

    useEffect(
        () =>
        {

            if (!Object.keys(paths).length)
            {
                return
            }

            // TODO: exact false uyiig ni hiih

            let path = location.pathname
            if (!location.pathname.endsWith("/")) {
                path = location.pathname + "/"
            }

            const codeObj = paths[path]
            if (codeObj)
            {
                setInroCode(codeObj.code)
            }
        },
        [location.pathname, paths]
    )

    return (
        <IntroContext.Provider
            value={
                {
                    introCode,
                    setInroCode,
                    getPaths,
                    option,
                    setOption
                }
            }
        >
            {props.children}
        </IntroContext.Provider>
    )
}

export const useIntroContext = (props) =>
{
    return useContext(IntroContext)
}
