import axios from "axios"
import moment from "moment";
import { isValidElement } from "react";

import { changeLanguage } from 'utils/language/fn'

let dollarUSLocale = Intl.NumberFormat('en-US');

export async function getExternalFile(fileName="index2.html") {
    const request = await axios.get(`${process.env.PUBLIC_URL}/${fileName}`)
    return request
}

/** dom element эсэхийг шалгах нь */
export function isDOMTypeElement(element)
{
    return isValidElement(element) && typeof element.type === 'string';
}

/** Тухайн нэг component ийн props дээр нэмэлтээр props нэмэхээр бол энийг ашиглаж болно */
export function addPropsToComponent(Component, extraProps)
{
    let validElement = isDOMTypeElement(Component)
    if (validElement) return Component
    return <Component.type {...Component.props} {...extraProps} />;
}

export function getFlagEmoji(countryCode) {
    const codePoints = countryCode
      .toUpperCase()
      .split('')
      .map(char =>  127397 + char.charCodeAt());
    return String.fromCodePoint(...codePoints);
}

export function later(delay) {
    return new Promise(function(resolve) {
        setTimeout(() => resolve({ success: true }), delay);
    });
}

export function getCounts(count) {
    let counts = []
    for (let index = 0; index < count; index++) {
        counts.push(index)
    }
    return counts
}

export function findLeftTime(day1, day2, getText=true)
{
    const now = day1 ? moment(day1) : moment()
    const medeetime = moment(day2)
    const zuruums = now.diff(medeetime)
    if (getText)
    {
        return msToText(zuruums)
    }
    else {
        return zuruums
    }
}

export function msToText(ms)
{
    if (ms <= 1000 && ms <= 1000 * 60)
    {
        return changeLanguage("Дөнгөж сая")
    }
    else if(ms <= 1000 * 60 * 60 && ms >= 1000 * 60) {
        return Math.floor(ms / (1000 * 60)) + " " + changeLanguage("минутын өмнө")
    }
    else if (ms >= 1000 * 60 * 60 && ms <= 1000 * 60 * 60 * 24) {
        return Math.floor(ms / (1000 * 60 * 60)) + " " + changeLanguage("цагийн өмнө")
    }
    else if (ms <= 1000 * 60 * 60 * 24 * 30 && ms >= 1000 * 60 * 60 * 24) {
        return Math.floor(ms / (1000 * 60 * 60 * 24)) + " " + changeLanguage("хоногийн өмнө")
    }
    else if (ms >= 1000 * 60 * 60 * 24 * 30 && ms <= 1000 * 60 * 60 * 24 * 30 * 12) {
        return Math.floor(ms / (1000 * 60 * 60 * 24 * 30)) + " " + changeLanguage("сарын өмнө")
    }
    else if (ms >= 1000 * 60 * 60 * 24 * 30 * 12) {
        return Math.floor(ms / (1000 * 60 * 60 * 24 * 30 * 12)) + " " + changeLanguage("жилийн өмнө")
    }
}

export function capitalizeFLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export const moneyFormat = (price) => dollarUSLocale.format(price)
