import React, { createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useApi from 'hooks/useApi';
import useToast from 'hooks/useToast';

export const AuthContext = createContext()

const initUserDetail = {}
export default function AuthContextProvider(props)
{
    const userApi = useApi({ isDisplayToast: false }).user
    const signOutApi = useApi({ isDisplayToast: true }).user
    /** хэрэглэгчийн мэдээлэл */
    const [ userDetail, setDetail ] = useState(initUserDetail)
    /** Нэвтэрсэн эсэх */
    const [ isLogged, setLogged ] = useState(false)
    const { addToast } = useToast()
    const navigate = useNavigate()

    /** системд хэрэглэгчийн нэвтэрснээр бүртгэх */
    const loggedUser = (userData, isUpdate=false) => {
        if (!Object.keys(userData).length)
            return
        //  хэрэглэгчийн мэдээллийг хадгалах нь
        if (isUpdate)
        {
            setDetail((prev) => ({ ...prev, ...userData, }))
        }
        else {
            setDetail(userData)
        }

        //  нэвтэрсэн гэж үзэх
        setLogged(true)
    }

    /**
     * Xэрэглэгчийн бүх мэдээллийг авах нь
     * setDetail хийнэ
     */
    const getDetail = async () =>
    {
        const { success, data, error } = await userApi.getUser().catch(err => { return err })
        if (success)
        {
            loggedUser(data)
        }
    }

    /** Системээс гарах
     * @param {boolean} hasToast гаргах эсэх
    */
    const signOut = async (hasToast=true) =>
    {
        const { success, info, error } = await signOutApi.logout().catch(err => { return err })
        if (success)
        {
            navigate("/")
            //  нэвтрээгзй гэж хадгалах
            setLogged(false)
            //  хэрэглэгчийн мэдээллийг хоослох нь
            setDetail(initUserDetail)
        }
    }

    return (
        <AuthContext.Provider value={{
            getDetail,
            signOut,
            setDetail,
            loggedUser,
            isLogged,
            userDetail,
        }}>
            {props.children}
        </AuthContext.Provider>
    );
}
