import Text from "components/atoms/Text"
import useApi from "hooks/useApi"
import useLanguage from "hooks/useLanguage"
import useMobile from "hooks/useMobile"
import moment from "moment"
import { useCallback, useEffect, useRef, useState } from "react"
import { Button, Form, Table } from "react-bootstrap"
import ReactDatePicker from "react-datepicker"
import { BsX } from "react-icons/bs"
import useSWR from "swr"
import { moneyFormat } from "utils"
import SupplyListCard from "../SupplyListCard"
import { CUR } from "utils/const"
import { NavLink } from "react-router-dom"
import CMPageLoader from "components/atoms/Loader/CMPage"

/**
    @param {} props
*/
const SupplyTable = (props) =>
{
    const { ispage=false } = props

    const filterRef = useRef({})

    const [ isToday, setToday ] = useState(false)
    const [dateRange, setDateRange] = useState([null, null]);
    const [ filteredData, setFilteredData ] = useState([])
    const [startDate, endDate] = dateRange;

    const { isMobile } = useMobile()

    const api = useApi().auctions
    const { lang } = useLanguage()

    const { data, isLoading, isValidating } = useSWR(
        ["supply", lang],
        ([key, lang]) => api.supply("all", lang),
        {
            revalidateOnFocus: false,
        }
    )

    useEffect(
        () =>
        {
            if (isValidating === false)
            {
                setFilteredData(data?.data ?? [])
                filter(filterRef.current)
            }
        },
        [isValidating]
    )

    const filter = useCallback(
        (filters) =>
        {
            if (Object.keys(filters).length)
            {
                let filtered = data?.data
                Object.values(filters)
                    .map(
                        (f) =>
                        {
                            filtered = filtered.filter(f)
                        }
                    )
                setFilteredData(filtered)
            }
            else {
                setFilteredData(data?.data ?? [])
            }
        },
        [data?.data]
    )

    const handleRange = useCallback(
        (update) =>
        {
            const start = update[0]
            const end = update[1]
            if (start && end)
            {
                filterRef.current["range"] = (e) => {
                    return new Date(e.date) <= end && start <= new Date(e.date)
                }
                delete filterRef.current['today']
                filter(filterRef.current)
            }
            else if (!start && !end)
            {
                delete filterRef.current['range']
                filter(filterRef.current)
            }
            setToday(false)
            setDateRange(update);
        },
        [data?.data, filterRef.current]
    )

    const handleToday = useCallback(
        () =>
        {
            if (isToday)
            {
                delete filterRef.current['today']
                filter(filterRef.current)
                setToday(false)
            }
            else {
                const today = moment().format("YYYY-MM-DD")
                filterRef.current['today'] = (e) => {
                    return e.date +"" === today +""
                }
                delete filterRef.current['range']
                filter(filterRef.current)
                setToday(true)
                setDateRange([null, null]);
            }
        },
        [data?.data, filterRef.current, isToday]
    )

    const handleType = useCallback(
        (event) =>
        {
            filterRef.current["type"] = (e) => {
                    if (event.target.value === "all")
                    {
                        return true
                    }
                    else {
                        return e.product_type_display +"" === event.target.value +""
                    }
                }
            filter(filterRef.current)
        },
        [data?.data, filterRef.current]
    )

    if (isLoading)
    {
        return <CMPageLoader />
    }

    return (
        <div>
            <div className="d-flex flex-md-row flex-column">
                <div>
                    <span className="fw-bold fs-6 mb-3 mb-md-0">
                        <Text>Search</Text>:
                    </span>
                </div>
                <div className="ms-md-3">
                    <div className="form-group">
                        <Form.Label>
                            <Text>Төрөл</Text>:
                        </Form.Label>
                        <div className="d-flex">
                            <Form.Check
                                onChange={handleType}
                                type={"radio"}
                                className="me-2"
                                name="supply-radio"
                                value={"all"}
                                defaultChecked
                                id={`sall-radio`}
                                label={<Text>Бүгд</Text>}
                            />
                            <Form.Check
                                onChange={handleType}
                                type={"radio"}
                                className="me-2"
                                name="supply-radio"
                                value={"coal"}
                                id={`scoal-radio`}
                                label={<Text>Coal</Text>}
                            />
                            <Form.Check
                                onChange={handleType}
                                type={"radio"}
                                className="me-2"
                                name="supply-radio"
                                value={"iron"}
                                id={`siron-radio`}
                                label={<Text>Iron</Text>}
                            />
                        </div>
                    </div>
                </div>
                <div className="ms-md-3">
                    <div className="form-group">
                        <Form.Label>
                            <Text>Огноо</Text>:
                        </Form.Label>
                        <div>
                        <div className="d-flex">
                            <Button onClick={handleToday}>
                                Today {isToday ? <BsX /> : null}
                            </Button>
                            <ReactDatePicker
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={handleRange}
                                withPortal={isMobile}
                                className={`form-control`}
                                isClearable={true}
                                dateFormat={"yyyy-MM-dd"}
                                monthsShown={2}
                            />
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={!ispage?{
                maxHeight: "800px",
                overflowY: "auto"
            }:{}}>
                <div className="d-block d-md-none">
                    {
                        filteredData.length
                        ? (
                            filteredData.map(
                                (item, idx) =>
                                {
                                    return (
                                        <SupplyListCard
                                            key={idx}
                                            {...item}
                                        />
                                    )
                                }
                            )
                        )
                        : (
                            <div className="d-flex justify-content-center">
                                <Text>{isValidating ? "Loading" : "no result"}</Text>
                            </div>

                        )
                    }
                </div>
                <Table striped responsive bordered className="d-none d-md-block">
                    <thead>
                        <tr>
                            <th><Text>№</Text></th>
                            <th style={{ width: 110 }}><Text>Огноо</Text></th>
                            <th><Text>Зарсан</Text></th>
                            <th><Text>Төрөл</Text></th>
                            <th><Text>Тоо ширхэг</Text></th>
                            <th className="text-end"><Text>А.Үнэ</Text></th>
                            <th className="text-end"><Text>Үнэ</Text></th>
                            <th className="text-end"><Text>ө</Text></th>
                            <th className="text-center"><Text>Валют</Text></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            filteredData.length
                            ?
                                filteredData.map(
                                    (item, idx) =>
                                    {

                                        const date = item.productCode.split("-")[1]
                                        const year = date.slice(0, 4)
                                        const month = date.slice(4, 6)
                                        const day = date.slice(6, 8)

                                        const strDate = `${year}-${month}-${day}`

                                        const dugaar = item.productName.split("-")[1]

                                        const aprice = item?.price?.replace(CUR[item.currency], "")?.trim()
                                        const sellprice = Number(item.sellPrice)

                                        let percent = "??"
                                        let textColor = ""
                                        if (aprice && sellprice)
                                        {
                                            const apriceNumber = Number(aprice)
                                            const huwi = (((sellprice * 100) / apriceNumber) - 100)
                                            if (huwi > 0) {
                                                textColor = "text-success"
                                            }
                                            else if (huwi === 0)
                                            {
                                                textColor = "text-success"
                                            }
                                            else {
                                                textColor = "text-danger"
                                            }
                                            percent = `${huwi.toFixed(2)}%`
                                        }

                                        return (
                                            <tr key={idx}>
                                                <td>{dugaar}</td>
                                                <td>{strDate}</td>
                                                <td>{item.seller_name ?? item.sellerName}</td>
                                                <td>{item.type_name ?? item.coalType_display}</td>
                                                <td>{item.quantity ?? "??"}</td>
                                                <td className="text-end">{aprice ? moneyFormat(aprice) : "??"}</td>
                                                <td className="text-end">{item.sellPrice ? moneyFormat(item.sellPrice) : "??"}</td>
                                                <td className={`${textColor} text-end`}>{percent}</td>
                                                <td className="text-center">{item.currency}</td>
                                            </tr>
                                        )
                                    }
                                )
                            : (
                                <tr>
                                    <td colSpan={9} className="py-5 text-center">
                                        <Text>{isValidating ? "Loading" : "no result"}</Text>
                                    </td>
                                </tr>

                            )
                        }
                    </tbody>
                </Table>
            </div>
            {
                ispage
                ? null
                : (
                    <div className="d-flex justify-content-center mt-1">
                        <NavLink to={"/exchange/supply/"}>
                            <Button variant="link">
                                <Text>Show all</Text>
                            </Button>
                        </NavLink>
                    </div>
                )
            }
        </div>
    )
}
export default SupplyTable
