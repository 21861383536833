import useAuth from "hooks/useAuth"
import useToast from "hooks/useToast"
import { useEffect } from "react"
import { Navigate } from "react-router-dom"

/**
    @param {} props
*/
const LoginRequired = (props) =>
{

    const { config={} } = props
    const { isLogged, userDetail } = useAuth()
    const { displayToast } = useToast()

    useEffect(
        () =>
        {
            if (!isLogged)
            {
                displayToast("Login required", {
                    type: "warning"
                })
            }
        },
        [isLogged]
    )

    if (isLogged && checkValue(config, userDetail))
    {
        return props.children
    }
    else {
        return <Navigate to={"/"} replace />
    }

}
export default LoginRequired

function checkValue(subObj, mainObj) {
    let has = true
    Object.keys(subObj)
        .map(
            (key) =>
            {
                const value = subObj[key]
                const mainValue = mainObj[key]
                has = mainValue === value
            }
        )
    return has
}
