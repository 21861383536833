import Footer from "components/molecules/Footer"
import Headers from "components/molecules/Headers"
import { useParams } from "react-router-dom"
import CMPageLoader from "components/atoms/Loader/CMPage"
import { useEffect, useRef, useState } from "react"
import useLanguage from "hooks/useLanguage"

/**
    @param {} props
*/
const NewsLetterPage = (props) =>
{
    const params = useParams()

    const ref = useRef();
    const [height, setHeight] = useState("720px");
    const onLoad = () => {
        setHeight(ref.current.contentWindow.document.body.scrollHeight + "px");
        setLoading(false)
    };

    const [ isLoading, setLoading ] = useState(true)
    const { lang } = useLanguage()

    useEffect(
        () =>
        {
            setHeight("720px")
            setLoading(true)
            return () =>
            {
                setHeight("720px")
            }
        },
        [params.name, lang]
    )

    return (
        <>
            <Headers />
            {
                isLoading
                ? (
                    <div className="container-fluid position-absolute mx-auto bg-white w-100 h-100">
                        <div className="container">
                            <CMPageLoader />
                        </div>
                    </div>
                )
                : null
            }
            {
                params.id
                ? (
                    <>
                    {
                        process.env.NODE_ENV === "development"
                        ? (
                            <iframe
                                src={`${process.env.REACT_APP_SERVER_URL}/newsletter/${params.id}/${lang}/`}
                                width="100%"
                                id="myframe"
                                onLoad={() =>
                                {
                                    setLoading(false)
                                }}
                                className={`container-fluid py-5 dynamic`}
                                style={{
                                    display: "flex",
                                    height: "calc(100vh - 76px)"
                                }}
                            ></iframe>
                        )
                        : (
                            <>
                                <iframe
                                    src={`${process.env.REACT_APP_SERVER_URL}/newsletter/${params.id}/${lang}/`}
                                    width="100%"
                                    id="myframe"
                                    className={`container-fluid py-5 dynamic d-block d-md-none`}
                                    style={{
                                        display: "flex",
                                        height: "calc(100vh - 76px)"
                                    }}
                                ></iframe>
                                <iframe
                                    ref={ref}
                                    height={height}
                                    onLoad={onLoad}
                                    src={`${process.env.REACT_APP_SERVER_URL}/newsletter/${params.id}/${lang}/`}
                                    className={`container-fluid py-5 dynamic d-none d-md-block`}
                                    scrolling="no"
                                    frameBorder="0"
                                    style={{
                                        overflow: "auto"
                                    }}
                                ></iframe>
                            </>
                        )
                    }
                    </>
                )
                : (
                    <CMPageLoader />
                )
            }
            <Footer />
        </>
    )
}
export default NewsLetterPage
