import axios from 'axios';
import useAuth from 'hooks/useAuth';
import React, { createContext, useCallback, useEffect, useState } from 'react';

export const MapContext = createContext()

export const PREMIUM_LAYERS = {
    "Power station": 1,
    "Results of mineral deposits": 1,
}

export default function MapContextProvider(props) {

    const [ layers, setLayers ] = useState({})
    const [ clickedFeature, setClickedFeature ] = useState({})

    const changeSelect = useCallback(
        (element, isSelected) =>
        {
            setLayers(
                (prev) =>
                {
                    prev[element.id] = {
                        ...prev[element.id],
                        name: element.name,
                        ...element.metadata,
                        visible: !isSelected,
                    }
                    return { ...prev }
                }
            )
        },
        []
    )

    const whenClickedFeature = useCallback(
        (event, feature, layerName, isLocal, layer) =>
        {
            setClickedFeature(
                {
                    latlng: event.latlng,
                    feature,
                    layerName,
                    isLocal,
                    layer
                }
            )
        },
        []
    )

    const getWFSData = useCallback(
        async () =>
        {
            const noDataDisplayedLayers = Object.keys(layers).filter(key => layers[key]._type === "wfs" && !layers[key].data && layers[key].visible === true && layers[key].url)
            // NOTE:  enechee wfs eer duudaj geojson datag turuulj tataj awj haruulah heregtei
            for (const key of noDataDisplayedLayers) {
                const layer = layers[key]
                const { status, data } = await axios.get(layer.url, { withCredentials: true }).catch(err => err)
                if (status === 200)
                {
                    layers[key].data = data
                }
            }
            setLayers({...layers})
        },
        [layers]
    )

    useEffect(
        () =>
        {
            getWFSData(layers)
        },
        [Object.keys(layers).length]
    )

    return (
        <MapContext.Provider
            value={{
                layers: layers,
                setLayers: setLayers,
                changeSelect,
                setClickedFeature,
                clickedFeature,
                whenClickedFeature,
            }}
        >
            {props.children}
        </MapContext.Provider>
    )
}
