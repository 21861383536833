import useSWR from "swr"
import NoticeListCard from "../../NoticeListCard"
import useLanguage from "hooks/useLanguage"
import useApi from "hooks/useApi"

import moment from "moment"
import Text from "components/atoms/Text"

/**
    @param {} props
*/
const CalendarModalBody = (props) =>
{

    const { event } = props

    const date = event?.start ? moment(event.start).format("YYYY-MM-DD") : ""

    const api = useApi().auctions
    const { lang } = useLanguage()

    const { data, isLoading, isValidating } = useSWR(
        ["notices-randomDay", lang, event?.title, date],
        ([key, language, type, date]) => {
            if (date && type)
            {
                return api.notice(language, type, date)
            }
        },
        {
            revalidateOnFocus: false,
        }
    )

    return (
        <div>
            {
                isLoading
                ? <div>loading</div>
                :
                    data?.data && data?.data?.length
                    ? data.data.map(
                        (item, idx) =>
                        {
                            return (
                                <NoticeListCard
                                    key={idx}
                                    {...item}
                                />
                            )
                        }
                    )
                    : <Text>No data</Text>
            }
        </div>
    )
}
export default CalendarModalBody
