import React, { createContext, useState } from 'react';
import useUpdateEffect from 'hooks/useUpdateEffect';

export const LanguageContext = createContext({
    lang: "",
    setLang: () => {},
})

export default function LanguageContextProvider(props) {

    const [ lang, setLang ] = useState(window.localStorage.getItem("lang") ?? "cn")

    useUpdateEffect(
        () =>
        {
            window.localStorage.setItem("lang", lang)
        },
        [lang]
    )

    return (
        <LanguageContext.Provider
            value={{
                lang, setLang
            }}
        >
            {props.children}
        </LanguageContext.Provider>
    )
}
