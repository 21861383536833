import { Button } from "react-bootstrap"
import NoticeListCard from "../NoticeListCard"

/**
    @param {} props
*/
const NoticeMiniListCard = (props) =>
{

    return (
        <>
            <div className="d-md-block d-none">
                <div className="card mb-2 hover-size">
                    <div className="card-body">
                        <div className="d-flex flex-column">
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                <span className={`fw-bold fs-6 ${props.diff.startsWith("-") ? "text-danger" : "text-success"}`}>
                                    {props.date}
                                </span>
                            </div>
                        </div>
                        <div className="d-flex flex-column" style={{ lineHeight: "1rem"}}>
                            <div>
                                {props.title}
                            </div>
                            <div className="text-muted">
                                {props.type}
                            </div>
                        </div>
                        <div>
                            {props.quantity}
                        </div>
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <span className="fw-bold fs-6">
                                {props.price}
                            </span>
                            <Button variant="primary" size="sm" className="ms-auto" as="a" target="_blank" href={props.other}>
                                See details
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-md-none d-block">
                <NoticeListCard {...props} />
            </div>
        </>
    )
}
export default NoticeMiniListCard
