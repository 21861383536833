import React, { isValidElement, useMemo } from 'react';

import css from './style.module.css'
import Text from 'components/atoms/Text';
import { isDOMTypeElement } from 'utils';

const StepWay = ({ activeStep=0, steps=[] }) => {

    const displaySteps = useMemo(
        () =>
        {
            return steps.map(
                (element, index) =>
                {
                    return (
                        <div key={index} className={css.step}>
                            <div className={`${css.icon} ${activeStep === index ? css.activeIcon : '' } ${activeStep > index ? css.activedIcon : '' }`}>
                                {
                                    isValidElement(element.icon)
                                    ? activeStep > index ? <i className={'fas fa-check'}/> : element.icon
                                    : <i className={ activeStep > index ? 'fas fa-check' : element.icon}/>
                                }
                                <br/>
                            </div>
                            <div>
                                <Text>{element.name}</Text>
                            </div>
                        </div>
					)
                }
            )
        },
        [steps]
    )

    return (
        <>
            <div className='position-relative d-flex justify-content-center'>
                <hr className={css.middleLine} style={{ width: "50%" }}/>
            </div>
            <div className={css.row}>
                {displaySteps}
            </div>
            <div className={css.content}>
                {steps[activeStep].component}
            </div>
        </>
    );
}

export default StepWay;
