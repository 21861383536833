import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import 'react-loading-skeleton/dist/skeleton.css'

import App from 'App';
import AuthContextProvider from 'contexts/AuthContext';
import LanguageContextProvider from 'contexts/LanguageContext';
import IntoContextProvider from 'contexts/IntroContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <LanguageContextProvider>
                <AuthContextProvider>
                    <IntoContextProvider>
                        <App />
                    </IntoContextProvider>
                </AuthContextProvider>
            </LanguageContextProvider>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
