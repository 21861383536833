import { Button } from "react-bootstrap"

/**
    @param {} props
*/
const NoticeListCard = (props) =>
{

    return (
        <div className="card mb-2 hover-size">
            <div className="card-body">
                <div className="d-flex flex-row justify-content-between">
                    <div className="d-flex flex-column">
                        <div className="d-flex flex-column"  style={{ lineHeight: "1rem"}}>
                            <div className="fw-bold">
                                {props.title}
                            </div>
                            <div className="text-muted">
                                {props.type}
                            </div>
                        </div>
                        <div className="d-flex flex-column mt-2" style={{ lineHeight: "1rem"}}>
                            <div className="fw-bold">
                                {props.price}
                            </div>
                            <div className="text-muted">
                                {props.quantity}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-column justify-content-between">
                        <span className={`fs-5 fw-bold ${props.date == props.today ? "" : props.diff.startsWith("-") ? "text-danger" : "text-success"}`}>
                            {props.date}
                        </span>
                        <Button variant="primary" size="sm" className="ms-auto" as="a" target="_blank" href={props.other}>
                            See details
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default NoticeListCard
