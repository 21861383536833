import Text from "components/atoms/Text"
import { Badge } from "react-bootstrap"
import { moneyFormat } from "utils"
import { CUR } from "utils/const"

/**
    @param {} props
*/
const SupplyListCard = (props) =>
{

    const date = props.productCode.split("-")[1]
    const year = date.slice(0, 4)
    const month = date.slice(4, 6)
    const day = date.slice(6, 8)

    const strDate = `${year}-${month}-${day}`

    const dugaar = props.productName.split("-")[1]

    const aprice = props?.price?.replace(CUR[props.currency], "")?.trim()
    const sellprice = Number(props.sellPrice)

    let percent = "??"
    let textColor = ""
    if (aprice && sellprice)
    {
        const apriceNumber = Number(aprice)
        const huwi = (((sellprice * 100) / apriceNumber) - 100)
        if (huwi > 0) {
            textColor = "success"
        }
        else if (huwi === 0)
        {
            textColor = "success"
        }
        else {
            textColor = "danger"
        }
        percent = `${huwi.toFixed(2)}%`
    }

    return (
        <div className="card mb-3">
            <div className="card-body">
                <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                        <Text>Арилжааны дугаар:</Text> <h5 className="mb-0 ms-1">{dugaar}</h5>
                    </div>
                    <div>
                        {strDate}
                    </div>
                </div>
                <div className="d-flex flex-column" style={{ lineHeight: "20px" }}>
                    <span className="fw-bold fs-6">{props.seller_name ?? props.sellerName}</span>
                    <span className="text-muted">{props.type_name ?? props.coalType_display}</span>
                </div>
                <div className="d-flex align-items-center my-1">
                    {/* <div>
                        <h3 className="mt-1 mb-0">
                            {aprice ?? "??"}{CUR[props.currency]}
                        </h3>
                    </div>
                    <div className="">
                        ~
                    </div> */}
                    <h2 className="mb-0">
                        {props.sellPrice ? moneyFormat(props.sellPrice) : "??"}<span className="ms-1">{CUR[props.currency]}</span>
                    </h2>
                    <div className={`d-flex align-items-end ms-3 ${textColor}`}>
                        <h4 className="mb-0"><Badge bg={textColor}>{percent}</Badge></h4>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                    <div className="text-muted">
                        {props.quantity}
                    </div>
                    {/* <div>
                        {aprice ?? "??"}{CUR[props.currency]}
                    </div> */}
                </div>
            </div>
        </div>
    )
}
export default SupplyListCard
