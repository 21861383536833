import Title from "components/atoms/Title"
import Footer from "components/molecules/Footer"
import Headers from "components/molecules/Headers"
import SecondaryHeader from "components/molecules/SecondaryHeader"
import NoticeNew from "components/organisms/auctions/NoticeNew"
import Notices from "components/organisms/auctions/Notices"
import NoticeCalendar from "components/organisms/auctions/Notices/NoticeCalendar"
import TodayNotice from "components/organisms/auctions/Notices/TodayNotice"
import SupplyTable from "components/organisms/auctions/Supply/SupplyTable"

/**
    @param {} props
*/
const AuctionPage = (props) =>
{
    return (
        <div>
            <Headers />
            <SecondaryHeader
                title={"Pages"}
                links={[
                    {
                        name: "Үндсэн",
                        to: "/exchange"
                    },
                    {
                        name: "Notices",
                        to: "/exchange/notices/"
                    },
                    {
                        name: "Supply",
                        to: "/exchange/supply/"
                    },
                    {
                        name: "Заавар",
                        to: "/exchange/laws"
                    }
                ]}
            />
            <div className="container mt-5 mb-5">
                <div className="row">
                    <div className="col-12">
                        <Title text={"Notices"} />
                        <div className="row">
                            <div className="col-12 col-lg-4">
                                <NoticeCalendar />
                            </div>
                            <div className="col-12 col-lg-8 mt-md-0 mt-3">
                                <NoticeNew />
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col-12 col-lg-6">
                                <NoticeCalendar />
                            </div>
                            <div className="col-12 col-lg-6 mt-3 mt-lg-0">
                                <div className="row">
                                    <div className="col-12 mb-3">
                                        <TodayNotice />
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <Notices productType={1} />
                                    </div>
                                    <div className="col-12 col-md-6 mt-3 mt-md-0">
                                        <Notices productType={2} />
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <hr />
            <div className="container mt-5 mb-5">
                <Title text={"Supply"}/>
                <div className="row">
                    <div className="col-12 col-md-12">
                        <SupplyTable />
                        {/* <Supply productType={1} /> */}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default AuctionPage
