import Skeleton from 'react-loading-skeleton'

/**
    @param {} props
*/
const InputFormLoader = (props) =>
{

    const { inputCount } = props

    return (
        <div>
            <Skeleton count={inputCount} height={32} enableAnimation  duration={5}/>
            <div className='text-center mt-3'>
                <Skeleton width={62} height={32} enableAnimation/>
            </div>
        </div>
    )
}
export default InputFormLoader
