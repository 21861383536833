import Text from "components/atoms/Text"
import useApi from "hooks/useApi"
import useLanguage from "hooks/useLanguage"
import { Button, InputGroup, Table } from "react-bootstrap"
import useSWR from "swr"

import Form from 'react-bootstrap/Form';
import { useCallback, useEffect, useRef, useState } from "react"
import ReactDatePicker from "react-datepicker"
import useMobile from "hooks/useMobile"
import moment from "moment"
import { BsX } from "react-icons/bs"
import NoticeListCard from "../Notices/NoticeListCard"
import { NavLink } from "react-router-dom"
import CMPageLoader from "components/atoms/Loader/CMPage"

/**
    @param {} props
*/
const NoticeNew = (props) =>
{

    const { ispage=false } = props

    const filterRef = useRef({})

    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [ isToday, setToday ] = useState(false)

    const { isMobile } = useMobile()
    const api = useApi().auctions
    const { lang } = useLanguage()
    const [ filteredData, setFilteredData ] = useState([])

    const { data, isLoading, isValidating } = useSWR(
        ["notices", lang],
        ([key, language]) => api.notice(language, "all"),
        {
            revalidateOnFocus: false,
        }
    )

    useEffect(
        () =>
        {
            if (isValidating === false)
            {
                setFilteredData(data?.data ?? [])
                filter(filterRef.current)
            }
        },
        [isValidating]
    )

    const filter = useCallback(
        (filters) =>
        {
            if (Object.keys(filters).length)
            {
                let filtered = data?.data
                Object.values(filters)
                    .map(
                        (f) =>
                        {
                            filtered = filtered.filter(f)
                        }
                    )
                setFilteredData(filtered)
            }
            else {
                setFilteredData(data?.data ?? [])
            }
        },
        [data?.data]
    )

    const handleRange = useCallback(
        (update) =>
        {
            const start = update[0]
            const end = update[1]
            if (start && end)
            {
                filterRef.current["range"] = (e) => {
                    return new Date(e.date) <= end && start <= new Date(e.date)
                }
                delete filterRef.current['today']
                filter(filterRef.current)
            }
            else if (!start && !end)
            {
                delete filterRef.current['range']
                filter(filterRef.current)
            }
            setToday(false)
            setDateRange(update);
        },
        [data?.data, filterRef.current]
    )

    const handleToday = useCallback(
        (isToday) =>
        {
            if (isToday)
            {
                delete filterRef.current['today']
                filter(filterRef.current)
                setToday(false)
            }
            else {
                const today = moment().format("YYYY-MM-DD")
                filterRef.current['today'] = (e) => {
                    return e.date +"" === today +""
                }
                delete filterRef.current['range']
                filter(filterRef.current)
                setToday(true)
                setDateRange([null, null]);
            }
        },
        [data?.data, filterRef.current]
    )

    const handleType = useCallback(
        (event) =>
        {
            filterRef.current["type"] = (e) => {
                    if (event.target.value === "all")
                    {
                        return true
                    }
                    else {
                        return e.product_type_display +"" === event.target.value +""
                    }
                }
            filter(filterRef.current)
        },
        [data?.data, filterRef.current]
    )

    if (isLoading)
    {
        return <CMPageLoader />
    }

    return (
        <div>
            <div className="d-flex flex-md-row flex-column">
                <div>
                    <span className="fw-bold fs-6 mb-3 mb-md-0">
                        <Text>Search</Text>:
                    </span>
                </div>
                <div className="ms-md-3">
                    <div className="form-group">
                        <Form.Label>
                            <Text>Төрөл</Text>:
                        </Form.Label>
                        <div className="d-flex">
                            <Form.Check
                                onChange={handleType}
                                type={"radio"}
                                className="me-2"
                                name="type-radio"
                                value={"all"}
                                defaultChecked
                                id={`all-radio`}
                                label={<Text>Бүгд</Text>}
                            />
                            <Form.Check
                                onChange={handleType}
                                type={"radio"}
                                className="me-2"
                                name="type-radio"
                                value={"coal"}
                                id={`coal-radio`}
                                label={<Text>Coal</Text>}
                            />
                            <Form.Check
                                onChange={handleType}
                                type={"radio"}
                                className="me-2"
                                name="type-radio"
                                value={"iron"}
                                id={`iron-radio`}
                                label={<Text>Iron</Text>}
                            />
                        </div>
                    </div>
                </div>
                <div className="ms-md-3">
                    <div className="form-group">
                        <Form.Label>
                            <Text>Огноо</Text>:
                        </Form.Label>
                        <div>
                        <div className="d-flex">
                            <Button onClick={(e) => handleToday(isToday)}>
                                Today {isToday ? <BsX /> : null}
                            </Button>
                            <ReactDatePicker
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={handleRange}
                                withPortal={isMobile}
                                className={`form-control`}
                                isClearable={true}
                                dateFormat={"yyyy-MM-dd"}
                                monthsShown={2}
                            />
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                style={ispage ? {} : {
                    maxHeight: "500px",
                    overflowY: "auto"
                }}
            >
                <div className="d-block d-md-none">
                    {
                        filteredData.length
                        ? (
                            filteredData.map(
                                (item, idx) =>
                                {
                                    return (
                                        <NoticeListCard
                                            key={idx}
                                            {...item}
                                        />
                                    )
                                }
                            )
                        )
                        : (
                            <div className="d-flex justify-content-center">
                                <Text>{isValidating ? "Loading" : "no result"}</Text>
                            </div>
                        )
                    }
                </div>
                <Table striped bordered responsive className="d-none d-md-block">
                    <thead>
                        <tr>
                            <th><Text>№</Text></th>
                            <th><Text>ХХК</Text></th>
                            <th style={{ width: 110, minWidth: 110, maxWidth: 110 }}><Text>Огноо</Text></th>
                            <th><Text>Төрөл</Text></th>
                            <th><Text>Тоо ширхэг</Text></th>
                            <th className="text-end"><Text>Үнэ</Text></th>
                            <th className="text-center"><Text>Файл</Text></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            filteredData.length
                            ?
                                filteredData.map(
                                    (item, idx) =>
                                    {
                                        return (
                                            <tr key={idx}>
                                                <td>{idx + 1}</td>
                                                <td>{item.title}</td>
                                                <td style={{ width: 110, minWidth: 110, maxWidth: 110 }} className={`${item.today === item.date ? "text-success" : item.diff.startsWith("-") ? "text-danger" : "text-success"}`}>{item.date}</td>
                                                <td>{item.type}</td>
                                                <td>{item.quantity}</td>
                                                <td className="text-end">{item.price}</td>
                                                <td className="text-center"><a href={item.other} target="_blank"><Text>Үзэх</Text></a></td>
                                            </tr>
                                        )
                                    }
                                )
                            : (
                                <tr>
                                    <td colSpan={7} className="py-5 text-center">
                                        <Text>{isValidating ? "Loading" : "no result"}</Text>
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
            </div>
            {
                ispage
                ? null
                : (
                    <div className="d-flex justify-content-center mt-1">
                        <NavLink to={"/exchange/notices/"}>
                            <Button variant="link">
                                <Text>Show all</Text>
                            </Button>
                        </NavLink>
                    </div>
                )
            }
        </div>
    )
}
export default NoticeNew
