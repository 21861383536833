import { getFlagEmoji } from "utils"

export const CENTER_OF_MAP = [46.8625, 103.8467]
export const FIRST_ZOOM_LEVEL = 5.5
export const MAX_ZOOM = 18
export const MIN_ZOOM = 3

export const DATETTIME_FORMAT = "yyyy-MM-DD HH:mm:ss"

export const LAYER_NAME = {
    "shatah": "Шатах ашигт малтмал",
    "notmetal": "Метал бус",
    "raremetal": "Газрын ховор элемент",
    "metal": "Метал"
}

export const DATA = {
    "Метал": [
        {
            "name": "Орд",
            "chidlren": [
                "Орд"
            ]
        },
        {
            "name": "Өнгөт метал",
            "chidlren": [
                "Хөнгөн цагаан",
                "Зэс",
                "Хар тугалга",
                "Цайр"
            ]
        },
        {
            "name": "Үнэт метал",
            "chidlren": [
                "Мөнгө",
                "Никель",
                "Цагаан алт"
            ]
        },
        {
            "name": "Хар метал",
            "chidlren": [
                "Хром",
                "Төмөр",
                "Манган",
                "Титан"
            ]
        },
        {
            "name": "Холимог метал",
            "chidlren": [
                "Холимог метал"
            ]
        },
        {
            "name": "Ховор метал",
            "chidlren": [
                "Берилли",
                "Молибден",
                "Цагаан тугалга",
                "Вольфрам"
            ]
        },
        {
            "name": "Бусад",
            "chidlren": [
                "Натри",
                "Мышьяк",
                "Висмут",
                "Кобальт",
                "Мөнгөн ус",
                "Магни",
                "Сурьма",
                "Бор"
            ]
        }
    ],
    "Метал бус": [
        {
            "name": "Орд",
            "chidlren": [
                "Орд"
            ]
        },
        {
            "name": "Барилгын түүхий эд",
            "chidlren": [
                "Өнгөлгөөний\n                                    чулуу",
                "Керамзитын\n                                    түүхий эд",
                "Өрлөг-Зуурмагын\n                                    түүхий эд",
                "Керамикийн\n                                    түүхий эд",
                "Цементний\n                                    түүхий эд",
                "Хэвний түүхий\n                                    эд",
                "Перлит",
                "Шохойн чулуу",
                "Эрдсийн будаг",
                "Гөлтгөнө",
                "Битум"
            ]
        },
        {
            "name": "Өнгөт ба үнэт чулуу",
            "chidlren": [
                "Өнгөт чулуу",
                "Үнэт чулуу"
            ]
        },
        {
            "name": "Түгээмэл тархацтай",
            "chidlren": [
                "Барилгын чулуу",
                "Шавар",
                "Элс"
            ]
        },
        {
            "name": "Үйлдвэр техникийн эрдэс",
            "chidlren": [
                "Болор",
                "Гялтагнуур",
                "Офиокальцит",
                "Хайлуур жонш",
                "Шаазан-ваарын\n                                    түүхий эд",
                "Шилний түүхий\n                                    эд",
                "Бари"
            ]
        },
        {
            "name": "Химийн үйлдвэрийн түүхий эд",
            "chidlren": [
                "Фосфор",
                "Хүхэр",
                "Бал чулуу",
                "Барагшун",
                "Давс",
                "Исландын жонш",
                "Мөсөн шүү",
                "Тальк",
                "Фосфорит",
                "Цеолит"
            ]
        }
    ],
    "Шатах ашигт малтмал": [
        {
            "name": "Орд",
            "chidlren": [
                "Орд"
            ]
        },
        {
            "name": "Нефть",
            "chidlren": [
                "Нефть"
            ]
        },
        {
            "name": "Чулуун нүүрс",
            "chidlren": [
                "Чулуун нүүрс"
            ]
        },
        {
            "name": "Хүрэн нүүрс",
            "chidlren": [
                "Хүрэн нүүрс"
            ]
        },
        {
            "name": "Хүлэр",
            "chidlren": [
                "Хүлэр"
            ]
        },
        {
            "name": "Занар",
            "chidlren": [
                "Занар"
            ]
        }
    ],
    "Газрын ховор элемент": [
        {
            "name": "Хөнгөн",
            "chidlren": [
                "Хөнгөн"
            ]
        },
        {
            "name": "Хүнд",
            "chidlren": [
                "Хүнд"
            ]
        }
    ]
}

export const socialLinks = {
    mail: "info@comexpartner.mn",
    fb: "https://www.facebook.com/profile.php?id=100094103191459",
    phoneNumber: "+976 9908 5997",
    address: "Seoul street 7/1, 2nd Khoroo, Sukhbaatar District, Ulaanbaatar, Mongolia"
}

export const LANGUAGES = {
    "en": {
        "name": "English",
        "flag": "/img/country/en.svg",
    },
    "cn": {
        "name": "中国",
        "flag": "/img/country/cn.svg",
    },
    "mn": {
        "name": "Монгол",
        "flag": "/img/country/mn.svg",
    },
}

export const ICONS = {
    "Aluminum": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAApUlEQVQ4je3UsQ3CMBCF4f+UrIHEBoxAwQpgz4BIJIYJriipTMMEiAFSsFB0KajtsxUaJF579z5ddS1fTvsH0YgHjoX9tzjOWRBlhbAtwAZgbV5YmEEcvUYey0HhIgf61LgO/GBdbqUcVIK4PFYOKkE8p5JVG6zAbLASy4NKwNHha7g0+KThyp2Nxmy/scGGFxMwsTPPEW4mKHtGYDSxRH7wfS3NDMuKIkY6pp5aAAAAAElFTkSuQmCC"
    },
    "Andalusite": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABe0lEQVQ4je3SvUtCURjH8e+5KlbmUlBiUTSEoNBUSxTUqGVTjv0BrkUQQS0N0dQf0NDSEgYOha4FLUEvUy/UEBFdpbIhTE299zSYEdfrC9HQ0G87zznP5/AcjpVfjvUfrMjUE85iAQ86RZnlMt7P+4/AwCMuUSCs5XkWgmthwSkdTE88oL3lWd3vI9cwGFTpkUVm03kWDI07AZVep52lsVtWzFBTUArmm1+Y2/ORN+7F3Nz5U6w5IQys1wWn7hnQJEcRE6yceDuvQRXNf4Pd+KYVoFQYUiAVUBn8XndYuYp0kC6vNbi2teAFzmqCKNilJKoYyrkio8Dh1zFBThfYjO1mb3huUquIAK9VY7su2OTiMJPgQoC3GhaSWLIJuqPdpOqCEYEWVJmRcAC0VmgSkVFZVixsmF1m+m123ZwGVMYV2AI8n+XOYJJJmWAYnc3dLm4bBgFibo5DEl8uyYgOXiRtQudkz81itZ6aIJTGpzT6Qa1zDYM/yd8HPwCejXprj70qZQAAAABJRU5ErkJggg=="
    },
    "Antimony": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAw0lEQVQ4jcXRsQ3CQAxA0U+4ghXCEszDCIgNKEJgCKClYAImYBDKcDNQIFOERCTcnR2BxC8t60mWHT/O/QX0UAhcp3D6GqygFFgDjxugoUmwgnJUYwBj4KihUbCHNaloEIxgJvQDVDAV7YBGLIm24EAsirZgBiKwusPeqjnIHOykO6vLYeOhmMA8N6ACmYeDwDl48gvdeig8LFJogwGX5FMsaAoLgilUw6JgCLVgSbCHLj3MNEwF31ELZgIb1LJnBof0BPNdWpeFSTU8AAAAAElFTkSuQmCC"
    },
    "Arsenic": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA+0lEQVQ4je3UP0oDQRQH4G9NGosgiE1W09l5BPEa1naJdoEonkA9gX+uo41FUDsLxS4bMGCbYEjWxoUQdzOL2gj+2uF9vJn3mKpfTvUf1Gc3JS5RG+EmprsQTNnGY8xlkTSg9k4nYkcJ8Cnlts9+nYsibMpZheb8ee4bbvDQI5pHB9TGHE45bTBMcmoLh7LOfQ99Duqcf2JHE04aDIvqFk45QxPaY1ZDWBCEZV5G7KU8h7Ag+MbKiM6E4wpbCc2Yq2+BM1h2zW7CNITmgjkYiLnrkSa0ivb0CxgVYFlmBtUq2+EaXis08/YsS8rmEtdBMKa9wAnmD35fP80H9VpYc6fUJ3sAAAAASUVORK5CYII="
    },
    "Asbestos": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAY0lEQVQ4je3SQQqAMAxE0V/owdqT6810U0GkyTRYutFZZfWYkGQmJ//gGvCADSBBfQ02rFyzQl3wjrUUhZpgBxtCu6CDSXTNlRNU0XIPrSxQE3NBA3UxCT7QOY89CoXASD4Ini0sH1PssF5UAAAAAElFTkSuQmCC"
    },
    "Barium (Barite)": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAzElEQVQ4jcXUsQ2CQBSA4Z9AYmd/WDKIE9iZWLCAOoSKS1hbGOdxAq1gBQsDFoAB7rh7KIl/9YrLl1e8XMDIBX8BU4g9iBQcfwZTiIE5cMtg50KtYI0pWHuQZ7Bxob1gFwNQcHKhRtCE1blQDbRhErQFSjAX+gGr01i8YPuA6d2mVT3hOinRvYJE27CApQ8rX4A1yyusBYZwScvxDIjNApIZHDTwG7SLaeAQ1IQZQQnah/WCNtSGWUET6sKcYBP1IArH+L5qVPJODA7pDWtaUm4H5fCRAAAAAElFTkSuQmCC"
    },
    "Bentonite": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA4UlEQVQ4jcXUPQ4BQRiH8WeC6PRL6Qp6BzAShUSxF8AhfF1CrRAH2HUPTkC1ewWJjVWwsszszPhIPPWbX/Jv3jI/rvwXUEb4QtAMPBZfgzLCB9op7LsxExtqBDOs5TGcCS6dmJENLQRfMYCtx9KGakEdlmVDFdCEuaBPoAtmQx+gjPARdM8J492RWu9g4u6d2CRVRjJmGnrM1ckp/UqJQVJywPJdbtgTGNZZywiAFeBOpszDBjMF/Ah9wRTwLVSDaUEntAArBI2oATOCWtSCWcE8KgTNoP6D95WhLnfO4DtdAfnEdggWreaoAAAAAElFTkSuQmCC"
    },
    "Beryllium": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABLUlEQVQ4je3UTStEURgH8N81Y2aUhSI1XhZ2ZEHyYSz4AGKPkqwoC9nK1pewUygrRchbDdElCwtR0nAtZtQYY+6QlfzrbJ7nnN9ZnJekX07yH/yUHJkUPXXU5znr5O5HYI5MmlG84ARPSYZC2gNWspzXDBax6TQLzdyXtNYjEiEzl6x2cloTmGIyYrkMAwEvEbPXzGEqFjwlDe1cVtqsiEY3bIUMtLFTFWygJ2CvtHZLY57+0lpEBgPiwAT1EY+ltTzd2CgDKRzah3wCUxw/M4K18l6FHMaCzdxf05Ij08VTFewoy1YsCAmW08wfMNHLc4UpDxgOCnc0HmzlJmSpicWQ7SLwnkOMlJ9uVRDauMD4FX0Bg3WMRexn2Qx4/Wpd7FvuYFdh1JQ/8H19N29Y60xOpqRstAAAAABJRU5ErkJggg=="
    },
    "Bituminous Rock": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABRElEQVQ4je3Rv0uCQRzH8ffJAw3VEhF6LkVTBC3u4Wb4PEpbkw0N7f0jQUNDS4tLSxDiI7mJS5NBNDSFFfhVauwnpF1DPmD0PD5P1tDQZzru7vvic5zFL8f6Bz8lbbAm2+waGAeIwWlJsz0SmBWWY0LCKDa9PQOzOeGuB/WK5joyaAsZ4KacpO4IBwPgQ1lTtFvkV9uoowRXoWD2ljhdcDUXQe3dJKWcUIAIoOqxp2DMEbZ8rJQjHPfXE/kOU6U4O8NBwzywGFBuGsgAmI+7l6ENf5ovoAGlIg4beAwFgXUDT96nOIIZOKuWNSsATou1+wSHoaCradgtNtJNmrU5Xvya2cLCG7Rrim6UhnQ0xbhQsIUTHywDUNFU/WZ9wYbiFdi3hZRSnBv6zzY8G4uzygwdv7lA0IuraQBLw+58Cxwlfx98BxSDYt2loBKdAAAAAElFTkSuQmCC"
    },
    "Boron Compound": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAsUlEQVQ4je3UsRHCMAyF4V84S3BpKCkzBBXxJMTsQkdDSUNvjpYdWAHDFCCqNBxECbjhjtfrO50suSBzij+Iv7BSmFiFojhgG0t2nSBQiXCKY5Zd4PxM5Rwzs0MAlOCvYKGv8n6GH6Ldj6IEn5BYEvKAAELjE/RF+62N0NQJ2Zc0eUBAhEWdwEIHLXYfdPCltKiDjeYAW/SuTAUONqisZUS0VbgpRxN8vs2h+cHv69s8AGz/LiOXa6KyAAAAAElFTkSuQmCC"
    },
    "Calcite": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAByklEQVQ4jdWUv2sUQRTHPzO7wePARklINjm1sDKNNoJ/Qi638SzSpBEEQRCViIWihfirsFCUoIWIVYhgE+5HUqURbdKLTURMvNnDqCkUQ+LOjIURdtbMmcIiedXw/c58eO/x3oT85wh3LrD8iV6Rcl3AXmAQWJOSN9qSGLg728fyloFxi3PmJ8ek4HIgkKnlPfCl1suR6hJHdchEeZHpmX1M/RNY/si4sRSbA4wBVBMOZP3pEvOjlrEfbR4Ot7DNfp57gWXFfgmHGxEnfa0AeCHQo5bzq4rJoYS5bPkOUMLNlS7OdoJlobFiIrBcBK5sChQCXnfzbStAgHrEqxHFmazmAK1huKL4nNVSi9w47sl7AAbeeYEICkDRk5Dg9wjlxUUvUAgaOuBCVgs0JWuZB76akME8UGru+zO0rNtuVmYFa3+kakIh3XBnemhnrx//wKF0l6s5QAN3woQH4DbaF2kX14x1K3KAzYi3cQtVUZxuRDzpBBtS3JKWuWbkruBfm1Lv50asuFpRPP7ex7hbEJxYoEcXuYfhZW2Ap/n3m+5yPeL2yBIHd7d5pC0FYBlYjVs8SwPWteRSvp8dgQC1EgvAKZ/vi+3/wf4C/WeXSVEaydoAAAAASUVORK5CYII="
    },
    "Chromite": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAXElEQVQ4jWNhoDJgGTWQ4TkDQ/x/BgZHYjT/Y2Dol2FguIjXwP8MDJYMDAzxRLpmDQMhAykFowaOGjgoDfzHwNDHzMCwghjNfxgYLhM0UIaB4RYDBJMFhmAYUgoAo/8OPw2JV9wAAAAASUVORK5CYII="
    },
    "Clay": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAb0lEQVQ4je3SMQ6AIAyF4d+ES3Ab2DkuO96GY+BkohFaiIRF39Tpy2taw+SYH1wDhkwCiBb/GgyZRMGds4aK4BUDoOA0tAk+sE60CjaxDnTNlaPFiy039qGVRVTARLCKKpgK3lAmPXYvNASO5IPgASt6Nxhn79fiAAAAAElFTkSuQmCC"
    },
    "Coal": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA1ElEQVQ4je3SPw4BURDH8e/sE83Gn06WxAUcQqlB4wIKxxJ3EPYOToCWZAWdVuKNgiVrk8XuFgq/6k3m5TNTTIGcU/iDkfS31K1hEtbisJ95DFODWqSJpfOolUOmDeMTuOQLfpAY2AtYK5TuG0UjVLoBAYAIxipzv8EoEVSoItQA9BUEF8ENZznO7V8imDVxUDC5gkZoW6EMwIWWCuPHLGGHMAjrs2HzFpx6LMN3bw/YZ08tzBssvtowaxJBazkJHIXbQatwygT6dVYQP43UYJr8PngF3CcyNHZFN04AAAAASUVORK5CYII="
    },
    "Cobalt": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA0ElEQVQ4jcXOvQ2CQBjG8T+GxNYWHMZRbB3BCowFI6gLEAdwHqONXmEcQBM4C8GAd9y9qIlP+X788oT8OOFfQAVpCcEYFl+DChIN9wCyE+BDnaCCBLjcYDuELIDUh3aCNRbB+gCjeu5DrWATs+1dqAH6MB/aAqWYC32BFXaVYk1UQRnBsgVq2AMTDYMAyh5mUf22G8aQn59NNxpmQrQApjHkBvgBamAG2AO1YlbQhh6FWCf4joYwl2BOsImGsNICzAs2UWDnw0RgjUruxGCfPABfwl53d4b7OgAAAABJRU5ErkJggg=="
    },
    "Copper": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABHElEQVQ4je3UvyuEcRwH8Nf9sFgMkrurs9osRBmYbKTsSgb/w2Wx8BcYTEopgzKbbaebriTJguc5UkY61zkDV7jnPA9ZlHd9l8+3z6vvZ/h8s3452X/wQ+7IN1jCOE7xgMMClW+DIYsNttD7VirlOb9hIWC2wHqKRiIwYA47SL2vp2hiP6Qcsoq1WLBFT43Nz9j7FLiscRIy9nn8DjBgKs1QN6ydHAc1SuLADMOtOM3r+GFEPWrkpwRe13SATY4zCRrv6avzGAsWqYYcYeorsM5Klt1YEDIsNymjP+o+YP6Z6wFqicBBLm6ZaLLdfmmavoDJNDOo5NmL6u26KYNcYPqKkQzjLUZbnOXYiNqQWLCdIlWvJ1H++Pf1k7wAarVF2eeyOHAAAAAASUVORK5CYII="
    },
    "Copper + Other Metals": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABEUlEQVQ4je3UoUpDURgH8N8dE9wDqLtMu9qFFZOmFZvBLBh8A4NgMfoCiq8gCwYRk8GxgcGgVRF3hlUExXFnGIibd7tjWAT/cMo5nB/f9x04eb+c/D/Ykw75FstYSHjLUYu5HQsMVAJlnOe4jrjqdPcvsBlzPzIYWE94L7ELTea/Vb2CWqCchv4Anym2mS1xMKSzGRxhNRNsszHB4RDsq9LAYv9M01ouTPGSBULSnXEmOHJyTPbvpYFRh1xEMoJ5lwlGnAXWcJKBPRS5zARjGoHKI/U5ngZgnYjtiHYmCEX2W+w0uYmpht7jV2zFnKbdTQUjPrAXWArsoIAq6nmOp2kNqHz4K8c0dNfI+ePf1zj5BNbMQskUBJuaAAAAAElFTkSuQmCC"
    },
    "Corundum": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABSUlEQVQ4je3SO0vDUADF8X/SQqU6amnTOlQRFBzcnAoOgsQ0QwfRxUHwc4ngIih2SB8WHSp2cBHdOwjaJFUHER/4aBMnBe29bRQHB894D/d3z3DD/HLC/+BHTIdlD3xRp/jcFpJYgUHDYQKVejHOkajP2iwuXDGwFeM+EKgqTFtx1mXrXY0dzWUJ2OgJGjazfos9GQZwrPCquZybTdJWnDMpqNeJAIOFYexuIICV4CBrswJdQDVKznsk3wt7j+dzMt9gqpTiVAh6PjU1SgbYDwIqKpMPCTalC8spGqZNRq8TKY/x3A0zLhlRPS6qCi0pCHD3Qr4/Sg4+v9yRFjNWkrWvxx1gNc2T4XAzd02iMoQrXOeQ8T1qok74D4saFdNhVW+wK+pVhVErxWFgEKAdphBqMy7q+kJsy+5JwVKMJtCU9d8Gf5q/D74BOuxet58W5PgAAAAASUVORK5CYII="
    },
    "Decorative Rock": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABXklEQVQ4jcWUPU7DQBCFv0WpaKniFNQUJBLpaDD95AYh3IFQIJo4BYqQgIIjwBGyFFREoqVBSNzA61swFN5Y/lUcCYnX2Jq1P72Z3bcd/lidfwFKTMQOKwDbTZ9bAyUmwjDLCpq+iwOUue0RtQaOHDcK1+W6gS+FQwwzcYQYorLjClAcC4Ur72pue0Ti+AYOlgF9SQiN8qhwghIBYSNQEkI0hdkAU+feO+qPHM8KY4mJ8u0XHSp3vrfTOlhey4AzcYz9nBuAMMy5aKNPYCAJ4fqfDOjbBdMaBoZXlAE/hFAC2i4rca1RqZRjgPUZLQBzH4VbIHfLhTLwCZiUd65OfkRHUJx5AWgDzsUxwTCThNWGzbkFKieiLikPwAXKm8TViGWRVADeNybFBkxHCarK1Ecsy7M4j0m1sEE1nrVZXna5lASLcg/sAfvrNQMfqrxsdTlANuhh03qT/ueC3Ua/Fql65+8AXOAAAAAASUVORK5CYII="
    },
    "Diatomite": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABQUlEQVQ4jdXSzysEcRjH8fczlqyDi9Ds5i/gJjcHdyOlxNXZQUQOak3ZtEflIIctV83JMnPZi5OD5ihO3MykNiVqS3a+TmLY+cVePMfn83xf32/fnhwdrtz/AWc8JhWUswICzd4c89YQLyFwXOfC9RkAxrKACiofWAg0hcDw2URhZ/AetG52vzZCf3im4xge58BUyteVaoM8R4IAAWxocAlIgnfVp1P93vwBOgVcw+cYxWKcpinWLKGVCAIojS1pMQf0RHhOrUi9XdAWtIe5MzwOgJU28ZuC9YiLohdboKxgCegPBYpDu8hNZvC0QMPwqMDnWgg8IZhRZ2JBgHyLvWYXy0ARIBB2bJ3Gr0FrhOb0PSURqgK3+Uf24+YTQYCJAkeuz2oA29Yor38GTSGY9Vk40blOmk0FAqTFUoNZquPgO8CsVQCGwaHEAAAAAElFTkSuQmCC"
    },
    "Dolomite": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA3UlEQVQ4jcXRwU3DQBCF4R9nkWjBNJFCWBdACYgOONhOighcOVCBTQHc0wJHsyUghKLhQhwn7O6MBRLvNqPRpxmN44/j/gWsArUIr/0lT78G/UArQgPs/BtoaBb0Ay1nNN/lAnjU0CR4gu2jolEwgZnQH6CCqegRaMSy6AjOxJLoYcMCQbhzH9xbtXdHce7YIIfeCPYlqypQf15w/VzqaCsU28ADQhc9GaArWVeB+ipwk0NHDF6yT7GgOSwK5lANS4Ix1IJlwSnqA7fbwFLDVHCKigEzgXvUMmcG5+QLM6+CjbmpvowAAAAASUVORK5CYII="
    },
    "Feldspar": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA6ElEQVQ4jcXOTQ7BUBSG4bfSxtS0LMYG2sQOTC3BqBoDS8AGxALULgxsQJhwB2IB/npNtGn16r1F4pue8z3n2Pw49l9ATzAgxopahF+DviCQkgsWI28POrQU9AVBDEfnzPxWZ4TFQIe+BRNs6TLpbGmkAw2qBLOY8loJWgC1mAbNgcZYCZqCviCQcDLGMqgviBcuwxwoJRugHUpqoUVcgbw/u/kPoyYz7wArwTSU9AzRO9CNmswK4AdoASuAFVAlpgRV6Hpnhr0FX9GrTd8xwErBLOrYjJF6TAtmUWChw4zABDXZMwar5AHUC41PTwk+2AAAAABJRU5ErkJggg=="
    },
    "Fluorite": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABH0lEQVQ4je3OMS/EYBzH8W/7tDltMMgNZ7HYREIs9CzXNCLxAgwWb4CBUSxWgtVkMEkIb8C1jYRIiBdwc5kslruea58aLJdLe+0JE7/x98/zeX4aPxztH/w9cPmJ8VhygYbs7mWHwJ1nbWCwE3FiTrDY28ch2PfceFVOC4P2LU5pjJW0mxgC3WCn5nHm20SFQNVkXwxnr9fLTMoWB8BWLmjfsV0qM5eFASgCxCjrtQcO/QWCvqCiMoLaj/tKoqILBTN3oWux5zyzalSYytYgeufctWjkggBJyG7c5FKY6VvbbwSywmbaLRV0q1w7j9QNk6Xem/yAqMmxP0NYGARAZ6P1wpWqkXTXcZtXz+Io61kmWJ+lAUxnfjgo+N38QfATyHhIjZQvdoUAAAAASUVORK5CYII="
    },
    "Garnet": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAG0lEQVQ4jWNhoDJgGTVw1MBRA0cNHDVwpBoIABArAFHmDwDcAAAAAElFTkSuQmCC"
    },
    "Gemstones": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABSklEQVQ4je3UzyvDcRzH8ef368u2QpSYbxx2lnbwDzgo9P1+S7kOFxcXKUqcVlx2ct9linJQqO+WOGg5OJCDP0BrzPc7Yk0RW2sfB5Hkux+2k7yO7089er/r834r1DnKP+gYw6LDVLmvC6hZBASMAIGawdEHWuUcQ8CpbjMY7SZeE6jkWS40sPLSSbLFJhIUHAclir8C9RR+IXjc7+ISwEgTObOZBsLVgwKJNLOeDDMfJdPLkXbD+FiCtj0f2apAI81UETa3+8h/rcsSoYKbJWCxYlBL0i4E/TGV9e9vpsqVbpHVU/ijPVxUBMqNLCgyIafun3KsNbsIA5NlQc1iAEjserlzAuM+Xg2LHcNiwlTZcASDAvncZlVIbGlW6U9cBCSYG74mdtBL5kfw5BZPk/Q+qiSV4j4zjxuXY4eHXp6h9CaUyx86X5XmDV9pXwd5ItcJAAAAAElFTkSuQmCC"
    },
    "Gold": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAoklEQVQ4je3PMQ4BURDG8f/IxhEUT9xDIXEMZxAU7mL3AiqFOyiUEqewbmEUaETmfS+2UPiaKWbml5mKjlP9QS4w68FIWXY4JDiFoMHcYSp4R3vUGFRisHHYAeP3XjHoUCdYtTD51C8CHeohLKMZGVQwGXRoFEwCn9hCwbJgKRaCDk0S38yCL8zAuwDPBoMrbNt4t3+DfRZMsC69KgS/ze+Dd+I2IoxuTouBAAAAAElFTkSuQmCC"
    },
    "Gold + Other Metals": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAqUlEQVQ4je3QsQ3CQAyF4d9RpghiB2ZgCRoWABoWYAM6yCy00FMwAhI6MkVyFFBESGf7AgUF7izbn55c8uUq/yABZgWMPccRjhWcVRCYt7DRIAEBtsWz1UGBdgQXJZU0UANT4PA+z/phD1ukdtxgBLnDXhTMDfawpbXrAr2YCwxQezETDBnJTPCFrXKwJNjALg7AUuA1wgQ4Wccd3EywgvWQZEnw0/p98AF90iVBTI9+agAAAABJRU5ErkJggg=="
    },
    "Graphite": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAXklEQVQ4jWNhoDJgGTWQwfc5g+d/BgZuYjT/+8twZ5sMwwW8BjL8Z2DfIsWwhhgDfZ4zhDAwEDKQQjBq4KiBg9LAf4wM/L7PGAKI1P+VoIFbJRkWkuEw3AZSCga/gQA8DxEdaP6pKQAAAABJRU5ErkJggg=="
    },
    "Gypsum-Anhydrite": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA4ElEQVQ4jcXUPRLBUBSG4ffG36iNIuiyAftwd2ADYhNCZQlahU6XLENjEWQRMqIhkx/33mOY8dbfPHOq0+bHtf8C6itzgGTE4Wvwie1RHPXVjVrBAoPWLWPZabF1oUawjAH0M+7TCYtTys6GvgXr2KtIcY9yO9oATZgUrYAuTIIWoBRzoQWoFEGey7AyqlPOCoLGhbHPRl/wUKyk4CwlVDCIfTYNECAZE+kLSNBZSujBsIw1QClqwt6CLtSGGUET6sKsYB3NeoQedGyYE6ygHt3YZ+3ai/5hMiaS7MTgJz0AwBlzPETenpMAAAAASUVORK5CYII="
    },
    "Halite": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAArklEQVQ4je3PMQ4BURDG8f+wcQTFE/dQSFxg1c6wQeEudi+gUqjsqhVKyZ7COgWjEYXIe/OyCoWvnfl+mUn4cpI/SHphJh2GlvJNOR4cZy+IkKkyMXinrgCEQEuEtShbYPQ+igeVvHQspw3jT+M4UMnLAQvfih00YGZQlaIyYCbwic0tWBCMxbygKkXlbG8GwRcmaGtQoEbop1c2NN5uT+/sguDesYq9ygu2ze+DDysCMnu+h8wyAAAAAElFTkSuQmCC"
    },
    "Iron": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAsUlEQVQ4je3UsW0CQRAF0DeIchy6AOTMBWAqIDBL6A6o5u5owh24B+o4xokDhAS7BySW+OFK+7TS/pm5B2f+BOXeq9Gi6XY4xIfuKmi0EN7x1kB+UwNh5svRGp9NL62CR2mpGJiKXvyUCJmp6KWwuRs8Qbd6WtFqbaaiTT2MkCjZ1dFJxY6VkoOUykNAiKVtDlxCbxq9E/SlDoYDdtkbr6oJfqrg32x25+et+Yfr6978AuehNFxbIeOOAAAAAElFTkSuQmCC"
    },
    "Kaolin": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA+UlEQVQ4je2SP0tCYRSHn1cvumhLkzfbQzfn9sirH8IpKCKCNiFyaBYhgsCP4Ob/TXcbmhvD9zbYEjhcKE9L1yF87RUbDHzGcw7P+XE4Dn+MsxUu5PiNHScg1nSZrCW8ESKPmpIE3ArEPU1lmuJuoPhYWVjUHI58aihyYU1BNeFzktdcdlx61sKiz7UIFcOugwh0vTH37T3OrIQCGVPyeVpF1jrhOvxXoZjfYo7hdRYKP+OcOgGvAudA7Ed7pqCOULYWdnd5B64KLzyoKFUB77s1ZMZFM82TKfjSG7b2eQYKnuZIKZKtFI1l878KQ9oufZs5a+EqbL7wC4J7PdFI7HnCAAAAAElFTkSuQmCC"
    },
    "Kyanite": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABe0lEQVQ4je3SvUtCURjH8e+5KlbmUlBiUTSEoNBUSxTUqGVTjv0BrkUQQS0N0dQf0NDSEgYOha4FLUEvUy/UEBFdpbIhTE299zSYEdfrC9HQ0G87zznP5/AcjpVfjvUfrMjUE85iAQ86RZnlMt7P+4/AwCMuUSCs5XkWgmthwSkdTE88oL3lWd3vI9cwGFTpkUVm03kWDI07AZVep52lsVtWzFBTUArmm1+Y2/ORN+7F3Nz5U6w5IQys1wWn7hnQJEcRE6yceDuvQRXNf4Pd+KYVoFQYUiAVUBn8XndYuYp0kC6vNbi2teAFzmqCKNilJKoYyrkio8Dh1zFBThfYjO1mb3huUquIAK9VY7su2OTiMJPgQoC3GhaSWLIJuqPdpOqCEYEWVJmRcAC0VmgSkVFZVixsmF1m+m123ZwGVMYV2AI8n+XOYJJJmWAYnc3dLm4bBgFibo5DEl8uyYgOXiRtQudkz81itZ6aIJTGpzT6Qa1zDYM/yd8HPwCejXprj70qZQAAAABJRU5ErkJggg=="
    },
    "Lead": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABGElEQVQ4je3TvyuEcRzA8ddz50cyn+5HCqsyYmRXymZRFpPJbJXJHyAZZTAoMdxgYJDyD8ikdM/zYJKycM9j0GW4u+fuZDB4r5/P5/Wdvn1+ub5/sKmIyYSZHIO4LXIZUO8ZjBlP2E+ZD5B+P3AfsV7irGvwkYk61yi0GI+lnISslDnoCqyz3wZrlMPuE+cjxJngA1OYy8AaDX+wiu1MMM90F1ijpt0mMGAgYDbhpQtwpSOYcId8hdssKSUf8dYRLHMRsomrLDBmKaDaEQx4j7mJWShy2gqrMZowWeGoIwhFTmssh2z0s1fgFVJyEYsBU0W2Wt22/SkVDp8pfbAWMoQ0+hpVSxy3u8v8ywUi7GTt9AT+pL8PfgIy+UNZnn4fUgAAAABJRU5ErkJggg=="
    },
    "Lead + Other Metals": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABN0lEQVQ4je3UsUpbURzA4e+aUARNl1IagwoBRWkLLTrapxCfwWeoLkobEPsSLdKpa6FzC92aduggEoeA1nuV6uSgV5schxKJISYXcRH8wVnO4Xz8OcPJu+Xy9+CVAgMJcxHPm5w1+THG7xuBMTMJG3gWEDGf48UeixHvSuxkBmNm8RXDbdu7JarbfBpi/YC1Jxz0BQO5hI8d2GWTpHVeP2AJK33BfV5huhvWqszpHod/KTzmuCeIp72wVhG1c6ZQ7QkG/mFBx/vk2eoAC40sb4hfEcURvveaMPDyhM99wRI/Y9YCXyJCNyxmPNCYJM0yoSbvY1YDbyIa7WdHPExZPKPS7W5XcJTaPh8S3ibsYjMwiKmUXEqlzGlmEIrUsfyHR3kmAscp366D+oJt0x75vzJ1x7+vm3QBqe9XmCkTwbEAAAAASUVORK5CYII="
    },
    "Limestone": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABNElEQVQ4jd3UIUgDcRTH8e/fHUviQGTomGVtIGi1KgjHuCByIBaLGAyKTWTgORB0sGI1WIUFwxhiNNjUJkaD8j8R1oQ7ZdwzTXR42922IL74e/D5P3i8v8GAy/g/YEGTr2d4GAhoaZaAPUeYcRRBX6B9T9KDMpC70awCp32B/iibCDkAFCX7ibPqJF5PoKUZQyh+i7J+gi3gsCcwEPaVItUW7yw+c3KepRELLGjyCtbbc4FUc4gisB0LVFAJ6wtsWC8c18Z5jARamgUBM+wxIBkEHAArXUFbSHgulQ4YAAqWC5pKPcNtR9B3WQOmuoGAUkIZmA8FzQYj8k4pAtYac85yMWsTXPwKGh/sCqQjgwDCkSNctk7yC7RfGfabTANX8Ty408wC1z/Aapo3Om82Uv39D/YTZRdURkYM8HQAAAAASUVORK5CYII="
    },
    "Lithium": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABLElEQVQ4je3Rvy8DYRzH8ff36SV0NWDwB1Cz5BaDMHXpdJcySzr4DzpILUb/ALHSI5EOBhExGDS9xGBoVwaJiFhEQrj72hqu90tjMPiMz49XPs/ztfjlWP/gt+g5Fg/MY5gh5BWhLS7doUBtUuYRG8MpAVcYLgHU44yAVVnmJjeoTVwMb+KwDqCHTBP2txcp0NY97Dh0AFSPSWBKHLZSXjZBgR1gKU/DFUbYTsH6TdWjFP3TOLAoFZ5zgKDYkA3mj2E0ujQICqINjDS+jCEpIb1sMOCEEhXgKIO7ZZyLTFCq+HpAWT064nKXgCmwJgt8ZDcEeGKTMerqcU2XVmT3BaEmDsdxV2NBqfEObOg+c8xSRykCLZQOwq443Cc0T5+yVPEBP+3Mj8Bh8vfBT2z3T9XQAhRUAAAAAElFTkSuQmCC"
    },
    "Magnesite": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAZElEQVQ4jWNhoDJgGTWQwfs5QzzTfwZHYjT//8fQv0WG4SJeAxn+M1j+Z2CIJ8ZARhaGNQwMhAykEIwaOGrgoDTw/z+GPkZmhhVEaf7DcJmggdtkGG4xMDDcIst52AykFAx+AwFcvBL5iLVaVQAAAABJRU5ErkJggg=="
    },
    "Manganese": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAXUlEQVQ4je3Ouw2AMBAE0fGnKUK6cOaOoA2QaAY5cy/oaADhEyZwcJPv00Z+LhqI7GQ8s2p9sbrE+QoCE0JWgYED2mBXBho4JigseDbVOlCaoEtUoH449wz2Nj54A4pXDYJ97JKDAAAAAElFTkSuQmCC"
    },
    "Mercury": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABD0lEQVQ4je3UzSqEURgH8N87ZiELNSmGYTHlI9nLDUgpG6W5C+UOZMEOC+7DyspmVpQdxUZZjGaGopTJYD4sZmaBmXlfslH+dTbP6fzOOT2nE/fLif+DH5Ij1cMKJlEOOHngYIbXb4N51rCJ3latjgTXBTLDnEYG86xiu8Ne6TpHeeZGuAwFbxmqstXp5M30YxcLoWCNZfSFgDB/R3KQYlew3mhAlARVpoSBKEcE4flzod0Jj4No2FOZs1BwhMNCo3vTIeBeus1tvoABlSKZGlkkOmDZEuvtJtq+wyTnOWZj7AQsItacesR+iY0JXiKDMMYVlm4YiDNeoZziIuCt05quYCuj3GuMSPnj39dP8g4dEjyhJT3DLgAAAABJRU5ErkJggg=="
    },
    "Mica": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABT0lEQVQ4jcXUTUrDQBjG8f/bhq5EECmYWARx2YXQdS+gySy7KN6gZ3BZL+DCG4gLdybpOYRewIVNXCpYESF5XWglTZsPP8BnOfPy45kZGIs/jvUvoBdxAhA4XP4adGMGKF2BuRszCG2ufwy6MQPgRWCSpDxLk90qtBBcYKFNaCL6liA3NqEb45aha8Eslt8LK9AVsAhTQeqgS2BZs7pNv0AzYwhsoQQmYq+hKMCbogptEV6P7um0PpumgqBMETwzY+jvcrUEqnCgSirKoYKkQAPEElDooswtoZUCKSgKAorQ1gbbKw0Dh7GJGSUN7iY7TLPH82KekoTHSYfb7LqJ6KHsBw7na+/Qt7kwMaPjB8ij+ZiIHtD3M9gKWBctwtaCVWgZVgjm0Y/3rsZKwSyapjRFmAMbZVgluEC9iFOBTd/hrGq+1n8YOIzrzNUGv5N3yK6iN6vcpCYAAAAASUVORK5CYII="
    },
    "Mill": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABY0lEQVQ4jcXUP2sTYRgA8N+bxJI/KKWikFxcnUUcHRwc/AriVGi/R0C7KGIVnJw6tBWLroro3K/QurSQ9AwEQZcWBO8cmobjvEtTCPiMz/s8v5e757mrmXPU/gv4gyvHXL7B0VzA39yuchdPLgymVEdcu85wWuOQ1gK1JX5NBWM6gbffedTmsAiLaSbsHPMYu1PBiP6A5QqbRWhMEzsJz7s5rBCELt+y6Fm+TwPvUl50+VrUWzqULBp4g0blFHsZ8aWsbwIesFjnVr4gZSPlFRYCz3ASn058En84OFupCVhnMeFewaWtQB2XAp2E+/mCCp/lwfHL72ULh7QS3uM1rqbcRC+if+4j5yODrY9Td1LWAlsDHnYZzAyOsQ+B9TafYh5AxP4RKxW2y9B/wBz2MX8esR+zWoYWfXrNhKdlewYd9mJWU5acB7YZmYJl0aL8TH+bwF7g5yy1M4HjlTqcG3iR+Avji2nJlmqOZAAAAABJRU5ErkJggg=="
    },
    "Mineral Pigment": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABtElEQVQ4je3QvWsUURTG4d+5O2ZHdtfCIuxYKRZBBUkloglsoY3MEisLt1KDEEUxjbWdf0D8AL+6FUmVEJM2C4kgJsIiRDCNqeYuARtNcExm7rERWePoxGBh4duee57Lezz+crz/4A+pKV6pw6CBQ84Re4aXkwFvdwTWO4TOMiawCrRF8FPlVmhZ9mB4ImBl22AY0VDHbQPnp/YxX3uPX9vPxhJIbLmewPxZy0AW+hN4ZpUqCfcQTieGD2HELDC4aIlFePLpCzcrRUjgEXAqF5SEi8D4WsybcpE20PdtVFLlWsUHv8roZ8vokOXw1ptmVT6mQrO8i5Nd2PeocuEI3FiE2cRxHHJAgR4cMYY9GZ8B7G6t0FMpEovBz60MLKuhv2B44FLWgdKW+VzrAHEY0a/wLBcUoYnyXFPuo1xCeNyFvksLDNcjBhz0rleZywWnAhbCiEmFpggNKXDQpZzA8XFtkxdln6MKTxFGWkKyncrYgKuB5a7CknPcEaWtBfaWDWMo54Ar0wHTWbuZ4GthE7hctzxEaSCMoGwovFKPvpleOll7vwS76wMLv3vzR+BO8u+DXwExQpc1jIolpAAAAABJRU5ErkJggg=="
    },
    "Molybdenum": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAyUlEQVQ4jcXUuxGCUBCF4Z+HWoBjgJhRlg2oTYhElmBqYGYzFgE0wYAB4FyBe3cdnfHEZ76z0Yb8OOFfwAK2AGu4fQ122NWDe6FAnWCPAUEFhwDOEmoFTQyggnoDuxIuLnQSHGJ9PKgbAR2BNkyLvoESpkFfoBaT0NAoJI0SM9ESHkAyujCCLAffg6MWLGEPLCPIRiBADGneLotoh61MbARqURs2CUqoC7OCNlTCnOAQXbTYzIWJoIn6MI/gJPVV/zCGVNNTg5/kCcD7TxD9JQVUAAAAAElFTkSuQmCC"
    },
    "Nickel": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABCElEQVQ4jd3UvyuEcRwH8Ne56ya5ki6us9x2pVitlLrZIotFBgPZJCVXCnWL1WD1H8hoMBplNNBzUrcpke4xPBE6zz33Y5D3+PnU6/up7+f7zehzMv8HDCgXuOkLGDCPnZCpFM2ewGuyOEQpYAknPYHDrIWUIEX1jtNxnrsCA0ZCtr+UimnWsd8VGLKbIvejvHnPcZFGR2BAGSstWrmBaOqNTiesxfRXHzga5TYRGDCHSsxh2SZ7WGwLhqTr0XTtshBQK3AVC9ZZxkQCMBVG+zn7K9hg6IVqAiwSmalTGeOsJfjKFvJJQQg5CDn/eJKf4CODb0ziohMQAqZx+Q3M8yT+ZhPl73+w77QVOL5ZznJJAAAAAElFTkSuQmCC"
    },
    "Peat": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACeElEQVQ4ja2UTUhUURTHf8d572mGTamYpaURWmrRIgaEKIIwiwxs00Y0iiwpKcooF63CEgKDIiFTggwXtUsKkbIkW1gtwkUaIlGZqGl+BH7MOM5pkW8aRx8i9IcL9/7vub977rlwDP6zjKUCVHUTkAJYQB/QJSKBZQFV1QJKgDPAlrDtn6r6CKgUkV9LAlU1FWgEtjskkQCUAcdV9aiItDgCVTURaAOSAWbx88nXxg9/FyZRbLWy2WBk2uGxQJOqHhCRV04Z1tuwD95n3BsvZWj2O4ZYAMyolx2R+zjnriPBlQpgAk9UNV1ERuYBVTUHyAFonWrg1lghipJh7eJm3FsADvcLHd4WyoazqYpvt6FxwJW5MS/DIoDRwADV46dR1KGEMBYY5M74SSpiX9pWgaqWi4iGAvcAvJh8wLROOMJsdXhb6PV32jVNAjYDPaHAdQDdM+/ZGXmQi6vrAXCJGQxoWDsUnBcOJvLZ1x76SevDgT7A9KsPQyxWRcQvyCrcm2E6dOmF+TX8CmQlGem0TT2mYjQfgI1GJkUxNwCCHoASINnICAV+Cwc2AVl7VxTQOHGbd9NPAfhtDQcDbA8gwZVClrXbXn4UkYFwYA1wPs30mLnRxTRP1i54si1BKHFX4/p3/K49CToi0qOqVUB5ibsagObJWnr9nVwbyQvComQlZ901eCIPBRMHHi4AzukqsM3AzCt13yc3upjWqQb6/N0YYlEYc52c6BOsiUi0478AR0RkdlGgiMyqaj5QCVxIMz1Gmulxevlz4Fh4x1nQbeZuu6yqdcApYD9/+6EJ9ANvgHoReb3YLY4NVkS6gUtO+05asmMvV38AT9fMIWkAU8wAAAAASUVORK5CYII="
    },
    "Perlite": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABS0lEQVQ4je3Uv0tCURjG8e+5KoRLEPTjKK4utVlrLYGUXqKhv0CI/oacmhxaWyMiCKIh1FtSCLomuAjSGBTeKwSBi1DmPQ0lqKnHoiXogbO8h/fDc5bj5Zfj/Qd7YjosKUW0a9R0vZxezVD/FhhR+KRN0oWqX5I6F7QBVp+ZnHhhO17jwQpyNjYo6+x64DAteeye56doAPtxm03TYS0ryWnBdZtFFJV0sBfrjhXgIlZjb0tx02k/FPRANCJJWcO0TgT5Zp1loDASBPxlh7Bpa0CDlqtY0IIuzAu40xXEBRSJ/vGghiVgQwsChsGtFnzzceRrkQT8ozABhaykqgWvp3FMmx0Fxx97A/OE8fW5A0GAbICTWI2GEBwAob7rovKQsGa5HxsEuAySiShyczYrhkEYxatoU8qEqAzbGQkClAUtIP95xsof/75+knf0VV+0i5b7mAAAAABJRU5ErkJggg=="
    },
    "Phosphate": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABa0lEQVQ4je3QzyvDYRzA8fez7zfJcJGwRimaUMSRg6u2KeJf8DfgYrES/wTJiYPa2lyn3IyDg8RB+fH9EjstbV+25+O02mr2HTk4eB+f5/O8evqY/HLmP1hRRPCc2kygGPZo3pXmNNbNxY/AsMVY2mZXwRACopjDYCT0yKJSbMV93NUNBi3GBVJAc+lMw33SR3r6hn3Dy+bMMxuxDp5dwQXByNnslWPlHfXjTN2y5G1gGVh1BfNPTAID1bBSqV7ywUdeZ15oibWTrQlqGFS1tFKK68IHASBdE1RCQcE8VO7Ha3JVMadokWIdOxQ4F0VnoouTWh8UYVRyxF3BhI+zkMUGQhKFVMPCFj1aKB7147iCAFqzHbSINAlrB4pi+d10hlbtsPj2TrTa26pg0s91+ImdnM160ObeA5daaPRAAAcj6xBN9ZKvGwSId3ILrMw+0OaY9BlCNutw/BXkCpY69JMBMm5zdYPf7e+Dn9pkfp7+cVhhAAAAAElFTkSuQmCC"
    },
    "Platinum-Group Elements": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABKklEQVQ4je3UoUudYRQH4Ofcey2WiX3VMuNWNYppDPUby8K2v2Emk2HVYhCRgeg3QbAIImjVKDijoGGDJYvBq/dYFPXO63cdK8J+8Jbz8j78Tnkb/nEa/8F7yWVv1I3cDpxhKQq/ngTmnB59ptQcSDNRuIQsvcDHLB1HYaX7hv2+uDAf753cHUfhFF+z9C6/G40JG5VgrnqtZT8+3Mfa4LUsTWdp86Z954YtI36Y6YTdqra0DGH7cTD0GjSQqxXghaaawWowvZIOKxvWkCbbxw+Be8LbShDqdqvBhgWXptBbwW3HuINKMMb8zNJnLCI6YL/5c92HGyIK37J0ilm8bLveUTcZY466Bq/R9Zyzoc+wmgGcC3sxbr/Tm0dBiE+a2Lo+XeWZf19/kyv10FLgY94BIAAAAABJRU5ErkJggg=="
    },
    "Pumice": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAwUlEQVQ4je3UsQ2CQBTG8f8DppAVLB2A2OnhGBZ6lG7gNNSKvRu4AwGXUJ4NhTGBO4TGxK+85H655L73IiZO9AdZ1yxCIfG5rA3lOSbvBUMh0YYVwtIpCldwgAABHBS2CjuflzrBh6KXGXZzh6Fo96cIelKsqVER9uPBFi2UzNTgi7prMxD166GgBVhTudFBxS5ibFqhCHYSEOAck6UVdKFfjd4bOneC2lAGwtHUPPtQBQRuTrCdzfzz3Dc/uL7G5gWNeTpYAkVQ6gAAAABJRU5ErkJggg=="
    },
    "Pyrite + Other Metals": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABF0lEQVQ4je3UIUtDURgG4OfOWSyCoHI3rCsatWoUk/gbBuJv0GRasFpFRBBMgmUgglaNAzEKyu4dCIJF0DGvwQ3m2HavYhF84ZTvcB7eA4eT98vJ/4NfErOQsNw1eslzNEXjW2DCaMQWbkIqAS14YvyV9Tr3RY4zgw02sRfy0D2f4Bk7EWsxKyHVVDBiPqFW7MG6U+CkznbCWaf9sIbLIZVBWCcB5w0WcZEGjsWUohQwRzNhLgs4i9u0hu9IKPfO+4HXWE0D2y2vUsFR9pufT2YsxbsIuUkFJ4kjNnCAYAD2mOtz3b4gFDis8xywi5me7csRytPcZQahyGlCNWIpRynhrcX1DLVBZ4aCENDEeXtlyh//vn6SDzIIRbPjXrF8AAAAAElFTkSuQmCC"
    },
    "Pyrophyllite": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACUklEQVQ4jdWUTUhUURTHf+e9scYyaBGiM0pFYVCLpAiSIKygzN5MmUhUm6BlqzYRUTBQtHFXtMiVQSIxlOEMEhE5EZKQhC4ixhSR+XiitLJ0at7c08IEv0YLWtSBu7rn/M7//M/l+vjL4fu/gY1ZtgqcENimSl6E4RLDi64qvvwRMDzFpsIP7llwUeE9QlLAJ9CUt2hzMrSWBrgdFQprAs+MsdnL89YSpqTA7lg1I4uaZThkLNpnXGpblOal0GVAz88jlNR0JeGE4NWP4d9Qyl7LI/c1yMduoa8pzcG8Tf9slltApCjQSVOHcrTEYkdC8Jw0x7B4jOE7FqVlLlOnsrR0BfgUznDJCC+b0txf6OkioFg0KzzrqmCycZIKPKKiXIkF6Ywo1oDLHYFovVLbLfSFsozmLRqAjhWBKtSI0gtgF2gABmNBOgEigokoNwdcLpdl2AMMKQyqUlPcQ8VnFA9AFT/Kt4XXEcE4WWaND//cSHgCJastZVysuY6W0muE1lCWfbEAHwBCGc4rrJ+ZZeiXgF0K/asp7BF42JLiWjRI0nG5rsobJ0NChI0KtRhaEtvJhVPsVDhg4EJRYDxA3HFxczZ3gavxSh6EM7wywhGEnLG50FPORL3iU5c2o3T0BBkvrlBQK8U5Y9MfyoK/wI1okCSQnE9pmqA879IObLHXcXqJZcsfdnc1I06KOrV5krMZdVyeqmHYEnwKtXnDWYTXdo7DzwNMrwkEiFfzGWW/k8UBGkU4qYInyrAajsereLdSXVHg/PhxiDF3fjv+/Q/2JxOf7DNWUeU9AAAAAElFTkSuQmCC"
    },
    "Radioactive Minerals": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABWElEQVQ4jdXUP0jVURTA8c95uFgIIWGBLUJbIEZTYyQEjb3+T60NIkXUGkFCtRXUHv3RX6/AJYiiXBscmyMHDV+jFJZ1Wn7Q+9nPnj5aPHCHe87he773wr19/nP0bV9gPrE7zvtSW3thKE5Y3jQwZxwXHmdhPE6br9QK/dbM5zPTTroaIf8JzMIYWujHLYyva5nEPumKljZudzO8X8LgaM44Fme8KocN4tqf6W7mU4/inMVaYLaM+uVwBR+m8qU5K3biMnZVhBouYKreMI3VGL+34iP2CA+qN4ZwsHNbBTa89uMvw/3CxXLgsNCUGh0d7Q2B0bSEpYr0rA9WzWEE9+KUtzWn2MCwLlZ9lT4L3/GtW3t3YJjA2XK3N985FEes9QTMwrB0oyM1qu0S7vRqeBcD63LX87kimj5tCZgptMxKQziAHVjAG/zcsmH5Rh+Wa9Oxjf/DXuM3BhBfs9F3HJgAAAAASUVORK5CYII="
    },
    "Rare Earth Elements": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABEElEQVQ4je3SsUrCURTH8e9BwXwCQWhxaWjoBVoCHyAS/9oS0tpQGDTU0GwoODQ2RZB/FZqSHqKlIaKpocEXUByS/3H4R4Pd4/8aDQ39xvu793POcNP8ctL/4Fc0pIlQc5ccSYUbb1D7rBNxaA4UGnrNnewx9tswom12cfJkOQXOEkEN2QaKC7DPi9S1y5UEvJmgDsgwopWIxVkBmkDJ3nBMDSh4ggA72mFDqjy5wSkDUkyArCf4Qo5nc0PZ5V1DLhDOPcG6bDE1wVilAewDqwnYvQQ8zB9+AyVgoj1OUG4XYB/Asatw/jUp09EuB8CmkxMupcyrNwhAioCINWenPFrPTFBKDIGhOXBZ8Kf5++AMLuQ9aCrSu3EAAAAASUVORK5CYII="
    },
    "Saline Compounds (Undifferentiated)": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACQUlEQVQ4ja2UP2hTURTGf+c2sX9BsIhJnhFcu9XWyaWCdmjS1NI6Cu2ig4PgIBYaW8RBHKTi4J+loCJIdWjaKhUHJ5daXEqpgyDWvNepIFhTSd87Ln3Je0laovhNl3O/+7v38F1OhP+sSD2mLiUKsCwU/xmYyXPKFYYMiDpsA5qyaRJQVV4tWHyoC9i7QWujxy1VVrbiXHsv7AT3e5RIm82FlM1w0ZB9G2NrT2C/TYt6TKnL5HySfKn+leNEGZmzmNi9YDq9jnUApvptrswl+FUTqJAtRrmxmMAJ1Q/wAjiRyfM8Z/EZYD5JPvONCS9CFhirAvbZdAMri4fDsHMOPTvKSQDP8BA47e/ljmGnHNYyeTpzFp9CQKOkuxPcnA/AJhWz5PBUyi30pBzOL8SZ8UstMZ4VNhiHCqAYvEnBC75u2eayCEeDNYGp3g1e+2HMCG460FMJqBqGDX6nvWi4jRKWkmiELHA9UHOrgEL4aFG4g9JCDXlwNZNn2g8oqHLKQoO/7LPpVhiVSrdvVaKucB/orTwb+jZdSrQf3I82jxH24vkdnU07DDdvkivUatnAy5jD6BIgQud+sJKUu4V2LOOWUy8BZ+OsZhxGVLlYmcM+SqIM5SzuVQEBioZHxqNDhFnPY30/koGkwoCaMqwK+OYIX7qUwZjDRAO0F5p48O4QP4KeM5scbCpwSYQ2J85g5Uirmja7hvEBh47m34ylHVpRXAFFaNBtforwZC7BWq2X7zkPZ+OsEvy8daquif03+gMH08pbuYvGQgAAAABJRU5ErkJggg=="
    },
    "Sand and Gravel": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABOklEQVQ4je3Sv0pCURzA8e85OkhEIEGZ1iCUEe3RC0RgXaEgfIugN4iG2qqhnqKhKLKlQaeCaCnIJWgwrhYYBIrX8t5fSw2l13OFhoa+4/nzOefACfPLhf/Bby2WSEiIFQ0pDxytuIy8cHQwzVvPoGWzJrCpICKAAkSgEeXBKpM9GeEqMGjZrAps+5yVFOF8wWb2NE7RCGaeGPZctvxu/tmAgl1g3gi6HssK+gwgwFz6mVhuiEpXUAspCaABKuQyCQZQwAnmgUDj51g7KFwoFcir1RxujGA9Tq6/TBGYMoB7+WT7a9rAvKKVrpDVHgUg6oMV3DrrnSY6/sNcjNtMiRlPs4MiDWgABa/AfqvOxtkEzcAgwPEY94C19MhgM8y4buHYCe6uFe9+e7qCXx2OUgWqpnWBwV77++AHDYBcUCxx34YAAAAASUVORK5CYII="
    },
    "Silica": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACKklEQVQ4jdXUT0iUQRjH8e8z7/si/aGb6Kt2iUAKI/pDERGkB7d13w1SIwiiU8cOHToGdYwohAIJ2UOHoFPlumoGSwQWRWQRsdEhoqh3dxFPUZa77zwdbGldfamgQz0wh5kf84F5mBmXv1zu/w0mizQ7kMSyAaGqUKgYpu628vmPwHTIahXOizKgMC5CQRUPONJkuZIOuTDmM4SgvwSTc6zTb9wTpQBszLXxpT5PzOJ7FW4HRbbllOON6DLQfGUECFXYa6EZeFefNy3QZQ0dKAtBkVM5uBQLpkJ2AF07fbY8KXHMUfLpkJ6xNt4D9IUcsEJGlHTkEjpVZnpLXK3v6RLQCIdVyZwVLHAt/RFXDfnkB7qNw1ajjIgllW3nGUBQ5L5nSQA3VwQVNqkwVZuPtZNJh7iO4YEoXmTpm+jged2WGYHNsSCKa6DSsFZCWKswFzVRro9EqaqyJraHKG+BTmD6R0/7VbhsqnSrw36vQv5gme5syyJsoVMMD2NBK+SMchrIBEUGUYZUSGTX8xJ4kQoxNiJ/qESPW+HTPKQiOBMLTvhMBUXOBSHDKGlH6B1tpVDLx9sYCkJMxZKvOEyLcGvSZzb+yIJGZY46EY+Bi6P+T6xWqyKG510GUXah7GvMl13syRbeJMvsdiOuByE9otxAeG0FD9g+r5wEHhmPE9nmpa9oRbCGouzpK5IwEAD9AlWBVxYGxn2errQvFqwdfwLusDh+u/79D/Y7tCzRqSTlNaMAAAAASUVORK5CYII="
    },
    "Silica Sand": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAtUlEQVQ4je3OPRLBUBTF8f+NrCLGHqxBm6g1NoDGBuxAJ9mJiZZeYQlmzJNVvFxNOt5HUCic+tzfPSlfTvoHKQwzSRjFHFvlWGecvSAwt5aNd4UgLWwHCUAAVMHWQy5OTZFpQwVMgMPTM98SF6awcFXiQUXyO6WKG4sHO0yEZagaBcZiUWBuqGKxIFgYSnpgXrDDVn0wJ1g07ND+2EtQ4IoyBk6hY225BcF9xvqdZU7w0/w++ACIZDFX2K2ELAAAAABJRU5ErkJggg=="
    },
    "Silver": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAArklEQVQ4je3UsQ3CMBCF4f+kTEFWoGSAiI5BKMAp2YBpMgYbsEOEmQIfRVxEEXBnkgaJV9mW/Okk37li4VR/kAgbgcZzOUFfQ/cRFGgS7AS2FihwwQJzTsAeOHgqNUEFXUG4D9si9O2jCKhCiKACx9ngCG3jsHahZtuUoq4+FFAg3BxoUWPXA6oCYREwo22u9CX61eiN0LUJJugFzhEeDvtqgnk2u+m5Nz/4fc3NE5ooKFXM328EAAAAAElFTkSuQmCC"
    },
    "Silver + Other Metals": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAoklEQVQ4je3UsQ3CMBAF0BeSJVAaSkqGYBoSdqGjoaShR6JlB1YgMIZpkgaBnUAaJH7vdyf7zoWRU/xBNzaYpQ4GcuxLDlEQi4zLlHUMvLLIWSY7bKvXd6TQV3l7h5+i0UcJ1A1ZST0KCBlVg75or7Fp0aykGgVs0VXbaRQdNNh90MGb0qHYjQJ2aGCOUxIMbCcce6AC5yT4vJtD84Pf17d5AEFwIB8W9BaBAAAAAElFTkSuQmCC"
    },
    "Sodium Sulfate": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABLklEQVQ4je3Sv0vDQBjG8W+SOrqpJNfJseLmKEpxEXtEBecs/mEujiKomIiLlDo4BZdix1ahl6BuLkLzw8EWtCZNrA4OPuNx74f33vcq/HIq/+Cn1FMqsyGrpMwB6BqdU4u7qcCGYl1XWJHO1YXgCUCGLNsKJ4aWJ7gvDUrFJvBwXqX18dw1aQNt2Wd7N0A7segVgo1HTCJwBZ287t0qZ7bCgRKgFrPmCo7ysFESndudgKXxmX4B9ZRBEQbvz7cVe1AA/jRZoFGmcCtgHnguBBPoSUVt0lIAjISNF8FxIegKfNlnv96l21zkNQuTiloCQVMjKgQBQsGhqXCk4ma80+EfxRNcZtVmgr7GADiQipXhJmOARGMmNbj2Fgiz6nLBUVyBD/iT7nwLnCZ/H3wDyMVflhRWHvcAAAAASUVORK5CYII="
    },
    "Specialty Sand": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAtUlEQVQ4je3OPRLBUBTF8f+NrCLGHqxBm6g1NoDGBuxAJ9mJiZZeYQlmzJNVvFxNOt5HUCic+tzfPSlfTvoHKQwzSRjFHFvlWGecvSAwt5aNd4UgLWwHCUAAVMHWQy5OTZFpQwVMgMPTM98SF6awcFXiQUXyO6WKG4sHO0yEZagaBcZiUWBuqGKxIFgYSnpgXrDDVn0wJ1g07ND+2EtQ4IoyBk6hY225BcF9xvqdZU7w0/w++ACIZDFX2K2ELAAAAABJRU5ErkJggg=="
    },
    "Stone (Crushed/Broken)": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABTklEQVQ4je3TsUsCYRjH8e95J4iLQ4SeYGtbi2HQYEsR5V1BexAN/Q/S0lJ/QUNTEAQNQoNR1tqm2CJERLhUd1cEjYpdd2+DSOVpr0JDQ7/xfd/nw/O88Gj8crR/8FvmX9DDLmtABrgBGj6cnyWpDg2aNqvCZQ+IAiiQj+jcNZ5YMSwMJ8n2lYI7EGhYmAIO2s5nCgoeUDBtyrrNJrAlBdOCMA673djXnCS5zzlcL9pMdo8fAHWLLCHG+mGdRBMcNx3yIAEVlXEhZFx7fMMOngf/UPAm5/on2KFHRajywtlXYqJFUwoWU9RMm0sB2Z/ASIsNV+NQCgK8q6yrHmVgpNe9abGMz+PFKM5AYClOfeGZKc1jv9OpFyKWs5hWQsz5UD3VOepV23dTSnHqwMzSAxNCJaMK0kJw6yTY6bUhUrCTYooaUJO9GxgcNn8f/ABpIWV29OoPgwAAAABJRU5ErkJggg=="
    },
    "Stone (Dimension)": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABTElEQVQ4je3ToUsDURzA8e+7m4qaJ7cNQa2DRTVqEvTOgWCwDCwmk9lgEZN/gMiiGBaE3TZYMGgQQdeHSZDdOzWJMIO79wwyELfdbWIw+I3v/X4feOHF+OVi/2BHjiStFHMYjAD1MYvLgiAYGHR8plHktWZRCEB/njcl9yuS7XKCct/gyiMzOuAaiH+/EzCFpmh75EpJTvoCRUC+G/YlAzhafuK8MoEfCq4+kFGwEIK1GzdabAIHoaA2me0Da9cx2/lkwbASzJuKlyhNQy4SVIo7Aaaboh6GrWvMN0kzEvSTXFgeu8BVGNj0WTME1UiwJnh3fG4cH9u1KHXDsg0mA0XaTVGIBAFci5LTYMP22DGGOC7GeQXY0xi3kmwgyEiL/W67PX+Km+J06ZlErMWW7TEqQNckKKhWEpz12gv9y9U4EjgMmxkI/El/H/wAKgpi+dJZtVUAAAAASUVORK5CYII="
    },
    "Stone (Undifferentiated)": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABPklEQVQ4je3Rv0pDMRTH8W/CFSzOakN1V2eFLoKgU7mlTg7OgoNv0EHq4ugLKL6C1raCiCA4WPpvcNBVEZviKgXFa+JWaG97Y4uDg78x5+TDSY7HL8f7B7uyYvEmWixLmDeGd09SzivuRwJ9TUpokgYukTSE4PbLgq+58mDrVPH4YzCt2cDwUUiwC5BuMtcpWlYDKK9rkv3QEJh6JW4DZooJDiJeNh3AEbDmBGXAphzjMALrTJrRLPT+aQgUEDub5M0JAoEhCQ5wmEjJeO9ZCLQgchaZExgXaODBCQrBRU2TAU4c3lM7zo0TLCiqviblP1MpzvIyALNWsHMtCJwggI6zr1pk/SZ3i4p8XXeV28B2SVHqd7cvWBd8AntpzVJNkxUQs5AHKtbj+HyK1oDJo7dcUFSBalTPUOAo+fvgN0SNYdb9fkhRAAAAAElFTkSuQmCC"
    },
    "Strontium": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABMElEQVQ4je3Tr0tDURjG8e97NhxiMhgMgi46WFSjdmGg3quWgUWLyWywiMk/QGRRhvduCDLDgkHDEPwHxpJNkyiCQTn3NeiS9557JwaDTzlwfnx4Tnjz/HLy/+C3aIMSllmEAkoX4Vp87MCgBkwBNSIWkK/Nz/VOA7bF5yIzqE2KWG6AsZjjSeBcQ6ricZKtoaWWgPVjUI404FJ8HpygNigTMe/A+hlB2AAO3A2VmQxY4t24Lw9hmAOeU0FLNR2M6GHIiUfXWS4gh+E1HXziilF2gY6znbCEpZ0KyhbvGnKrIYvi0YptV2eCiJKsEaY3BMSjpaesa8AOBY6lwguA7mGYpoJQ5pH9uLeJkyKr1LXJOG9sasgwigJgaMsKZ0nvnLMsy9wDh647A4E/yd8HPwAB71Ht238mJwAAAABJRU5ErkJggg=="
    },
    "Sulfur": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABMElEQVQ4je3UP04CQRTH8e+wFECyMTE2rNLZeQRjZwe7pbUdaEeCxhOoJ/DPGehc5QDaWBC1s9DYMZuwiZ1AJDtjtcbALEPUxsRfO3mfvJn3Mnl+Ofl/ED9iS2k8W2EORAI3HY/uTFBr1gU8XnqcZWFBjJu803IEG2AB0TwpzW01YueqzGkWVlIcDxzqk+fGN+ys8BD0EJNoEOPqMXslxVG7wrAqp2szh3KxzH3QAz9iNyxzEsS4asx+MeGwXWGYVTdzyp+opKnGLNowKwgwKPJSGLGtNc82zApuvrJQGNEqJhy8Oaz5knrocf4t8AuWXrPrS5QNNYIGDIDQ4y7ooWuSRtaeToFCmLE06aBqkoaes8MloD9wqJv2DCABhGY1l+PaCoYeTTMzX/7g9/XTfAAx4H5KbUnRWAAAAABJRU5ErkJggg=="
    },
    "Talc": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA3klEQVQ4je3QsS4EURQG4G/GbiNRiWJ2dTpRaZWi8QAKjRegoBTNtgStSqGSEF6AF1GPUYiaLHEVq1hyr5kVKv7y/jnfPTktP5zWP/h74D1Fn7Oc1+H3QNlhdWSwz1HGQoh0t1x1OW4MlixmLKc+y9kOnGS8NAJzdlMdBGbu2MNmLViyhfkUNoSuPbA/SfklOMZE7G6fk9N+ZLx2w4JexQpmazY8neamFnzPDs4Hi0RTFmzEiijY4bLiGksJ8DDjqTEIbdafucCHkwaqLgepuSQ4NbjPXKofGfxu/iD4Btb9LTPwyJTqAAAAAElFTkSuQmCC"
    },
    "Tin": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAq0lEQVQ4je3QsQ3CQAyF4d+QNdiBGTIDCg0LAA0LZAM6kiG4DEALPQUjIDFHYhqKCMl3TqCg4LW2Pz0548vJ/iAaWKLMXNdTzrLgGgWBFUIZhSYIHXtagDTYSsHNslQRGmogB07v80E/7GFra8cNqiIEKsTG3GAP26R2fQ2dmAvUI7UXS4LaUKF+LAq+sO0QzAQ1cBiDWeAdmAOX5LXwSIJSsBtRzAY/ze+DT5aALZZ9lgrjAAAAAElFTkSuQmCC"
    },
    "Titanium": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAbUlEQVQ4je3SwQnAIAyF4V9wMB3CKR3CbpYeSqGlmigVL+07hRw+EhLP5PgfXANKpgC4RHwNSqYghLO2UBW8YkeDYKFN8IF1olWwiXWga67sElGd0rENrayiCqaCVdTATPCGMumxe6EhcCQfBHehsDahYvUJdQAAAABJRU5ErkJggg=="
    },
    "Tungsten": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAo0lEQVQ4je3UsQ0CMQyF4d/cDUJJeUOwAscOJOxCR0NJk1yLRMsOzAOmSXVCiQNpkHi9P1mOnZ7G6f8gGjkAS0NtB5xlJGRBYADuMrLPaRoZUNbFDlO8Riih75Kb4Udo6VG8Tohs8K1AUJxOYEVta6M4DYhscW1AAGGnAUpo3WIb0PpLSSgLTjxagAnlyQq4WsAjysUEd9yK4Pw2a/OD39e3eQFm6SkaCtlOIwAAAABJRU5ErkJggg=="
    },
    "Uranium": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABIElEQVQ4je3SMUubURQG4OeEDKWKUIpYcBJE6R8oXTuUQsAlYJLVqVvBfyAOutkO7V8omkGcnFycLLgpNIvgKkiGgGJsQ65DgmCT+H0BB4e+47n3Pvdczi164hT/gw+S6maFZcmC0Nb1S9iLij9jg2nHKjYkL3oFBDhP26pRc5wbTDu+CFsj7ppTcJC2vY+aRiaYfpoRNkd13s+Ugm/4lN1hURkvM0D4mOreRMXF42BvAHkSWCQLDO1cXC83/xaGDeUoJ3bl2kk2OG3fpQbeZoDfY2XwNQNgfNBJdVUc4tUI7NCktWELQ/9hVJymunf4ihIK/aWW8MOE9Si5zQ320TMspV2vdc3raGv5HZ/9HXXmUfAeLmuimbUvNzhunj94B8lAQu3okMXuAAAAAElFTkSuQmCC"
    },
    "Vermiculite": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABSElEQVQ4jeXUv0uCQRjA8e8dgmFEk9SruARNTc2t0dDVS1CuDbXV2pJLSwQF1toQLU3h9uqrrvkXNAb9GsJXQmiRfNPy3qZA5PX1LRuCnvGe5/lwx91zEX45Iv8cNOuMeR+sfgfQkoo9wYMvaMVpKIcFKTlD0w6EQEjYj7W57LtDANFhTwtMO0E2CFRVNrTkKJfCDQQLKW6VQ1Q9M9V9lO6Yf2FctJgrGGz25nwvJdbh5A2ywJZffqRFpiM58Mv5grkUrqpSVjXStkGuO7dcY8bTuCWD+9AggJ3EUg6nZp2yFafxta41O1Kw3a8v8B1KyaF+JwPsAizVWBNQyhs0fwTmJ3lUDg2zymxUc+N6mPkE60E9AydFv5IVo5w3JXee5nhQ/UCwNE1r0eFCCFaKSa6HBgGKCcrpJ67C1Ib+HHonYmgwbPx98BN/mWQzgwmhxQAAAABJRU5ErkJggg=="
    },
    "Wollastonite": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABeElEQVQ4je3Uvy9DURjG8e+5t3qv+BFJRVJlsBEDETF3RTs2MfAHNOxIREwkBrGK1daFaCq2JjQxSSo0qKSkctsYDI3S1O09BolcRW+JSTzje9738w4n57j45bj+wQ/xZ9Bb3fRZCg3PJlf73dz/CPRn0Fs0wkDFhEsFSm4XE0EDnyrY3PZyXTfoz6A3aSyYGqt7Hgq2o3hIoj4aLAaybEW7SdcFNruZUyUbO+8xACKCCpKlQI5lYN4RHE2jAez4yH62DACBFHkSQYOh3U6Oa4KikT4pOLHXQnc0l0wG7TUp0QUMgQOoqjRYkqK9VjTpVeDgXaMECeHq+Q+g6eZCLTMF7FefVceClCO456EQyNHuz6DHeyjV8M6bvCQcQQBLZaNFYyV0xmykn/InLQ8SJiOCSl1grIP8mMH6Uxtr4wZHyisAgISUAlPRqtutCQLEOrkBZgK3DCAYFgrTFcnpiJfDJYH11ZzjW452kQSSb4sc+v/A9/XdvADERHC2v8RrYwAAAABJRU5ErkJggg=="
    },
    "Zeolites": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABX0lEQVQ4je3UPy+DURTH8e95tCRGTZTnUUnfgcQiEbyA/hEJq4UBiVgIqaKTTkyNGCRegBi0lMFL6OBPbLZqG7EZqsJzr6WRtto+JRaJ33jOyeee5N5cF78c1z9YlUCBgMCoaPwauoFrpThM93H1LXDiAc+bwSY2yVOLtVCBVSAOjBkGi6E88ZTJekvg1B3tJYNtu4OVcw/PAOSrRkRDJJDj/cxiyxEsdTFrC4lPrEFEiIazHCd93DQFlcKftthrhpVjqDZmgKWmIMJrC1h5lIHaWkvPxoZLgVIdccgRNEDV1tImGSBTWRvUuHsK9DuCSvMUfsSb9PLYbGszzyTChSPYqTgo2URjmo2YfN0WIJjFQhhJmSw4gkc+XsZz7GcK7IazJJI+7iv74RzDWpgWN8v1Dqt7KScW2VCeiO1iPpjDj2ADRRFEaW5Pe5lD0C2DACmTIrDTqN8of/z7+kk+AOIeZrysloLwAAAAAElFTkSuQmCC"
    },
    "Zinc": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABE0lEQVQ4jdXTzypEYRQA8N9lWNoI3Zk8AY9g4RmU2FpbiMhCDWXSLJWFLJStBzAbGysLWYoVO3NTkxI1JeNaGDWXmTt/2DjLc06/7+t0TsYfR+b/gGWmUOjBqGaYHeUlAYacRwxjskuw+IUlwID3iPWYky6whwF2GhOJGYaUypxhukMwP8JzS7Aea7hA0Aa7Cjn8nvwBZrmMOI6ZT9NiVgJqbUHoY6PGDAZbeKUcp80KTcEx7srsY6lJ+Q2rLR5KXewCFjDUmIw5yHHTNZilUqYouRZPAVspn0g/vRq7/SwiBwHbIZWewXGq9+SDz/W4fWQvrb8tCFmOIpaxOcHrr8H6Sc6FXLfr7QiETrGOwW7iz8EPo6E7KXKTNSAAAAAASUVORK5CYII="
    },
    "Zinc + Other Metals": {
        "height": 20,
        "width": 22,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABYAAAAUCAYAAACJfM0wAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABwklEQVQ4jbXVP0iVURQA8N99vchawiCe7yuhpoiQBoOIghaJCHIJGtqSNjWTKLTCQQgKIcM2QSiIGgqnppqiwFIQIoJqFPw+H0QJET3r+b4G+6f59KF54A6Xc8+PO5x7btYaRXZN4QK5Wd783BdX4dWiO+JGFnIUEs6nNEecWIk4QZTlQZ6B3zeGPHdiWiZpqmF0hpNVmuMR41luoitQmgdDhraUu1tonKI55fgy6FfsjjmKYsSzX4l5cB2vE54mtAfOogkbl4CvzlDYwPA6jv2d+KcrivTUMJbhXom+QE8F9P0n+mq5knI7R2FJeCfTMddLXAu04jTqF4HbN7MDRyIOLkwu2sd5hhLOZGiY5WLg/oIjDyMexzwp0xmYrQoOpFN0lBmIOJDQ5s+tvpTpjDkVmNjOyGJGxZdXx8uYtwkt6MAoMim9m/hc5HLK4Ur1Sz7p9XR95/kMjRsYwqGI/pj+wK2IDyuCt5JMMlhDb8qllF0JewP78nPtWDGWHULT9NfyKsNgjpGEF4HWQHlV8B6+TXGhzEDMcGA8z9hydVWNzToexbRm6C7RUE1N1fM45Vxgfz0f/yu8jXfmVlWxZj/ID6aDe8pV2iGMAAAAAElFTkSuQmCC"
    },
    "Zirconium": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABG0lEQVQ4jd3Ur0tDURjG8e/rxiziiphMaxcFrVYFYVXxMiyWYVjYsIkMvA4EFW6xGiwi1zOwitFgNBv3F9gEg+BjcBOV3bt7twXxTYfzHj7ngfMjz5gr/39ARXhW4WksoK7ZwDhQwJIFvI8EylEAToES82wDF6MlFHWMUnfckiMyn9ehQF0xg9H8NjUHNIDj4RLmOASKv2b3dMO5rfOcCVSEh7HTp1XkjSawmy3hBGFs36jJcWY+nVSg2qwhyrGbQQHjCNgaCMqRQ4QJWHchFTlC83lMTmhUEQsDQTA+7+dqLKhLphGtFFivVtSmbJvc9k84yT5iNgMI4kQBd70n+QXKMYVYBO4zgQAey8DDD9B8XiDxZFPV3/9gPwCAYkW0DaRg5AAAAABJRU5ErkJggg=="
    },
    "": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAASklEQVQ4jWNpbext4WDjdmCgAvjx6+sBFg42bgcn83hrahi47+RCBhZqGIQMRg0cNXDUwFEDRw2EGvjj19cD+04upIphP359PQAAE80TclqXpTwAAAAASUVORK5CYII="
    },
    "Brown": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAQElEQVQ4je3MMREAIAwDwFyuwjogAy+M2KqyHEsHBHTMC/i4eyWFxAARFZ2diZACYiL6OXTo0KHDDkUUNZOJqAdhRwv3UIwxBQAAAABJRU5ErkJggg=="
    },
    "Dark Lavender": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAQ0lEQVQ4je3MMRHAMAwEQflHwMQsQFKaiBiZwY2bFAGg8hfA5n5PCSoGIHUKKtZ6JkJB5ET059ChQ4cOvxCpBSMZUl8ZKBD9mjY7mQAAAABJRU5ErkJggg=="
    },
    "Green": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAM0lEQVQ4jWPZ/H+zAwMDgwMDdcABFqhh9VQykIGFWgaNGjhq4KiBowaOGohp4AEqmncAAI/fBjVAjxWRAAAAAElFTkSuQmCC"
    },
    "Light Lavender": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAO0lEQVQ4je3MQREAIAwDwetMhdUZUrBSB0gKTxDQZ1bA5tkqoJjRCVQEa2KTICein0OHDh06fGFLY19fxOwKOyeOYdkAAAAASUVORK5CYII="
    },
    "Orange": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAQklEQVQ4je3MoRGAQAwAwf+bFJaycJSFTU/4A4OggMhsARv3daSSqwFYoeTez9kRKis6or8JJ5xwwgm/ECylJQPrBcyyEfEvbgNfAAAAAElFTkSuQmCC"
    },
    "Pink": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAO0lEQVQ4je3MQQ0AIAwDwGaZsDlDGnODi8KHgIE+ewIu91wFREGCnTcbmjCQmuhz6NChQ4cvZAMh6tgHkdsJd/mdhMQAAAAASUVORK5CYII="
    },
    "Red": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAOUlEQVQ4je3MQREAMAgDwWMGYTgr0nBGn62APLMCNre7gEJjEih2j6SLICXRx6FDhw4dvnCIUH1zAQVNB9G8lxTfAAAAAElFTkSuQmCC"
    },
    "Yellow": {
        "height": 20,
        "width": 20,
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAASklEQVQ4jWM5eFC2hZ2dy4GBCuDnz28HWNjZuRzMzSOtqWHgyZPLGVioYRAyGDVw1MBRA0cNHDUQauDPn98OnDy5nCqG/fz57QAAWpITkBZbEtwAAAAASUVORK5CYII="
    }
}

export const ICON2 = {
    "0": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAT0lEQVQ4jWM5Oud/CxMrgwMDFcC/3wwHWECGiRoyWFPDwNfnGRhYGKgMWEYNpBiwUG4EKhg1kHLAQgUzUMCogSMiDP/9ZjgAKmmpAUBmAQBl7g/j6fwlmgAAAABJRU5ErkJggg=="
    },
    "1": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAT0lEQVQ4jWOZt+pJCysLkwMDFcDvP/8OsIAMk1aRtKaGgU/vPGdgYaAyYBk1kGLAQrkRqGDUQMoBCxXMQAGjBo6IMPz9598BUElLDQAyCwCIlxSZKXNaGAAAAABJRU5ErkJggg=="
    },
    "2": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAT0lEQVQ4jWPpv5rVwszM5MBABfD3778DLCDDhGX5rKlh4NvHnxhYGKgMWEYNpBiwUG4EKhg1kHLAQgUzUMCogSMiDP/+/XcAVNJSA4DMAgAlMBRYcMFG0wAAAABJRU5ErkJggg=="
    },
    "3": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAT0lEQVQ4jWN5s0C8hYmFw4GBCuDfnx8HWECGCUq5WlPDwPfPdjOwMFAZsIwaSDFgodwIVDBqIOWAhQpmoIBRA0dEGP778+MAqKSlBgCZBQA5fxP1k/I7EgAAAABJRU5ErkJggg=="
    },
    "4": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAT0lEQVQ4jWN5kJjYwsTM7MBABfDv798DLCDDhMXErKlh4NtXrxhYGKgMWEYNpBiwUG4EKhg1kHLAQgUzUMCogSMiDP/9/XsAVNJSA4DMAgAPahQr7PuoFQAAAABJRU5ErkJggg=="
    },
    "": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABFElEQVQ4jdWUMUvDYBCGn9NvdXFzEwShWP9AFw04iIP6H4pQxFm6JfkZbq5ugqtinATBxVEQCoUuXRUqak/yJcT0i+mQBMR3Se7lePJyd8TQsMz/B6pqAPhp6YlIVBmoqtufU/zzB1hdhp11bgGplXDyAUcXcNC2QPuReSkNDcu4RpwgV+bfM09Vs0KctGbOAsrk53tUNRSRoCyhf3oFT6Mf42uaPO8HsHs229zrwOGmhZcCeRzC3Usx1vgNrp9nvb1Wsc8FejfH9jQyvb7DUh/2N+CyWwQAXikwHrCqes5S3JmGgF3Eb+djXCPflG7TBUZ/e4euFhdgaw3aK9QHSjLTMDpp6OdAAo1vLLuzWgmrqHHgN9pJUu4YQSsiAAAAAElFTkSuQmCC"
    },
    "ADVISORY": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABgAAAAWCAYAAADafVyIAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABjklEQVRIibWUPWoCQRxHH2JhrSfIGbxArMQbWNpYKpiAq/gxIyS6jUgg2AUkhQQvkCrGysLGwgsIFoI2CRIkATU4G5fVrLpuzK/a+Xrvv7Oz4+Wf491pyzNxpZ3gEhBnEpgSq+C1UoFMhj+l04FQSBX6S1DSdUQ8DoGAO/hyCVdXhmfTZxXI93fEzQ3Uau4EjQb0+0axdgI1UK8jkkm4uDgN/vEB+bwJt32DdeTXF+RyiGbzNIGuw3hsMKz9uwKVpye4voZg0Bl8NIJqVT2GdsfsBHK1gnQa8fLiTJDNwnyutqXjRKAk7Tbi+RkikcPwXg9+ttP8sE4EaoGmIcJh8Hj2T0qlYLWyr/6YQA4GiMdHiMXsJ7Ra0O3ur/6YQC0sFhHRKPh82wOfn6BpJrzjViBHI8TdnQkzs/4Zh0NjziHAMcHWFeL3qzaTCZTLxhhH4kQg394Qt7fmWadQgNlMbYs8h2Cd0v09IpFQYB4ejD4cxKlAbq6Q6RQWi/3H0q2ANdByPzmCnyw4BexG4CrfiBuAXPvavTIAAAAASUVORK5CYII="
    },
    "CLOSURE": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAArElEQVQ4ja3V0Q3EIAgGYB6Ypl3iNtNu1iXu5rlgJGkbkR8siYny8GlUlAmPSkRnb25wAC29HX28DFcB694H34ZrPg1XRct2SQI4R9GyYThH0AjOURTFOYMiOGdRD+cVdIYL/FlBLVzht+Nk3ZPaZ8qu+vg1o3UVplX8gd4OL42PUOseE4pb6AiG8RnqvRVk4R46g00cQdH3mC4VBaEIPMJf+5oUl09UqtRFJf51H2vSLADTVwAAAABJRU5ErkJggg=="
    },
    "OPEN": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA7UlEQVQ4jbWU3Q2EIBCE94FnK7ACq7AI6znx6rEIq7ACKrCCywBDgMNf7ibZSAh8zrKwSs7V+6AWH0WpA5AWkbEwzzlApxyujkDtINJ0LqhtFTGz/dL55PcUgRYGEKIkwLuXGwNs5uBY58BTWC6uMxGUwP4ubAeqCQxn9kTt4NJPHMYHXwEdFe/YU3cUDQVgrcMmAv5U6h/ABVXGC6hJe1tToNQCzZymPKHkNZXenMNFxc8Of3kCNd4djMVFsS4xuAM1rkHY/bFDugwP/Qo0g311mwSKhSgSeyKFs2JPZGZH/ZBQXqV+fe8aTEDUBwH0WlgR1ruGAAAAAElFTkSuQmCC"
    },
    "PERM_ADVISORY": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABgAAAAWCAYAAADafVyIAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABjklEQVRIibWUPWoCQRxHH2JhrSfIGbxArMQbWNpYKpiAq/gxIyS6jUgg2AUkhQQvkCrGysLGwgsIFoI2CRIkATU4G5fVrLpuzK/a+Xrvv7Oz4+Wf491pyzNxpZ3gEhBnEpgSq+C1UoFMhj+l04FQSBX6S1DSdUQ8DoGAO/hyCVdXhmfTZxXI93fEzQ3Uau4EjQb0+0axdgI1UK8jkkm4uDgN/vEB+bwJt32DdeTXF+RyiGbzNIGuw3hsMKz9uwKVpye4voZg0Bl8NIJqVT2GdsfsBHK1gnQa8fLiTJDNwnyutqXjRKAk7Tbi+RkikcPwXg9+ttP8sE4EaoGmIcJh8Hj2T0qlYLWyr/6YQA4GiMdHiMXsJ7Ra0O3ur/6YQC0sFhHRKPh82wOfn6BpJrzjViBHI8TdnQkzs/4Zh0NjziHAMcHWFeL3qzaTCZTLxhhH4kQg394Qt7fmWadQgNlMbYs8h2Cd0v09IpFQYB4ejD4cxKlAbq6Q6RQWi/3H0q2ANdByPzmCnyw4BexG4CrfiBuAXPvavTIAAAAASUVORK5CYII="
    },
    "Ambient Monitoring Stations": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAf0lEQVQ4je2TwQnAIAxF/8Gl6smdHEAdwJ16StYq0XgsxdhDkX6QaDDPr0GHl+V+4LLcFsAMIOn8BFA0moBBYDH2BTMCUcsVPWgamLwHBlBUaxvJCgzH8bBjtSnMLZjfsMj1BDKcEvW8FZjFDRGSNmPAzA6hxbeAPX/KlL4PvACMZh+cJvL7wwAAAABJRU5ErkJggg=="
    },
    "USGS Surface Water Gages": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAgElEQVQ4je3T0Q3AEBAG4P/BLKxhAGbCTAzQNdw0Dc5j0/T0oZHeyznhyyEUXg71g8uhtgAjgMDjA0DiLAJtx7wfVa0WRJbRKAEDtAacG1XLpQA5ByloYczNktVHqRUrd5j68RoyOyUa80Iw9m6IAj/GxMQdgjdfAnv+lEfxffAEh44hHAm+saYAAAAASUVORK5CYII="
    },
    "Anadromous Rearing Reaches": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABP0lEQVQ4je2SMUsDQRSE591tQiysrLxcDi3ExlL8B6KNioUgiFYWVpZWEtcUBhuDaCNapBcs/A3+AbGwsYjmDoNKAhaRk2RHzoBGvKQxnRnYYt/wDY/ZVeixVD/wv3SYdYI5S7gI8uk90Tzcux95jOYaVHD8DQimDHhdGwiPj+7Gwm6M0m55nTSnjG4CJBv2mnbLE9rPVOH4RQpWWpasDtVT0wBmuzEKxGb7ygSGSVnSbvmcbIV9eYKZbScYB00sA+BEUWBHk3ZZNHbYTNhJu/G7I9OwqaxYBpFPypmAB21eFcnmRb7kPe+k/UuA89+WXOmKd5tNB7EMosBc4BZ0+qFGkQUYvIiFfV0arUTmq3B50GBLBJMC3tTDVB4Q5gJ0ZNTnawZeEUB0fqjgZ94A7MZ9j06MQo+l+oF/Vs87/ACk8ps3XVGYlQAAAABJRU5ErkJggg=="
    },
    "Anadromous Spawning Reaches": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABG0lEQVQ4je2QMUoDURCGv8m+pMkFsiewsRRvINqoWAiCaLXss9oihXWsxEYRJGTXLdILFp7BC4iFR1BUYhXCEh4ju1EIrpvGdOYvXjHz/n9mPsOcZRaB/4Wh9nqbiOwg8orqpVj7XNQ7HYPvHwGrwANZdiVRlM3yGI3jALgu4lXz91DTdFmCYECr1Qf2v4Yf0GisARuzPAaIfmzt49yupukNzn2HTSSyrt3uUpUHiPNArwRCxKNW83CuDCmvV3mYMEyB86nWgPH4VsLwTeP4Dtia6t1j7RNJ8quHPFCsvdAk+UB1G3jH887E2pfi23C4R7N5jOoK8Ei9fioiObRKjym2DcMcfr90Rbs9Ak7Kd1d7DHOWWQT+WXNn+Aku+nD3zDcsAgAAAABJRU5ErkJggg=="
    },
    "Healthy": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAsElEQVQ4je3SsQnCUBDG8X/iI5tYiNYuIbaJ2gvaiNkgCwQjOkOQVFY22jmAC9gJVlbpIsk7IYVo6TONxK+8gx/H3SkqjvqDddnhfEMLoYfNOfLYfgX6CV3RHLFwkBJfRQNmxqBoxoDzUpv6MTsa5J9AC49DCQLpW0fItKKwxXDCXAiVRR9oApnYTJYuezMO1HrINUjopAVtS7iEI26m2PPKgcsdOFGfx64yqlLtJ8AHKOotLaUCJr8AAAAASUVORK5CYII="
    },
    "Depressed": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAs0lEQVQ4je3SsQ7BYBSG4fdvf2Zbo4PNIDW7CbG6g2oN3IlJRK/BZLKwuQCTzSZpE2GxlcqRSohVdZH61pM8+XLO0eQc/QeLssNwQkOZtEWxsz3mX4HRlJYo1gJlBKKAcdVnmBkUcEmxZ4T+IWAhBsknkNVj9QBRnN8HoojF5Ja2zdQwuTLSJTpAHYgB33JZZuNA1waE2xnNyglHGextj2NW7HVlp8sF2FCcx84zOlftJ8A7dp8qb4+CEP4AAAAASUVORK5CYII="
    },
    "Critical": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAmUlEQVQ4je3SsQpBcRQH4E/dzLabO9gMYvYS8hiKxZuYvIjJwuYBTDaburdkshH9RZLVdRddv/Gc+jqdcyIFJ/qDZdlhSqtCL7BNmH0FZnQDq0D1XsiY1hnnBgMDT+yZ0Z554PIJFLN8gDi+NwKnwDX3hBcmEX00ccIwZpEbbJBu6NRoV9glHPJiryu3OWOtPI9dZKJCtZ8AbyTxH60ygAcSAAAAAElFTkSuQmCC"
    },
    "Extinct": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAs0lEQVQ4je3SMQoCMRAF0D8QvImFaO0lRLIp9giCNt7EymOkMQnYaOcBvMB2gpWVXSSTSAQt7IzbyPrLGXgMMyPQcsQf7MoOlVKDEMIEQOOc23wFVlU1ZuYDEfVyQUq5NsYsi0Fmnj2xnJTSQim1jTGGTyBjzP4BEtH1reeZmYmobEIAKwBTAP2MAZhba3dFGgBhrT3XdT3y3g9TSifn3KUUe11Za30DcER3HrvNiFa1nwDvaIg9dj5R6Q4AAAAASUVORK5CYII="
    },
    "Unknown": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAqUlEQVQ4je3SPQrCQBCG4XdhsU63ZIt0FhJrLyEeQ9DGm1h5ESsb7TyAVTo7IQvByi4S+cQfxNaYRuJXzsDDMDOWhmP/YFt2mOf0jGEosfee5VdgCAwkthKdWyEEFnHMrDYoMYYH9sy0KFhJVJ9AzrG5g8DpvSFRSlxqT1hVzK1lBHSBEpg4x7o2mCTkWUY/ikiN4eA9x7rY68ppyhnY0Z7HbjK2Ue0nwCuPUSzvRmeaewAAAABJRU5ErkJggg=="
    },
    "Not Rated": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAtklEQVQ4je3SMQrCQBAF0L9D2CY3SLFTWchaewnJMQRtvImVF7Gy0c4DWKUTsmHBysouojuiYHrXbST+cgYew8xkSJzsD/Zlh865IYAJER2NMeuvQO/9OISwB6BFBE3TrJh5EQ2GEKZP7F1QSs3rut4Q0e0TiJl3L1BELkqpriEiLRHdoyckoqWIlAAGAFoAM2beRoPGmFNVVaM8z63W2hdFcY7Fuitba68ADujPY6dMllT7CfAB5pQ0RvDSYBkAAAAASUVORK5CYII="
    },
    "Aquatic Bed": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAARElEQVQ4jWNhaP7vwMDAAMLUAAdYwIYxMtRTxbj/DAwgA6kKWEYNpBiwUG4EKhg1kHLAQgUzUMCogSMlDA+ASloqgQMA9CcHUUgMLU0AAAAASUVORK5CYII="
    },
    "Emergent": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAQklEQVQ4jWNh+N/swMDAAMLUAAdYIIYx1lPHvP8MIAOpClhGDaQYsFBuBCoYNZBywEIFM1DAqIEjJQwPgEpaKoEDAPKkB1G8IFkCAAAAAElFTkSuQmCC"
    },
    "Forested": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAQ0lEQVQ4jWNhaGZwYGAAY2qAAyxgwxgZ6qli3H8GBpCBVAUsowZSDFgoNwIVjBpIOWChghkoYNTAkRKGB0AlLZXAAQCpHgZS0jiZ/AAAAABJRU5ErkJggg=="
    },
    "Shrub/Scrub": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAARElEQVQ4jWN5nvPcgYGBAYSpAQ6wgA37z1BPFeMYGRhABlIVsIwaSDFgodwIVDBqIOWAhQpmoIBRA0dKGB4AlbRUAgcAfWcHC64mse0AAAAASUVORK5CYII="
    },
    "Tidal Aquatic Bed": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAARUlEQVQ4je3UoQ0AQAhD0ZJ0sNvsBIuxGRckmrrjJ9gnKqB7HgB1ioKFmeEqtEygQGlccBznRG/BeRQYrQV/2TDq04qKBzMyCdN8w7ovAAAAAElFTkSuQmCC"
    },
    "Tidal Emergent": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAASElEQVQ4je3UoREAIAxD0dDLYAj2QnSxTgYcEt04+gd4IiJ0Hx1YHZIseLHWMBXc3guEOBaYjnnircB8FBhPBX6yocV9Wk0WB8neDRa9qG4UAAAAAElFTkSuQmCC"
    },
    "Tidal Forested": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAARklEQVQ4je3UoQ0AIBBD0R/SwRDsxWpMRoAEg7467g/wREVFozKpOCoMXaxbwAnCnBIMpzjxlmA8GYynBP/YsDDO01ra1gJSqggSCfjXFwAAAABJRU5ErkJggg=="
    },
    "Tidal Shrub/Scrub": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAARUlEQVQ4je3UoQ0AQAhD0ZJ0sNvsBIuxGRckmrrjJ9gnKqDDD4A6RcHCDHYVWiJRoDQuOI5zorfgPAqM1oK/bBj1aUXFA+WYCNRPuzieAAAAAElFTkSuQmCC"
    },
    "Single Family": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAARUlEQVQ4je3UoQ0AQAhDUUg62G12q+EZikslmrrjJ9gnKkBmHjPjKQoQc/er0KrKCErDguMwJ3oLzoPAaC34y4bBTysqHnecDC4PWSaXAAAAAElFTkSuQmCC"
    },
    "Multi-Family": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAARklEQVQ4je3UsQ0AMAgDQZA8WDbLICzDZkQuqXEXXqK9wgWIiGNmPEUJYu5+FVpVGUFpWHAc5kRvwXkQGK0Ff9kw+WlF5QPpQAueQWwKqAAAAABJRU5ErkJggg=="
    },
    "Manufacturing": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAARUlEQVQ4jWP5z3DYgYGByYGBKuDfARaoYfXUMZCJgYWByoBl1ECKAQvlRqCCUQMpByxUMAMFjBo4QsLw3wFQSUsd8O8AAPbvCJQvMBqgAAAAAElFTkSuQmCC"
    },
    "Utility-Trans": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAARUlEQVQ4jWN5vP+3AwMDAwhTAxxgARvGyFhPFeP+/2cAGUhVwDJqIMWAhXIjUMGogZQDFiqYgQJGDRwpYXgAVNJSCRwAAPB1Cmv56M+QAAAAAElFTkSuQmCC"
    },
    "Whole Sale-Retail": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAR0lEQVQ4je3UsQkAIBBD0Y9kMDdzNcG9VAQb60vn/QFekSIarFqgYmhC18WaAyyAMKcEwylOvCUYTwbjKcEvNpzQz9M6OtYG/HsIlLPy6EkAAAAASUVORK5CYII="
    },
    "Services": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAARElEQVQ4jWO5X/LfgYGBAYSpAQ6wgA37z1BPFeMYGRhABlIVsIwaSDFgodwIVDBqIOWAhQpmoIBRA0dKGB4AlbRUAgcAFQYHIzGJdrIAAAAASUVORK5CYII="
    },
    "Culture-Recreation": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAASElEQVQ4je3UOxUAIAxD0cCJMJwhjbrBBd+NudnoE3CHDOFsvWCgQFGG8WIpVQk4JghxDNAd/cRbgP4oMJ4C/GPDDDtPK2lbC2EKDHNvgbMRAAAAAElFTkSuQmCC"
    },
    "Parks": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAR0lEQVQ4je3UsQ0AIAwDwRfyYAzBPqzGZIBSUscd+QGucGGNQ2/QMbRhKbAD0wE2QJhTgemUJ94KzCeD8VTgFxtuWPG0jsK6N7sJJLFVU5UAAAAASUVORK5CYII="
    },
    "Agriculture": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAPUlEQVQ4jWP5/5/BgYEBjKkBDrBADaunkoEMIAOpClhGDaQYsFBuBCoYNZBywEIFM1DAqIEjJQwPUNG8AwAFwgXOXgdM7QAAAABJRU5ErkJggg=="
    },
    "Fishing": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAPklEQVQ4jWNhKPjvwMDAAMLUAAdYoIbVU8lABpCBVAUsowZSDFgoNwIVjBpIOWChghkoYNTAkRKGB6ho3gEAe7oFP0FgnzEAAAAASUVORK5CYII="
    },
    "Mining": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAASElEQVQ4je3UoQ0AIBBD0R/SwRDshWQtJiOEBIO+Ou4P8ERFNVqrBSqGFkxdrDvAAghzSjCc4sRbgvFkMJ4S/GLDBfM8raNjbZFoB+FMd4hlAAAAAElFTkSuQmCC"
    },
    "Other Resource": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAQklEQVQ4jWP5v7zBgYGBAYSpAQ6wQAz7X08d8xgZQAZSFbCMGkgxYKHcCFQwaiDlgIUKZqCAUQNHShgeAJW0VAIHAP5uBvfLJSGZAAAAAElFTkSuQmCC"
    },
    "Public Forest": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAQ0lEQVQ4jWMpWM7gwMAAxtQAB1jAhjEy1FPFuP8MDCADqQpYRg2kGLBQbgQqGDWQcsBCBTNQwKiBIyUMD4BKWiqBAwBRMgbmk3zEygAAAABJRU5ErkJggg=="
    },
    "Private/Comercial Forest": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAQklEQVQ4jWNZ/L/EgYGBAYSpAQ6wQAz7X08d8xgZQAZSFbCMGkgxYKHcCFQwaiDlgIUKZqCAUQNHShgeAJW0VAIHAJj+BucsYHW6AAAAAElFTkSuQmCC"
    },
    "Undeveloped": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAASklEQVQ4jWO5fni+A8O/fw4M1ABMTAdYwIYxMtZTxcB//xhYGKgMWEYNpBiwUG4EKhg1kHLAQgUzUMCogSMjDJmYDoBKWqoAoFkA8isOB40JYBYAAAAASUVORK5CYII="
    },
    "Water": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAARklEQVQ4jWOZfvuzAwPDPwcGqgCmAywQwxjrqWPgPwYWBioDllEDKQYslBuBCkYNpBywUMEMFDBq4AgJQ6YDoJKWOoDpAADJoQo07XWT4gAAAABJRU5ErkJggg=="
    },
    "Open Space": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAASklEQVQ4jWPZfH2+AwPDPwcGqgCmAywgwxgZGOupYdx/hn8MLAxUBiyjBlIMWCg3AhWMGkg5YKGCGShg1MAREoZMB0AlLXUA0wEAUSAL9yrY2QUAAAAASUVORK5CYII="
    },
    "Swamp/Marsh": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA3klEQVQ4jWPZcfZBCzsLiwMDFDx9+5lBWpgXTMMAjI9LHMb++efPARaQYQ560tYMVAAHLj1lYGGgMmAhVuHS/TcYoh01wDQMIPOjHTVIMxCmAUaji8PAwHmZZAOXIoURrrBC9x5eA6PRwohQWBE0kFqABZcELi8T8joLLglcXibkdRYGKoClSK6mfhg+ffsZxQb0rETIReiABVTsOOhJwwVwKUQ2BF/ksDAQCYhN3CzEGohuKDYLQMGHNQzxuYKQS1lE+DkPsLOwgEtbEACFKYyNDJCrABANMhSdBpkFAPTChVFF80IXAAAAAElFTkSuQmCC"
    },
    "Lake/Pond/Estuary": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAASUlEQVQ4jWNZsvdZCwPjPwcGaoD/TAdYIIZ9tqaKgYy8DCwMVAYsowZSDFgoNwIVjBpIOWChghkoYNTAkRGG/5kOgEpaqgCgWQCLHgwntdyDmAAAAABJRU5ErkJggg=="
    },
    "High; H": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA+ElEQVQ4ja2UIQ7CQBBFH8mQVCDRIDkAsgi4Rcs90ECCIGnvAbegggpED0E1Eg+ZLtuUphg6X+1mJy/5s39GZnAYwRKggCsNzWFBSw8o71B21TwhE4VtIHzBPoadfzzDBQhbvCyCtb+cYDdo1CSA6OEHbNkBW7Vg27YDUQtWsAeU0uxHH9gL9ncIxAoWO5dHMYRVks+3hxawKUxqy31hZ7gkMBQrGK5luRjCKoklrA52xzj9BVN9BbsnLCvgJlawyLXMBdsIVkk0jAOIMYBNNdhjmFjA1GXaNSk9d2MuurZ103oVEGhzvQUdJy1s0vQ3fY0qdfsgV9Ybliycae2JuuMAAAAASUVORK5CYII="
    },
    "Moderate; M": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABF0lEQVQ4ja2VMU7DQBBF30qDlIYO2kBHl4qKUIQL4AohOAgtiURByElQKnyBuMAFkqucIHEbOg6waNY4ccw24Pntjp70Z/7MysUZT0eHjADSgncauj7nkpZWG8rlmjJW8/lFJgp7e2CIZ+JuGdePfs4Cz3CP5sjcDffbmlfGuF1N8gJSvURhowjsqgV7bDsQtWAFW20opdmPLjA8k+WanljBXOXyWQxhQRLGHp/mn2GDE/pby11hfs4imXIgVjCqluViCAsSS9gu2L/X6V8w1X6wu8AcWVrwIVYwV7XsJ9g2sCDRMOK4wwA20GCfHtO3gKnLZBbblG63MRc923ppa6UFPW1ubUHXSQubPJ1mXaNKZuEbyJX1DSep4GAGNR4VAAAAAElFTkSuQmCC"
    },
    "Low; L": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABE0lEQVQ4ja2UMW7CQBBFn6WJgpSCglQpUE6QA8Qp4AJxBVJymYBEkbNAhS+AC2+RlhMgF67SUFBQbTR2FhnLaeL51a529KQ/+2dk9Mzq9p4JQJmS09DDKy+0dDpQHPcUXTXnbzJRWLwljmC5jliEx5lnB8QtXraJeA+XuWfhGzUuAdHDH7BJB2zagn20HYhasIKdDhTS7EcfWATL456BWMHWtctPMYRVkt9vjy1gwyfGF8t9YTPPziXciBWMumVODGGVxBJ2CXbHOP0LproKdk9YVqZ8iRVsU7esDrYRrJJoGD28YQAbarDvHhlbwNRlnnRMSs/d6ETXtm7aoDJloM0NFnSctLBJ098MNao8qfaBU9YP67DQr8xxkDwAAAAASUVORK5CYII="
    },
    "Not Rated; N; U": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABG0lEQVQ4ja2VMW6DQBBFd6Sx5IqWzqLlBBRO4RwAUSdXoYgtwSrXQXsAU5hibrGi25YLjDWgRYTgImF/BWL0pD/zZ8A0Tasoii5KKUVED7VQlmVvaiXnXG+t7bdqhmFoUWBlWZ6Z+VYUxdV/NMbcmfm8hAFAm+f5p39vmuYKAHNNXdcK5eEF7LIBe1/BvtYOUCyEgjnnelz2Yw+MmW/W2iOGghWTy28MCBuFMvYX0/wzLEmS02x5L8wYc6+q6oChYDy1rMOAsFEYEjYHe2Od/gUT/Qj2HhgAtEREGAqWTy2bgh0INgoljADwoQLAEgl2HMenEDBxqbX+vSk7b2OHcrbl0noR0VGa6y3IOknhEijT9DUirbX8BjphPQHyLlm7kuJNLgAAAABJRU5ErkJggg=="
    },
    "Miltary Base": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABhUlEQVQ4ja2UO04DMRCGZxLXVNvSUCFRcgi4wJ4giJ5UFIk3YxuJihtwAk7ACVJRglLlBKno40GTZIR3sb0NI608ux59638eNs65e2a+AIAGjrY7rYCIl8y8gYxl9hpE3BqBdV33KF9CCK2si8Xi7eR/iJ8DOueuAKDtum4lPjO3zHxm0iAFee9fAGBdgolZaz+dcyCxMcZvARPRcw/4H2bSF5W8XC7n4stTkywyrbVz8Ylo1QOm+dO1BFWYyIREPgA8mRxMLQcdwtQESkQbo+1SkpZCY4xfOVhqhxPWqqn73vsZIj5Ya+9qsQYRm1ryVWaM8Xw6nb6PxRrt9lLgMGdj1TcqKReYK0Ct+gDQmFJgqZolaAih3e/3/cbWQGlSZoZaNVMo/Nruz+hJayDibDKZvJZgKdQf5/4wXUR03QMmMm/Hkq8yAWCdlTzM2Ujy/0xXCKGVO/IALBWgBM2NqvgquamN0xBamns1mZSt3LRyOZZyJbmRHxPRTYxRDrCR0wzjhPUD8yp2npqGwp8AAAAASUVORK5CYII="
    },
    "Not Tested - Possibly Contaminated": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAASUlEQVQ4je3UMREAMAhDUchFWJ1VFAaqLNdj7Axb+QLekCGMiCVpWUMADhNz990BSjJacxywHOvE24D12GA8DfjFhgBOPm1HaV1pzxHmorsTjQAAAABJRU5ErkJggg=="
    },
    "Non-Detect to 20.0 ppm": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAS0lEQVQ4je3UMREAIAxD0dCLMJwhAUt4wUfhOjI3G/0C3pAhPHt2h3UIMvhiYA0YCtBhIMSxwHTME28F5qPAeCrwiw0NvuJpFYV1AYuYDULN43JaAAAAAElFTkSuQmCC"
    },
    "20.1 ppm to 40.0 ppm": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAS0lEQVQ4je3UsQ0AIAwDQQd5MEZgI1ZDYi+CUlLHHfkBrnBh+h79AB2CGrAYmJlPBXjcQIhjgemYJ94KzEeB8VTgFxs2YMXTKgrrAsKpDOE5lNhLAAAAAElFTkSuQmCC"
    },
    "40.1 ppm to 100.0 ppm": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAATElEQVQ4je3UMRHAMAxDUUcnMIERZoVROkGm9Dxmtrb6A3iDBvG8cwlYYQjSZmIjzuMABQTDHBssxzpx12A9GoyrBn+xIaSdT+sorQ+HExCQGiODgAAAAABJRU5ErkJggg=="
    },
    "Greater than 100.1 ppm": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAATUlEQVQ4jWO5niDgwMTwz4GBCuAfA9MBFrBh/xnqqWEgE+M/BhYGKgOWUQMpBiyUG4EKRg2kHLBQwQwUMGrgiAjDfwxMB0AlLTUAyCwAJXkNGKRvvTQAAAAASUVORK5CYII="
    },
    "Ruston Superfund Site": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAQklEQVQ4jWPxYShxYGBgAGFqgAMsEMP+11PHPEYGkIFUBSyjBlIMWCg3AhWMGkg5YKGCGShg1MCREoYHQCUtlcABACajBZGOWfmXAAAAAElFTkSuQmCC"
    },
    "Basin": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAATklEQVQ4jWNJrglt+c3yw4GBCoD1D8cBFpBht9Q3W1PDQLWbvgwsDFQGLKMGUgxYKDcCFYwaSDlgoYIZKGDUwBERhqx/OA6ASlpqAJBZANxBEFnmokWuAAAAAElFTkSuQmCC"
    },
    "Harbor": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAATklEQVQ4jWNJqalp+cfC6sBABcD05/cBFpBhD9W1rKlhoPzNawwsDFQGLKMGUgxYKDcCFYwaSDlgoYIZKGDUwBERhkx/fh8AlbTUACCzAEEfEfPZU04aAAAAAElFTkSuQmCC"
    },
    "Passage": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAT0lEQVQ4jWPpby1q4eFgcmCgAvjy498BFpBhKW481tQwcM6uLwwsDFQGLKMGUgxYKDcCFYwaSDlgoYIZKGDUwBERhl9+/DsAKmmpAUBmAQCvuBOKM38NewAAAABJRU5ErkJggg=="
    },
    "Rural": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAT0lEQVQ4jWPp729s4eFhc2CgAvjy5dcBFpBhKSnm1tQwcM6ckwwsDFQGLKMGUgxYKDcCFYwaSDlgoYIZKGDUwBERhl++/DoAKmmpAUBmAQB8xhNSUyf23gAAAABJRU5ErkJggg=="
    },
    "Urban": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAATklEQVQ4jWPJKWhuYWDkcGCgBvj/4wALyLDfbM7W1DCP9ddeBhYGKgOWUQMpBiyUG4EKRg2kHLBQwQwUMGrgyAjD/z8OgEpaqoD/Pw4AAA4aD+JHhx4AAAAAAElFTkSuQmCC"
    },
    "Admiralty Inlet": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAARklEQVQ4je3UoREAIAxD0cBlMDZjtTq2ajlw6MbRP8ATEWEsDKAPSHLjxVpMiRcdhDgWmI554q3AfBQYTwV+sqHbeVpNbhsHvguWtJSg5QAAAABJRU5ErkJggg=="
    },
    "Central Sound": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAASklEQVQ4jWM5XfHdgYHpnwMDNcA/pgMsYMP+M9ZTxUCmfwwsDFQGLKMGUgxYKDcCFYwaSDlgoYIZKGDUwJERhv+YDoBKWqoAoFkAIbAOCo9R7oUAAAAASUVORK5CYII="
    },
    "Eastern Strait of Juan de Fuca": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAPUlEQVQ4jWP5/5/BgYEBjKkBDrBADaunkoEMIAOpClhGDaQYsFBuBCoYNZBywEIFM1DAqIEjJQwPUNG8AwAFwgXOXgdM7QAAAABJRU5ErkJggg=="
    },
    "Hood Canal": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAR0lEQVQ4je3UsQ0AIAwDwRfyYAzBPqzGZIBSUscd+QGucGGNQ2/QMbRhKbAD0wE2QJhTgemUJ94KzCeD8VTgFxtuWPG0jsK6N7sJJLFVU5UAAAAASUVORK5CYII="
    },
    "San Juan Archipelago": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAASElEQVQ4je3Uuw0AIAxDQYM8GJuxGhJ7JXw66rgjb4ArXJiO2VDRoMgweDEvXQJWByGOCYZjnHhLMB4FxlOCf2xoGOdpJW1rAe+mC5I+XjkLAAAAAElFTkSuQmCC"
    },
    "South Sound": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAASElEQVQ4je3UOxUAIAxD0dATYUjCAdZQVn4bc7PRJ+AOGUK0WWFeocht8GKzdAloDkIcEwzHOPGWYDwKjKcE/9jQbZynlbStBR2MDT8m3Ck7AAAAAElFTkSuQmCC"
    },
    "Strait of Georgia": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAASElEQVQ4jWP5v329AwMTkwMDNcC/fwdYoIbVU8VAJiYGFgYqA5ZRAykGLJQbgQpGDaQcsFDBDBQwauDICMN//w6ASlqqAKBZAG3MDDlNHbFhAAAAAElFTkSuQmCC"
    },
    "Whidbey Basin": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAASklEQVQ4je3UsRHAMAxCUYljMG+W1bxTepxT6Vp00R/gFRTwvGdJWmEIwGZhmfk4QEnBMMcB27FP3A3YjwbjasBfbAhg19M6KusDv0oSvr2YILcAAAAASUVORK5CYII="
    },
    "FBE": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAASklEQVQ4jWNhoDJgGTWQYsAyQg1cznDbgRqGRTKoHmABGcbIwLCfGgYuZ7jtSH0vRzKoHgCZTDUvM0AZDCMr2VATsFDVNIYRaSAAEzwNE15W6lAAAAAASUVORK5CYII="
    },
    "FB": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAARUlEQVQ4jWNhoDJgGTWQYsAyUg2c/t+BKqZlMh5gARvGxLCfKgZO/+9IAy9nMh4AmUw9L4MAyNARlmyoCFioaRjDyDQQADf0DRQxGx6NAAAAAElFTkSuQmCC"
    },
    "TZ": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAASklEQVQ4jWNhoDJgGTWQYsAyQg0suf7fgRqG9WgyHmABGfb/P8N+ahhYcv2/I/W93KPJeABkMtW8zABlMIysZENNwEJV0xhGpIEAy6ATEARiRVEAAAAASUVORK5CYII="
    },
    "Talus (FB)": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAASklEQVQ4jWNhoDJgGTWQYsAyQg18Pp3BgRqGSWYyHGABGfafkWE/NQx8Pp3BkfpelsxkOAAymWpeZoAyGEZWsqEmYKGqaQwj0kAAgn4OEtwseOsAAAAASUVORK5CYII="
    },
    "MOD": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAO0lEQVQ4jWNhoDJgGTWQYsBCuRGoYKQa+IyB4T81DJNiYGCkjQulGBgYqWogNQELVU1jGDWQGmDwRwoAPlUCbJpGBkgAAAAASUVORK5CYII="
    },
    "AS": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAOUlEQVQ4je3RsQkAMAwDQQm0/8pOndpvCMQ/wCFQBJcF26VP3P0KVqkIzJZnFtoyCpIF1bQg0funHHZhA1TlMQgQAAAAAElFTkSuQmCC"
    },
    "NAD-AR": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAP0lEQVQ4jWNhoDJgGTWQYsBCuRGoYCQb+H8iw39kCcZ8BkZkPiF52ruQEYeNxMoPwUihFmChmklQMGogA8UAALPxBzMS3rJRAAAAAElFTkSuQmCC"
    },
    "NAD-B": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAP0lEQVQ4jWNhoDJgGTWQYsBCuRGoYCQbuHlq9X9kCd/sVkZkPiF52rvQF4eNxMoPwUihFmChmklQMGogA8UAAMBqCs2PSF71AAAAAElFTkSuQmCC"
    },
    "NAD-D": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAP0lEQVQ4jWNhoDJgGTWQYsBCuRGoYCQbWHDG7D+yxASTU4zIfELytHfhBBw2Eis/BCOFWoCFaiZBwaiBDBQDAHNCC289vq9sAAAAAElFTkSuQmCC"
    },
    "NAD-LE": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAP0lEQVQ4jWNhoDJgGTWQYsBCuRGoYCQbaNge8h9Z4nzlGkZkPiF52rvwPA4biZUfgpFCLcBCNZOgYNRABooBAJeODDt7BG1vAAAAAElFTkSuQmCC"
    },
    "<all other values>": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAQUlEQVQ4jWNhYGBIY2BgUGKgDrjHAjWsgkoGdoAMpCpgGTWQYsBCuRGoYNRAygELFcxAAaMGjpQwvAcqaalk3j0ArYYFHXp+ESgAAAAASUVORK5CYII="
    },
    "10, 20, 30, 40, 50 m": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAQ0lEQVQ4jWNhoDJgGTWQYsBCuRGoYKQaePXq/wYmJgZ7Sgz694/hoLY2YwNtXKitzdhAVQOpCVioahrDqIHUAIM/UgCoSQgoWGV7nwAAAABJRU5ErkJggg=="
    },
    "60, 70, 80, 90 m": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAARElEQVQ4jWNhoDJgGTWQYsBCuRGoYKQaOHfx8wYmRkZ7Sgz69///weRYyQbauDA5VrKBqgZSE7BQ1TSGUQOpAQZ/pAAAI0oJqhnW8W4AAAAASUVORK5CYII="
    },
    "100, 150 m": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAQklEQVQ4jWNhoDJgGTWQYsBCuRGoYKQamFN3uYHhP6M9RSYx/j84pUm3gTYunNKk20BVA6kJWKhqGsOogdQAgz9SAD3XCRTAzcgqAAAAAElFTkSuQmCC"
    },
    "200 m": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAQ0lEQVQ4jWNhoDJgGTWQYsBCuRGoYKQa6BC3t4HhP6M9RSYx/j94YJFzA21ceGCRcwNVDaQmYKGqaQyjBlIDDP5IAQA3Mgl2LCBSCgAAAABJRU5ErkJggg=="
    },
    "400 m": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAARElEQVQ4jWNhoDJgGTWQYsBCuRGoYKQaKOe1vOH/f0Z7SgxiZPx/8NG2yAbauPDRtsgGqhpITcBCVdMYRg2kBhj8kQIAan4KwliSRB0AAAAASUVORK5CYII="
    },
    "600 m": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAQUlEQVQ4jWNhoDJgGTWQYsBCuRGoYMQaaDW5geE/oz1FJjH+P8hwLLeBRi48lttAXQOpCFioaRgIjBpIORj8YQgAnhcIBIsuxSUAAAAASUVORK5CYII="
    },
    "Area to be Avoided": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAWklEQVQ4jWP5z3D0PwMVAQuYtOJlYJBhp8ykJz8ZGI59hhoIMkyWjQru+ww1kIqAZdRAigEL5UagglEDKQcsVDADBYwaSDmAhCGocKQUQM2AGHjsM7hwpAYAAK1ADrN4euA/AAAAAElFTkSuQmCC"
    },
    "Mandatory Ship Reporting for the Protection of Northern Right Whales": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAXElEQVQ4jWPhmvznPwMVAQuIMBVgYpBip8ygZz8ZGE5/+AcxEGSYFAcjhW77j3AhNQHLqIEUAxbKjUAFowZSDlioYAYKGDWQcgAOQ1DhCCvPyAUQM6AGgkpaagEAMw0QonJezNcAAAAASUVORK5CYII="
    },
    "Particularly Sensitive Sea Area": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAVUlEQVQ4je3UIRLAIAxE0RU7oNr7X4GLcQBQrWkHEoPeyHwR+cSKEL19CIz7XjdQqia9DzCHgwtTQc/AwJigHHXiLEE9BhhHCerZhus5qrlh4Bw66P0UUwzEixe/NgAAAABJRU5ErkJggg=="
    },
    "Precautionary Area": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAVUlEQVQ4jWMp3vT/PwMVAQuIUBNgYBDioMygdz8YGG59gBoIMkyYg4oupCZgoappDKMGUgOwUMUUJDBqIOWAhQpmoICRauC7H5QbBDMDbCCopKUWAABbqg0/LT6rkQAAAABJRU5ErkJggg=="
    },
    "Recommended Routes": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAXUlEQVQ4jWNZfTbtPwMVAQuIEBThYuDkYqXIoO/ffjO8f/MNYiDIMA4KDQSB9zAXUhOwjBpIMWCh3AhUMGog5YCFCmaggFEDKQfgMAQVjpQCmBlgA0ElLahwpAYAAHckE9w+Ky7bAAAAAElFTkSuQmCC"
    },
    "Separation Zone": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAASklEQVQ4je3UsREAIQwDwcOjwujsS3t3QxfAEBLbGb4CNlAgjX91Jp2MDNfBWuPL8NYEkZwKDKc4cVdgPCUYVwW+saHh52lTMnwDZNgMc0qw0osAAAAASUVORK5CYII="
    },
    "Shipping Safety Fairway": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAZ0lEQVQ4je3UsRGAMAxDUZHzEmzDGkzDMQ3HFkwDW8Q5JTSp5dK/cPkKF7IXtyMw43HgLKiPAlWUbQGODhJbsUvghwuOMsDILEE504m5BPUswJhKUK//kOPIPVP6B3aAXFqOowISYw1+DxPv1NdHNgAAAABJRU5ErkJggg=="
    },
    "Traffic Lane": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAzklEQVQ4jbWV6w2DIBRGD8oSxmGKS9Q4jToNsUvoMoYpKs2NtfHVx4/b8/MGjvDBRcuTgHeRpGeHYSoyqmFdG+kaA/V2nBkyroVdCjIp4Iu9cC8TEiapHerCS/hu8hmfxllZfk7ZoISVLAJ++HV1X4UoEfAO0lpNKESiUxUKVu4Pili5jKpClMiohpGuVc0wp2z0DwVtYeDWw71V65RIdAZarW6xGiJW7+l/DmUicWcP5tzwR87yFodZWs/A5fx7aS0ZrytxznsjnLc7/xIe9ZxBRle1pv0AAAAASUVORK5CYII="
    },
    "3 - 6": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAARklEQVQ4je3UoQ0AIBBD0R/SwdiM1dgJT4AEg7467g/wREXFoFKoOJp0XaxZwALCnBIMpzjxlmA8GYynBP/YcNLP01ra1gLdHAfFFEtlggAAAABJRU5ErkJggg=="
    },
    "7 - 8": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAARUlEQVQ4jWOZ/J3BgYEBjKkBDrCADGNkYKinhmn/GRgYQAZSFbCMGkgxYKHcCFQwaiDlgIUKZqCAUQNHShgeAJW0VAIHACpXB1nTNqjdAAAAAElFTkSuQmCC"
    },
    "9 - 10": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAASElEQVQ4je3UsQ0AMAgDQQt5MDbLatkpPYqQ0lDjLvwAV7gwz4GbwSEoApsPWwrQDCDEccB27BO1AftRYJQG/GLDCOx8WkVpXVGYC7rkKw4uAAAAAElFTkSuQmCC"
    },
    "11 - 12": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAARUlEQVQ4jWP53c3gwMAAxtQAB1hAhjEyMNRTw7T/DAwMIAOpClhGDaQYsFBuBCoYNZBywEIFM1DAqIEjJQwPgEpaKoEDABF3B1Ud78UkAAAAAElFTkSuQmCC"
    },
    "13 - 17": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAR0lEQVQ4je3UsQ0AIAwDwRfyYGzGauxEHyEkGuq4Iz/AFS6sBb1Bx1DA1MWGA2yAMKcC0ylPvBWYTwbjqcAvNgyY52kdHWsD4REHxfc3P1gAAAAASUVORK5CYII="
    },
    "12 - 14": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAARklEQVQ4je3UoQ0AIBBD0R/SwdiM1dgJT4AEg7467g/wREXFoFKoOJp0XaxZwALCnBIMpzjxlmA8GYynBP/YcNLP01ra1gLdHAfFFEtlggAAAABJRU5ErkJggg=="
    },
    "15 - 16": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAARUlEQVQ4jWOZ/J3BgYEBjKkBDrCADGNkYKinhmn/GRgYQAZSFbCMGkgxYKHcCFQwaiDlgIUKZqCAUQNHShgeAJW0VAIHACpXB1nTNqjdAAAAAElFTkSuQmCC"
    },
    "17 - 20": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAASElEQVQ4je3UsQ0AMAgDQQt5MDbLatkpPYqQ0lDjLvwAV7gwz4GbwSEoApsPWwrQDCDEccB27BO1AftRYJQG/GLDCOx8WkVpXVGYC7rkKw4uAAAAAElFTkSuQmCC"
    },
    "21 - 23": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAARUlEQVQ4jWP53c3gwMAAxtQAB1hAhjEyMNRTw7T/DAwMIAOpClhGDaQYsFBuBCoYNZBywEIFM1DAqIEjJQwPgEpaKoEDABF3B1Ud78UkAAAAAElFTkSuQmCC"
    },
    "24 - 28": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAR0lEQVQ4je3UsQ0AIAwDwRfyYGzGauxEHyEkGuq4Iz/AFS6sBb1Bx1DA1MWGA2yAMKcC0ylPvBWYTwbjqcAvNgyY52kdHWsD4REHxfc3P1gAAAAASUVORK5CYII="
    },
    "General Use": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAATElEQVQ4je3UMREAIAxD0ZSLMJwhixUHSMAJcB2Zm41+AW/IEK7Z6wYqBBVg0DEzawpwnwNCHBMMxzjxlmA8CoynBL/YsADDn1aRWxfQ0g4yYVjvAwAAAABJRU5ErkJggg=="
    },
    "Dominant Use": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAS0lEQVQ4jWNpL3BxYGJgcGCgAvjHwHCABWpYPTUMZGJgYGBhoDJgGTWQYsBCuRGoYNRAygELFcxAAaMGjogw/MfAcABU0lIDgMwCAKePCA3MvN6UAAAAAElFTkSuQmCC"
    },
    "1 - Poor": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAPklEQVQ4jWP5//+/AwMDAwhTAxxggRpWTyUDGUAGUhWwjBpIMWCh3AhUMGog5YCFCmaggFEDR0oYHqCieQcAUMsGzaatHBgAAAAASUVORK5CYII="
    },
    "2 - Marginal": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAATUlEQVQ4je3UoRHAMBADwbNHhbmzwJRk6k7SiZN5aPxi+StggYD0zHtsGBjqsBRYa1wOcL8gzKnAdMoTZwXmk8E4KvAXG3ZY8bSOwvoAC4sL6zBwwTQAAAAASUVORK5CYII="
    },
    "3 - Fair": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAASElEQVQ4je3UuRXAIBAD0YGnwujMJbkFKuOwM+JVxk4BP1AgzZfGoOGo0vVjhccCDhDmlGA4xYmzBOPJYBwleMeGlf49raVtLUohCX2tJ3WxAAAAAElFTkSuQmCC"
    },
    "4 - Good": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAASUlEQVQ4jWP5X7DZgeEfgwMDNQATwwEWiGGM9VQx8N9/BhYGKgOWUQMpBiyUG4EKRg2kHLBQwQwUMGrgyAhDJoYDoJKWKgBoFgAfHgvvFkPlggAAAABJRU5ErkJggg=="
    },
    "5 - Excellent": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAATUlEQVQ4jWOZbnDagYGJwYGBGuAfwwEWkGGM/xnrqWHef6b/DCwMVAYsowZSDFgoNwIVjBpIOWChghkoYNTAkRGG/xgOgEpaqoB/DAcAwh0NYSDFOPwAAAAASUVORK5CYII="
    },
    "6 - Outstanding": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAPElEQVQ4jWP5z8DgwADB1AAHWKCG1VPJQAaQgVQFLKMGUgxYKDcCFYwaSDlgoYIZKGDUwJEShgeoaN4BALmrBM+A5UK1AAAAAElFTkSuQmCC"
    },
    "7 - Superb": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAPklEQVQ4jWNhYPjvwMDAAMLUAAdYoIbVU8lABpCBVAUsowZSDFgoNwIVjBpIOWChghkoYNTAkRKGB6ho3gEAt60Ez/21euUAAAAASUVORK5CYII="
    },
    "Launch Facilities, Hoist or Lift": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAES0lEQVRIib2WfUyVVRzHv+ecC9wXeUcv8hZvQrxJqKkBTaUmYdJWwoaOCJxlpDXbWmMtN/sjR2tqrVqTITExiOVWxhaOtKDMVrFxFQkTEEQCBCKUuFzuveec9lyG8ADCdbK+f53nOb/zfJ7v95znt0eD/0kaZwtlAZbbNZo1UsoAQOolSB/A291KcfmBQRIg1j0shxo8XrAbQ7zIqtU25hugg6urRQz2mdHZYrYVXiLCZrniKsURcgLD9w2yvohH7B7G45rNzwbSDVsDQYhqnkUDSH3a8T7yxp/u9pPF5dY9d2pdS8WnToOsB3yfpytDD7PcN4LgqlVPmv+FnBgD8TZO3SHkoegtmoOfcV57cswqzsS5aMTrpAS2BUH2oqAdCI46xrL3+852IdsuQ5h+Arz9AM7B0nepTLKMvBwaGFFj+/zoMUDsvyfIUqgNh4fPJyzrlTkQRcLUALZjH0ApePWHSmiKH1UNWZ2SSbvbqq20pnBmjCoQWxFSQbP2GUHZ3DyVhwRGOhwRY9BUaPPWse35WbKpQS/zR6pIOUZUIOvBsEQSHJlIvFeo4+q4AtF4bvr6ZgfkxDhocCR49QeOezRpC0hUojrG3DfdrSVvHwDEIRWILvMuouvSDHNcRMSDRcRPg/q7Ia+3gCZnzOvm7rqwmE3UVXsbMM+KjtuTSWAEllAEobEulvzGVdpytE2DdAavpaQoIqExOtZmCgfsM0BMo8NSSE6fROrjr7ML6a+Mp0GE0KkhrykDCYkGTUy5P0ZXK/jXJdC89A6g94C0T2glwZgaxLkFgAHmO5DXTICbDryuGywtG9As0nsFB689BaJ3B/TuEK2NoGvTIIf6LJTwXjVIiBFYzAbx+w+gG9MBTz/QyASAEiesADR1O4inL+Q/A5A32wEF1PmHi4ajWQ3yC/hOXDPlk7hHofQ3cf5LIH6Dc5kxNgnpvgriHwK46R0uMdzfR8owqgKxyIR3+S+1eTThMTq1mXK4H8THsZdOSfxcC5ZZACzzgvjxTB8ZHZnbgkjyoXZ7RVa9MF1Io0mPgz2VC/7VcbBndjsWLhydBK+rAolKmqy1mCF+q2tzKeXfzgE5XD25LYdXlXTJgFA9MQaDPfcy+PenQbyWg8Std8Sjkm0CsqMFoqkeJCEFVIlagVYe7eGDvQUzS1UgsnL3oGwsTudnK+ppei5T8mbb8iY3uPkixMBfgM0COT4GeaMVMHiChMeCZb82eTLtdvDTHw9j6NZebRmu3xPkgK0ruiAbizfzc1+cJTHrDXTNJiiNlqRmLpzeQA9ETWm/tIztdHm/p372/LwfiAPWWxnKG2oq+an3niCxGyl9eC1gcFcXCg7ZdRWiqcEsh29d1PzduZMcGR2a75n3/BJJwC5lwVZ56aMw0fprEf+mNAOCeztalZQC3DaOCcswtIbzbPz2YfJWsyoqp0F3gYmvdgLYu1jdYnL6v+5B9R/px6RBABuweAAAAABJRU5ErkJggg=="
    },
    "Launch ramps": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAEX0lEQVRIib2WW0wcVRjHvzOzt5nZZdllL7AXYSkNTTChMa1BiqW0SQkmDQ/6QDVqjSa0xEQxivXNFzFBDQmmRROjxsbbkw9NRIO1iEsFqYmx1SqLQFnYXdgLuzt7v5xjZizbHSiwBOM/mWTOnO/kd77/+eabkcH/JFmpgUP940Y5JXuAIGIBhFlCkBdReLbnlaO/7RlECEEX33J2sTT9tJ5TltvKmKxWqWDkCKXW0rmEj08kPnh7AmUwuamQUe8891JzaNegCwMTBz8dmnr/aLXJ2mDUWtGG+fsAoNGsFfezEk9qRly+j4cHJkbO9R0ZLhn00eDkk0ZW0X9yX6VNTlGSuXQeQzqXhzKlfP0RMnNM25mDjvy1pUD84oCzgdaqeru7D2W3BV16d/pRC6scPO4wVWycW4omwBXiQa2QC7bCg1ZJCN1sM3SZWOby6Jx3EACe3xL04ZvTtWVy+sKxGuMmiKCZYAzaHCZAgODK/Mq9QqBOz51ajWu/HB6YOFdsowSkKaMutVWbzBTaeCL/ysQpwRXkQccoYTs1242PzQRj7ODg1c97e9vCEtAXQ1ONZk7RqFFK3Vzmk3ArEC2M/bE0ZDAWod/dyaq+QgP2MrZ4Gd1eV6X56pb7RQB4XQLilPLzBwxl3MbdWTWMeK0rlMyAh0/C/Sax4rZUlVrVqpBRkfVxAYQxbjay21uyS6EqDSN/b+CH/Wf7Wl0FkIKmy+E/VqWaYdyhVC0A3AXRFLrrzx5EhFTu3GuVcoYgXCncF5984c2ccAfAzKmgTq/eFcTLJ2F80Q+d9VZQyWjIYqIigOISECYkJdREKpeHxUgchI7w83IIDln0QN272gvChMDkcghUNCUCFsIJOGDQQDSVSQFBHgmIEBTOYMz9GeChwagFtUIGNrFki83YWo1mLXByGfCZHKzGUyLIyyflKoRvSEDlKtmoO5I4U6NjxWyue9agVleadRRCIsQXS4GeUYCCRmKW0VTW++yrLbwEZNOq3rixEnuqQ1dZOKtoOlvcPHfUzdUIHLEbgJHT8Ksv7E3m8ptbkL2zbnbyk9/HXEH++P4KDTTZ9DB+2w8tdqO4cCdNLQfF7iDEZvIY/vBHXWf7Wr7eBBLUYNB3OZd8CwZOyepUCmitNsEvnjUQ2pJDx4n2FCuHidiiZoJR2KdTi1YLJzo651uKJNLPFMdKm+ojVX7PiKf9J7dvrMlqoAW/H7JXAJ/OwWwoBuFUFrIYQyaXB28sCYyMhio1CycclWJlYgLw/fxKKJLKdPe89vDcliBBlg6Lc/Xy7WPTntA3NVqOqzdoxIwazds3jrVkFpxuvy9N8qefeKFprKQvrOlUtZNc9dRcX1r7bGTWd6JWx1LVWjWoZNKvLSZErLS/gtEEn8pfiyfw6cdfPhzY1T8DarMIC06Gv11w/B1Inv9x0d9BCNFRCIRWhXOYJLOYhJQ0dSWbzfZ39hyWWFUyaF3l7TXzANC9U9xOKvm/bq/6B3Vzqqnf6bmpAAAAAElFTkSuQmCC"
    },
    "Marine Rail Launch": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAEkElEQVRIib2WCzBcVxzGv10rwmIJGwRhpR5FSsSrRQStkAwhknRNSyJtiqCVtBHtSGsQMk3Wo8OItJGGVqYYmalO6XhWlyJ9SEhLvVPdaImwa5fE2u3c26GuIDSm38ydOfeex++c75z//1wG/icx1tCWDcAOwBYAKgDuAegBcHs9QDQAXLau5hELa47GLq+dM5xn9JWZTKXp3q4hSVNDm6Sh+ifapGiqAwAPwNh/AdkaGuvmxcRx9UPC/fTpdIL5rzx9nXA8Nogoyn9o/kXtiH/Cp6Mj4xUAclcN2qynFWJlsy01vzTRQIW5kVI3PiaCcGISWzl686u2d7b0uPNX2WziO5fEubxiKwAnAcysCDIxMQhydLPMyLx6RotGo66iofpHlBXVQH+rDqTSWbybcmxhtULixQiurYN5eTg3OQNA9LIgFmujiZ6Rdg7vk9OPQQiVFFYhMz8OCgp0RIWkQS6XY3G7gJc9/NpaO7/ITS+JXGgjBcQxNSrMyo/TYTAUljAUsHWwIFdkZmn0j2dLTIZQIi/yYElhlcroyPh1wm0KyGoHx8bOycLG0FiX0qmxrg2fX/l6/r3tZicmRVOwdTDHiVdTyW+HQ72x29ueYmN+WZKav9ubsQSXAmKzteO5Yb7MxbNz8bAlnzl1dvSjsf4WXosOwEpycrV2V1VTmZgUSUABSaXSF2x2mmEdRXN03a5YW9FiCqB7HqTOYmpgneXkYq1cW9FiQgEpKSkqr8fgMpkcc8FNZBEAutRTR6PR54oJsTmwd34WAVzPNUFa+O2Ii8zEjbp0bNJmQSKZJqJdTAHNzEinATDHRidQV9kKVTVlnD+bj7fPhkJxw8opUSqdRUr8x9DUUscmLXV8U/49gsN80N/zBzGmgAKSzcrGRUIJ8/rVShyN9McWw81w87IDbVGOW0pyuRzhJw9CT18bvw8MkyFAgJq/u60IoJ0C4pgZVNVWth712e8CpqoyeEkF2HfAbVWWKSoySMjNpjswtzIGS0OVXOVAr4C4SkQUkLeP47lLmaWh/ofc6XMRP9ArgPE24vpZna5k30ByRhTYOprI5RXf+1Nw//EUtN/jVE9MfHB9WVGNZ9ArLyLh/HGcjsjAuaxosuOTrEtLyIfHHgeyrXBCjIK8r7oBzKcUyi6HvRXITTjx0cD2HaYqRD67mHcKGSmFMDDSxd5AV9KehZqSPAS/7meUflYN/0PupNXE8X6DmzzU1z0UtrAtBWSnd3ikgp+zJ+nM5fqEtNcVLKw5+OBCBLnBXxbXo7vzLiTiaQjHJ9Ha2AFtNgvOu55D9rV48mTOPJIi9tiHY32/3Q0H0LcsiJCvaxS/ojln94XEa5Xefs8ziYRJJFriVK2k7l8H8V5M9rBwQhw82Ddcv7h+yQDxdY7idwnLjS+nlxaFHXjfa2+gG/2lfc5knCyUVDqLVn47YZ1ksE/Q1NfVGywQiEaXGnPZSDRX9yM6eH97q4BTUV4fHx+d5SuTyjQZGxjKcrlc9nD60ZREPDWmxlKrefDgfiq/rp1i1apBc3K3Ce0HQHj+VFrLf91T6W9Y96XhyFB+WgAAAABJRU5ErkJggg=="
    },
    "Other Moorage Facilities": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAEWUlEQVRIib2WW0grVxSG/3itxhiLilqridcHFdJUxFCjbRrwgqhoK+bgpY0vXlBUEM2Dgog9CpqoYCkBTSoJKRXxJXASqEXRKKJPtoLXpD6UnGIkGkNS0dGUmWLqHD1We6Q/bJiZvfd8e/97rTXjh/9Jfk8YGwngYwAfAAgG8BrAAYBfngPEACCJjo7+KiMjI0wsFl8mJycHMZnM893dXffS0pJ7fn6e4XQ6twDIAdj/C+gjLper7Orqim1oaIj18fGhdRYVFaG9vZ289KytrbFKS0u/t9lsBgDfPRoUExNTy+PxXs7Ozn7IZDJpfXa7HQ6HAwkJCd5dCwQC0dHR0VVnZ6dLLpenA+gAcPkgKDEx8Yvc3NxRtVodzmCQzv2j+fl56HQ6xMfHgyAIDAwM3O72HRkZkWRlZeklEskogJa3gthsdiKHw/l2cnLyDoSURqOBSqWCr68vamtr4fF48Oa4qqqqkvX19R8VCkXTbRtpoJSUFI1KpYry87v/6LKysqgdpaWl/e3ZPYshJZfLv9RoNME2m+0HAKc0EJ/P52VnZ/O4XC5t0sLCAqamprz3GxsbcDqdFLSmpoZ6VldXh/z8fJqNc3NzrNzcXDJa+migyMhImVQqpZ88AJFIRLUbbW1tYXFxES0ttCO4I6FQ+CmLxXKQi6KBCIL4JDMzE88ohlAo9DcYDCkA9r0gNpsdhmdWTk5OkMFgSKSBAgMDg57j5dfX17hJbrKKAIgmr70gBoPhTX0y4wUCASQSyZMgJpMJTU1NVABFRETA7Xa/B8BFA11eXp4DYB4fH8NoNILFYqG3t5dqAQEBDwIIgoBMJkN4eDjV9Ho9pFIpDg4OyHdaaaCrq6vTs7MzplqtplYVFxcHsVjsteEheTwedHR0IDY2FoeHh1QKkKDl5WV/AL/SQKmpqT8Zjcavy8rKEBISgv7+flRUVDzKMn9/fwqyurqK9PR0hIWFUbs0m83kp8RJAxUWFn4zNjZWV1lZ6XOT8WazGUlJSXisJiYmMDo6iqioKLI6vLZarXdLkEgkOpDJZIs6ne7z6upqDA0NobGxEePj49TEf7Oup6cHBQUF1FiyuiuVyn0Ar+6ASLW1tUmam5sP+Xx+MFnPlEolVaE5HA7Ky8spe27L7XZTEabValFZWUlZTYa3RCL5fX9/X3p7LA0UExNjM5lMBd3d3YuDg4O+GRkZGB4epg54ZmYGOzs7cLlcOD09xcrKClm2kJeXh+npaSoyLy4uUF9fb9/b22sAYHkriJRQKDStra191tfXZywpKWGSBZMstGRUPaTt7W20trb+4XA4XlgslsU3++/9HggEAtPZ2RlXoVDoKioqxOXl5T7FxcVUjtwWQRBUkmq1WrfFYlnd3d19YbVaj5/0zxAaGkpOyN/c3EzQ6/WylpaWIoIg3g8ICAjyeDzX5+fnf7pcLjubzf755OTk5cLCAs2qR4NuxOPxfgNAev5Oesp/3TvpLyHfpkV7VevoAAAAAElFTkSuQmCC"
    },
    "Canopy Kelp": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAARElEQVQ4jWNhKPFxYGBgAGFqgAMsYMP+M9RTxThGBgaQgVQFLKMGUgxYKDcCFYwaSDlgoYIZKGDUwJEShgdAJS2VwAEAJ4AFkbhPba4AAAAASUVORK5CYII="
    },
    "Estuaries": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAATklEQVQ4jWO5Pnu3wz8mBgcGKgCmfwwHWECGMTIw1lPDwH9M/xlYGKgMWEYNpBiwUG4EKhg1kHLAQgUzUMCogSMiDJn+MRwAlbTUACCzAN64Dv0bPJLiAAAAAElFTkSuQmCC"
    },
    "Rocky Reefs": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAASUlEQVQ4je3UMREAMAhDUchFWJ1VFAaqLNdj7Axb+QLekCGMiCVpWUMADhNz990BSjJacxywHOvE24D12GA8DfjFhgBOPm1HaV1pzxHmorsTjQAAAABJRU5ErkJggg=="
    },
    "Seagrass": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAARklEQVQ4jWO5fn2+AwPDPwcGqgCmAywQwxjrqWPgPwYWBioDllEDKQYslBuBCkYNpBywUMEMFDBq4AgJQ6YDoJKWOoDpAAAyhQocfZSMSQAAAABJRU5ErkJggg=="
    },
    "High": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAWklEQVQ4jWNRK2b4z0BFwAIihK0YGLhkKDPo2xMGhrfHoAaCDOOUpdx1b2EupCZgGTWQYsBCuRGoYNRAygELFcxAAaMGUg7AYQgqHCkFMDPABoJKWlDhSA0AAFZnDXYrt9X6AAAAAElFTkSuQmCC"
    },
    "Medium-High": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAATUlEQVQ4jWOJub7cgYHpnwMDNcA/pgMsIMP+MzDWU8M8RqZ/DCwMVAYsowZSDFgoNwIVjBpIOWChghkoYNTAkRGG/5gOgEpaqoB/TAcAyaUNrJmtegYAAAAASUVORK5CYII="
    },
    "Medium": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAVklEQVQ4je3UoRnAIAyE0RNnYYF2JLZhZVigHaD9IDHoi8wvIp84EaK3D4Fx3+sGStWk9wHmcHBhKugZGBgTlKNOnCWoxwDjKEE923A9RzU3DJxDB70fhj8L2CreXe0AAAAASUVORK5CYII="
    },
    "Medium-Low": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAATElEQVQ4je3UMREAIAxD0dCLMJwhBxt4QAYeelxH5majX8AbMoTz7O6GDkHmWAysAUMBugGEOBaYjnnircB8FBhPBX6xoTlWPK2isC5CJg028dFZDAAAAABJRU5ErkJggg=="
    },
    "Low": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAT0lEQVQ4jWOZt+pJCysLkwMDFcDvP/8OsIAMk1aRtKaGgU/vPGdgYaAyYBk1kGLAQrkRqGDUQMoBCxXMQAGjBo6IMPz9598BUElLDQAyCwCIlxSZKXNaGAAAAABJRU5ErkJggg=="
    },
    "Sporadic": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAPklEQVQ4jWO5Pn++AwMDAwhTAxxggRpWTyUDGUAGUhWwjBpIMWCh3AhUMGog5YCFCmaggFEDR0oYHqCieQcAllkF5SIO8BUAAAAASUVORK5CYII="
    },
    "None": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAT0lEQVQ4jWM5Oud/CxMrgwMDFcC/3wwHWECGiRoyWFPDwNfnGRhYGKgMWEYNpBiwUG4EKhg1kHLAQgUzUMCogSMiDP/9ZjgAKmmpAUBmAQBl7g/j6fwlmgAAAABJRU5ErkJggg=="
    },
    "Trace": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAT0lEQVQ4jWM5Oud/CxMrgwMDFcC/3wwHWECGiRoyWFPDwNfnGRhYGKgMWEYNpBiwUG4EKhg1kHLAQgUzUMCogSMiDP/9ZjgAKmmpAUBmAQBl7g/j6fwlmgAAAABJRU5ErkJggg=="
    },
    "Moderate": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAT0lEQVQ4jWPpv5rVwszM5MBABfD3778DLCDDhGX5rKlh4NvHnxhYGKgMWEYNpBiwUG4EKhg1kHLAQgUzUMCogSMiDP/+/XcAVNJSA4DMAgAlMBRYcMFG0wAAAABJRU5ErkJggg=="
    },
    "Elevated": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAT0lEQVQ4jWN5s0C8hYmFw4GBCuDfnx8HWECGCUq5WlPDwPfPdjOwMFAZsIwaSDFgodwIVDBqIOWAhQpmoIBRA0dEGP778+MAqKSlBgCZBQA5fxP1k/I7EgAAAABJRU5ErkJggg=="
    },
    "Seasonal Opening": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABOklEQVQ4ja2UP07DMBTGv0geioQYmomQBYl7JN1zADr1EGRCVEkV1HswhVsk2UCit0jZzAADbIFnh9SvbSit+yTn32f/4vf82QI3UYMjhmjvJVwXGJyE6u3rs8SbrFjPoRv8qZ+eJfh4b4EOKtw+pMizFI6TAAhxeVVhPE3ZIK7PMJ6WnRZHdE1+Z6iDAHkGNYgaPZtQrhfIsxGDGikfDSq6+thCF88GkIr9eF/g+m50MHTolpCSpWwHHWgHaOBrPYPnB1ZQgFbfmCFBCHYoNN5mGxtor20soWIDaAkV6ur5m3/bF7owfUixLYV9oJ4PLOu1lG2gbax8eH7RX+z/QLHuw10ruAvKfNggQBylqrC0J/U2SjCfBOwgfXmq4PmkhZhPCqYRw5hhiAb6NJay66O+67aKZd2v/Sjf2GwFBzHJ23kAAAAASUVORK5CYII="
    },
    "YRCA": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAuklEQVQ4ja3UsQ3CMBCF4b+4msoTUEWizCxegSVoKNkls1AipfIEGYASHbKlYGxw4nP55Pts6UknwBk4AgMw83lKWS13QJCIPYEL8Fhd8MAdmLLBUn6K+UHiayWMCkYFuwI3iV+3wN5HQaywHOzGACeGmE8/dFYYsIghNgGjgosR1txyM9bS8iaMPy1vxfyvlndhVFrei02llnuwr1K6MVagBabGkEqxwDSfFQy6aXU5ZoNpzY8NecrCC539T7y7KexTAAAAAElFTkSuQmCC"
    },
    "Shorelines": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAUUlEQVQ4jWNhoDJgGTWQYsAyEg08vtyBgZG5noHhvwNlRjEeYPj/t5GFOoaBwH+Qw2jh5f9/G0EmU8/LlpEHGBgYDlDPhVQGLKMGUgwGfxgCAH69ExTbiYz0AAAAAElFTkSuQmCC"
    },
    "Shorelines of Statewide Significance": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAATUlEQVQ4je2SwQkAIAwDT8hgruBE4kSu4GBCpTO0vvR+/RxNiEhGXxhGDwplsxboQA26lsFQkszxxy5ENhhuTou8S1t+8M5s+MIo6R0eR7sNE70SI6MAAAAASUVORK5CYII="
    },
    "0.000000 - 0.225313": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAKklEQVQ4je3MIQEAAAzDsIP613w83MFFQLgyFmr4Ii30KBxhoUfhCAtPexArAFF5acCuAAAAAElFTkSuQmCC"
    },
    "0.225314 - 0.438676": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAT0lEQVQ4jWPpbMhp4WVndmCgAvj88+8BFpBhmT4C1tQwcPqWDwwsDFQGLKMGUgxYKDcCFYwaSDlgoYIZKGDUwBERhp9//j0AKmmpAUBmAQBKDxNpgUMXLAAAAABJRU5ErkJggg=="
    },
    "0.438677 - 1.000000": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAATklEQVQ4jWMpy/BvYWH86cBABfDnP/sBFpBheuw7rKlh4KWfHgwsDFQGLKMGUgxYKDcCFYwaSDlgoYIZKGDUwBERhn/+sx8AlbTUACCzAOaBEhL3cZB8AAAAAElFTkSuQmCC"
    },
    "0.000000 - 0.133638": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAKklEQVQ4je3MIQEAAAzDsIP613w83MFFQLgyFmr4Ii30KBxhoUfhCAtPexArAFF5acCuAAAAAElFTkSuQmCC"
    },
    "0.133639 - 0.210743": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAT0lEQVQ4jWPpbMhp4WVndmCgAvj88+8BFpBhmT4C1tQwcPqWDwwsDFQGLKMGUgxYKDcCFYwaSDlgoYIZKGDUwBERhp9//j0AKmmpAUBmAQBKDxNpgUMXLAAAAABJRU5ErkJggg=="
    },
    "0.210744 - 0.848993": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAATklEQVQ4jWNJDrdrYWP86cBABfDrP/sBFpBh9hInralh4MEX5gwsDFQGLKMGUgxYKDcCFYwaSDlgoYIZKGDUwBERhr/+sx8AlbTUACCzAKLJEaimSrUhAAAAAElFTkSuQmCC"
    },
    "0.000000 - 0.055396": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAKklEQVQ4je3MIQEAAAzDsIP613w83MFFQLgyFmr4Ii30KBxhoUfhCAtPexArAFF5acCuAAAAAElFTkSuQmCC"
    },
    "0.055397 - 0.089608": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAT0lEQVQ4jWPpby1q4eFgcmCgAvjy498BFpBhKW7c1tQwcM6urwwsDFQGLKMGUgxYKDcCFYwaSDlgoYIZKGDUwBERhl9+/DsAKmmpAUBmAQCvuhOL6KulAQAAAABJRU5ErkJggg=="
    },
    "0.089609 - 0.411765": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAATklEQVQ4jWMpy/BvYWH86cBABfDnP/sBFpBheuw7rKlh4KWfHgwsDFQGLKMGUgxYKDcCFYwaSDlgoYIZKGDUwBERhn/+sx8AlbTUACCzAOaBEhL3cZB8AAAAAElFTkSuQmCC"
    },
    "0.000000 - 0.271475": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAKklEQVQ4je3MIQEAAAzDsIP613w83MFFQLgyFmr4Ii30KBxhoUfhCAtPexArAFF5acCuAAAAAElFTkSuQmCC"
    },
    "0.271476 - 0.470147": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAT0lEQVQ4jWPpbMhp4WVndmCgAvj88+8BFpBhmT4C1tQwcPqWDwwsDFQGLKMGUgxYKDcCFYwaSDlgoYIZKGDUwBERhp9//j0AKmmpAUBmAQBKDxNpgUMXLAAAAABJRU5ErkJggg=="
    },
    "0.470148 - 1.000000": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAATklEQVQ4jWMpy/BvYWH86cBABfDnP/sBFpBheuw7rKlh4KWfHgwsDFQGLKMGUgxYKDcCFYwaSDlgoYIZKGDUwBERhn/+sx8AlbTUACCzAOaBEhL3cZB8AAAAAElFTkSuQmCC"
    },
    "0.000000 - 0.038107": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAKklEQVQ4je3MIQEAAAzDsIP613w83MFFQLgyFmr4Ii30KBxhoUfhCAtPexArAFF5acCuAAAAAElFTkSuQmCC"
    },
    "0.038108 - 0.120227": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAT0lEQVQ4jWPpbMhp4WVndmCgAvj88+8BFpBhmT4C1tQwcPqWDwwsDFQGLKMGUgxYKDcCFYwaSDlgoYIZKGDUwBERhp9//j0AKmmpAUBmAQBKDxNpgUMXLAAAAABJRU5ErkJggg=="
    },
    "0.120228 - 0.653988": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAATklEQVQ4jWMpy/BvYWH86cBABfDnP/sBFpBheuw7rKlh4KWfHgwsDFQGLKMGUgxYKDcCFYwaSDlgoYIZKGDUwBERhn/+sx8AlbTUACCzAOaBEhL3cZB8AAAAAElFTkSuQmCC"
    },
    "0.000000 - 0.070370": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAKklEQVQ4je3MIQEAAAzDsIP613w83MFFQLgyFmr4Ii30KBxhoUfhCAtPexArAFF5acCuAAAAAElFTkSuQmCC"
    },
    "0.070371 - 0.149841": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAT0lEQVQ4jWPpbMhp4WVndmCgAvj88+8BFpBhmT4C1tQwcPqWDwwsDFQGLKMGUgxYKDcCFYwaSDlgoYIZKGDUwBERhp9//j0AKmmpAUBmAQBKDxNpgUMXLAAAAABJRU5ErkJggg=="
    },
    "0.149842 - 0.799720": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAATklEQVQ4jWMpy/BvYWH86cBABfDnP/sBFpBheuw7rKlh4KWfHgwsDFQGLKMGUgxYKDcCFYwaSDlgoYIZKGDUwBERhn/+sx8AlbTUACCzAOaBEhL3cZB8AAAAAElFTkSuQmCC"
    },
    "Shoreline armor": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAN0lEQVQ4jWNhoDJgGTWQYsBCuRGoYCQb+B+MyAeMYERLFzJCbaCagdQCLFQzCQpGDaQcDP4wBAD2ogJTO+UmyAAAAABJRU5ErkJggg=="
    },
    "Pocket Beach - Artificial": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAASklEQVQ4jWNhoDJgGTWQYsAygg18zrDcgRKDJBkiD6AY+J+BaT+FjmNEMZBagAVh/D9HqhooCQ0DqhlILcBCNZOgYNRAygHVwxAAi48HhL4rhlEAAAAASUVORK5CYII="
    },
    "Pocket Beach": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAASklEQVQ4jWNhoDJgGTWQYsAygg38X3LfgRKDGHsUD6AYyPCfYT9lbmNgRDWQSoAFyXxHqhrICA0DqhlILcBCNZOgYNRAygHVwxAABBEHwOHDs8MAAAAASUVORK5CYII="
    },
    "Left to Right": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAPElEQVQ4jWNhoDJgGTWQYsBCuRGoYCQbWLCCYT8lBk2IYHBEMZBagAXdBqoZSC3AQjWToGDUQMrB4A9DAFrjBHpereXJAAAAAElFTkSuQmCC"
    },
    "No Appreciable Drift": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAQ0lEQVQ4jWNhoDJgGTWQYsBCuRGoYKQa+JSBoYGJgcGeEoP+MTAclGZgaKCNC6UZGBqoaiA1AQtVTWMYNZAaYPBHCgD0gQTsmLBpMAAAAABJRU5ErkJggg=="
    },
    "Right to Left": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAPElEQVQ4jWNhoDJgGTWQYsBCuRGoYCQb+H81w35KDGIMZXBEMZBagAXdBqoZSC3AQjWToGDUQMrB4A9DAOSoA+hgVVIBAAAAAElFTkSuQmCC"
    },
    "Groundwater": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABR0lEQVQ4jdXUsUtWYRTH8Q94kyYrFMFZWhxM3GxVN1tEs78gdC8kF0lTdBLBv0CIdBB9QYfAOYRwCSJIkJZINLUlEBSU5/F6kZd7X19BUH/Dw49zOF/O4RyexA0rudfAGvThQVn8EGvXBSb4iJcF+VGMVQtM8CntznMtmtTHxIYffvsb7HucYvwqYBhvAb0XgUEvdGmP/rWZC6C0w1N8qAQcuQyrQuP4is9FwCfh6dCiXl0MNHqcJds9zfymLX/sZzVFwKg3+iO0XIN6Mj9kVsmXW7rDTT/9dxR9m2YNHkX/zbZd/6LfcVAV8CQ8k/FqzjVvONvynJJVG/JqioBT6Ear6rSElUrAcGSdWMezEPjul4dqY3IvHTfVMl5d1WE5tG3aYl5nJQyUwypteT+Fvsv5HEKbEzjOK0wKgEFhjW8r5HN19z/YMw8BQ88wi7q0AAAAAElFTkSuQmCC"
    },
    "Surface Water": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABN0lEQVQ4jdWUT0sCQRyGH2gErx0UD36G6iJ4U4hO3iIrP4d22BBis2C/iOTBqMAOQScvCiH4GURBoX8HbwnGOKuj2+64QVC9h+HlfXcefruzjOCHJf41cAM4ACKe/A24/y5QAFXgMKA/A87DAgVw5U5HNgvJpCqaTej1ZtYGpkBlHVC+Xg3YnwelEuRyyufzCyDuhFPgwgQ8XYaFUAV4Ah6CgJtyyWQgFlNBIqHLdFr7dhv6fb0nCDiTbSuoV8Wi9oUC1OTH+ZX/sNWC8Vj5VAriceU7HRgOlR8MwgEncrEsHTQa+pQdB+r1L4yJCegAe8AW4XQN3JqAz8Au8Ahsy6DbhWhUlaPRyrM3wPG6Cb3QnXLZd7I74MgLM53yiwu1fC6Hd+AS+PDbKAKAUq/AiaH31d+/YD8BobRDXygSfiQAAAAASUVORK5CYII="
    },
    "Other": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABEElEQVQ4jdXUSytFURjG8V/ZytSADHwGTJQZJSMzuX4ODI6U3MoXEQNCMVBGJpSUzyCKchuYOXW0s7Bs5+yzTyk8g9XT+6z1712XVuKHlfxrYBNG0ZypP+KgUWCCdYzXyBewVBSYYCN0ZwCdITjG5ZtdRAXL9YDp9jYx8l6YwXDwY59AocMKVvKAczGsgJZxhsNawNZ06EdbKHREYV/kT3EVrakF/DigFJrVdOSnwtn8zjs8wXPwvWgP/hw3wV8XBJbToRQV9qNbXsPWd0Y5D5iuGUKXYtrGbh7wDoM4QndauEBLCG+/zt3BZL0Os9Ce+eqd7WEiC8u75fsALVX5HJ6wihcNPpsHzObkVfX3P9hX/TAxXPRuesMAAAAASUVORK5CYII="
    }
}

export const ICON3 = {
    "401CZM Mitigation Site": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA7ElEQVQ4jWNhoDJgGTWQTmEY/1KZgfHbW4YFih+oY+Dff30MDGw3GRgYyig3MPaFE8P/f34MDIw/GOKeTWFYJPWIfAMb/jMx3HneB+VxMPxjaAYFAPkG3nmexMDAoI8kEsMQ86SPYYnMRdINDH3Fw8DwB+QiZMDE8J+pk4GBwYN0A9n/VDIwMEhgiDMyuDPEPXVhWCS9h3gD457JMfxjKMJp2T/GTgaG/yYMDIz/iTMQooEDp4EMDEYMMc+jGJYwLCXCwP+MDIwvZjD8Z5yJx0AGBkbGN9iEWbCo/M+wmOEgA5mAhVyNowYywAAAZCU6U8PCvCQAAAAASUVORK5CYII="
    },
    "401CZM OLD Mitigation Site": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA6UlEQVQ4jWNhoDJgGTWQTmGoUqrM8Of3W4YHEz5Qx0DGf30M7Mw3GRgYyig3UL3EieH/fz+G/ww/GJTKpzDc63xEgYENTAz/P/dBORwMLH+bGRgY4sk3UO1zEgMDgz5C4H8Mg1pRH8OtvoukG6idxcPwmwHkImTAxPCfsZOBgcGDdAN/c1YyMDBIYIgzMrgzqJW4MNzq2UO8gUrlcgwMf4pwWsb4D+RKE1AYMBBlIMsfkAYOnAb+ZzRiUC+OYrjZu5QYAxkZ/v2bwcDCPBOngRBVb7C6hQGL/Qx3+g8ykAlYyNU4aiADDAAAyos33M9fHqgAAAAASUVORK5CYII="
    },
    "401CZM Project Site": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA70lEQVQ4je3RPWoCQRjG8f8si1h4gESDEDyAEHICCZjKA4SAaGFSWwh2gVTbbGXIjta5RRqPkDJtiK6KAauQgMwbRAMLWT92lVT7dPPOzO+dD5sDx07A/3nDsabwmeLjtMbsIKABN/XNK9DaGxw9UjJCRcHXUNPJ3vAWG5Q7LF/hroZphHugGhv0j6kjFAOl63cP9+SWl8jg5IHMfHmiYCwLHOAyMji3aANHIVPlQZeLXIPnncGhJo/QXNdMGRwRzpVCdgIVOLL4hPU58z2ugKetoAhq1MNTBr0BXDSdhtXtPwuX1+gTM3bcjQnIb34AuyQ68OktF+cAAAAASUVORK5CYII="
    },
    "401CZM OLD Project Site": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA9UlEQVQ4je3QMUuCQRzH8e//eCiHXoBTS0tbIPLwIA4SQU2BS0oIgi+hIWgLnFyeqcFeQKFPb8FBQVDaHF2dFdp6RLuLHlIENfVRmvxNd/+7+/zvzmLHsfbg//xhJcHJ4QGDdJ2PnYCicYefdIH7rcFXm3MD1wi+l+TppkkvNPgISinc32lEjygC+dDgqUMBOJsWhFzFxs2+09kY9FIcaT+40WwUQgm42hg0Pg9AdG5BuKw6XGTa1NYGvSTHeszd0mZQMhCXYLgGqMfBsyLLQCBWdbilzctK0IC8CeUvw/MfIKLoL6pbcxt/zBYNQsYKe3APMsk3S+A3jtgvVvIAAAAASUVORK5CYII="
    },
    "AP Aquatic Invasive Species Management GP": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAArUlEQVQ4je2UQQqDMBREp5DjVLAnSKSn6LIXaUMukl09huYEFdIDlUnzwUWLJHUj+CB+snnRmaDCyqhduN0MbZ5jXtVCA2CY7e95dr/EakE4NPqERre43K6IYULvPGJ48pBDqdDwQRGFhPOlWwolBlssFJnAAx7O15fSO58kAj87U5yhZQkUHMOU3vSTX72QdCwgnlNmc9gyaoRjbtNIpt+KqLnYixda2H9f+Js3QaU0FuFCx54AAAAASUVORK5CYII="
    },
    "AP Aquatic Mosquito Control GP; AP Aquatic Mosquito Control GP": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABCElEQVQ4je3RsU4CMRzH8W8vTV2UlxA9nU1YWUwceAQnFreLMchmDJowCRJDcDDyBPoALg4MjJowYCzhDWAiTBpagk7mrsdBdCDxN/b/76f/tpJfjvwH/+4N0wEpobgV4Z63XpXLhUFPcQ4cRtX8Is/6inZicLfI1sQQuA6zhhtKZChhEoETQw1QLhDY88fkNTTngjsFDgzkYrDvKS3ldMBDv84oFrSwQbKseSp8ixCoqzz6p7SsJRunCcGFrjCcC35NaThG8Dr7bIf3vj6iEVWQUYu9azrbBe6Bo8jpDCcvd3wmBmcxH5x5ik0hfvZYS1fXeMIR6Sr06wyAfVd9YXDZyKV3riw4Bb9HP8UG/mMgAAAAAElFTkSuQmCC"
    },
    "AP Aquatic Noxious Weed Management GP": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAArklEQVQ4je2U0QnCMBRFr5Bx7Ag1Kf64QldRaEOgjmLHaFNHiAPJDS8gopSm/gg9kDzyc/J4N0Thx6hN+L8ztFJHWdlCA2B4ObdSq29iNSMcykLjUGic6wb34NH1jpWX7JYKDbdL3YBSEuUPHcUyBrtYmGQJdtrdXH4o195FSUK6Q84MLUOYgke597FTyqcVQlIxgFM44g2mjBzhKGmaNNNPQeQ87NkHndi+L6zmCQylMv1rUvD3AAAAAElFTkSuQmCC"
    },
    "AP Aquatic Plant and Algae Management GP": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABC0lEQVQ4je2QsUoDURBFz6xPA0JsrNbYhXxBwDaNYOEfuLGwsZNgH0ISSC0WWohik8VCP8DGwsJWsFBsrCTbJGnslsCOLIKQZF+yCVoI3vLdmTP3XcMPy/wDf7FDr7+ChKeIDM9EvOC7zdmBTlhD8VAdfpf4WOcOP/eQHrjzXkA5sB+TY+q6QV2idEBn4QhYsgKVIm/BHnAxHbgbbKFsW2HfUGnh9a/xVz8mAyPJIiO9JStDFI79YhzouzeUg3ugNDkhDa7WetOBXzEr4DzGbSbawivL7kmSZRIX2utPlINzYN+S7pAzGaQHxlqUKgPyoCMz+kw7d4tFxmZw6XaBTas/M3BOmXkX/y7wE3zvPyfePnFvAAAAAElFTkSuQmCC"
    },
    "AP Eel Grass Management GP": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAs0lEQVQ4jWNhoDJgGTVw6IZhA5Q+AMVkG+jAwMCwH4lfD6UdcRnMQsDA/Xpm9gy65vYM0Tl1DJdPHWRYOrmJ4dKpgyBLGEk10AFEROfWMeia2YMFdKGGXzp1EBYMDSQbCDMMBkAuBbmS7EhZOqUJbAgMgLwNBSSHYQMoEi6fPMhw2ewg2KUgw0F8cg0EAUdQBFyKdWZAA6BYZiDHwAPQ2HSAhSm2iCAnYRNM0DAwWnwxUAwAbjQzCTNZYNcAAAAASUVORK5CYII="
    },
    "AP Fisheries Resource Management GP": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAApElEQVQ4je2U0QnDMAxEL+Bx6hni0CmaTfrTGg/jjJFkBnegckaCUhpC1PwEciAL/zyLO2GHneVO4HE9jNInKTMwABg/7k/p3RLYrQBH33qwbo8eZS4YUmbnI81WYOBBEIEU+6v1FSw2xM1Ahan4QE7ZHsqQcoWoZDpYPIwMgYDLXOqk4p8ZSHUMoFzv+BJThgU4SZpBPf0VhGWxVxdadX5f+FtvjVwzxU7SzysAAAAASUVORK5CYII="
    },
    "AP Irrigation System Aquatic Weed Control GP": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAArUlEQVQ4je2UUQqDMBBEp5D7qCcw0nu0eIUewIYcpGIvojmB9kZl4qb40SJu+1NwIFn252WZWWLwY5kd+L8eOqmDHDXQAugX/VVq9QlsVoB9mRUo8wLNqUZ4jPBdy8pHDluBlldzrkEoNcOnCBYb3GZggiVxUt/d9KH4exshSTIdNB46hhCmESHL46SEs9cCqYoBHC8vCBYpQwMcJE2bPH0XhGaxVxc6af++8LWe+XszEWcRWL0AAAAASUVORK5CYII="
    },
    "AP Oyster Growers IP": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAqElEQVQ4jWNhoDJgGTVw6IZhA5Q+AMVkG+jAwMCwH4lfD6UdcRnMQsDA/Ub2NgwgnFxXwXDu4BGGuU0dIBpkCSOpBjqACJBBIANBAESft7cBGwwNhgaSDYQZBgMgC+Y0dZAfKXObOsCGwADUdQzkhGEDKBJABhgePAJ2KTT8yDYQBBxBEXDO2YcBDYBimYEcAw9AY9MBFqbYIoKchE0wQcPAaPHFQDEAAExmM38L4RLqAAAAAElFTkSuQmCC"
    },
    "Air Qual Annual Reg Source": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABPElEQVQ4je2Su0rEQBSGv4khVgs2okbTqGBnYaMPYCMmu6gsCha+hYJP4OUFLGy2FJSFjYuoha21INrYKBu8gBZ2CZmRxHghGHYjKSz8mzn8c/hm/jmjU7D0f+CPalWwdB+j74gbinhDEVINdQxgoxCggqpQdBcCbFWwRMhkdNGHGUbaxdbThuewgGIN0GJD0hPBojLs4sSzeflsFuwPuGyKOEQG0HQ58GwkUANKX62xhpM1VLA+6LLVUWTzkLo3yzWCOjCW2n4UiiWzyVlHkT9kNrlqOWwLxW5qQLUsWNuhCJhLigAVhze0d281N/CpTCmQTAO3GixKgS8UewpG78qMWw0ucgF9hQ2cyoAV85jn5JCJQLKjSeYhJ1AKLodcyt+/RG+DV2D53mEqd2QrI1Kkfpfz3EB+qb8PfAOTpVymP5lm6AAAAABJRU5ErkJggg=="
    },
    "Air Qual Local Authority Reg": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABOUlEQVQ4je2Su0oDQRRAz12WTRWwEXV1GxXsLGz0AyxFUAkGYgz4EQp+gY8fsBAh0RVEJb0WttaCaGOjZPEBWtjIrjsjeSgaXZKVLSw81XDv3DNz74xJwpj/wp/JVhxSpkWx64pEZmhKhtfQAlaSEaIziKSSEWYrDsgoIBTuBlq1bX6LzHkzwBKCUb8cHTVZlTA8Iu89fexVHOD2rILoaOGOfUjOU0ARSDdl+6mXhohexu1da69l1y6T9y7RlIGhpuw9SrLs2ifttfzOtn1BvrKOlk2+oItRstaPomWqsQpq0wQLjGpsMb5w4SGNH4wD12hjFlE+yB7oQeZvhik5Z/GEvj+BcMyLKrBvPzYOGSEINlDGNBBTaKhzSn2Tn78EW53PQI7c7VhUmRkpjGiphtt9Gl/I7/j7wjd5JVtpSIV0NgAAAABJRU5ErkJggg=="
    },
    "Air Qual Oper Permit Source": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABMklEQVQ4je2SMUvDQBiGn0tCnAr+gS4mbTYHF/0BjiKoFAUH/4LEWMFfoG0aHR1cOgpKdx1cnQXRa+vi6KCDm5qcpEYpwdBGMjj4Lne83/F89353BgXL+Af+KMulLHTMfpN7ipihDjUiTGCvECCCGjBRCNByKQOzMdbexhoV20gbFZcVIdgBtMSaHNwxNiLOq1s8Dx0/7bbYB1QmsBdwVvWIULSBUqo8layhgF3ZojFW5K5Pp7LJndDpAE6q/CgUazLgcqzIX+odcut4NJXi+Nv8zN7Ogo18FKVYSrZvyZxMxcCr5wY6dUoqZF4oHkJYRedVizgBbMdjWvpc5wJGIQsCLgzFhjzgKWkywztHSrAMOYGa4Eb6LA5/CdngBVi3XeZyR5YZkWL1A65yA/ml/j7wA6dpVX1GBNsXAAAAAElFTkSuQmCC"
    },
    "Air Qual Periodic Reg": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABP0lEQVQ4je2SPUvEQBCGn02WszqwETWaRgU7Cxv9AZYiqBwKFhaKMWev4C/wo859gBZXCsr1Z2FrLYg2NkqCH6CF3YVkJRrhCCd3kRQWvtUws/vMvDsryVjyH9hWroMpJbl+izuyeEOhUwggB+xlAlRQEIqeTICugylgKhr0qcpoJ9symfDKLAI7aGjxeL0RLAoDRcOr8tZy/HRwg30hIhM/AA2bM69MiKIG5BPlkfhqoBS7QzYHWF1YNmzqnsMtOnVgPFF+FoJlY5OLrix/y9jixq1wKOAosaCaYbWHdVyKgPk49L9Y5DTxmdtODXw5Ju/7zAD3WshSqNMUcKIUYw8lJswiV6mATZ9Z4DyUrBrrvMZNJv0mFU2wACmBYcj1sM1c65foW+MdWHksMZ3ashlZKravDRS5TA3kl/r7wA+bIliT0nFOGQAAAABJRU5ErkJggg=="
    },
    "Air Qual Permit Source": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABOklEQVQ4je2SPUvDUBSGn5OGOBVcRI1mUcHNwUV/gKMoKmliHfwXCv4CP/6Ag0sXmxSluOrg6lwourgoDX6ADg6WluSKmkIpljaSwcF3Ovc9l+fw3nN1Upb+D/xRRWqWoBsuw7ek8YYZxIbQAHZTASqULchAKsAiNQtkToF4PE72iq13Gj7BGrANaLE1CMh3GZ77BK+tuwInNqN7gqiuQAfztEQQKSgA2faewERchqB2cozt9xU5h1n2CG4EysB0R/sJxHUwL/uK3JKLeV2idqCQo44FFdwusJ5LUchKXDa/jmAI2qe3lRh4xnO2TnMBuFNoDkQNQXxQU8fcz+SxKomAdRqLAhcQbTqYL/GQ2Xeahxm0VSAZMCSqrjO+1P4llhl6AzY8HuYTR85jVfJdei4jV4mB/FJ/H/gB3EBW5u+V5YcAAAAASUVORK5CYII="
    },
    "Air Qual Synth Minor Source": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABOElEQVQ4je2SPUoEQRCF35NlJxK8wCYqmBmY6AEM7e4ZZVEw8BYKnsCfCxiYbCirzHZNpoGpsSCamBgaaGCmzJQ0zIIOLrsjExj4kq6uKr7mVVcLDav1D/xRzrkOyfZgMHhEEzNU1S6ANoCDRoAAuqoaNQJ0znVUdRkA4zieG2e7VU0YYzZI7gGYCndVnQmwEBdFcWmtfR32kjz33h+GtpHALMsunHOFqvYATFfKs+WZA9j33h9NZNl7nyZJ8pDneQpgoVJ+BrAlItcTWR4qTdN7Y8wxyVN8V28UbOynkEzK8KOcU1idkNutDbTWhvmtAnhS1U0A7yTPAMxbaxdF5LYWUFXXSF5FUbTT7/dfykeWVPWE5DqAekCSdyJiv66EiLwB2DbGrNS2LCMsBWVZdlMbiF/q7wM/AbVrbnk+OkxgAAAAAElFTkSuQmCC"
    },
    "Biosolids; Biosolids": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAuUlEQVQ4jWNhoDJgGfoGmi5nkGVmYDgPYn/7zSB/KY7hK0UGMoMQI4MwiC3EwcBIsQtJBSxDy0DjZQwi6AoYGRgE/0PZv34zCBsvY+BAlmfmYPh/KojhLVYDWZkYXqMbCDMMBP4xMzxgRVfwi+Evsjm09TIHEwMvuoLvDAxyjP8YroJd+49BgpMFNR2++4HiCVQDD4QxfEE30GI5wzdY6gMZhk3NEE42Q9PAE5EMDyDpm0oGkgqobiAASKEmPLxF2BwAAAAASUVORK5CYII="
    },
    "Boatyard GP": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABRUlEQVQ4jdXUsS8DYRjH8e9zd01HsZRe0miIxSA2f4NJImHotYk/ggnRjmKz6oC2U22mdjJIzIJBxCDRaxlMtEV7r9xCSa93FwZ+yTu8eZNPnuF5fwa/HOOfg6m7GTR9HtQ48ApcoHUPOUjUwoGp22EkkkdYAPX1zdG3sOxtJuObZMXxBxcfh9DaJ8CUxxBRhHVu6u7Ulj8Ybe8MwD6jSGHZx5TMXW9wuZ6kozIEjcYGqDyI6g92mAMkMKhIkK5NU+SsPwhjgbGPKSUJ3mArNOjQ7L1+A9VZaM6InHuDL90KUf0eGAkIVtiLNbzBcqKFZa8gFAJgTXRZ9d/DklkkXZ8AlR2APSOyxH780h90U4znsOxTROVAZntWyf3PR6DWKJhX4cqhZFaBKplGjK5KYjhvtCLXlGNPP6uvwugD4J5A+fsF+w51nFuSZKvzYAAAAABJRU5ErkJggg=="
    },
    "CAFO GP": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABU0lEQVQ4jdXUsUuCQRjH8e9dkhBEtNQUvWhpBEVbf0NT0NBQ9D+EKQUV6ViKQkNLbjW2NenUEDRHObxvthVUY5Sm8t5FBmnR6/u+1FA/OI674XPHw8MT4JcT+N/gaIIpYTOLJISmLuBSK46sHLe+wIk1+msN8ijmEIB+v29uku3oCmmzly2SKFcwtEpfrcEpMO7wiaCGjegjIRMWXcGAzW4H7CNasBCJc2Jl2HcEx5YxlGbJDWupbAL5VlG+gFoyA82qec1QJMakleX8exCG8RkhMcABBKp+QaWptJ8/g6L1klevW3LhCFYUhR7JPTDoESyU0tw5gjc5qtEYcS04cJOEpmJrEq59aGY5jMQII0h28J41zJdzlFzBt1hZUiMJzqQiBUy3tVJdCI6RrJs7mL6GQzlNESiG4wx02RhK0Ai+cFXa4+lH4+s6wwM0l6f8/QH7Ckb6Zit23en0AAAAAElFTkSuQmCC"
    },
    "Composting": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAArUlEQVQ4jWNhoDJgGQYGRjyVZWBhPA9mMzLJMyyW+EqZgRxMzAx//guD2T+YGCl3IYmAZYgZGPlMBEPFv3+CDAzQoGP9I8wQ+YwDRZ7133+GRTJvsRvIzPAa00CkeGBieIApz/QX2Rwae/knCy+GCra/cgyM/6+C2X+ZJRj+MKKmQ45//3EbuFrsC4aBCc+/MfyBskGGYVMzhJPN0DRwgSQo7REsFIg3kERAdQMB0Uco3tYsVlgAAAAASUVORK5CYII="
    },
    "Construction SW GP": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABUElEQVQ4jdXUvy8DYRzH8ffTayOxiKXVSxpNbQax+RtMEoOBWFV1EyZEO4rNoD26MdpM7WSQmAWDmCR6LYNJf4TcfUVprpJe7xoGPsmTJ88zvJ5vnjzPN8gvJ/i/wYcDJjVhRoQEwiuKa0s4iaUo9wXe7zMcClDAZlbam+pz0hQ7Zp7daJVtlcH2BJ8NhprCOTDuUsQAsFmJkAAWPMGmsNcDc6KYN3Oc6SkOXcFKjrjAoifmoFsiFJRCulcYYBpp35avxMoGE8Bld1AY7QP7KpK4O6hoOMX7Tr1z8Q2UjpN8xg5ZXLmCVo2iNsgjEPHFCcVwmqorGFulYeZZA458cHWlse75DvVljisGYyJkemA1BXPRJW48wY9Ek2RNgwsRsgqmnI/X+s+nYrGhr3DbV3PQk5SAUjVHWCBuw1vI5i6c5uVH7WskxRO0hq/8/Qb7DjIjY/BO2R+uAAAAAElFTkSuQmCC"
    },
    "Dairy": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABzUlEQVQ4jdWUzUtUURiHnztz73zJNOOMkyBGBBriQpFwkUX9A+rCvURQGw1yp0igWIj/gxYK085VCOImkOjDhQSjEC2CQpvScW7jHZzu3Pm4oie5Y9w7zkKkfnA473kP78P7cn4cmXOW/F8DY0qYJ6bJQDnHZdMEt59dJBaLGtNAqmagEmawdMDzQgZFDkLgqsjn92guZBhxKQxLfu6XNF6eCfRFuaerzGOKc7gTWh6J+OsC/FyGcgGFInHPJSRDI14NGDOyzJ3AqsqEos4LYKVy/FPAwBVmclu1P1TZQPE1Ma0neWgLLOXok+sg1GHlgtetuO4aRG9a5/0EmHn6wQFYyBLxN0PrY/uOYnfEOtHGOPzeJnpxPlRCZI004W/x02M23BLxr3XQPll3Rho8ITQ95QD0NvJG26Tvx5KVa7htAfc3hW0qFWhn1REouxhy++gt6Ui1jOfyUjaK/HGpDVBNsB3pYUp9z8SZXpSgvouJ9Ae+OwKPoe+YjN3Fo64xdtRp5iMkRsWdoYrd7cOsv8HTvbc8+7ve9pVTq4w39bOg7zCb26I7v4P3qGFPBD3YxpoS5MHua77Y1cpOEyVf8RmocB3oSbGq6d//YA8BIBKUoqzTucwAAAAASUVORK5CYII="
    },
    "Dairy Unpermitted": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB3UlEQVQ4jdWUwUsUURzHP6szzrJpm7vuWGqdJC8laNjBg3nw0CFFhKBLhFCXCvIYIhkq4lXwIGSSYNDBQxIEBR1MD/kHFGxoBWu77rpruuvYOLOOMUwyq86sHjzoFx7v9/u+eZ95P96PJ3DMEk41MFQu0ZOFjoyObBolIgmPwVRSZxBYOTJQ9nJ3XeNlfAsxUARXzlr+0l+qEjpdUgGPfAKdfzReHwo87+VeXOXVzv+8OQQjdVb87CuM/YQtA1HTmAx68aRUJvMBQ2s6Y7uwfDK/UTTGgQ+55e8B1pQwFM4c/aJUA7G6mMGFDR44AjNZWv0i3AjZ3rVSO77qh7YKO59ZAWWbNnABrmoELhfDaL3ziW5XWWNXN2fh+wbBY+1Dz758D1CWyMRUzvV9s71aP7RXWvHHOHxJ2WsxFcok0pFNF+AlH7NzSVpHf9heR6UNnEtabZOrxiAzrkDd4KGvkFub2wcqcZSvEEOBx64lz6+y1F5B33SU3sN60fxjs0zv+xi/XYGm3kZ5fuciRe9iPFWyeD4loOWztbasWvMZgZ0Wmf7pKAP79zve8psI3U9qmFhI8yKcpuGXgmT6F7yo1wPMl4vcn4iw6LRXcCtpOEwYaMr1FhVr5NPJf2D/AT96kpZ2MIJMAAAAAElFTkSuQmCC"
    },
    "Dam Site": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABz0lEQVQ4jdXUv2sTcRjH8fdz900dBAOCWH8UXOpgR3Gyi9JJCgaFSw4K6lQ7O5X0x5m0+i/0oC2F/siPpXRuEYQOgooI0sXFQSkJcRFcklweubTBtF6aWrv4wB08fO5efL/fezjDKZf5/8EiLy40CNYF63uS9P0Tgatk+2PoOYep91WqZwzmtqKlVl4ge69GsD2C96MruIJ31WC9aqBn88wO1amV2/MCM67CssF+U8S76+BVjwR/cqkUp/IOSIBu2diPW1me2ZSiy4AtWJsOkwewSHCU0ZqP78SpFEETguT3o/OgK3uYeEnSz6OOy7Q3a2QGbKQfyiiyBtwE+vbjWHhT2BYaHwtkE2EvyFuHiW+RoIU8UeQZR5TAoCKDrV4Rt3kaUaBifRJ0o+31W6CXD5k7wOffi9CvHbfskl4CwoscM0lBhyMW2as0RlymPhx7DnNNjNXwAyjMCTwFKsBr4KFgbeXIDEWh5k/s5TUhaI4GMB1QXzSYEAwuUk+ViBVAHwjWehHvetc5dBn/kiM7JsiVFBOZcNBb2R28uo+filOeV8Q/jHXcssvkAh0qnFPg0Yl/Dj3c2K2y06fEgm7PchzQwQmhA6PxT+Df1qmDvwDQ2pIQgSfY7wAAAABJRU5ErkJggg=="
    },
    "Emergency/Haz Chem Rpt TIER2": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABY0lEQVQ4ja3Tv0sCYRzH8fcdlg4RCSFpBYk0NPUPtETQEjgENd2VNEaB2RQRRZBQBA1FDUGIGkFuQdgULc1NZdTQUKk0VKPCeU+clJD44zz9TM/zfT68eIbnsdHi2Ey1pu7bcTh9xDyp1oCOriACPzDSPKhmXQh9FehEyUwTd583Bwp9q4gV12KbwMsFEW/OGqi8DQNzpb3EAJo9BIStgZK8h0Aum64Q+Dgh4so2BqrpSQSjFU460ArhfzevCxrPRLBD1YhZZt73ifbemQPtzgXAVx1ERmcXGDMHwkMN7M+s2LFV7MY9VyjpS2CiivZFXls3DxqR9RC6PA60UR6JDRL9n42B0b4nlPQBsFR2kuLVfWjtHdrym2h2FeguzYS0zI2kWQMj3m/U9BqCo99JklN3suYdqJec+xh7Zh4YooDx7WgOTEgF1GwQofs58zw2DxqJ9Vyz+HxrpmoONLI/mDfT+wGDgGCkcBGyTgAAAABJRU5ErkJggg=="
    },
    "Energy Recovery": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAtElEQVQ4jdWSuwrCQBREzz4KEVJIRCL4+DN70d8RyVdZ2acxFmLlo1CTlRUC2SVNSCx2qoF7OczuHU3P0uEDTzvmQnGwXg5ZJisenYBSogzEP39DdE7YVjosYJ4y9hfKklH1cR9DnKcMnPkbM9tybU5ouPhAUT+DJsO4c6kp6pz/PlkXRP5CoVgYOFqvFIl4uT0sIzezA5xsuPvA855n1T4La9oJuDZhAqdrMnuP3oBt1TvwC1G9J6M+5Y6oAAAAAElFTkSuQmCC"
    },
    "Enforcement Final": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABVUlEQVQ4jdXSMUibQRgG4CcmphCjCBpjimLRFhHBrbgVQWenDi6lFOxkQYtbaDFS0RZcxFEQBIUODroIjo7ZXQQ7SNulbQgUIupQJYVCIv+vibj05Zb7jnu47+5i7jmx/xpMpcl28rSFZJ6BZn5E2P7FIn7WDHbwYoB3H+nrIVquPUSBLsw8YCrBqyJbt4KdvHzOpyzpsKOf03jBZhuRAps3gakhcjdh/3KJEuvYr2y/CuxnaZ5HaswZjY9ZPOZ1IJhiuEd9KTEuDGz9O+pLkbbKeRUYvcO/jFybVwElTusF2/n9NQy84PCE3nrusZuDUPCcqWVGV2mqBUvwp8QbYWCebxmWV3g/TcNtdzfC3B7fQ8FydshNEJ9h9i3xoPabuBzjwy4L19cCX/Uz2Wk2FliLMZikJUE0w9kT8mkmN/gStDcW1tIKR3hWWSsLgUot4F1z7+AVC/9EV1aa0OkAAAAASUVORK5CYII="
    },
    "Federal (Superfund) Cleanup St": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAuUlEQVQ4je3SMUrDUBjA8V/0dZU6VCTQoXiCgOABHAQ3px5BvIJ779DBixS6lYBQ8QJOIsQeQNdEipIpaRKqg5A/vOnx/fh4vOCXCz1Y9s5dwYWKDpif8tj6DTPuC2ZqyrnZcFWFhgrs1g7sp6OcxRvnY16aNkwasBI95EwLcK9CD/7JGz5gimHDbDogbQRjnjIuscRxDbYKXI/4aLPhFn3OSCImVfcR6xM+O32bmFffp1Oh68D/B78A5C8e1bjORn0AAAAASUVORK5CYII="
    },
    "Formerly Used Defense Site": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAxklEQVQ4jWNhoDJgGTUQAWKeZzIw/DdnwAb+M81gWCpxgvgwjHlWxcDwv5UBF2D8F8gQ/cIdm6EsWAxLZ2BgwG0YBPAxMP7byRD12IRhmextQi40JGAYwlAmZmUGBgaCBlIEWEYNpEEYMjLMYfjPEM7AwCBAQO9RBjbWo4QNXCx1hiHmmTMDA8MeBgYGQRyGHWb4yeLFsET0C2EDQWCJ1DmGqGeGDEyMiljlGRlPM6wW+0paslkm9ZCBgQGESQIspGoY+gYCAF7gKYUD2R4kAAAAAElFTkSuQmCC"
    },
    "Fruit Packer GP": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABGElEQVQ4jdXUMS9DURQH8N9LNUaxlDZpNGwGsfkMJonBQHwLJoSOYrOyMdpM7WSQmAWDmCS0GEy0QtorYinJa98LA//kDjc3+d0znHP6/HL6/jd4w2SGmcAoXnHe4qDIbSrwmsEsu5gN394ybNbYyrMe0e4JPjLwwjHGY4rox2r9s+qFnuAL212wzszXOCqwEwvWKQUWJc9aYDcixFU4jSgFWLxlAqdx4IiUiSh1A5tpQTQ6L1/A0PFTwrSznMWCLSoZ7jGUEKzkuIsFizRrLGEvAdaIWO7ZhwX264wFNrpgzxFzeS56gh/JU65xEihHTHW00sc8HwZWClymWg4FqqjekQuU2rxlucrx9KP1NcyDz5Mof3/BvgPWaUVoLfmw4QAAAABJRU5ErkJggg=="
    },
    "General Permit Industrial": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABYElEQVQ4jdXUMUtCURQH8P9RQbdoqSkS2xoiXPoMod6egQ1F36Kmip5jtLXmVtsLxOtD0KkhaHKQaojmoJpLuSXnxKsUCZ/vSQ31h8vl3uF37nDuieGXE/vfoGVZi8y8AiAlIq9EdM3MZ67r3o8FZjKZyWg0WmLm1d4dEX3skUjkQCl1mE6n923b5kCwUChMGGMuAMz7PCIOYLfZbKYAbASCxpijEVg/RLSulDrXWh/7gpZlJZl5E+GzB6AEQIaCzLzsFR8DnMlmswuu67aGgkQ0K9IvFipElAQwHBSRzljaJ9gePH8HW732CBnudrtXvmAikagbYx4BTIcE67Va7cEXdByno5TaAnASAmuLyHZgH2qtT5VScwDsEdgLEa1prW8CwS+0mMvlLomoCGBpoJW8/1wVkZ1KpXI71nCoVqsNAI18Pj8lIl7Dv8Xj8TvHcZ5/NL7K5fITAG+Fyt8fsO8yZIXTvMvl6gAAAABJRU5ErkJggg=="
    },
    "General Permit Storm Water Ind": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABTklEQVQ4jdXUO0sDQRSG4fdsFhQsxEYrMcRGLMTO32AlWGwkoj9CtEpWN8SgYmdrKi/EDXZWSWUhWAe1CNaCWmsCucxIMGoQx92ghX4wxczAMxdmjs0vx/7fYIHstIY5jYqB1EFfW8ipQ+quJ/CYraEIKqfQ868j0pkRFOzk2dyt0Nzw8FQgWGB7UNG8ACYNm+gTSE0QiQGLgaCisQdiwroiCZ/MeRx33wjm2YpCa4mQ0Yir0TlB9Jeg0JwFebuwMBn1yUwBZQPI2PtSoWNFjSBYtfZBeolCqt39T6AqfzyRcJ5N48oICgNFTfUBGAkJFh28eyPosFLzya5q9GEIrKpQa4HvME7yyCc7rtHeN9izoJ0E6zeBYAdNn5C5BEkDM11/rw6cWeikg1vpqTgs4JaA0gHecD92tIVqRFC3Dt7Tj8rXMt4j0G6h8vcL7AszmGdhr6nvzwAAAABJRU5ErkJggg=="
    },
    "Haz Treatment Storage Facility": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABbElEQVQ4ja2UO0sDQRSFv8mrCqnERpBAHiIp/AM2ItgIKSzsREgpCjEbCxFxERTUvCCiheAP0E6QWImNtVXeKYKVlQbTaF4jGwU1ZGXN5lTDuZdvDsO9Y2PIshlpCqg4GjU85RT5oQBbdcLCQhCYNg30RBmVki0Erokoi8UYF6aAVske4NLOUnLgVrmqqrwNBPRHmAJCPyy3o04E2B8soSAJWHrczcAG59kjnv4F9CksADN9Ss5Wp5swZBgYUHE06xzqXSZh2RshXUnwYAjYfGUVgUcPqD2DRRADZg0BhSQnxR+4z5i5fratn1lMcuNXuAbmdXAvdsmOYaAmiyTSEcwBdnokBWo2yTN9pAssJCj5FY6B9V8FQX7MyUl5kDlstNl1WFkCRr7jodyptBgEWE1R8ylsCzj9sjKlOBlTu1x+5Mw/zgowCd21M/l9XdIWUcIdSbAcp2AeqI1RjFvvGvdGeg0BNVXSvBtp/ADWRmMmyszvkgAAAABJRU5ErkJggg=="
    },
    "Haz Waste Management Activity": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABZ0lEQVQ4ja3Tv0uCQRzH8fep6RKREZFWkEhDU/9ASwQtgUODW4QSlhlhNkVEEhQUQdCvxwhqdwzCprbmJjNqaCmVoqfGDH2eeB4hRDQeffwsd3zvy+uO485Gi2Mz0pROYne+43VHyLQE7PwgihUfMGoazEv0KII1oCMn4XeFSZoCFdjSMG2uCnaeL7j0BPhuCnxJMAIEK0qDjh9iwHZToAX2y0NFVFbfjjnviZBvCMwmmALGaiy1l6z6CYOGwXQSOzK79TZTYeZV4rAvzJ0h0CmzCHjrgfo1CPaAcUMgcP8PVhZF7R5braJ7nutsgitgso73WbSyYRjUoliIWRQmgDaqI4gPzCI3BPaHeMyecoTKchWWceU4aeodFuxsOgpMA91/nmBFxCk2BXoCfGUTrAOSXlBJuUKkTP1lVxdnOZkFYBiL/u0wBQo/pbxEVBH43HM8mAa19Ia5eTrg1kivIVDL0BIFI42/g7dZLzzjwFsAAAAASUVORK5CYII="
    },
    "Haz Waste Transfer Facility": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABb0lEQVQ4ja3TP0tCURzG8e8xTaKQhJC0gkQamnoDDUUQlH+gwLYIHKPBbIqIIigogoaihqAXUEsoai3h0tykRg0tdZWGCoJU1HtDCSG5xdXrM12e8+NzD4dzjLQ4Ri1DST/t1i9cjijploDdOYKADxjVDWZnsMlF1hBYMl7m7BHOdYFykW3AUvlWZHafxgg7E+SbAp89jACBWiEYNHcSAnaaAg2CAxQMv0rB6us0Z7YY2YZAyc0sCuMqS11lQ3WHAc1g0k87efZQ1H+mwMKLj8O+MHeaQGuOJcD132kgsw9MaAKRSdWdnIpISq03qpWOGFeSlygK7j+891KRDc1gJXKZkKGNSRRM1EewOXDNW0Ngf4wHyc0RguW6pbT9k+Om7mHBxJa5xDzQU9ucYEUkKDUFOi/5kDysAyc/VdweIa7rLds7OM3kWURhGFF9dugCxQXlrJegDD5HhHvdYCW9EW4ep7jVMqsJrGQoTkHL4Dee/WGk6qnzbwAAAABJRU5ErkJggg=="
    },
    "Hazardous Waste Generator": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABYElEQVQ4ja2TPUsDQRRFz45rsJBUIpaCIFj5B2yCYKEQ0GKNAREtRSTGyoTVIV+gCIKKFoKChWZTChorO2u7RPILrNTSYNyRRYWQxLDZzW1mePdyeMy8p9Nl6W5CkkKgB3vEJFLuClBHxRSEgQnfQMnlIKikBsEslpFkvuALqBPIAkHnrlC7kosbyfKHJ2CK/DiwUlca1umLAzlPQAEHP0e9tC1J4VxivHQETJOfA0ItrH4dO9fQeXugMyYa9h7/aynF9dE2C0+ugDr2GjDSBigEYh+YdAVU2CWt8emaU6XWzbSQSfQ+g3ULaqaVD7zVEDsdfYpAxW2YAnqbXSUlxmtHwASRShbrWKE2GqxyjaETT3P4SSClU10EBv5qGmxKQjVPQMnsewbLBHX6WyomiRR97fIo2lkFtQqMCWxn7fAFNDC+0lzFBCKcIPrsG+jIJPpwyN2jm6wroKN1pqtugt8NS12oPJbVKgAAAABJRU5ErkJggg=="
    },
    "Hazardous Waste Planner": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABdklEQVQ4ja3TMUvDQBgG4PfrVbPYTuIsdHLyDzhUBKG1d1AhbiJ0FIdaJxFRBAVFcFB0EPwBZkjJYeMkWZzdrPgLnFQ6eZA0EugQpCln0ndJeO/juRDu8hhz8jpDpmlO+r5fsm27OxZQKdUEIAAsZAbr9fpMEAR7AIqc8zUp5X0m0Pf9YyIqRu9EdFoulx3P835SgbVabZ6IGrFqtlAotACcpAJzudxF9Ih3RLRbrVbvOp3Ox79AIcQqgMUhS1OMsegLG9qgaZqTSqmzpM2IaEMIcek4zosWqJTaAlBKAge/4RzAkhYI4HUENnImP6x0HOdRCPEAYCUB+zIM40AbjMIYawVBsAxgAn9CRIeWZX1iSBJB27bfOedXRLQd78Mw7PZ6vetU55AxdtTv99cBTMfqHc/z/FRgu93+FkLsA7gZVK6U0s10lw3DuFVKbQKYC8MwunbIBFqWFXDOm0QkpJRvmcEoUsqnSqXyrDOrBUZxXVfpDP4Crph28eAyCiIAAAAASUVORK5CYII="
    },
    "Independent Cleanup": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAz0lEQVQ4je3SvwpBURzA8e85nWsUjyD/uqNSHsCgbCaPIOuVxW4SNhm8iLJJKTIoSSzegTJwj5IMun/cMCjfOss5nU+nX0fx4dQffJS2qGpBDodsTX/XYfryDFM1GhqauCQFpYRFwQlVzxtJiwoe2L2wFAzNOtl1i60nKCUZrX24O2qfiYMP+G7qD35hhhcGSMpAxPOmZoLBxBfcdJkn6uSlzQiIumBj40Rx1ePg/0Jg12Jh1shoQczpPCSYLXscA32bdZs93FagVNALvw9eAQgFMS31dCeBAAAAAElFTkSuQmCC"
    },
    "Independent Remedial Actn Prg": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAzklEQVQ4je3SQQoBcRTH8e+Mv5USC9KUhZxgSjmAhbKzsrM15AT2biBmykWUnZQiF7CSGg7A0vw1ko0xfxMWyq/e6vU+vV5P8OGIP3jP3qEtPcoERJfYuQ6Ll2/oOvSkpI8W1AVPo34YUg1CxQM2ooWPhSfp6Ux2NqW8xSZ8Qx0TqeBuaAyKoALfjPiDX7ihZAw0gJRidh6PM1eChsXKdaggmQLpJ9hMnKllLI7qDX20xdodYGoxCkF9LcEy2+QU6W2MLlu4VqSIqAO/D14AXTguRM8A/14AAAAASUVORK5CYII="
    },
    "Industrial (IU) to POTW SWDP I": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACH0lEQVQ4jdWUXUiTURjHfye3xKBcrs2itrqRWV2U0cgoUiFUKEcEiTetJnVTgbcRxZbJ6LabbvpYs8xSL/qA4L1pjgocXfRx4xbuZuag1LS9ZB9qi7M320a+06CL+sOB8zznfX/v8/zPe46BvyzDfw20YCo/S3rmIFOqlTRQsvw9QvSRGvMDo4sHmqyH+fTxGiutBmqaBfZKLZ+IriPU3cZn9SQlyzykJroWBppXH2FyLIDbJ2jwgBDZtc27oLFVoASMdPpussIsSI3fKgS0oE5exX1e0OiZ3wj5gcbWzIyujuuAktt+PtDmuIix2EDD0WxudgZe9WvzLbVQ9PMVWX2o28i3r36SQ8fnB06pTbjc2TZnp6G9GaIRLd5YDed6NKh8prYFHl52ATpA9UMZdkc2ftmfhUkNDmjVbturxXKz1AlzIQ8z1vxSWv4rC0mgDyy1qgxHTWzaqcVVdeBwQuy5Flfu0HycUyIKpatSjA7rAMvtTwj3NmUMlyoygrcPXoQ0z7bmbIqsPtwDFltYH7hk+gQjb/ajBEQedHv9750qAUjGv7O+4pR+y68jb9l9oJ2g15uJ56C5kpUpN6DTB856LwOPRvSBUk/v+ahrWcpt/2nCvYKaQ2CTRy8NiRiE78rK0lTvu8Cz+x2LO8uhO2dwtQV5N3QFJeBkPFmcyZet+cLaiggbqo7xOBj/s9vmwaUYsCcvl4xro4D+/Qv2B2HEpZteDJOpAAAAAElFTkSuQmCC"
    },
    "Industrial IP": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACaUlEQVQ4jWNhoDJgGdIGinJLitb8//Mv6Oenz2IM/xkY2Ph5XjEwMK35/vpNGwMDw2uiDeSWFI79+f7LXG4JMRbthBBGUV11sPjryzdlrsxblf/r8+dsVh6uxB9v3i8laCCvnET812dv5jv01TAa5sQxMDAywuVkHS0ZjHLjGc9PWcR6oKh5MYeYMOOPV2+X4DNQ9PvrD3Mc+mshhmEDjIwMhrnxYNahsvZ5DAwMO5G9j2KgsJZqBwsnB4thdixc7N+fvwwPdh4EsxXc7RmYWJjBbJCFV+atYv3z/Wfbu5t3U7Ea+OvTF1/9zBi4N//9/sOw2jmK4cnh02C+jJ0ZQ+jeZRBDGRkZtBNDGU53zfRjYGDAbuD3t++FRHTU4Pz7Ow7CDQOBJ4dOgV2r5O0ECR9ddYYf794L4wtDBkakSGD4/x97OKJoYGLAaSC3hOjnN1duCsjYm4P5ip4ODNLWJgxPj56BeNnWFByOMPD68k0GLjHhT58ePMFuIL+S3OGrC9f6GmRDYpiJlYUhbP9yhvvbD4DDTNEDESkg119buJaBX0H2IE4D2f7+yXp+/Y7PhamLGJENVfZzwfDp+SmLGN7dvPdPXE81B6eX7xw4+UQjwq9pf2FzPYgPMxQF/P/PcH7qYoYDxS0Myr4u9bfX7XiK00AQuLFiU4NOfAjb4YquiqsL1zJqxwcziOioM/z//5/hzZVbDFcXrAG57L9aiHfzjeUbW4jKy1cWrqkyKctc+Onu/dnnpywy/fz4OTtInFdG4oeQpupJUWOtlCuzVt4lqbQ50zX9JgMDgx2y2Lub98AYHxj8BSwA9PbmKDBFx4AAAAAASUVORK5CYII="
    },
    "Industrial NPDES IP": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACD0lEQVQ4jdWU30tTYRjHP+92Vok/NmfbiBRXIC4DyYU4ilpXGZG76N4iqZsKuo2QjAzpf+iHyMqrrgrMeVEdxHAXm7GIEgsMdTAXsba2ZjQXZy9zrnZWRBf1hQPned7zft7n+zwvR+EvS/mvgTYsdYPk8yfJrNnJ56Fm2yqCByTTI0D894GWhn7SmTs01it43YIWh8wvxZp5GrrEl7UL1ChnSGbv/xrYVH+axOdR+o8LentAiNLa3t3Q6xEEgib8E34aagXJ9L1qQBup7G1OaTBP5UZoBxwrrAnGJ+8Cgc32y4EtjpuYjApHe0q53DpEFuR7ZxsYDfJdq/5ZyMTXbyNE4+cqAzPZPnyHSjZzORgehTeLMt7jhMEBCdW+OeKGR9M+QAeYylg3BqDpxUIJpun1oqy2q73oCFKZpmo9/AMJ9IHmuhTLqxY6dsl4Xxu0t8L8exm7nLKPRS3FwFybJJ7QAToap1HDfRtDMRrh6oC0XjygOBTtoqtzYLOq+kCD4Twr8RNMBUUZdL/rZ6eBIEQ/rNNqv6hvOfJ2mQOd1xmbGCrEm69PUVplU0HwP4Zu1xCzr1b0gZqeR67h7drCeOAyaljgdUOzvdQzNaxVlsfTMczMyxs/bq88ZXXuCr6DY8QSt5ic7ebjp62FvNWcZef2IM4dZ3kSeldpq1IRqOnhzDxwuCwXjcuniv79H+x3dLWi19CNUVkAAAAASUVORK5CYII="
    },
    "Industrial SW GP": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABWElEQVQ4jdXUP0vDQBjH8e8dhXYRcdFJLHVzEAfBN+CijfhncFB8D1JTFFRsR21pwcHFbrpVEGwU2slBcHEp6iDOgjqKttI0ObGCNGBMgg76gxuOg8893D08IX45of8NJscZsiSTEmIKGsCVDQd5g7tA4EqcLlNSsBUzAlBtZxI2lzQyHcNspFLYnuDyKJ0mnKEYcCkiDKw9XRAD5j1BK8I2uGKfETCna5xmDXZdwcUpoqrJAj6jYB0otL+KA5QmY4j3y32nN6ExmDOofgki6XP8gI9IQRTcQEU9GAdKUGvfO0AhqAas0JZNLl1BO0JZ1nkAenyC5cwJ965gvkg9EUcXgj0vSUFNCZKefZg7Zj+h0S8g9Y33AszmS1x7gi3UIJ2c4NxWpIGRjz5upSGgJCWrW0fcBBoOmRIVoKJP022ZRIXEfA1zu1Pk+UfjK3vII7SWr/z9AfsGsj1hlnpZ6C8AAAAASUVORK5CYII="
    },
    "Industrial Sites": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAtklEQVQ4jdWSvQrCMBSFv6QBdRCRiij480RF39NBZ1/Cyb2LdRAHwTpU20iEQhO6lNYhdzo3OXycm1xFx6X8B142LEXOyWiZsZ4dSVsBpSDQEP70CNE6YdNSfgGTiIlrKArG5cN9UsIkom/dK/Riz70+oebmAoWuNJKYam+O3uRVzn9HVgOGriHPWOmcs9GBZCZ69h4WDzuzBZzueLrA65ZXqQ2szuPx2vgJnB+IzX90BmxanQO/4Y8p14XzSicAAAAASUVORK5CYII="
    },
    "Industrial to ground SWDP IP": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABtklEQVQ4jdXUO2hUQRTG8d+FXUUQFCVLAkHETok2YiUqYqGiayEWNrIGtNAYRIUQRIioBEmhzabxsbqoaCG2aiE+goV1ApIiIPgosj5gUygYjUwykZuwu4lgoR9cuN+59/znzJyZyfjLyvzXwKYWzoyzt0puAksYxf0KvajMG9jCgS9cbyZzkGRtjA/SWuL4GB2Laf/InTmBKyh84MYlkmNIUt+2opOkSPYkt3Iko9xuBGyqcO1yhNVSGKAzvnZRwuP09GcA13BxEZmOVGw8ZgRtTyWEAUtkv9I7zOGawCr5I6lpfsc2DES/GU9iUvinHX3sUQ/4iWVtKf8oBQt6EavdFX1o1meWN2xKuglhq8ylZJafAWxmbIilW6LfiY14Gf2muI7TGkSO6pt6wFUMlMlPNyWLp3gYK9mRSgjVl7GS53WBPzj6mt39JGloWPXZKmKYn+umGl4b+Ix3+zl3gp7g09tHqrJ+nEKenge8rwsMusfZAgu66S6TFNAWQUO4OVXZxD7O3+XCvM5ymdNdlEe4WmTDWxaGeCvfVvNqPYeuMPJHt03fZCGTe/m3QiA8jfTvX7C/AI4gZOVQUAfOAAAAAElFTkSuQmCC"
    },
    "LUST Facility": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAzUlEQVQ4je3SvwpBURzA8e+5jkmJR5AnUMoDGJRFJlfKKou/m92Im8lgvbmvoGy6pcgLmLwDC3HEYNDluLEo3zrLOZ1Pp19H8uXkH7znNKgqSOGRMBiZfRZvz3DSpIOiK3jSmbzTIuOFyscNp07livG6MGemdo1kacjm9QsFCQ12R0WAOOjAD5N/8PszVIKxUBSAiOauGwriasHigJXTII1ghiL6BJsbB7I5i50WvGZarO02CeNEzOv8KFmWLfa+vk2pxxZuy1fS74XfBy/WXS2pAEFowgAAAABJRU5ErkJggg=="
    },
    "Land Application": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAtklEQVQ4jdXSMQrCMBTG8X/bUAviIHVwUe/kILh4CwedOgjepD2Ok3t3cbMixqYSB6mhCqVxyJse5OOXPPIElku4D+7IJsBB9z3kbM2q6AQGEJR4se4LIq/zC9uWcAtMSEdmQMLQf4dlnJBG9fOQqNoyPzeCAv/0+/4gN0dS3Mu689+RH/gDMxDCVKGOuvdQY4n42MM+t+ormLC4mOCe7KopXRpryji8Nm6CG5b564NtgW3LOvgETVgl8njacJ8AAAAASUVORK5CYII="
    },
    "Landfill": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAtklEQVQ4jdWUvQrCMBSFvXghLlKkDi7qE4XmPTO0c1/Cyd1dHApGSiA9JahgQ/0prUPOdBJOvpvAITybWBw/MMuyLYCD93Vd78uyNKOARDQHkHqfJAmNvuFQcVxAKeU6DDRNs3p6Y0wqpVx0AMzI8/zSCySi88fpzKdwzznnXjn/fbIQYhkGrLU7AMfHciOE6PSwqiq8BWqtryFQKXUD7mc8rC8TcW3iBBZF4bv39VP4GThUkwNb+s07DwhanIoAAAAASUVORK5CYII="
    },
    "Local Source Control": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABj0lEQVQ4ja3TzysEYRgH8O+zO+tX2hzkpsjJyT/gsDJWUg6Km9QehcxYy5K8KeyiHSEOyh/ATWnR7rYXZzfk4uLgtLOtgx9559EoEbsaO76n6fs+fd63t3kV/HMUJ0NCDFTkq82WjenU1b+AD1W5CQ+oD0C7azC8FmywpDUHwK/F1EFjJnXgCrQsuQQiv/1NxHEhAkdCZJ/KAvVYdxsgQ58NNT3U+HQAy+WdkKQBwPO1Yubo1Gpgfy2Svf8TOBlX+xnoKLJUa7FinzDkGBRioKIAc7XUZgwM6yvqViKaunAEFqpyowC1lALta2AvrwPodASyB5fE+P16mS6L9Uqx0oikT7S4ekxAbwnPZJ93wTFohyTr8FIQgA8/wsLQT3N/AhOz6Rstpm4TQfu2dOV/lDtl/Ydc+bpIL8oQgPqPjtiaFCL7Wha4oWXzWrxrnsC77xuAkomZTNLVW75rrttrvDVHALR6JNnPDq7Aw8FDGV4JTkiSfYnZs2vXoJ316FlmbLPn3MmsI9DO1njy2cngG0TVg2T9NYpnAAAAAElFTkSuQmCC"
    },
    "Minor Industrial": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACIElEQVQ4jdWUXUhTYRjHfye3YhBtKU4lHdJNzL4/hKAyR4ST2i6ii25qCXVRKlGBSESKhYQXdTNv+ljOGnURXXjTdpFbmVBXZQpjF0K4XOSy4oxalGvx8jLOVp5p0EX94cDzf87h9z7P+7znNfCXZfivgeVVFs7PZTmgfsGazYLZxAwK95MqvUBy0cAqC4c/fuZm5UoMRxtR1ttkfnyKat8wp1JpWpebaHmvElgQaCvDk/jErStHUNqcoCjaO8daaHeieIMYzwxy27oCZUblTjFgeTLFjaseCZtPYoH2Zhl2BPABofz2C4B1NVw2GTG0Nmm5uQyExmTctBEMJTIWC/rCGNPf6I0lOD4vUE3jOrFXa/N7Bvb0wEhU+gY7PLogoeKbFgf0DeEGHeCsSum6Gs0HX2owoSdRWe2+LdKLYX1IUVZsDwuGII7KQlJ+8QXASjOpiTiW3XXSN2+GHWtgNCb9Lrvcx5zGp8BqRn2d1AGurmDEH8GVG4qxBMLd8PCFrNy5SRuKqN4fgdoKHusCM0s4GZ1mf38IJR/q3vZ7q94gxN7yY4ONNt2WI694c2gnPacH6BI+//jkJCrrD8HZQXDV0/XgGdO6QKF7T+n2OFjaGaDTH0HxNIKYvABNxGEgArEE2YPbuXh3lEuL+pf9Yc51uPFPvuO6N0h9fJZlIl9dylf7Kp5vreXYtWEm/+i26RtCzLYhPxdLyKeY/v0L9ichlqeKvPXw8AAAAABJRU5ErkJggg=="
    },
    "Minor Municipal": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACS0lEQVQ4jdWUXUhTYRjH/8/ZloO6SI8rpUjM8ir6VmJ9SRAtyDW9iAlFFHmjQRcRRARGhQRG0kVFmVZkcC6CjV20CRX0CTOKkG7CassljCSdc1aIe594z5ybsqMW3vTnXJzzvM/7O8//ec57zJhnmf9roE214WwyidrRBJaAgYWL8B2Eh7FBNAMYmDNQVXFoJIH2gkIyO5xEpatJj4d6efkjL5/4meBGqxVH4nE8mBVoK8LhwQHcaTilUI1bAaVYujZUEGrqQB5NWK63iPv5KmjoBzpnAtriQ7jdKGF1Ss4+yBfUptboZqvoANCVbX8KsGQlLuVZyexyZ2DJJPDmNev3FXaCyZSKy+r9HraMjXFzXwj1OYG/RlG9/wBN2hwfB07WJ9HzLgVcu4lwpc2kQ2XOXhdBu8tOwAA4HENBegBS3a94EibV85b1ardsT+XI3OEYVOMekn5llGEZiqZsmAYsUGkk9IkXr9ucyqrcRliznvDh/YTljaT3Ma1QLyNfpXi0n3MDi5fhRZePq13uiUUz0NpuQvdL1kuv3JoZCjMQ8DGKivlZtN+gQgtxw9cv2OfVBKUnLaH2qmm+AHg0gUiYxYpyHDe0HAzi2y6Hcv5ai2iSz9mfT1qyMq8mcOOygL1KaXr+WPTPeFKeBsQ5h1NZcOuqON3lY9rjJJSuIn0+smfSZiTMvHO3cuGJX1yc01kO+MSZg0dxr68PbR6NKwaiIk/GC5fS75IyBMvKcczvFZ//6m/T2YGPAO/IjkXCsjqjHbMA/1XzDvwDXKfQiv8dIE4AAAAASUVORK5CYII="
    },
    "Moderate Risk Waste": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAvUlEQVQ4jdWSMQrCQBRE3yZBjSAisbBRTxT0KGIOYWNh5SUstPYSVvbpxUIwERKTlQWFZEkTEoudaj47PGZhHFqWYz5wvWAqMi7Ki4T59kzUCJgLbBs85eMhonHDunLMAgY+Yz0gc0Y/70Z4gU+v+N5xkJsj9+qGkpsOFLJwWIQUbyBJyYqc/3755TLQA27CjIyr8m+LSdot77D/KHcuAfcHnjpwtSS2v17BqjIGz8ZM4O5EqObYGrCuWgd+AAy5K1m+fPQ8AAAAAElFTkSuQmCC"
    },
    "Municipal IP": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACE0lEQVQ4jdWUP0gbURzHPweXFkGwtBgSCCJuirpIJ1ERBxUbh+LgUmKgHWwMRYUQSkFpi0gGXS6Lba8ebdGhuKqDaA0OnROQGwKCrUPOP3AZLBib8nwNl7QmWujQfuHgvr977/N+7/1+91T+stT/Gljr9fIsl+O+bePO56Gmhgzw0bKYAaxrA71eHpyc8MbjQR0ZQWlpkfFkEp+u8ySbJVRdTfDwkA9XAuvqCBwc8HZuDmVsDBTF+dbdDeEwiqbhmpjgnduNksnwvhKw1rJ4PT8vYZdJLBAOy9dIBB1YL95+CbCpidmqKtRQyInlcrAupgC9vaD+nCEW1HVcp6fMmCaPLgXaNv7RUWebZ2fQ0wOJhPSdnbCxIaFiTDAIsRiDUAZ4dMTt5mbHr605MKHtbZntwID0oljHx9ypdIYlRRCtcpWUovG/AT0esqkUt7q6pO/vh/Z22NmRvqNDnmNBySS43dh7e2WADQ0kDAN/oSguF2xuwuqqzKSvzymKyN4woL6eT2WB5+c83t3lXjyOUgwdFMf+izQNTJPvra2UNFgJcGuLL8PDPB8fZ0r44vYpSGQWj8PkJPj9TK2s8LUsUGh5melAgBvRKFHDQAkEQFRegFIpWFy8yCw/NMSLpSVeXutfNgyeRiIY6TSvNI27+/vcFHGfj2+NjXxua+PhwgLpP7ptYjFM0cvFMdOUTyX9+xfsD3qNqFvWprUTAAAAAElFTkSuQmCC"
    },
    "Municipal SW Phase I GP": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABdUlEQVQ4jdXUv0tCURQH8O95vRBqiJaaIjFaGqKtpVH7QUMQ1OBT6G+Q/AEVPSefurXmUKmTbU3+IB0EZ6mGiMagmktT33snlBIJX+9JDfWFe+He4cO5l8MR8csR/zfojrkWBOYNMBwgboKEa0HUzs98lw8Dge7I+jgJjQQxb3Yu6GNjht4SopLijM++LR3KsqybgluKc0xAowxgzqAIGxH270fKDgCSKWgDjkCGWDfMcEuKs5QOFY4NwZ3Iql0l1QuLEQgHYCRA4L6gSura549ZCQNTnujKfArZal8QhGmrWLdKaHYABiBQHxTUiWu95y8gVwd4cccTSbsyBBvDtaytNfoEYNKaR9mTQOnREMz4KnVJce0ScdKCVhti1W/ah+lQPuWJOmcAyN9gr0S0fRos3piC7aSChbAUc1WIOQxgsaeVmmBcgLS9ZKB4O9BwSAfyOQA5b3x5QtNVu6gLrXpDvcvIpZcfja+kP/cMoL0s5e8P2HfKG31FHloH/gAAAABJRU5ErkJggg=="
    },
    "Municipal SW Phase II Eastern WA GP": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABYElEQVQ4jdXUMUtCURQH8P9RQbdoqSkS2xoiXPoMod6egQ1F36Kmip5jtLXmVtsLxOtD0KkhaHKQaojmoJpLuSXnxKsUCZ/vSQ31h8vl3uF37nDuieGXE/vfoGVZi8y8AiAlIq9EdM3MZ67r3o8FZjKZyWg0WmLm1d4dEX3skUjkQCl1mE6n923b5kCwUChMGGMuAMz7PCIOYLfZbKYAbASCxpijEVg/RLSulDrXWh/7gpZlJZl5E+GzB6AEQIaCzLzsFR8DnMlmswuu67aGgkQ0K9IvFipElAQwHBSRzljaJ9gePH8HW732CBnudrtXvmAikagbYx4BTIcE67Va7cEXdByno5TaAnASAmuLyHZgH2qtT5VScwDsEdgLEa1prW8CwS+0mMvlLomoCGBpoJW8/1wVkZ1KpXI71nCoVqsNAI18Pj8lIl7Dv8Xj8TvHcZ5/NL7K5fITAG+Fyt8fsO8yZIXTvMvl6gAAAABJRU5ErkJggg=="
    },
    "Municipal SW Phase II Western WA GP": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABUklEQVQ4jdXUP0tCYRTH8e95EJoiWmoS9dZUEG29hqagoaHoPYQpBRXpWIpCQ0tuNbY16dQQNEc1ROW1IajGSA3/3BNlhIF676WG+sHzwPMMn3OGwwnwywn8bzCu9mQTZgxYitRALxwCh1kJ3vsCV/VusI6Tc2BWAP34bd2GxtayllL9hDYTIo4ruKK3A3WcE2CsSxN9oOvPlCxgwRVsYnZ6YF8RmI9p8Tgt1l5XcEntsMIiHqPIBqo5RLQjaGC6VdxzglHsiQycdQSBkA/sswkJ0wOs+gUVrbS/v4HSVsljHIOcdwUdAnlD4xEY9gjmUxJ56ApmJViNajEmyL6bpFBRTNx1DjNiHUTVHhFI9PDKIHNZCV26gi00koxr8dRBksBU2yjVBD0ysLYtkStfyyElVgEoxPRmqIkJC6b+Svl6V8ZffrS+0jL6BLwfT/n7C/YNSKprdEkrvNMAAAAASUVORK5CYII="
    },
    "Municipal to ground SWDP IP": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACK0lEQVQ4jdWUXUhTYRjHfwfPsigoGxsuJMwgMMq+iKKskIJSmxdrF10US6iLmh9UIBKBUSHhRcGcBX2sDtnHRZMurCUVaRK0u0ohdjExs4KtNLewqOnifcnDsbZl0EX94cD7f845v/d53ud9X5W/LPW/BlpsmI8mGHfE+GRNArOZGQFuRfnYCESnDLRh3j1M/FIuZnUPZcpSFsp4D+E8H+21cUbds5he+Z7Ytd8C52NxvWXo8mlqlCp2oKDo70pYSTVOxYvfdAjPVSs5SoTh1kxAS5TYxTPUSlgqiQmqccphHWd9QIex/EnAxeSfmkG26sahxxKM0UFQjreyBpUsORYT+mg3feZrY4hX+1ICY4za9+PQy/xGgs3U0M1z6TeynId4JFR8U0k5TVyvAFIDPzAydwkFur9HUIcJPeaZzLacddKLZg0xYs60hoYWQBKxWTJLmfTHT8BczPFe+uZsYoX0paxlPUU84YX0G1gm13FCPYSxkhPr511qYAHzujUCdvePDptQeUQzAZ7KTLYZmiKy1wiQj60rLXCMrAMv6d/egl8xQiso/qVUL35CDIwXsagqbcmdBAd3suX4QTwNwk9AjRKZtdDGYZqxU9zQRuebtEChmzw45qJsWj3n6jUCiotSROdFe3rp4wp3RGZJJyUnbnD/5JTOssbdI3Xs0sIMXvDiX/2aSLaI52H5UsiC4CoK957ndviPbpsmWkNyLxsUYkA+mfTvX7DfAQKBpYKMc3NNAAAAAElFTkSuQmCC"
    },
    "Non Enforcement Final": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABtklEQVQ4jdXUT0jTYRjA8a9zMpjattyGxA6af3YIithlWqIF6TrskOlMoYMLPIgReOjixUshCB46eJNAKJRZBEGJg10kEIbB1g6CDkWFpnNz+Vv7sTmZtJAc7s9v4sEeeA/v+7x8eHjfh0fOBYf8/wY/DHNvyc/z0AHXZSUk9So8TfWMWcdZLQqcGuLaog/X8jpGmxmuKMH4Ip0yqZXYn93H2VHFQ5uDo4LgaCeGL258409R1er/ngUi//KRGEy5eLB5M11lHZDKC3q2cL6xozJU5XsMcHqp7TIzO7eELSdoM2NtvYGxEHYSCx4e63RUBINEs4K/RAb77lIijYMDEZmlkYH5IBNZwdJStOpyigrxENPpfQaYPCJZHAdiIvOnM8BwlED8EBRl0sGQgDsnuLHL209u2nqaUUvB5DJS/h1mcoJ7Ap9ff+SH1USLUlEYrNbwfjtEMCf4p0m9m/S3v8KxMMLtfKimAv92iIEzVWe56/+2Qvetl0yOPKKm9w4NkNlKVyv5GhZ4AsSkgGl07SeW/kk6hqexapTotZXoxATff8d5FxZYPs+0SQHz+9H0khyXf8AeA3QxgOqzYhFXAAAAAElFTkSuQmCC"
    },
    "ORA Project": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAxklEQVQ4je2SMQrCMBiFP23E7q1jB52c6iZ6AL2DW2ez5QSeyRt4gs4uboqbCG3pUKwoSrUGDUUQxQcZ3sv/viF/BG+W+ANP6gEjHjUAGqUsBGav3nAYhkw9j+waRBH1bpdOmlIrzbZMgLgubccpfLMJlqWb5PAjW97twLYLH8eQ59p+3Qjo+4/ZZALjceGThK2ULI2AOvX7EAR30V5K1pWBphIfA+b5+c/driYzBW6ARTlUipVS57unfaEBzi+nkkTV4vcCj/YSJ4p4RK7aAAAAAElFTkSuQmCC"
    },
    "Oil Facility Contingency Plan": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAnklEQVQ4jWNhoDJgGXIG/ifTHEZcBjJEn9rIwCsrSZQp31+/Y1io54HXhQxcokIM3BKiQyRSvr16y/Dq3BUUMXETPQZOEUHyDHx27CzDxsB0FLHg7QsYFDzsyTOQHMAytA2UNDdg8F8/E0VMzEiHfAO5JcUYVALcqOdCcgALusDnJy+I1vzt9TvCBq6wDSXLZQw4DISXGtQykGJAdQMBgeweNZhqy00AAAAASUVORK5CYII="
    },
    "Recycling": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAwElEQVQ4jWNhoDJgGfoGFvW6yTL++XcexGZkYpLvKd31lSID//1iYmZi+icMYn//+ouRYheSCliGloHFPQ4i6Ar+//4jCGNz8jAJF/c4cCDL//jF/H9q1d63WA38/5flNYaVTMjyTA9QBBgYGNiZGf4im0NbL//4/ocXXQEnD7Pc/7+MV0Hsv3/+S/z+/RclHXJxs/3HaeC0hgNf0A0saPf4xsT0B8wGGYZNzRBONkPTwAmVOx6AChqqGUgqoLqBAD/oPP/pJgV2AAAAAElFTkSuQmCC"
    },
    "Revised Site Visit Program": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABM0lEQVQ4ja3TvyuEYQAH8M97jkkmGaVMJv+ARcqiDAablFEU955BkjdF4ThFDMofwKbEZDPbkNFkQm6h8Or8KF3nvO58p7fv++3TMzxP2j8nnWQU0XBP+zoX/wIWmEjRj66awSwtMTMBTVkGc+zVBMYsoOnzeyniIOKxKjBDJ0a+VW2F99piVWBAHqmSenqK3RVu/gSGDKC7zK/G148TjiQGIxoKLFc4/HCGjTXOEoEPjAW0VwBTATn0JAJjzoMK2temXJ8uV+Y5DjlE3w/eXcxcYrCYmExAL+qVJCDKc6tMfgTXuArZxGQJdtHIVlX38IX5OobQ/NXFhBHPVYHr3IfMYvuzOlrlqKa3fM1OK6Po+HiNlZP+bbDPS5aJmP5VLmsGi8lxMs5pkm0isJgNnpIM3wA0ckZ25/cvsAAAAABJRU5ErkJggg=="
    },
    "Sand and Gravel GP": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABUklEQVQ4jdXUMUvDQBQH8P9LAg4dxEUnsejmIG5+BifBwUHxSxSdzCUvuaEVN1e76ejm1E4OgrOogzgL6hwtXOA9CUjp0DQpOugfDo47+N0d73gBfjnB/waZed33/S1VXQbgiOiBiC7DMHyZCmy323N5nncBbKvqcL2Yq+pxmqYnIhIzs1SCnU5n1jl3A2C15BIzAELP84pb71WCzrnTCdhodpMkuY7j+KwUZOYmgH3UDBEZVe0SkY4Ffd/fVFWqCwJYtNauAbgbC4rIEtE0HooiNUtBz/MGo1Wtmc9JRRmeVDOiqvelYKPR6GVZ9gZgoSbYY+bXUrDVag3SND0AcF7zqYeV/zCKogtr7Yqq8gTsg4h2jDGPlWARY0xirb0VkYSINoov973lAFwR0ZEx5mmq5mCM6QPoM/N8EARNEclF5JmZsx+1L2Z+B1CMWvn7DfYLs3aGsVQ8PhQAAAAASUVORK5CYII="
    },
    "Sediments": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA00lEQVQ4je2SPQrCQBBGvwlL0okeQTxBQPAAFkLIJFh5BPEK9t7Bwhuk2w0BwU4EQfECVt5BU2ZFDBaSX0wj5MHXzDCvmBmBhhGt8IPneQsAI2SgtV4rpY6Vd8jMSwAr5EBEU2aeZEnFd8H3/bnWOleW0iGireu6wzAMr4XCJElsIirxvaWGYQwAFAt/RbTC5ndIRBsAMwDdktlDmmKhlPLMzGMi2gHoIZu9ZVlOEAT3SldWSl0cx7FN0+xn9eM4PkkpH7XeJoqiG4BXaiHqDvy/8AmLhTbRwjnhMAAAAABJRU5ErkJggg=="
    },
    "Source Control Inspection": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA2ElEQVQ4jWNhoDJgGTUQDor+389kZGAwZ8AC/jMwzOhjVDxBdBgW/39QxcDwv5UBB2BkYAgs+n/fHZuhLOgCRf/vp+MzDAr4GBkYdpb+v2vSzah8G6+BTAwMhv8JmAYz9A8DkzIDAwN+AykFLKMGUj8M/zL8m8PEwBTOwMAggE/jf4b/R1kZfh0laGA/o/KZ0v8PnP8x/N/DwMAgiN0whsM/GL57TWPU/kLQQBDoZlQ4V/z/niEjA6MiNnlGBs7T0xgVv5KUbHoZlR4yMDCAMEmAhVQNQ99AAFAdOHxYj9HIAAAAAElFTkSuQmCC"
    },
    "State Cleanup Site": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAuUlEQVQ4je3SMUrDUBjA8V/0dZU6VCTQoXiCgOABHAQ3px5BvIJ779DBixS6lYBQ8QJOIsQeQNdEipIpaRKqg5A/vOnx/fh4vOCXCz1Y9s5dwYWKDpif8tj6DTPuC2ZqyrnZcFWFhgrs1g7sp6OcxRvnY16aNkwasBI95EwLcK9CD/7JGz5gimHDbDogbQRjnjIuscRxDbYKXI/4aLPhFn3OSCImVfcR6xM+O32bmFffp1Oh68D/B78A5C8e1bjORn0AAAAASUVORK5CYII="
    },
    "Storage & Handling": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAw0lEQVQ4jWNhoDJgGfoGZlWfkv3zh/k8iP39L4v84h79rxQZ+O8vKzMDwz9hEJuXh4WRYheSCliGloFpxWdEMJX8EWRgYAKzfn7+KZxWfIYDWZaV7e//qe3mb7G7kIXxNbpx//4j4oGZ5f8DBgbUePn9j+Uvsjm09TILJwcvuoK/P3/K/f/3/yrYtcy/JdjYeFHS4ecvf/7jNHBag/YXdAMzKs5/+88A0QMyDJuaIZxshqaBMzoMHzCgJz5KDCQVUN1AAP6jOls7gicxAAAAAElFTkSuQmCC"
    },
    "Toxics Release Inventory": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABi0lEQVQ4ja3TPUhCURgG4PdczSkMJRqaAmlo6sdqiBYJWgKHILcoRa/2i9kUEUlQUARBlj9dK2fbgrCpppbiGg1lVHtNWrSoeP3ihkGIys3bu53vvDyc4Tta/HO0Skr3Y3FdtunDZI66Uv8CZg0ZLyNmBTCoGrzzhFoKRSwTI73oOrCZBT6uCpSK3DoA/feB0ebl5PGpJWbP1gUm3eFOIjh+jdr0urwPwEZdIBHbAcCVjZeup/eP+oMzb38CRXd4FARLhatGTUErv9ChGLyX14QyWzUeP3HjCQX6wlO3isCcMT0LYqYaIMcR2wYwpAgk4KEGViqxih1tpaE54jkX+cgZgJEqXCYnaVYVg3I0xHwSo2EADSgLAf6BQ2caFVIV7BL4J5GP7AFYKLtKfba+BuvaQy6vWyvq8uMAmn9mjNiixe8v1AV2x+zvSXd4hYiFSqNEj8AnVP3ll7RRMBky0wA6CJC/HVSBthObJDoFLzSStTfieVQNyjFHXRfPc7tXSrqKQDntgfmckuIXC7uBJ0Vx9NIAAAAASUVORK5CYII="
    },
    "Underground Storage Tank": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAy0lEQVQ4je3SwQoBQRyA8W+2SUqJR5An2FIewEG5yMlKucoDSLFOu/sODq6bfQXlJqXICzh5B07SaFMOWjs2e1G+mstM82v6N5KUk3/wWYAzUIgqEQmYWdjbj2e4wB0DnuBtrQC3HoXK140Apx9ixJcHlj5Opcv0qHmhYYLSeA9UQBnQgd8l/2D6M1SouYA2UIi/qjY5Mhst2MHeB3g1UCug+AZbG9waTUZnLRhmMTn4uKaBKkWdX8nuegwvib5NF/sEhCtRMumF3wfvLhMskqFwnJ4AAAAASUVORK5CYII="
    },
    "Upland Fish Hatchery GP": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABM0lEQVQ4jdXUsS8DYRjH8e8jjY6NhUk0bAax+RtMEoOB+ANMvUrFgGhHqdypwaIbo83UTgaJuaGDmCVYLLRU3CN3kbTE630vDPySe5P38uaT57m896T45aT+OejpJDADjAIdhAvgiECuk4FLOkCaKjD74b3G6xZ5LZNhk6KEdnBVM3Q4RRk3FJFGWec+rnrBDj6zC0asG2GenJ5QkX0z6GkWWLRiXXQDtAqipgqn42PuGWaZCXwaX4PCyPuHd48SdWUAlXZCDkJavdvPLTcSc/2cm0GhhnILDDmCNcpyYwYDaZPTAsKBA9ZCWLHfw4oc4ukYUPwGe6SPOXxp2sEoO1Iir2eElBCmeq5S9D8fE7KGL5fJhkMgdaBOQQd5JYvwwhNX7MnDz8bXttwB0eOUvz9g3wBOkFFtONEw7QAAAABJRU5ErkJggg=="
    },
    "Urban Waters": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABb0lEQVQ4ja3TMUtbYRjF8f+JqYOKU+nWcm9Dh05+ARcRuhQcOnQrgmOpoLlxKCJeCi2o0QiWdij0A9RNEDt1c3ZSi0py6eTUilkU1CMhLZQ0httcz/Ry3ocf7/A+eW45+TRDsXd7T+gvrCrYvxWwTt9UjqsxYDgzWPLRPcOs0GDJ1edlPfySCTT5t+DB5lkLsWsbscKzrsCiq0Pgib+qoI6KwLuuQKEKkGupX8+49nlJ4fF/gZGTZ+CRf288cNV84URqMPZubx0vdnj8eNHJ2oqCnVTgKf2vhAsdwJxwGRhNBRrvqYPWnNFeuz7frqwo/Bq5ugl6eoP3y/TMpwYbMS4KPQHu0BJBXNH9n7TJjeCKCgeRa++B6RZsf4DkQ1f/8BLe9KAX4Lt/OqMo1shFV+CqwpPItTng4+9qa1nBVqZd/kHw6QHJS9BjcGPtyASuS5clJ1PGY8sKv2cGGykr+Dbpw+00s6nARtb06DzN4DXyqnF/JUFV8QAAAABJRU5ErkJggg=="
    },
    "Voluntary Cleanup Sites": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA10lEQVQ4jWNhoDJgGTUQDmI6XDIZGBnNGbCA/wz/Zywt33OC6DCM6XKtYvj/vxWkFRtg/M8QGN3p4o7NUBYMwzqc0yGG4QGMDHyM/xl2RnW5miwr230bvwuZmAwZ/mN3GbqhTAz/lBkYGAgYSCFgGTWQ+mHI+J9hzn8GhnAGBgYBAnqPsv3/cZSggYvLd5+J6XJyZvjPtIeBgUEQh2GHf37/47Wk4egXggaCwJKyfeeiepwMmX4zKWKTZ2RhOr264cBXkpLNspJ9DxkYGECYJMBCqoahbyAAZKM/pO2yhTMAAAAASUVORK5CYII="
    },
    "WSDOT Municipal SW GP": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABeUlEQVQ4jdXUu0tCURwH8O9PbxQ2REsNEYltBSXYA/oPmoKGhqLRR6vkAyrSyasOQUP52mpsa9KpIWhRxB5DNBfVnFr35vnFtaAib95LDfWFw+EHhw8/zjn8JPxypP8NlhNup2CaY2aHhUgRxOeSZD1w+lPXpsDT2EqvamnkBGNeq4kIrO1MaKgiXpTdSdfjwCZFIqItWJQ9PSoaxwBGdJroJKL1su3GAWDJQIe8DSI97P0UY7Eku49c4WxWFyzHfHZBYhlGQ7TBjNzbjXwFGyRmCSDDIDBYjnvHgHSlJQjiIXMetNN2AK1BAtVNaQCeCbWP9SeQgYq5/iCYrGe6oNLRne9Sq3cM9BsE81PBnVtdcMa/VS/J3lUQ7xnAalbmQNt/6Aqn90txzzCAyDdYVRAtuEKZi7ZgEw1losWE54SYogBPvz5mMwoYh4Ksa5PB3UtTw2EimCkAKFSSvj5FwA4B1fZkuRqN7Dz8aHyNB1L3ALRlKH9/wL4A24B/6vzVqUwAAAAASUVORK5CYII="
    },
    "Water Treatment Plant GP": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABVElEQVQ4jdXUP0uCURTH8e+5CE0RLTWJ+tRUEG29hqagoaHoPYQpBRXpWIpCQ0tuNbY16dQQNEc1RKU2BNUYqeGf54QaaeDjo9RQP7jDvZf7OWe4HA+/HM//BjWcm6bGHAYLlTLoFbbnWJLex75AXX8YpmKnsJlH6geN0+alqe7oaj7GoG9bImK7grp2P0TFPgMmHJoYAN3kNW8BS+4d1sxeF6wVYVFD2VOJWweOoK7k/CjLrtjXA9lSNCWIdu7QMNuo3Xu8BHNTJLjoDIKvD+yzCfGDM1jqG1Qttm+/g9Kq1GNsjFw6g7Ynjak+A6M9gmmJBZ4cQUl6SxrMhhA5dKWUImrCrv9QEtaRBnNjCJEuXAFkQZK+a1ewiQaiGs6eY0sUmGn7SmVETzBsyG7gpq/hIDErA2Q0dDdCzfgRU+G9cCv7k28/Gl8SH38B6qun/P0B+wE1o2t0SjlusAAAAABJRU5ErkJggg=="
    },
    "Other sites/interactions": {
        "image": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAgUlEQVQ4je2U0QnAIAxEr+BejZPVTma6WREuIFKRxv4UDEgw4PPiBQM+jrCA/33DxKxcbqAAyNX+YI49cBgAMw9eVCmElvr2FijMZ6VGWRdekDzAtrVUte4ypVXSu2gINCU7Icpa2cNrSqQB8lCHB6h004ww5dODraOBtljfF6bjBoomGU4FCh3JAAAAAElFTkSuQmCC"
    }
}

export const HEADER_NAV_ANALYSIS = [
    {
        link: "/m/coal",
        id: "3001",
        name: "Coal",
    },
    {
        link: "/m/copper",
        id: "3002",
        name: "Copper concentrate",
    },
    {
        link: "/m/gold",
        id: "3003",
        name: "Gold",
    },
    {
        link: "/m/iron",
        id: "3004",
        name: "Iron Ore",
    },
    {
        link: "/m/zincum",
        id: "3005",
        name: "Zincum concentrate",
    },
    {
        link: "/m/flour",
        id: "3006",
        name: "Fluor spar ores and concentrate",
    },
    {
        link: "/m/molybdenum",
        id: "3007",
        name: "Molybdenium ores and concentrade (thous.t)",
    }
]

export const HEADER_NAV_TRADING = [
    {
        link: "/c/mines",
        id: "4001",
        name: "Mines",
    },
    {
        link: "/c/contract",
        id: "4002",
        name: "Contract",
    },
    {
        link: "/c/logistics",
        id: "4003",
        name: "Logistics",
    },
    {
        link: "/c/customs",
        id: "4004",
        name: "Customs & Taxing",
    },
    {
        link: "/c/insurance",
        id: "4005",
        name: "Insurance",
    },
    {
        link: "/c/accreditive",
        id: "4006",
        name: "Accreditive",
    },
]

export const Premium = 'premium'
export const Basic = 'basic'

export const PRODUCT_TYPE = {
    1: "Coal",
    2: "Iron"
}

export const HL_LANG = {
    "mn": "mn",
    "cn": "zh-CN",
    "en": "	en"
}

export const CUR = {
    "USD": "$",
    "CNY": "¥"
}
