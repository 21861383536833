import Text from "components/atoms/Text"
import useApi from "hooks/useApi"
import useLanguage from "hooks/useLanguage"
import useSWR from "swr"
import NoticeListCard from "../NoticeListCard"

/**
    @param {} props
*/
const TodayNotice = (props) =>
{

    const api = useApi().auctions
    const { lang } = useLanguage()

    const { data, isLoading, isValidating } = useSWR(
        ["notices-today", lang],
        ([key, language]) => api.notice(language, "all", "today"),
        {
            revalidateOnFocus: false,
        }
    )

    return (
        <div>
            <div className="news-title-bar">
                <span><Text>Today</Text></span>
            </div>
            <div
                style={{
                    maxHeight: "200px",
                    overflowY: "auto"
                }}
            >
                {
                    isLoading
                    ? <div>loading</div>
                    : (
                        data?.data && data?.data?.length
                        ?
                            data.data.map(
                                (item, idx) =>
                                {
                                    return (
                                        <NoticeListCard
                                            key={idx}
                                            {...item}
                                        />
                                    )
                                }
                            )
                        : <div className="text-center" style={{ height: "200px" }}>
                            <Text>no result</Text>
                        </div>
                    )
                }
            </div>
        </div>
    )
}
export default TodayNotice
