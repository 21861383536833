import axios from 'axios'
import { useCallback, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import useToast from './useToast'

// /**
//  * get value from cookie
//  * @param {String} name cookie name
//  * @returns cookie value
//  */
// function getCookie(name) {
//     let cookieValue = null;

//     if (document.cookie && document.cookie !== '') {
//         const cookies = document.cookie.split(';');
//         for (let i = 0; i < cookies.length; i++) {
//             const cookie = cookies[i].trim();

//             // Does this cookie string begin with the name we want?
//             if (cookie.substring(0, name.length + 1) === (name + '=')) {
//                 cookieValue = decodeURIComponent(cookie.substring(name.length + 1));

//                 break;
//             }
//         }
//     }

//     return cookieValue;
// }

interface SocialInterface {
    facebook: String,
    twitter: String,
    youtube: String,
    instagram: String,
}

export interface UserUpdateInterface {
    firstName: String,
    lastName: String,
    nickName: String,
    phoneNumber: Number,
    isActive: Boolean,
    avatar: FileList,
    bio: String,
    social: SocialInterface
}

export interface ApiInterface {
    isDisplayToast: boolean,
    isAlert: boolean
}

export const defaultOptions = {
    isDisplayToast: true,
    isAlert: false,
}

export default function useApi(props: ApiInterface)
{

    const { displayToast } = useToast()

    const options = { ...defaultOptions, ...props ?? {} }

    const controller = new AbortController()
    const navigate = useNavigate()

    const _instance = useMemo(
        () =>
        {
            const instance = axios.create(
                {
                    baseURL: process.env.REACT_APP_SERVER_URL,
                    withCredentials: true, // Хүсэлт болгонд ээр cookie явуулах нь
                    // cancelToken: source,
                    signal: controller.signal,
                    xsrfHeaderName: 'X-CSRFTOKEN',
                    xsrfCookieName: 'csrftoken',
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                        // 'X-CSRFToken': getCookie('csrftoken'),
                    },
                }
            );


            instance.interceptors.request.use( req => {
                req.headers['Language'] = window.localStorage.getItem("lang")
                req.headers['ACCEPT-Language'] = window.localStorage.getItem("lang")
                return req;
            });

            /**
             * Axios ийн response датанаас
             * миний back аас ирсэн data -г л буцаадаг болсон
             */
            instance.interceptors.response.use(
                success,
                errror,
            );
            return instance
        },
        [controller, process.env.REACT_APP_SERVER_URL]
    )

    useEffect(
        () =>
        {
            return () =>
            {
                // controller.abort()
            }
        },
        []
    )

    function success(rsp)
    {
        const data = rsp.data
        //  хэрвээ хүсэлт амжилттай болоод тэр нь info буцааж байх юм бол toast гаргах
        if (typeof data?.success === 'boolean')
        {
            /** Хүсэлтийн хариуг харуулах нь */
            if (options.isDisplayToast)
            {
                let msg = typeof data?.info === "string" ? data.info : data.info?.message
                if (msg)
                {
                    if (options.isAlert)
                    {
                        // displayAlert(
                        //     {
                        //         title: "Анхааруулга",
                        //         message: msg,
                        //         buttons: [
                        //             {
                        //                 text: "OK",
                        //             }
                        //         ]
                        //     }
                        // )
                    }
                    else {
                        displayToast(msg, { type: "success" })
                    }
                }

                if (typeof data?.error === 'string' && data?.error)
                {
                    displayToast(data.error, { type: "error" })
                }

            }
        }
        return data;
    }

    function errror(err)
    {
        // axios huseltiig abort hiisen uyd uildel hiihgv
        if(err.message === 'canceled')
        {
            return { succes: false }
        }

        let error = err?.response?.data

        /** login session duussan bh uyd login huudas ruu usergeh */
        if (error?.detail === "unauthenticated")
        {
            navigate(
                // name: MAIN_STACKS.Login,
            );
        }

        /** хүсэлт явахгүй алдаа гарсан бол backend асуудалтай болсон байнаа гэж үзээд алдааны toast харуулах */
        if (error?.success === undefined)
        {
            error =
            {
                success: false,
                error:
                {
                    message: "Сервертэй холбогдоход алдаа гарсан байна.",
                    name: "ERROR 004",
                    code: 4,
                }
            }
        }

        if(error?.error?.code === 403)
        {
            // navigate("/")
        }

        // Нэвтрэх эрх нь дууссан байвал гаргах
        if (error?.error?.code === 6)
        {
            // 
        }

        /** Алдааны messege ийг харуулах нь */
        if (error?.error?.message)
        {
            if (options.isDisplayToast)
            {
                displayToast(error?.error?.message, { type: "error" })
                /** Хүсэлтийн хариуг харуулах нь */
                // const text = rspText(error.error, true)
                // addToast({
                //     text,
                //     time: 3000,
                //     type: "error",
                // })
            }
        }
        else if (typeof(error?.error) === "string" && error?.error)
        {
            if (options.isDisplayToast)
            {
                displayToast(error?.error, { type: "error" })

                /** Хүсэлтийн хариуг харуулах нь */
                // addToast({
                //     text: error?.error,
                //     time: 3000,
                //     type: "error",
                // })
            }
        }

        return error
    }

    const multiPart = { headers: { 'content-type': 'multipart/form-data' } }

    const dTableQuery = useCallback(
        (page, limit, sorting="", search="") =>
        {
            return `&page=${page}&limit=${limit}&sorting=${sorting}&search=${search}`
        },
        []
    )

    return {
        instace: _instance,
        user: {
            login: (body) => _instance.post("/user/login/", body),
            register: (body) => _instance.post("/user/", body, multiPart),
            update: (body) => _instance.put("/user/", body),
            setPassword: (token, body) => _instance.post(`/user/new-user-password/${token}`, body),
            getUser: () => _instance.get("/user/login/"),
            logout: () => _instance.get("/user/logout/"),
            forgetPassword: (body) => _instance.post("/user/forgot-password", body),

            loggedChangeEmail: () => _instance.get("/user/change/email/"),
            loggedChangePass: () => _instance.get("/user/change/password/"),
            loggedSetEmail: (body) => _instance.post("/user/set/email/", body),
            loggedSetPass: (body) => _instance.post("/user/set/password/", body),
        },
        metal: {
            create: (body) => _instance.post(`/req-metal/`, body),
        },
        news: {
            getDetail: (id, lang) => _instance.get(`/news/${id}/${lang}/`),
            getHome: (type, lang) => _instance.get(`/news/home/${type}/${lang}/`),
            getList: (type, lang) => _instance.get(`/news/${type}/${lang}/`),
            recent: (language) => _instance.get(`/news/recent/${language}/`),
            getFlash: (lang) => _instance.get(`/news/flash/${lang}/`)
        },
        license: {
            pagination: (...params) => _instance.get(`/license/?${dTableQuery(...params)}`),
            get: (id) => _instance.get(`/license/${id}/`)
        },
        project: {
            list: (lang) => _instance.get(`/project/list/${lang}/?page=1&limit=10`),
            get: (id, lang) => _instance.get(`/project/detail/${id}/${lang}/`),
        },
        hansh: {
            metals: (language) => _instance.get(`/license/hansh/metals/${language}/`),
            list: (metalId, language) => _instance.get(`/license/hansh/${language}/${metalId}/`),
            weekChange: (metalId, language) => _instance.get(`/license/hansh/week/${language}/${metalId}/`)
        },
        faq: {
            list: (type) => _instance.get(`/license/faq/${type}/`)
        },
        requestForm: {
            post: (body) => _instance.post(`/req-cooperation/`, body)
        },
        train: {
            list: (refresh=false) => _instance.get(`/train/?refresh=${refresh}`),
            stations: (geree) => _instance.get(`/train/stations/${geree ? "?geree=1" : ""}`),
            request: {
                create: (body) => _instance.post(`/train/request/`, body, multiPart),
                pagination: (...params) => _instance.get(`/train/request/?${dTableQuery(...params)}`),
            }
        },
        intro: {
            get: (code, lang) => _instance.get(`/content/intro-page/${code}/${lang}/`),
            getPaths: () => _instance.get("/content/intro-paths/"),
        },
        auctions: {
            notice: (lang, productType, sell_date="") => _instance.get(`/auctions/notices/${lang}/${productType}/?sell_date=${sell_date}`),
            noticeCalendar: (lang) => _instance.get(`/auctions/notices/${lang}/calendar/`),
            supply: (productType, lang="") => _instance.get("/auctions/supply/?" + `productType=${productType}` + `&lang=${lang}`),
        }
    }
}
