import { changeLanguage } from 'utils/language/fn';
import * as yup from 'yup'

yup.setLocale({
    mixed: {
        required: ({ path }) =>
        {
            return  `${changeLanguage("this is a required field.")}`
        },
        // notType: ({ path, type, value }) =>
        // {
        //     return  `${getLabel(path)} талбарын төрөл нь ${type}. Та ${type} хэрэглэнэ үү`
        // },
        // oneOf: ({ path, values }) =>
        // {
        //     return  `${getLabel(path)} талбар нь '${values.toString()} 'уг утгуудаас сонгоно`
        // }
        notType: (custom) =>
        {
            let errorText = "Буруу утга оруулсан байна"
            return changeLanguage(errorText)
        }
    },
    number: {
        positive: ({ path }) =>
        {
            return `${changeLanguage("Заавал эерэг утга байх ёстой.")}`
        },
        min: ({ min, path }) => (`${changeLanguage(`Хамгийн багадаа '???' байна`, undefined, [min])}` ),
        max: ({ max, path }) => (`${changeLanguage(`Хамгийн ихдээ '???' байна`, undefined, [max])}` ),
    },
    // boolean: {
    //     isValue: ({ path }) =>
    //     {
    //         return  `${getLabel(path)} талбар нь бла бала`
    //     }
    // },
    string: {
        min: ({ min, path }) => ( changeLanguage(`Хамгийн багадаа '???' байна`, undefined, [min])),
        max: ({ max, path }) => ( changeLanguage(`Хамгийн ихдээ '???' байна`, undefined, [max]) ),
        length: ({ length, path }) => ( changeLanguage(`Урт нь '???' байх ёстой.`, undefined, [length]) ),
        email: ({ path, value }) =>
        {
            return  changeLanguage(`email must be a valid email`)
        },
    },
});

export const validates = {
	string: yup.string().required().trim(),
    array: yup
            .array()
            .typeError(changeLanguage("Дор хаяж нэг утга сонгоно уу"))
            .required(changeLanguage("Дор хаяж нэг утга сонгоно уу"))
    		.test("len", changeLanguage("Дор хаяж нэг утга сонгоно уу"), value => value?.length >= 0),
	selectMulti: yup
		.array()
        .typeError(changeLanguage("1-ээс илүү утга сонгох ёстой"))
		.required()
		.test("len", changeLanguage("1-ээс илүү утга сонгох ёстой"), value => value?.length >= 1),
	positiveNumber: yup.number().required().positive(),
	select: yup
        .number()
        .typeError(changeLanguage("Сонгоно уу"))
		.required()
		.test("len", changeLanguage("Сонгоно уу"), value => value?.length !== 0),
	file: yup.mixed().test("fileRequired", changeLanguage("Файл оруулна уу"), value => {
		if (value === undefined) return false
		if (value?.length === 0) return false
		return true
	}),
	acceptsZero: yup.number().required().moreThan(-1, changeLanguage("Эерэг утга оруулна уу")),
	code: yup.number().required().moreThan(-1, changeLanguage("Кодоо оруулна уу")),
	percent: yup.number().required().min(0).max(100),
	selectBoolean: yup
		.bool()
		.required()
		.test("len", changeLanguage("Сонгоно уу"), value => typeof value === "boolean"),
    true: yup
		.bool()
		.required()
		.test("true", changeLanguage("Бөглөнө үү"), value => value === true),
	date: yup.date().required(),
	email: yup.string().email().required().max(80),
	register: yup
		.string()
        .length(10)
        .matches(/[a-яА-Я]{2}[0-9]{8}/, `ТБ98020531 хэлбэрээр регистрийн дугаараа оруулна уу`)
		.required(),
    password: yup
                .string()
                .required()
                .min(8, changeLanguage('Password is too short - should be 8 chars minimum.'))
                .matches(/[a-zA-Z]/, changeLanguage('Password can only contain Latin letters.')),
    passwordCheck: yup
                    .string()
                    .oneOf([yup.ref('password'), null], changeLanguage('Passwords must match'))
}
export default yup

export const registerValidation = yup.object().shape({
    first_name: validates.string,
    last_name: validates.string,
    email: validates.email,
    wechat_id: validates.string.notRequired(),
    phone_number: validates.string,
    purpose: validates.string,
    password: validates.password,
    password2: validates.passwordCheck,

    company_name: yup.string().when("is_company", ([isCompany], field) =>
    {
        if (isCompany)
        {
            return field.required()
        }
        return field
    }),
    position: yup.string().when("is_company", ([isCompany], field) =>
    {
        if (isCompany)
        {
            return field.required()
        }
        return field
    }),
    captcha: validates.true,
})

export const forgetValidation = yup.object().shape({
    code: validates.code,
    password: validates.password,
    password2: validates.passwordCheck,
})

export const changeEmailValidation = yup.object().shape({
    code: validates.code,
    email: validates.email
})

export const tumurZamRequestValidation = yup.object().shape({
    achaa_belen_eseh: validates.selectBoolean,
    achaa_turul: validates.select,
    achilt_hiih_hugatsaa_end: validates.date,
    achilt_hiih_hugatsaa_start: validates.date,
    branch_road: validates.string,
    contact_baiguullaga: validates.string,
    contact_description: validates.string,
    contact_email: validates.email,
    contact_name: validates.string,
    contact_phone_number: validates.string,
    contact_tel_code: validates.string,
    currency: validates.select,
    dawtamj: validates.select,
    from_station: validates.select,
    geree_end_time: validates.date,
    invoice_packing_list: validates.selectBoolean,
    is_branch_road: validates.select,
    is_etax: validates.selectBoolean,
    is_garal_uusel: validates.selectBoolean,
    is_tatwar_batal: validates.selectBoolean,
    lab_has: validates.selectBoolean,
    las_end_time: validates.date.notRequired(),
    nuuts: validates.string,
    talbai_phone_number: validates.string,
    talbai_tel_code: validates.string,
    talbai_username: validates.string,
    teewer_nuhtsul: validates.select,
    to_station: validates.select,
    uniin_sanal_end: validates.acceptsZero,
    uniin_sanal_start: validates.acceptsZero,
    wagon_too: validates.acceptsZero.when('achaa_turul', (value, schema) => {
        if (['1', '2', '3', '4'].includes(value)) {
            return yup.number().min(50).max(50).required()
        }

        return yup.number().min(5).max(50).required();
    })
});

export const contactUsValidation = yup.object().shape({
    title: validates.string,
    know: validates.array,
    captcha: validates.true,
})

export const loginValidation = yup.object().shape({
    email: validates.email,
    password: validates.string,
    captcha: validates.true
})

export const emailValidation = yup.object().shape({
    email: validates.email,
    captcha: validates.true,
})
