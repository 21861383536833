import { useForm } from "react-hook-form"
import { Form, NavLink } from "react-bootstrap"
import { useCallback } from "react"
import { useNavigate } from "react-router-dom"

import useLoader from 'hooks/useLoader'
import useApi from "hooks/useApi"

import CButton from "components/atoms/Button"
import InputFormLoader from "components/atoms/Loader/InputForm"
import Text from "components/atoms/Text"

/**
    @param {} props
*/
const MainForm = (props) =>
{

    const { setRegister, setActiveStep, setEmail } = props

    const userApi = useApi().user
    const navigate = useNavigate()

    const { register, formState, handleSubmit } = useForm({ })
    const { isLoading, Loader, fetchData } = useLoader({ isSmall: true })

    const onSubmit = useCallback(
        async (formData) =>
        {
            formData['username'] = formData.email
            const { success, data } = await fetchData(userApi.register(formData)).catch(err => err)
            if (success)
            {
                setEmail(formData.email)
                setActiveStep(1)
            }
        },
        []
    )

    return (
        <div className="p-4">
            <Form onSubmit={handleSubmit(onSubmit)}>
                {
                    isLoading
                    ? (
                        <InputFormLoader />
                    )
                    : (
                        <>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    <Text>Email address</Text>
                                </Form.Label>
                                <Form.Control {...register("email")} type="email" placeholder="example@example.com" />
                            </Form.Group>
                            <div className="d-flex justify-content-center">
                                <CButton variant="primary" type="submit">
                                    Next
                                </CButton>
                            </div>
                        </>
                    )
                }
                <hr className="my-3" />
                <div className="text-center">
                    <Text>Already member</Text>? <NavLink style={{ display: "unset" }} onClick={() => setRegister ? setRegister(false) : navigate("/login")}><Text>Sign in</Text></NavLink>
                </div>
            </Form>
        </div>
    )
}
export default MainForm
