import { forwardRef } from 'react'
import { Dropdown } from 'react-bootstrap';

import Text from '../Text';

const CustomMenu = forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {

        return (
            <ul
                ref={ref}
                style={{
                    ...style ?? {},
                    top: "48px",
                }}
                className={`custom ${className} myshadow`}
                aria-labelledby={labeledBy}
            >
                <li className='dropdown-title-category'>
                    <Text>By category</Text>
                </li>
                <Dropdown.Divider />
                {children}
            </ul>
        );
    },
);

export default CustomMenu
