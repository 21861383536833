import Footer from "components/molecules/Footer"
import Headers from "components/molecules/Headers"
import SecondaryHeader from "components/molecules/SecondaryHeader"
import NoticeNew from "components/organisms/auctions/NoticeNew"

/**
    @param {} props
*/
const AuctionNotices = (props) =>
{
    return (
        <div>
            <Headers />
            <SecondaryHeader
                title={"Pages"}
                links={[
                    {
                        name: "Үндсэн",
                        to: "/exchange"
                    },
                    {
                        name: "Notices",
                        to: "/exchange/notices/"
                    },
                    {
                        name: "Supply",
                        to: "/exchange/supply/"
                    },
                    {
                        name: "Заавар",
                        to: "/exchange/laws"
                    }
                ]}
            />
            <div className="container mt-5 mb-5">
                <NoticeNew ispage={true} />
            </div>
            <Footer />
        </div>
    )
}
export default AuctionNotices
