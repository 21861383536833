import useSWR from 'swr'
import CButton from "components/atoms/Button"
import { useIntroContext } from "contexts/IntroContext"
import { useCallback, useState } from "react"
import { Modal } from "react-bootstrap"
import useApi from 'hooks/useApi'

import useLanguage from 'hooks/useLanguage'
import CMPageLoader from 'components/atoms/Loader/CMPage'
import { TfiClose } from 'react-icons/tfi'

const fetcher = async (api, code, _lang) =>
{
    const rsp = await api.get(code, _lang)
    if (rsp.success)
    {
        const str = rsp.data.acontent
        const doc = new DOMParser().parseFromString(str, "text/html")
        const imgs = doc.getElementsByTagName("img")
        for (const idx in imgs) {
            const img = imgs[idx]
            if (img.src)
            {
                const source = img.src.replace(window.location.origin, process.env.REACT_APP_SERVER_URL)
                img.src = source
            }
        }
        rsp.data.acontent = doc.body.innerHTML
    }
    return rsp
}

/**
    @param {} props
*/
const Intro = (props) =>
{

    const { lang } = useLanguage()
    const { introCode, setInroCode, option, setOption } = useIntroContext()
    const isDisplay = Boolean(introCode)
    const api = useApi().intro

    const { data, isLoading, isValidating } = useSWR(
        ["intro-page", introCode, lang],
        ([key, code, _lang]) => {
            if (code && _lang)
            {
                return fetcher(api, code, _lang)
            }
        },
        {
            fallbackData: {
                data: {}
            },
            revalidateOnFocus: false,
            keepPreviousData: false,
        }
    )

    const odata = data.data

    const handleClose = useCallback(
        (key) =>
        {
            setInroCode("")
            setOption({})
            if (key === "continue" && option.continueFn)
            {
                option.continueFn()
            }
        },
        [option]
    )

    return (
        <Modal
            show={isDisplay}
            onHide={handleClose}
            keyboard={false}
            animation={true}
            scrollable={true}
            fullscreen={"md-down"}
            centered
            size='xl'
        >
            <CButton
                onClick={handleClose}
                className='bg-transparent custom-close'
                style={{
                    border: "unset",
                    position: "absolute",
                    top: 0,
                    right: 0,
                    color: "black",
                    zIndex: 1,
                }}
            >
                <TfiClose size={20} />
            </CButton>
            <Modal.Header className='myshadow'>
                <h4 className="mb-0">{odata.atitle}</h4>
            </Modal.Header>
            <Modal.Body className='p-0 pt-3'>
                {
                    isValidating
                    ? (
                        <div className='container'>
                            <CMPageLoader />
                        </div>
                    )
                    : (
                        <>
                            <div dangerouslySetInnerHTML={{ __html: odata.acontent }}>
                            </div>
                        </>
                    )
                }
            </Modal.Body>
            <Modal.Footer>
                <CButton onClick={() => handleClose("continue")}>
                    Continue
                </CButton>
            </Modal.Footer>
        </Modal>
    )
}
export default Intro
