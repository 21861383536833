import CButton from 'components/atoms/Button';
import { useCallback } from 'react';
import { forwardRef, useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { TfiClose } from 'react-icons/tfi'
import { addPropsToComponent } from 'utils';

const OnlyBodyModal = forwardRef(
    (props, ref) => {
        const [show, setDisplay] = useState(false);
        const [opt, setOpt] = useState({})

        const setShow = useCallback(
            (value, props={}) =>
            {
                setDisplay(value)
                setOpt(props)
            },
            []
        )

        useEffect(
            () =>
            {
                if (ref && ref.current)
                {
                    ref.current.setShow = setShow
                }
                else if (!ref.current)
                {
                    ref.current = {
                        setShow: setShow
                    }
                }
            },
            [ref]
        )

        const handleClose = () => {
            setShow(false)
            props.onClose && props.onClose()
        };

        return (
            <Modal
                show={show}
                onHide={handleClose}
                keyboard={false}
                size='lg'
                centered
                {...props}
                backdrop={props.backdrop ?? "static"}
            >
                <CButton
                    onClick={handleClose}
                    className='bg-transparent custom-close'
                    style={{
                        border: "unset",
                        position: "absolute",
                        top: 0,
                        right: 0,
                        color: "black",
                        zIndex: 1,
                    }}
                >
                    <TfiClose size={20} />
                </CButton>
                {
                    props.header || opt.header
                    ? (
                        <Modal.Header>
                            {props.header || opt.header}
                        </Modal.Header>
                    )
                    : null
                }
                <Modal.Body>
                    {addPropsToComponent(props.body, {
                        setShow: setShow,
                        ...opt
                    })}
                </Modal.Body>
            </Modal>
        );
    }
)

export default OnlyBodyModal;
