import { useForm } from "react-hook-form"
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { Form, InputGroup } from "react-bootstrap"
import { useCallback, useState } from "react"
import { NavLink, useNavigate } from "react-router-dom"

import useApi from "hooks/useApi"
import useLoader from 'hooks/useLoader'

import CButton from "components/atoms/Button"
import InputFormLoader from "components/atoms/Loader/InputForm"
import Text from "components/atoms/Text"

const INITIAL_DATA = {

}

/**
    @param {} props
*/
const Finish = (props) =>
{
    const { token } = props

    const userApi = useApi().user
    const navigate = useNavigate()

    const [ showPassword, setShowPassword ] = useState(false)
    const [ showPassword2, setShowPassword2 ] = useState(false)
    const { register, formState, handleSubmit } = useForm({ defaultValues: INITIAL_DATA, })
    const { isLoading, Loader, fetchData } = useLoader({ isSmall: true })

    const onSubmit = useCallback(
        async (formData) =>
        {
            const { success, data } = await fetchData(userApi.setPassword(token, formData)).catch(err => err)
            if (success)
            {
                navigate("/login/")
            }
        },
        []
    )

    return (
        <div className="p-4">
            <Form onSubmit={handleSubmit(onSubmit)}>

                {
                    isLoading
                    ? (
                        <InputFormLoader inputCount={2} />
                    )
                    : (
                        <>
                            <Form.Group>
                                <Form.Label>
                                    <Text>Password</Text>
                                </Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control {...register("password")} type={!showPassword ? "password" : "text"} placeholder={showPassword ? "password" : "*******"} />
                                    <InputGroup.Text role="button" onClick={() => setShowPassword(prev => !prev)}> {!showPassword ? <FaEye></FaEye> : <FaEyeSlash></FaEyeSlash>} </InputGroup.Text>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    <Text>Check password</Text>
                                </Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control {...register("password2")} type={!showPassword2 ? "password" : "text"} placeholder={showPassword2 ? "password" : "*******"} />
                                    <InputGroup.Text role="button" onClick={() => setShowPassword2(prev => !prev)}> {!showPassword2 ? <FaEye></FaEye> : <FaEyeSlash></FaEyeSlash>} </InputGroup.Text>
                                </InputGroup>
                            </Form.Group>
                            <div className="d-flex justify-content-center">
                                <CButton variant="primary" type="submit">
                                    Sign up
                                </CButton>
                            </div>
                        </>
                    )
                }
            </Form>
        </div>
    )
}
export default Finish
