import { NavLink } from 'react-router-dom'
import css from './style.module.css'
import { TiSocialFacebook, TiSocialInstagram, TiSocialTwitter, TiSocialYoutube, TiMail } from 'react-icons/ti'
import { HEADER_NAV_ANALYSIS, HEADER_NAV_TRADING, socialLinks } from 'utils/const'
import Text from 'components/atoms/Text'

/**
    @param {} props
*/
const Footer = (props) =>
{

    const socials = {
        fb: TiSocialFacebook,
        // instagram: TiSocialInstagram,
        // twitter: TiSocialTwitter,
        // yt: TiSocialYoutube,
        mail: TiMail,
    }

    return (
        <footer id='site-footer' className={`${css['footer-section']}`}>
            <div className="container">
                <div className={`${css['footer-content']} pt-5 pb-5`}>
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 mb-30">
                            <div className={`${css['footer-widget']}`}>
                                <div className={`${css["footer-widget-heading"]}`}>
                                    <h3><Text>Useful Links</Text></h3>
                                </div>
                                <ul className={css['new-footer-ul']}>
                                    <li><NavLink to={"/"}><Text>Home</Text></NavLink></li>
                                    <li><NavLink to={"/p/about"}><Text>About</Text></NavLink></li>
                                    <li><NavLink to={"/p/services"}><Text>Services</Text></NavLink></li>
                                    <li><NavLink to={"/p/contact"}><Text>Contact</Text></NavLink></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 mb-30 cfooter mt-5 mt-md-0">
                            <div className={`${css['footer-widget']}`}>
                                <div className={`${css["footer-widget-heading"]}`}>
                                    <h3><Text>Data and Analysis</Text></h3>
                                </div>
                                <ul className={css['new-footer-ul']}>
                                    {
                                        HEADER_NAV_ANALYSIS.map(
                                            (nav, idx) =>
                                            {
                                                return (
                                                    <li key={idx}><NavLink to={nav.link}><Text>{nav.name}</Text></NavLink></li>
                                                )
                                            }
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                        {/* <div className="col-xl-4 col-lg-4 col-md-6 mb-30 cfooter">
                            <div className={`${css['footer-widget']}`}>
                                <div className={`${css["footer-widget-heading"]}`}>
                                    <h3><Text>Partner and Consultancy</Text></h3>
                                </div>
                                <ul>
                                    {
                                        HEADER_NAV_TRADING.map(
                                            (nav, idx) =>
                                            {
                                                return (
                                                    <li key={idx}><NavLink to={nav.link}><Text>{nav.name}</Text></NavLink></li>
                                                )
                                            }
                                        )
                                    }
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className={`${css['footer-cta']} pt-5 pb-5`}>
                    <div className="row">
                        <div className="col-xl-3 col-md-3 mb-3 mb-md-0 d-flex justify-content-center">
                            <div className={`${css["single-cta"]}`}>
                                <i className="fas fa-map-marker-alt text-primary"></i>
                                <div className={`${css['cta-text']}`}>
                                    <h4><Text>Find us</Text></h4>
                                    <span><Text>{socialLinks.address}</Text></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-3 mb-3 mb-md d-flex justify-content-start justify-content-md-center">
                            <div className={`${css["single-cta"]}`}>
                                <i className="fas fa-phone text-primary"></i>
                                <div className={`${css['cta-text']}`}>
                                    <h4><Text>Call us</Text></h4>
                                    <span><Text>{socialLinks.phoneNumber}</Text></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-3 mb-3 mb-md d-flex justify-content-start justify-content-md-center">
                            <div className={`${css["single-cta"]}`}>
                                <i className="far fa-envelope-open text-primary"></i>
                                <div className={`${css['cta-text']}`}>
                                    <h4><Text>Mail us</Text></h4>
                                    <span><Text>{socialLinks.mail}</Text></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-3 mb-3 mb-md d-flex justify-content-start justify-content-md-center">
                            <div className={`${css["single-cta"]}`}>
                                <i className="fa-brands fa-weixin text-primary"></i>
                                <div className={`${css['cta-text']}`}>
                                    <h4><Text>WeChat</Text></h4>
                                    <span><img src="/img/wechat.png" width={80} alt="" /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${css["copyright-area"]}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-6 text-start text-lg-start">
                            <div className={`${css["copyright-text"]}`}>
                                <img src={"/comex.png"} height={"30"} />
                            </div>
                        </div>
                        <div className="col-6 text-end">
                            <div className={`${css["footer-menu"]}`}>
                                <ul>
                                    <li><Text>Find us on</Text></li>
                                    {
                                        Object.keys(socials).map(
                                            (key, idx) =>
                                            {
                                                const Icon = socials[key]
                                                return (
                                                    <li key={idx} className='bg-light' style={{ borderRadius: 3, height: 25, width: 25, textAlign: "center" }}>
                                                        <a href={`${key === "mail" ? `mailto:` : ""}${socialLinks[key]}`} target={key === "mail" ? "_self" : "_blank"}>
                                                            <Icon size={20} color={"#000"} />
                                                        </a>
                                                    </li>
                                                )
                                            }
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
export default Footer
