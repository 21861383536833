import AsyncStoryTable from "components/atoms/AsyncTable"
import Text from "components/atoms/Text";
import useApi from "hooks/useApi";
import { useCallback } from "react";
import { FaExclamationTriangle, FaStar } from "react-icons/fa";
import { changeLanguage } from "utils/language/fn";

/**
    @param {} props
*/
const TrainRequestList = (props) =>
{

    /**
     * @type {import("react-bs-datatable").TableColumnType}
     */
    const POKEMON_HEADERS = [
        {
            prop: 'achaa_turul_name',
            title: changeLanguage('Name'),
            isSortable: true,
            cell: (row) =>
            {
                return (
                    <span>
                        {row.is_me ? <FaStar className="text-warning" /> : null} {row.achaa_turul_name}
                    </span>
                )
            }
        },
        {
            prop: 'wagon_too',
            title: changeLanguage('Вагон тоо'),
            isSortable: true,
        },
        {
            prop: "request_type_name",
            title: changeLanguage("Status"),
            isSortable: true,
        },
        {
            prop: "created_at",
            title: changeLanguage("Created at"),
            isSortable: true,
        }
    ];

    const api = useApi()

    const fetchPokemonData = useCallback(
        async ({rowsPerPage, currentPage}) => {
            const response = await api.train.request.pagination(currentPage, rowsPerPage);
            const data = response.data

            return {
                data: data.results ?? [],
                maxPage: Math.ceil(data.count / rowsPerPage),
                filteredDataLength: data.count
            };
        }
    )

    return (
        <div>
            <span>
                <FaExclamationTriangle className="text-warning" size={18} /> <Text vars={[<FaStar className="text-warning" />]}>Хэрэв өгөгдлийн өмнө "???" энэ тэмдэг байвал энэ нь таны үүсгэсэн хүсэлт гэсэн үг</Text>
            </span>
            <AsyncStoryTable fetchFn={fetchPokemonData} headers={POKEMON_HEADERS} />
        </div>
    )
}
export default TrainRequestList
