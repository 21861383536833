import { Controller, useForm } from "react-hook-form"
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { Form, InputGroup } from "react-bootstrap"
import { useCallback, useState } from "react"
import { NavLink, useNavigate } from "react-router-dom"

import useApi from "hooks/useApi"
import useLoader from 'hooks/useLoader'

import CButton from "components/atoms/Button"
import Register from "../Register"
import useAuth from "hooks/useAuth"
import InputFormLoader from "components/atoms/Loader/InputForm"
import Text from "components/atoms/Text"
import HCaptchaComp from "components/atoms/HCaptchaComp"
import { yupResolver } from "@hookform/resolvers/yup"
import { loginValidation } from "utils/validation"

const INITIAL_DATA = {

}

/**
    @param {} props
*/
const Login = (props) =>
{

    const { loggedUser } = useAuth()
    const { setShow, cburl } = props

    const userApi = useApi().user
    const navigate = useNavigate()

    const [ isRegister, setRegister ] = useState(false)
    const [ showPassword, setShowPassword ] = useState(false)
    const { register, formState: { errors }, handleSubmit, control } = useForm({ defaultValues: INITIAL_DATA, resolver: yupResolver(loginValidation) })
    const { isLoading, Loader, fetchData } = useLoader({ isSmall: true })

    const onSubmit = useCallback(
        async (formData) =>
        {
            const { success, data } = await fetchData(userApi.login(formData)).catch(err => err)
            if (success)
            {
                loggedUser(data)
                // herwee train ruu orohoos login hiihgej bgaa bol nuhtsul shalgaj
                if (cburl === "train") {
                    navigate(data.has_train ? "/train" : "/train/request")
                }
                else {
                    navigate("/")
                }
                if (setShow)
                {
                    setShow(false)
                }
            }
        },
        [cburl]
    )

    if (isRegister)
    {
        return <Register setRegister={setRegister} />
    }

    return (
        <div className="p-4">
            <div className="text-center pb-2">
                <NavLink to={"/"}>
                    <img src={`${process.env.PUBLIC_URL}/comex.png`} alt="logo" width={"120"} />
                </NavLink>
            </div>
            <div className="text-center">
                <Text>Sign in to</Text>
            </div>

            <Form onSubmit={handleSubmit(onSubmit)}>
                {
                    isLoading
                    ? (
                        <InputFormLoader inputCount={2}/>
                    )
                    : (
                        <>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    <Text>Email address</Text>
                                </Form.Label>
                                <Form.Control {...register("email")} type="email" placeholder="example@example.com" isInvalid={Boolean(errors.email)} />
								{errors.email?.message ? <div className="invalid-feedback">{errors.email.message}</div> : null}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    <Text>Password</Text>
                                </Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control {...register("password")} type={!showPassword ? "password" : "text"} isInvalid={Boolean(errors.password)} placeholder={showPassword ? "password" : "*******"} />
                                    <InputGroup.Text role="button" onClick={() => setShowPassword(prev => !prev)}> {!showPassword ? <FaEye></FaEye> : <FaEyeSlash></FaEyeSlash>} </InputGroup.Text>
								    {errors.password?.message ? <div className="invalid-feedback">{errors.password.message}</div> : null}
                                </InputGroup>
                            </Form.Group>
                            <Form.Group className="mb-3 text-end">
                                <NavLink to={"/forget-password"}>
                                    <Text>Forget password</Text>?
                                </NavLink>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <HCaptchaComp control={control} />
                            </Form.Group>
                            <div className="d-flex justify-content-center">
                                <CButton variant="primary" type="submit">
                                    Sign in
                                </CButton>
                            </div>
                        </>
                    )
                }
                <hr className="my-3" />
                <div className="text-center">
                    <Text>Not member yet</Text>? <NavLink to={"/register"}><Text>Sign Up</Text></NavLink>
                </div>
            </Form>
        </div>
    )
}
export default Login
