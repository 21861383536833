import Text from "components/atoms/Text"
import Title from "components/atoms/Title"
import useLanguage from "hooks/useLanguage"
import { Table } from "react-bootstrap"

/**
    @param {} props
*/
const ExhangeLaws = (props) =>
{

    const { lang } = useLanguage()

    return (
        <div>
            <Title text={"Заавар"}></Title>
            <div className="text-center row">
                <div className="mb-3 col-md-4">
                    <h5><Text>1. Экспортод гаргах нүүрсний нээлттэй арилжааны видео танилцуулга</Text></h5>
                    <div>
                        <video className="hover-size" width="100%" controls role="button">
                            <source src={process.env.REACT_APP_SERVER_URL + "/files/1.mp4"} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
                <div className="mb-3 col-md-4">
                    <h5><Text>2. Экспортод гаргах нүүрсний нээлттэй арилжаанд бүртгүүлэх видео заавар</Text></h5>
                    <div>
                        <video className="hover-size" width="100%" controls role="button">
                            <source src={process.env.REACT_APP_SERVER_URL + "/files/2.mp4"} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
                <div className="mb-3 col-md-4">
                    <h5><Text>3. Экспортод гаргах нүүрсний арилжаанд оролцох видео заавар</Text></h5>
                    <div>
                        <video className="hover-size" width="100%" controls role="button">
                            <source src={process.env.REACT_APP_SERVER_URL + "/files/3.mp4"} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </div>
            <div className="mt-5">
                <Title text={"Журам, Гэрээ"}></Title>
            </div>
            <Table bordered responsive striped>
                <thead>
                    <tr>
                        <th>№</th>
                        <th><Text>Title</Text></th>
                        <th className="text-center"><Text>Date approved</Text></th>
                        <th className="text-center"><Text>Download file</Text></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        DATA.map(
                            (item, idx) =>
                            {
                                return (
                                    <tr key={idx}>
                                        <td>{idx + 1}.</td>
                                        <td>{item.title[lang]}</td>
                                        <td className="text-center">{item.date}</td>
                                        <td className="text-center"><a href={item.link[lang]} download><Text>Download</Text></a></td>
                                    </tr>
                                )
                            }
                        )
                    }
                </tbody>
            </Table>
        </div>
    )
}
export default ExhangeLaws

const DATA = [
    {
        title: {
            mn: "Уул уурхайн бүтээгдэхүүний арилжаа, төлбөр тооцоог гүйцэтгэхэд баримтлах журам",
            en: "Уул уурхайн бүтээгдэхүүний арилжаа, төлбөр тооцоог гүйцэтгэхэд баримтлах журам",
            cn: "执行矿产品交易、费用结算时应遵守制度",
        },
        date: "2022-12-14",
        link: {
            mn: "https://mse.mn/uploads/laws/%D0%A3%D1%83%D0%BB%20%D1%83%D1%83%D1%80%D1%85%D0%B0%D0%B9%D0%BD%20%D0%B1%D2%AF%D1%82%D1%8D%D1%8D%D0%B3%D0%B4%D1%8D%D1%85%D2%AF%D2%AF%D0%BD%D0%B8%D0%B9%20%D0%B0%D1%80%D0%B8%D0%BB%D0%B6%D0%B0%D0%B0%2C%20%D1%82%D3%A9%D0%BB%D0%B1%D3%A9%D1%80%20%D1%82%D0%BE%D0%BE%D1%86%D0%BE%D0%BE%D0%B3%20%D0%B3%D2%AF%D0%B9%D1%86%D1%8D%D1%82%D0%B3%D1%8D%D1%85%D1%8D%D0%B4%20%D0%B1%D0%B0%D1%80%D0%B8%D0%BC%D1%82%D0%BB%D0%B0%D1%85%20%D0%B6%D1%83%D1%80%D0%B0%D0%BC_0001.pdf",
            en: "https://mse.mn/uploads/laws/%D0%A3%D1%83%D0%BB%20%D1%83%D1%83%D1%80%D1%85%D0%B0%D0%B9%D0%BD%20%D0%B1%D2%AF%D1%82%D1%8D%D1%8D%D0%B3%D0%B4%D1%8D%D1%85%D2%AF%D2%AF%D0%BD%D0%B8%D0%B9%20%D0%B0%D1%80%D0%B8%D0%BB%D0%B6%D0%B0%D0%B0%2C%20%D1%82%D3%A9%D0%BB%D0%B1%D3%A9%D1%80%20%D1%82%D0%BE%D0%BE%D1%86%D0%BE%D0%BE%D0%B3%20%D0%B3%D2%AF%D0%B9%D1%86%D1%8D%D1%82%D0%B3%D1%8D%D1%85%D1%8D%D0%B4%20%D0%B1%D0%B0%D1%80%D0%B8%D0%BC%D1%82%D0%BB%D0%B0%D1%85%20%D0%B6%D1%83%D1%80%D0%B0%D0%BC_0001.pdf",
            cn: "https://mse.mn/uploads/Trading%20and%20settlement%20rules%20_Chinese.pdf",
        }
    },
    {
        title: {
            mn: "Биржийн зах зээлийн зарим оролцогчийг итгэмжлэх, тэдгээрийн үйл ажиллагааг зохицуулах журам",
            en: "Accreditation and Regulatory Procedures for Certain Exchange Market Participants",
            cn: "交易所市场有关参与方的授权、其业务管理制度",
        },
        date: "2023-06-30",
        link: {
            mn: "https://mse.mn/uploads/laws/%D0%91%D0%B8%D1%80%D0%B6%D0%B8%D0%B9%D0%BD%20%D0%B7%D0%B0%D1%85%20%D0%B7%D1%8D%D1%8D%D0%BB%D0%B8%D0%B9%D0%BD%20%D0%B7%D0%B0%D1%80%D0%B8%D0%BC%20%D0%BE%D1%80%D0%BE%D0%BB%D1%86%D0%BE%D0%B3%D1%87%D0%B8%D0%B9%D0%B3%20%D0%B8%D1%82%D0%B3%D1%8D%D0%BC%D0%B6%D0%BB%D1%8D%D1%85%2C%20%D1%82%D1%8D%D0%B4%D0%B3%D1%8D%D1%8D%D1%80%D0%B8%D0%B9%D0%BD%20%D2%AF%D0%B9%D0%BB%20%D0%B0%D0%B6%D0%B8%D0%BB%D0%BB%D0%B0%D0%B3%D0%B0%D0%B0%D0%B3%20%D0%B7%D0%BE%D1%85%D0%B8%D1%86%D1%83%D1%83%D0%BB%D0%B0%D1%85%20%D0%B6%D1%83%D1%80%D0%B0%D0%BC_0001.pdf",
            en: "https://mse.mn/uploads/laws/%D0%91%D0%B8%D1%80%D0%B6%D0%B8%D0%B9%D0%BD%20%D0%B7%D0%B0%D1%85%20%D0%B7%D1%8D%D1%8D%D0%BB%D0%B8%D0%B9%D0%BD%20%D0%B7%D0%B0%D1%80%D0%B8%D0%BC%20%D0%BE%D1%80%D0%BE%D0%BB%D1%86%D0%BE%D0%B3%D1%87%D0%B8%D0%B9%D0%B3%20%D0%B8%D1%82%D0%B3%D1%8D%D0%BC%D0%B6%D0%BB%D1%8D%D1%85%2C%20%D1%82%D1%8D%D0%B4%D0%B3%D1%8D%D1%8D%D1%80%D0%B8%D0%B9%D0%BD%20%D2%AF%D0%B9%D0%BB%20%D0%B0%D0%B6%D0%B8%D0%BB%D0%BB%D0%B0%D0%B3%D0%B0%D0%B0%D0%B3%20%D0%B7%D0%BE%D1%85%D0%B8%D1%86%D1%83%D1%83%D0%BB%D0%B0%D1%85%20%D0%B6%D1%83%D1%80%D0%B0%D0%BC_0001.pdf",
            cn: "https://mse.mn/uploads/laws/%D0%91%D0%B8%D1%80%D0%B6%D0%B8%D0%B9%D0%BD%20%D0%B7%D0%B0%D1%85%20%D0%B7%D1%8D%D1%8D%D0%BB%D0%B8%D0%B9%D0%BD%20%D0%B7%D0%B0%D1%80%D0%B8%D0%BC%20%D0%BE%D1%80%D0%BE%D0%BB%D1%86%D0%BE%D0%B3%D1%87%D0%B8%D0%B9%D0%B3%20%D0%B8%D1%82%D0%B3%D1%8D%D0%BC%D0%B6%D0%BB%D1%8D%D1%85%2C%20%D1%82%D1%8D%D0%B4%D0%B3%D1%8D%D1%8D%D1%80%D0%B8%D0%B9%D0%BD%20%D2%AF%D0%B9%D0%BB%20%D0%B0%D0%B6%D0%B8%D0%BB%D0%BB%D0%B0%D0%B3%D0%B0%D0%B0%D0%B3%20%D0%B7%D0%BE%D1%85%D0%B8%D1%86%D1%83%D1%83%D0%BB%D0%B0%D1%85%20%D0%B6%D1%83%D1%80%D0%B0%D0%BC_0001.pdf",
        }
    },
    {
        title: {
            mn: "Уул уурхайн бүтээгдэхүүн арилжаалах брокерын гишүүнчлэлийн журам",
            en: "Membership Procedures for Brokers in Commodity Trading",
            cn: "交易矿业产品经纪商的会员制度",
        },
        date: "2023-06-30",
        link: {
            mn: "https://mse.mn/uploads/laws/%D0%A3%D1%83%D0%BB%20%D1%83%D1%83%D1%80%D1%85%D0%B0%D0%B9%D0%BD%20%D0%B1%D2%AF%D1%82%D1%8D%D1%8D%D0%B3%D0%B4%D1%8D%D1%85%D2%AF%D2%AF%D0%BD%20%D0%B0%D1%80%D0%B8%D0%BB%D0%B6%D0%B0%D0%B0%D0%BB%D0%B0%D1%85%20%D0%B1%D1%80%D0%BE%D0%BA%D0%B5%D1%80%D0%B8%D0%B9%D0%BD%20%D0%B3%D0%B8%D1%88%D2%AF%D2%AF%D0%BD%D1%87%D0%BB%D1%8D%D0%BB%D0%B8%D0%B9%D0%BD%20%D0%B6%D1%83%D1%80%D0%B0%D0%BC_0001.pdf",
            cn: "https://mse.mn/uploads/laws/%D0%A3%D1%83%D0%BB%20%D1%83%D1%83%D1%80%D1%85%D0%B0%D0%B9%D0%BD%20%D0%B1%D2%AF%D1%82%D1%8D%D1%8D%D0%B3%D0%B4%D1%8D%D1%85%D2%AF%D2%AF%D0%BD%20%D0%B0%D1%80%D0%B8%D0%BB%D0%B6%D0%B0%D0%B0%D0%BB%D0%B0%D1%85%20%D0%B1%D1%80%D0%BE%D0%BA%D0%B5%D1%80%D0%B8%D0%B9%D0%BD%20%D0%B3%D0%B8%D1%88%D2%AF%D2%AF%D0%BD%D1%87%D0%BB%D1%8D%D0%BB%D0%B8%D0%B9%D0%BD%20%D0%B6%D1%83%D1%80%D0%B0%D0%BC_0001.pdf",
            en: "https://mse.mn/uploads/laws/%D0%A3%D1%83%D0%BB%20%D1%83%D1%83%D1%80%D1%85%D0%B0%D0%B9%D0%BD%20%D0%B1%D2%AF%D1%82%D1%8D%D1%8D%D0%B3%D0%B4%D1%8D%D1%85%D2%AF%D2%AF%D0%BD%20%D0%B0%D1%80%D0%B8%D0%BB%D0%B6%D0%B0%D0%B0%D0%BB%D0%B0%D1%85%20%D0%B1%D1%80%D0%BE%D0%BA%D0%B5%D1%80%D0%B8%D0%B9%D0%BD%20%D0%B3%D0%B8%D1%88%D2%AF%D2%AF%D0%BD%D1%87%D0%BB%D1%8D%D0%BB%D0%B8%D0%B9%D0%BD%20%D0%B6%D1%83%D1%80%D0%B0%D0%BC_0001.pdf",
        }
    },
    {
        title: {
            mn: "Нүүрсний арилжааны гэрээний стандартыг тогтоох, мөрдүүлэх журам",
            en: "Procedures for Establishing and Enforcing Standards for Coal Trade Contracts",
            cn: "煤炭交易合同标准制定和执行制度",
        },
        date: "2023-06-30",
        link: {
            mn: "https://mse.mn/uploads/laws/%D0%9D%D2%AF%D2%AF%D1%80%D1%81%D0%BD%D0%B8%D0%B9%20%D0%B0%D1%80%D0%B8%D0%BB%D0%B6%D0%B0%D0%B0%D0%BD%D1%8B%20%D0%B3%D1%8D%D1%80%D1%8D%D1%8D%D0%BD%D0%B8%D0%B9%20%D1%81%D1%82%D0%B0%D0%BD%D0%B4%D0%B0%D1%80%D1%82%D1%8B%D0%B3%20%D1%82%D0%BE%D0%B3%D1%82%D0%BE%D0%BE%D1%85%2C%20%D0%BC%D3%A9%D1%80%D0%B4%D2%AF%D2%AF%D0%BB%D1%8D%D1%85%20%D0%B6%D1%83%D1%80%D0%B0%D0%BC_0001.pdf",
            cn: "https://mse.mn/uploads/laws/%D0%9D%D2%AF%D2%AF%D1%80%D1%81%D0%BD%D0%B8%D0%B9%20%D0%B0%D1%80%D0%B8%D0%BB%D0%B6%D0%B0%D0%B0%D0%BD%D1%8B%20%D0%B3%D1%8D%D1%80%D1%8D%D1%8D%D0%BD%D0%B8%D0%B9%20%D1%81%D1%82%D0%B0%D0%BD%D0%B4%D0%B0%D1%80%D1%82%D1%8B%D0%B3%20%D1%82%D0%BE%D0%B3%D1%82%D0%BE%D0%BE%D1%85%2C%20%D0%BC%D3%A9%D1%80%D0%B4%D2%AF%D2%AF%D0%BB%D1%8D%D1%85%20%D0%B6%D1%83%D1%80%D0%B0%D0%BC_0001.pdf",
            en: "https://mse.mn/uploads/laws/%D0%9D%D2%AF%D2%AF%D1%80%D1%81%D0%BD%D0%B8%D0%B9%20%D0%B0%D1%80%D0%B8%D0%BB%D0%B6%D0%B0%D0%B0%D0%BD%D1%8B%20%D0%B3%D1%8D%D1%80%D1%8D%D1%8D%D0%BD%D0%B8%D0%B9%20%D1%81%D1%82%D0%B0%D0%BD%D0%B4%D0%B0%D1%80%D1%82%D1%8B%D0%B3%20%D1%82%D0%BE%D0%B3%D1%82%D0%BE%D0%BE%D1%85%2C%20%D0%BC%D3%A9%D1%80%D0%B4%D2%AF%D2%AF%D0%BB%D1%8D%D1%85%20%D0%B6%D1%83%D1%80%D0%B0%D0%BC_0001.pdf",
        }
    },
    {
        title: {
            mn: "Уул уурхайн бүтээгдэхүүний арилжааны хяналт шалгалтын журам",
            en: "Trade Inspection Procedures for Commodities",
            cn: "矿产品交易监督检查制度",
        },
        date: "2023-06-30",
        link: {
            mn: "https://mse.mn/uploads/laws/%D0%A3%D1%83%D0%BB%20%D1%83%D1%83%D1%80%D1%85%D0%B0%D0%B9%D0%BD%20%D0%B1%D2%AF%D1%82%D1%8D%D1%8D%D0%B3%D0%B4%D1%8D%D1%85%D2%AF%D2%AF%D0%BD%D0%B8%D0%B9%20%D0%B0%D1%80%D0%B8%D0%BB%D0%B6%D0%B0%D0%B0%D0%BD%D1%8B%20%D1%85%D1%8F%D0%BD%D0%B0%D0%BB%D1%82%20%D1%88%D0%B0%D0%BB%D0%B3%D0%B0%D0%BB%D1%82%D1%8B%D0%BD%20%D0%B6%D1%83%D1%80%D0%B0%D0%BC_0001.pdf",
            cn: "https://mse.mn/uploads/laws/%D0%A3%D1%83%D0%BB%20%D1%83%D1%83%D1%80%D1%85%D0%B0%D0%B9%D0%BD%20%D0%B1%D2%AF%D1%82%D1%8D%D1%8D%D0%B3%D0%B4%D1%8D%D1%85%D2%AF%D2%AF%D0%BD%D0%B8%D0%B9%20%D0%B0%D1%80%D0%B8%D0%BB%D0%B6%D0%B0%D0%B0%D0%BD%D1%8B%20%D1%85%D1%8F%D0%BD%D0%B0%D0%BB%D1%82%20%D1%88%D0%B0%D0%BB%D0%B3%D0%B0%D0%BB%D1%82%D1%8B%D0%BD%20%D0%B6%D1%83%D1%80%D0%B0%D0%BC_0001.pdf",
            en: "https://mse.mn/uploads/laws/%D0%A3%D1%83%D0%BB%20%D1%83%D1%83%D1%80%D1%85%D0%B0%D0%B9%D0%BD%20%D0%B1%D2%AF%D1%82%D1%8D%D1%8D%D0%B3%D0%B4%D1%8D%D1%85%D2%AF%D2%AF%D0%BD%D0%B8%D0%B9%20%D0%B0%D1%80%D0%B8%D0%BB%D0%B6%D0%B0%D0%B0%D0%BD%D1%8B%20%D1%85%D1%8F%D0%BD%D0%B0%D0%BB%D1%82%20%D1%88%D0%B0%D0%BB%D0%B3%D0%B0%D0%BB%D1%82%D1%8B%D0%BD%20%D0%B6%D1%83%D1%80%D0%B0%D0%BC_0001.pdf",
        }
    },
    {
        title: {
            mn: "Худалдан авагчидтай байгуулах гэрээ",
            en: "Buyer Agreements and Contract Terms",
            cn: "交易所与买家签署的合同",
        },
        date: "2023-07-19",
        link: {
            mn: "https://mse.mn/uploads/%D0%A5%D1%83%D0%B4%D0%B0%D0%BB%D0%B4%D0%B0%D0%BD%20%D0%B0%D0%B2%D0%B0%D0%B3%D1%87%D0%B8%D0%B4%D1%82%D0%B0%D0%B9%20%D0%B1%D0%B0%D0%B9%D0%B3%D1%83%D1%83%D0%BB%D0%B0%D1%85%20%D0%B3%D1%8D%D1%80%D1%8D%D1%8D%2020230719%20Final.docx",
            cn: "https://mse.mn/uploads/%D0%A5%D1%83%D0%B4%D0%B0%D0%BB%D0%B4%D0%B0%D0%BD%20%D0%B0%D0%B2%D0%B0%D0%B3%D1%87%D0%B8%D0%B4%D1%82%D0%B0%D0%B9%20%D0%B1%D0%B0%D0%B9%D0%B3%D1%83%D1%83%D0%BB%D0%B0%D1%85%20%D0%B3%D1%8D%D1%80%D1%8D%D1%8D%2020230719%20Final.docx",
            en: "https://mse.mn/uploads/%D0%A5%D1%83%D0%B4%D0%B0%D0%BB%D0%B4%D0%B0%D0%BD%20%D0%B0%D0%B2%D0%B0%D0%B3%D1%87%D0%B8%D0%B4%D1%82%D0%B0%D0%B9%20%D0%B1%D0%B0%D0%B9%D0%B3%D1%83%D1%83%D0%BB%D0%B0%D1%85%20%D0%B3%D1%8D%D1%80%D1%8D%D1%8D%2020230719%20Final.docx",
        }
    },
    {
        title: {
            mn: "Төмрийн хүдэр, баяжмалын арилжааны танилцуулга",
            en: "Introduction to the iron ore trading	",
            cn: "铁矿石、铁精矿电子交易介绍",
        },
        date: "2023-08-30",
        link: {
            mn: "https://comex.mse.mn/uploads/comex-iron%20ore%20fluorite-final.pdf",
            cn: "https://comex.mse.mn/uploads/comex-iron%20ore%20fluorite-final.pdf",
            en: "https://comex.mse.mn/uploads/comex-iron%20ore%20fluorite-final.pdf",
        }
    },
]
