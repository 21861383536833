import Text from 'components/atoms/Text';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { changeLanguage } from 'utils/language/fn';

/**
 * Loading ийг хүсэлт явуулж байх хооронд харуулах эсэхийг ашиглах
 * @param {boolean} hasToast   дэлгэцний баруун доод буланд loading тэй toast харуулвал
 */

export default function useLoader({ initValue=false, timeout=false, source=null, hasToast=false })
{
    const [isLoading, setLoading] = useState(initValue);

    // const navigate = useNavigate();
    const fetchData = async (axios) =>
    {
        setLoading(true)
        let toastId = ""
        if (hasToast) {
            toastId = toast.loading(changeLanguage("Loading"), {
                position: "bottom-right"
            })
        }
        const rsp = await axios
            .catch((err) => {
                if (timeout)
                {
                    setTimeout(() => {
                        setLoading(false)
                    }, 1000);
                }
                else
                {
                    setLoading(false)
                }
                if (hasToast)
                {
                    toast.update(toastId, {
                        isLoading: false,
                        render: changeLanguage("Something is wrong"),
                        type: "error",
                        autoClose: 3000,
                    })
                }
                // navigate('/')
                return Promise.reject(err)
            })
        if (timeout)
        {
            setTimeout(() => {
                setLoading(false)
            }, 1000);
        }
        else
        {
            setLoading(false)
        }
        if (hasToast)
        {
            toast.update(toastId, {
                isLoading: false,
                render: changeLanguage("Success"),
                type: "success",
                autoClose: 3000,
            })
        }
        return rsp
    }

    const cancel = () =>
    {
        source && source.cancel('cancel')
    }

    return { fetchData, isLoading, cancel };
}
