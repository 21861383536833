import css from './style.module.css'

/**
    @param {} props
*/
const BigLoader = (props) =>
{

    return (
        <div className='d-flex justify-content-center align-items-center' style={{ height: "100vh" }}>
            <div className={css.bigLoader}>
                <div className={css.box1}></div>
                <div className={css.box2}></div>
                <div className={css.box3}></div>
            </div>
        </div>
    )
}
export default BigLoader
