import { Button, ButtonProps } from "react-bootstrap"
import Text from "../Text";

/**
    @param {ButtonProps} props
*/
const CButton = (props) =>
{

    const handleClick = () =>
    {
        if (props.className && props.className.includes("smooth-goto"))
        {
            // tuhain element ruu usergene
            window.$('html, body').animate({scrollTop: window.$("#" + props.href.split("#")[1]).offset().top - 200}, 1000);
        }
        if (props.onClick)
        {
            props.onClick()
        }
    }

    return (
        <Button variant="primary" {...props} onClick={handleClick}>
            <Text>{props.children}</Text>
        </Button>
    )
}
export default CButton
