import { useCallback, useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { DatatableWrapper, Filter, Pagination, PaginationOptions, TableBody, TableHeader } from "react-bs-datatable";
import Text from "../Text";
import { getCounts } from "utils";

const DEFAULT_ROWS_PER_PAGE = 10;
const DEFAULT_ROWS_PER_PAGE_OPTIONS = [5, 10, 15, 20];

export default function AsyncStoryTable({
    fetchFn,
    headers = [],
    rowsPerPage: rowsPerPageProp = DEFAULT_ROWS_PER_PAGE,
    rowsPerPageOptions: rowsPerPageOptionsProp = DEFAULT_ROWS_PER_PAGE_OPTIONS
}) {
    const [data, setData] = useState([]);
    const [filteredDataLength, setFilteredDataLength] = useState(0);
    const [filter, setFilter] = useState('');
    const [sortState, setSortState] = useState({
        prop: 'name',
        order: 'asc'
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageProp);
    const [maxPage, setMaxPage] = useState(1);
    const [ isLoading, setLoading ] = useState(false)

    const onFilterChange = useCallback((text) => {
        setFilter(text);
        setCurrentPage(1);
    }, []);

    const onSortChange = useCallback((nextProp) => {
        setSortState(nextProp);
    }, []);

    const onPaginationChange = useCallback((nextPage) => {
        setCurrentPage(nextPage);
    }, []);

    const onRowsPerPageChange = useCallback((rowsPerPage) => {
        setRowsPerPage(rowsPerPage);
        setCurrentPage(1);
    }, []);

    useEffect(
        () =>
        {
            getData()
        },
        [rowsPerPage, currentPage, sortState]
    )

    async function getData() {
        setLoading(true)
        const response = await fetchFn({
            filter,
            sortState,
            currentPage,
            rowsPerPage
        });

        setFilteredDataLength(response.filteredDataLength);
        setData(response.data);
        setMaxPage(response.maxPage);
        setLoading(false)
    }

    return (
        <div className="position-relative">
            <DatatableWrapper headers={headers} body={data}>
                {
                    isLoading
                    ? (
                        <div className="position-absolute card p-3 myshadow-small"
                            style={{
                                top: "calc(50% + 45px)",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                            }}
                        >
                            <Text>
                                Loading
                            </Text>
                        </div>
                    )
                    : !filteredDataLength
                            ? (
                                <div className="position-absolute card p-3 myshadow-small"
                                    style={{
                                        top: "calc(50% + 45px)",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                    }}
                                >
                                    <Text>
                                        no result
                                    </Text>
                                </div>
                            )
                            : null
                }
                <Table bordered striped>
                    <TableHeader
                        controlledProps={{
                            sortState,
                            onSortChange,
                            filteredDataLength,
                        }}
                    />
                    {
                        !filteredDataLength
                        ? (
                            <TableBody>
                                {
                                    getCounts(rowsPerPage).map(
                                        (count, idx) =>
                                        {
                                            return (
                                                <tr key={idx}>
                                                    <td style={{
                                                        height: "2rem"
                                                    }}>
                                                        <span></span>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    )
                                }
                            </TableBody>
                        )
                        : (
                            <>
                                <TableBody
                                    controlledProps={{
                                        filteredDataLength,
                                    }}
                                >
                                </TableBody>
                            </>
                        )
                    }
                </Table>
                <Row className="mb-4">
                    {/* <Col
                        xs={12}
                        lg={4}
                        className="d-flex flex-col justify-content-end align-items-end"
                    >
                        <Filter controlledProps={{ filter, onFilterChange }} />
                    </Col> */}
                    <Col
                        xs={12}
                        sm={6}
                        lg={6}
                        className="d-flex flex-col justify-content-lg-start align-items-start justify-content-sm-start mb-2 mb-sm-0"
                        // className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
                    >
                        <PaginationOptions
                            controlledProps={{
                                filteredDataLength,
                                onRowsPerPageChange,
                                rowsPerPageOptions: rowsPerPageOptionsProp,
                                rowsPerPage,
                            }}
                        />
                    </Col>
                    <Col
                        xs={12}
                        sm={6}
                        lg={6}
                        className="d-flex flex-col justify-content-end align-items-end"
                    >
                        <Pagination
                            controlledProps={{ currentPage, maxPage, onPaginationChange }}
                        />
                    </Col>
                </Row>
            </DatatableWrapper>
        </div>
    );
}
