export default {
    "Navigations": {
        "en": "Navigations",
        "mn": "Холбоосууд",
        "cn": "导航"
    },
    "Data and Analysis": {
        "en": "Statistics and data analysis",
        "mn": "Өгөгдөл ба шинжилгээ",
        "cn": "数据与分析"
    },
    "Trading Partner & Consultancy": {
        "en": "Trading Partner & Consultancy",
        "mn": "Худалдааны түнш, зөвлөх үйлчилгээ",
        "cn": "贸易伙伴及咨询"
    },
    "FAQ": {
        "en": "FAQ",
        "mn": "FAQ",
        "cn": "FAQ"
    },
    "Search": {
        "en": "Search",
        "mn": "Хайлт",
        "cn": "搜索"
    },
    "User": {
        "en": "User",
        "mn": "Хэрэглэгч",
        "cn": "用户"
    },
    "Partner and Consultancy": {
        "en": "Consultancy",
        "mn": "Зөвлөх үйлчилгээ ",
        "cn": "咨询服务"
    },
    "Mining Partnership & Management": {
        "en": "Mining Partnership & Management",
        "mn": "Уул уурхайн түншлэл & менежмент",
        "cn": "矿业合作与管理"
    },
    "Overview": {
        "en": "Partnership",
        "mn": "Түншлэл",
        "cn": "合作伙伴"
    },
    "Products": {
        "en": "Mapping",
        "mn": "Байршил",
        "cn": "位置"
    },
    "Consulting": {
        "en": "Projects",
        "mn": "Төслүүд",
        "cn": "项目"
    },
    "Need help? Read our FAQ": {
        "en": "Need help? Read our FAQ",
        "mn": "Тусламж хэрэгтэй бол FAQ-г уншина уу",
        "cn": "需要帮忙？ 阅读我们的常见问题解答"
    },
    "Log in": {
        "en": "Log in",
        "mn": "Нэвтрэх",
        "cn": "登录"
    },
    // NOTE: Ashiglahaa bolison 
    "Comex Partners": {
        "en": "Comex Partners",
        "mn": "Comex Partners",
        "cn": "Comex Partners"
    },
    // NOTE: Ashiglahaa bolison 
    "Learn how you can enter into a contract to move forward your business and become our partner": {
        "en": "Learn how you can enter into a contract to move forward your business and become our partner",
        "mn": "Та бизнесээ урагшлуулахын тулд хэрхэн гэрээ байгуулж, манай түнш болох талаар суралцаарай",
        "cn": "了解如何签订合同以推进您的业务并成为我们的合作伙伴"
    },
    // NOTE: Ashiglahaa bolison 
    "WATCH THE REPLAYS": {
        "en": "WATCH THE REPLAYS",
        "mn": "Бичлэг үз",
        "cn": "观看重播"
    },
    "Integrates the process of cross border trade, export and logistics of mineral products and accommodates investment to natural resources..,": {
        "en": "We are providing services for the trade and transportation of mineral products and accommodate investments.",
        "mn": "Бид эрдэс бүтээгдэхүүний худалдаа, тээвэрлэлт болон хөрөнгө оруулалтын зуучлалын чиглэлээр үйлчилгээ үзүүлэх зорилготойгоор ажиллаж байна.",
        "cn": "我们的目标是提供矿业产品贸易，运输与投资中介服务"
    },
    "Contact us": {
        "en": "Contact us",
        "mn": "Холбоо барих",
        "cn": "联系我们"
    },
    "Call us at": {
        "en": "Mobile",
        "mn": "Утас",
        "cn": "移动的"
    },
    "+976 9908 5997": {
        "en": "+976 9908 5997",
        "mn": "+976 9908 5997",
        "cn": "+976 9908 5997"
    },
    "Chat": {
        "en": "Chat",
        "mn": "Мессеж",
        "cn": "聊天"
    },
    "Get live help and chat with support": {
        "en": "Get live help and chat with support",
        "mn": "Шууд тусламж авах бол чатлана уу",
        "cn": "获得实时帮助并与支持人员聊天"
    },
    "Send us your comment, questions or feedback": {
        "en": "Send us your comment, questions or feedback",
        "mn": "Бидэнд санал хүсэлт, асуулт болон санал хүсэлтээ илгээнэ үү",
        "cn": "将您的评论、问题或反馈发送给我们"
    },
    "Get all the information you need to operate mining products to import across borders": {
        "en": "Get all the information you need to trade mining products",
        "mn": "Уул уурхайн бүтээгдэхүүнийг худалдаалахад шаардлагатай бүх мэдээлэл",
        "cn": "获取运营跨境进口采矿产品所需的所有信息"
    },
    "Statistics and data analysis": {
        "en": "Statistics and data analysis",
        "mn": "Статистик ба дүн шинжилгээ",
        "cn": "统计与分析"
    },
    "Achieve less risky business decision with more detailed, factual numbers and data analysis": {
        "en": "Achieve less risky business decision with more detailed, factual numbers and data analysis",
        "mn": "Эрсдэл багатай бизнесийн шийдвэр гаргахад тань бид туслах болно",
        "cn": "我们帮助您为作出最低风险的生意决策。"
    },
    "Integrated transport and logistics information": {
        "en": "Integrated transport and logistics information",
        "mn": "Тээвэр ложистикийн нэгдсэн мэдээлэл",
        "cn": "物流综合信息"
    },
    "Provides information that containing adjsuted traffic and logistics maps and border point information. It includes railway and paved road map data": {
        "en": "We provide logistic maps including railways and roadways.",
        "mn": "Төмөр зам, авто замын тээвэр логистикийн зураглалыг манай системээс харах боломжтой",
        "cn": "铁路和公路运输物流图像、可以在我们的系统中查看。"
    },
    "News on mineral deposits": {
        "en": "News on mineral resources",
        "mn": "Уул уурхайн салбарын мэдээ мэдээлэл",
        "cn": "矿业的信息"
    },
    "Provides an opportunity to introduce the conclusions of a business analyst and experts who made a conclusion on detailed report": {
        "en": "We constantly deliver the latest news from the mongolian mineral resources industry.",
        "mn": "Бид  эрдэс баялгийн салбарын шинэлэг мэдээ мэдээллийг тогтмол хүргэж байна.",
        "cn": "我们定期发布矿产资源行业的最新信息。"
    },
    "Explore our portfolio": {
        "en": "Explore our portfolio",
        "mn": "Манай багцыг судлаарай",
        "cn": "探索我们的产品组合"
    },
    "How is your experience with this page": {
        "en": "",
        "mn": "Таны энэ хуудасны туршлага хэр байна вэ",
        "cn": "您对此页面的体验如何"
    },
    "Useful Links": {
        "en": "Menu",
        "mn": "Меню",
        "cn": "菜单"
    },
    "Home": {
        "en": "Home",
        "mn": "Нүүр",
        "cn": "家"
    },
    "About": {
        "en": "About us",
        "mn": "Бидний тухай",
        "cn": "关于我们"
    },
    "Services": {
        "en": "Services",
        "mn": "Үйлчилгээнүүд",
        "cn": "服务"
    },
    "Contact": {
        "en": "Contact",
        "mn": "Холбоо барих",
        "cn": "接触"
    },
    "About us": {
        "en": "About us",
        "mn": "Бидний тухай",
        "cn": "关于我们"
    },
    "Our Services": {
        "en": "Our Services",
        "mn": "Үйлчилгээнүүд",
        "cn": "我们的服务"
    },
    "Coal": {
        "en": "Coal",
        "mn": "Нүүрс",
        "cn": "煤炭"
    },
    "Copper concentrate": {
        "en": "Copper concentrate",
        "mn": "Зэсийн баяжмал",
        "cn": "铜精矿"
    },
    "Gold": {
        "en": "Gold",
        "mn": "Алт",
        "cn": "金子"
    },
    "Iron Ore": {
        "en": "Iron Ore",
        "mn": "Төмрийн хүдэр",
        "cn": "铁矿"
    },
    "Zincum concentrate": {
        "en": "Zincum concentrate",
        "mn": "Цайрын баяжмал",
        "cn": "锌精矿"
    },
    "Fluor spar ores and concentrate": {
        "en": "Fluor spar ores and concentrate",
        "mn": "Хайлуур жоншны хүдэр ба баяжмалын",
        "cn": "萤石琼什及其精矿"
    },
    "Molybdenium ores and concentrade (thous.t)": {
        "en": "Molybdenium ores and concentrade (thous.t)",
        "mn": "Молибдений хүдэр ба баяжмал",
        "cn": "钼矿石与其精矿"
    },
    "Mines": {
        "en": "Mines",
        "mn": "Уурхайнууд",
        "cn": "地雷"
    },
    "Contract": {
        "en": "Contract",
        "mn": "Гэрээ",
        "cn": "合同"
    },
    "Logistics": {
        "en": "Logistics",
        "mn": "Логистик",
        "cn": "后勤"
    },
    "Customs & Taxing": {
        "en": "Гааль, татвар",
        "mn": "Гааль, татвар",
        "cn": "海关和税务"
    },
    "Insurance": {
        "en": "Даатгал",
        "mn": "Даатгал",
        "cn": "保险"
    },
    "Accreditive": {
        "en": "Accreditive",
        "mn": "Магадлан итгэмжлэгдсэн",
        "cn": "认可的"
    },
    "Find us": {
        "en": "Address",
        "mn": "Хаяг",
        "cn": "地址"
    },
    "Seoul street 7/1, 2nd Khoroo, Sukhbaatar District, Ulaanbaatar, Mongolia": {
        "en": "#503, ZAISAN SQUARE , 11th khoroo, Khan-Uul District, Dunjingarav 14, Ulaanbaatar, 17023, Mongolia",
        "mn": "Улаанбаатар хот, Хан-Уул дүүрэг, 11-р хороо, ЗАЙСАН СКУЭР,  503 тоот",
        "cn": "#503, ZAISAN SQUARE , 11th Street, Khan-Ul District, Dunjingarav 14, 乌兰巴托, 17023, 蒙古"
    },
    "Call us": {
        "en": "Phone",
        "mn": "Утас",
        "cn": "电话"
    },
    "Mail us": {
        "en": "Mail",
        "mn": "Мэйл хаяг",
        "cn": "邮箱"
    },
    "info@comexpartner.mn": {
        "en": "info@comexpartner.mn",
        "mn": "info@comexpartner.mn",
        "cn": "info@comexpartner.mn"
    },
    "Find us on": {
        "en": "Social network",
        "mn": "Олон нийтийн сүлжээ",
        "cn": "社交网络"
    },
    "Geographic Information System": {
        "en": "Geographic Information System",
        "mn": "Газарзүйн мэдээллийн систем",
        "cn": "地理信息系统"
    },
    "Request a demo": {
        "en": "Mapping",
        "mn": "Зураглал",
        "cn": "测绘"
    },
    "Geographic information systems, are computer-based tools used to store, visualize interpret geographic data of Mongolian mineral deposits as well as traffic information": {
        "en": "Geographic information systems are visualize the information of mineral deposits and roadways as well as railroads",
        "mn": "Манай газарзүйн мэдээллийн систем нь ашигт малтмалын орд, авто зам, төмөр замын мэдээллийг дүрслэн харуулах боломжтой.",
        "cn": "我们的地理信息系统可以可视化矿藏公路和铁路的所信息。"
    },
    "Read the guidence": {
        "en": "Projects",
        "mn": "Төсөл",
        "cn": "项目"
    },
    "Learn how companies are dealing mineral products to export cross border trade with help of our solution": {
        "en": "Investing opportunities in Mongolia's rich mineral deposits and projects",
        "mn": "Ашигт малтмалын арвин нөөц бүхий төслүүдэд хөрөнгө оруулах боломж",
        "cn": "投资各种矿产丰富资源项目的机会"
    },
    "Explore our newsletters": {
        "en": "Explore our newsletters",
        "mn": "Мэдээ мэдээлэл",
        "cn": "探索我们的时事通讯"
    },
    
    "Read more": {
        "en": "Read more",
        "mn": "Дэлгэрэнгүй",
        "cn": "阅读更多"
    },
    
    "Sign in to": {
        "en": "Sign in",
        "mn": "Нэвтрэх",
        "cn": "登入"
    },
    "Email address": {
        "en": "Email address",
        "mn": "Имэйл хаяг",
        "cn": "电邮位置"
    },
    "Password": {
        "en": "Password",
        "mn": "Нууц үг",
        "cn": "密码"
    },
    "Forget password": {
        "en": "Forget password",
        "mn": "Нууц үгээ мартсан",
        "cn": "忘记密码"
    },
    "Sign in": {
        "en": "Sign in",
        "mn": "Нэвтэрнэ үү",
        "cn": "登入"
    },
    "Not member yet": {
        "en": "Not member yet",
        "mn": "Хараахан гишүүн болоогүй байна",
        "cn": "还不是会员"
    },
    "Sign Up": {
        "en": "Sign up",
        "mn": "Бүртгүүлэх",
        "cn": "报名"
    },
    "Register": {
        "en": "Register",
        "mn": "Бүртгүүлэх",
        "cn": "注册"
    },
    "Confirm": {
        "en": "Confirm",
        "mn": "Батлах",
        "cn": "确认"
    },
    "Finish": {
        "en": "Finish",
        "mn": "Дуусгах",
        "cn": "结束"
    },
    "Next": {
        "en": "Next",
        "mn": "Дараачийн",
        "cn": "下一个"
    },
    "Already member": {
        "en": "Already member",
        "mn": "Бүртгэлтэй гишүүн байна",
        "cn": "已经是会员"
    },
    "Profile": {
        "en": "Profile",
        "mn": "Профайл",
        "cn": "轮廓"
    },
    "Log out": {
        "en": "Log out",
        "mn": "Гарах",
        "cn": "登出"
    },
    "By category": {
        "en": "By category",
        "mn": "Ангилалууд",
        "cn": "按类别"
    },
    "Iron Or": {
        "en": "Iron Ore",
        "mn": "Төмрийн хүдэр",
        "cn": "铁矿"
    },
    "Main": {
        "en": "Main",
        "mn": "Үндсэн",
        "cn": "主"
    },
    "Points": {
        "en": "Points",
        "mn": "Цэгүүд",
        "cn": "积分"
    },
    "New": {
        "en": "New",
        "mn": "Шинэ",
        "cn": "新的"
    },
    "General information": {
        "en": "General information",
        "mn": "Ерөнхий мэдээлэл",
        "cn": "一般信息"
    },
    "Last name": {
        "en": "Last name",
        "mn": "Овог",
        "cn": "姓"
    },
    "First name": {
        "en": "First name",
        "mn": "Нэр",
        "cn": "名"
    },
    "Phone number": {
        "en": "Phone number",
        "mn": "Утас",
        "cn": "电话号码"
    },
    "Layers": {
        "en": "Layers",
        "mn": "Давхаргууд",
        "cn": "层数"
    },
    "Border status": {
        "en": "Border status",
        "mn": "Хилийн боомт",
        "cn": "边境状况"
    },
    "Available": {
        "en": "Available",
        "mn": "Боломжтой",
        "cn": "可用的"
    },
    "Not Available": {
        "en": "Not Available",
        "mn": "Боломжгүй",
        "cn": "无法使用"
    },
    "Planned": {
        "en": "Planned",
        "mn": "Төлөвлөсөн",
        "cn": "计划"
    },
    "Infrastructure": {
        "en": "Infrastructure",
        "mn": "Дэд бүтэц",
        "cn": "基础设施"
    },
    "Highway": {
        "en": "Roadway",
        "mn": "Авто зам",
        "cn": "巷道"
    },
    "Railway": {
        "en": "Railway",
        "mn": "Төмөр зам",
        "cn": "铁路"
    },
    "Train turees": {
        "en": "Freight train ordering complex system",
        "mn": "Ачааны галт тэрэг захиалах цогц систем",
        "cn": "预订货车的综合系统。"
    },


    "Power station": {
        "en": "Power substation",
        "mn": "Цахилгаан дэд станц",
        "cn": "变电站"
    },
    "Results of mineral deposits": {
        "en": "Results of mineral deposits",
        "mn": "Ашигт малтмалын орд илэрцүүд",
        "cn": "矿藏结果"
    },
    "Metals": {
        "en": "Metals",
        "mn": "Метал",
        "cn": "金属"
    },
    "Not metals": {
        "en": "Not metals",
        "mn": "Метал бус",
        "cn": "不是金属"
    },
    "Combustible minerals": {
        "en": "Combustible minerals",
        "mn": "Шатах ашигт малтмал",
        "cn": "可燃矿物"
    },
    "Rare metals": {
        "en": "Rare metals",
        "mn": "Газрын ховор элемент",
        "cn": "稀土元素"
    },
    "Toggle": {
        "en": "Toggle",
        "mn": "Сэлгэх",
        "cn": "切换"
    },
    "Legends": {
        "en": "Legends",
        "mn": "Таних тэмдэг",
        "cn": "鉴别"
    },
    "Layers are not selected": {
        "en": "Layers are not selected",
        "mn": "Давхарга сонгоогүй байна",
        "cn": "未选择图层"
    },
    "Province": {
        "en": "Province",
        "mn": "Аймаг",
        "cn": "省"
    },
    "Sum": {
        "en": "Sum",
        "mn": "Сум",
        "cn": "和"
    },
    "Location": {
        "en": "Location",
        "mn": "Байршил",
        "cn": "地点"
    },
    "Basic minerals": {
        "en": "Basic minerals",
        "mn": "Үндсэн ашигт малтмал",
        "cn": "基本矿物质"
    },
    "We're sorry, but the page you were looking for doesn't exist": {
        "en": "We're sorry, but the page you were looking for doesn't exist",
        "mn": "Уучлаарай, таны хайж байсан хуудас байхгүй байна",
        "cn": "很抱歉，您要查找的页面不存在"
    },
    "UNEXPECTED ERROR": {
        "en": "UNEXPECTED ERROR",
        mn: "Тодорхойлогдоогүй алдаа",
        cn: "意外的错误"
    },
    "An unexpected error has occured": {
        "en": "An unexpected error has occured",
        mn: "Тодорхойлогдоогүй алдаа гарлаа",
        cn: "发生意外错误",
    },
    "GO BACK TO HOME PAGE": {
        "en": "GO BACK TO HOME PAGE",
        mn: "Нүүр хуудас руу буцах",
        cn: "返回首页"
    },
    "Silver": {
        "en": "Silver",
        mn: "Мөнгө",
        cn: "银"
    },
    "Copper": {
        "en": "Copper",
        mn: "Зэс",
        cn: "铜"
    },
    "Aluminium": {
        "en": "Aluminium",
        mn: "Хөнгөн цагаан",
        cn: "铝"
    },
    'Titanium': {
        "en": "Titanium",
        mn: "Титан",
        cn: "钛"
    },
    "Chromium": {
        "en": "Chromium",
        mn: "Хром",
        cn: "铬"
    },
    "Lead": {
        "en": "Lead",
        mn: "Хар тугалга",
        cn: "带领"
    },
    "Zinc": {
        "en": "Zinc",
        mn: "Цайр",
        cn: "锌"
    },
    "Manganese": {
        "en": "Manganese",
        mn: "Манган",
        cn: "锰"
    },
    "Iron": {
        "en": "Iron",
        mn: "Төмөр",
        cn: "铁"
    },
    "Nickel": {
        "en": "Nickel",
        mn: "Никель",
        cn: "镍"
    },
    "Platinum": {
        "en": "Platinum",
        mn: "Цагаан алт",
        cn: "铂"
    },
    "Tungsten": {
        "en": "Tungsten",
        mn: "Вольфрам",
        cn: "钨"
    },
    "Tin": {
        "en": "Tin",
        mn: "Цагаан тугалга",
        cn: "锡"
    },
    "Molybdenum": {
        "en": "Molybdenum",
        mn: "Молибден",
        cn: "钼"
    },
    "Beryllium": {
        "en": "Beryllium",
        mn: "Берилли",
        cn: "铍"
    },
    "Sodium": {
        "en": "Sodium",
        mn: "Натри",
        cn: "钠",
    },
    Arsenic: {
        "en": "Arsenic",
        mn: "Хүнцэл",
        cn: "砷",
    },
    "Bismuth": {
        "en": "Bismuth",
        mn: "Висмут",
        cn: "铋",
    },
    "Cobalt": {
        "en": "Cobalt",
        mn: "Кобальт",
        cn: "钴"
    },
    "Mercury": {
        "en": "Mercury",
        mn: "Мөнгөн ус",
        cn: "汞"
    },
    "Magnesium": {
        "en": "Magnesium",
        mn: "Магни",
        cn: "镁",
    },
    "Antimony": {
        "en": "Antimony",
        mn: "Сурьма",
        cn: "锑",
    },
    "Boron": {
        "en": "Boron",
        mn: "Бор",
        cn: "硼"
    },
    "no result": {
        "en": "no result",
        mn: "Өгөгдөл байхгүй байна",
        cn: "没有结果"
    },
    "Trading": {
        "en": "Exchange",
        mn: "Арилжаа",
        cn: "贸易"
    },
    "That means that export shares for the countries with domestic markets for their minerals will tend to understate their mineral dependence.": {
        "en": "Mining and non-mining commodities can be traded in over-the-counter auctions",
        mn: "Уул уурхайн болон уул уурхайн бус түүхий эд бүтээгдэхүүнийг биржийн бус дуудлага худалдаагаар арилжаалах боломжтой",
        cn: "采矿和非采矿原料可以通过场外拍卖进行交易。"
    },
    "Visit to site": {
        "en": "See more",
        mn: "Дэлгэрэнгүй",
        cn: "查看更多"
    },
    "Орд": {
        "en": "Ord",
        mn: "Орд",
        cn: "奥德"
    },
    "Өнгөт метал": {
        "en": "Non-ferrous metal",
        mn: "Өнгөт метал",
        cn: "有色金属"
    },
    "Үнэт метал": {
        "en": "Precious metal",
        mn: "Үнэт метал",
        cn: "贵金属"
    },
    "Хар метал": {
        "en": "Har metal",
        mn: "Хар метал",
        cn: "黑金属"
    },
    "Холимог метал": {
        en: "Mixed metal",
        mn: "Холимог метал",
        cn: "混合金属"
    },
    "Ховор метал": {
        en: "Rare metal",
        mn: "Ховор метал",
        cn: "稀有金属"
    },
    "Other": {
        en: "Other",
        mn: "Бусад",
        cn: "其他的"
    },
    "Барилгын түүхий эд": {
        en: "Building materials",
        mn: "Барилгын түүхий эд",
        cn: "建筑原料"
    },
    "Өнгөлгөөний чулуу": {
        en: "Polishing stone",
        mn: "Өнгөлгөөний чулуу",
        cn: "抛光石"
    },
    "Керамзитын түүхий эд": {
        en: "Siltile raw material",
        mn: "Керамзитын түүхий эд",
        cn: "硅红石原料"
    },
    "Өрлөг-Зуурмагын түүхий эд": {
        en: "",
        mn: "Өрлөг-Зуурмагын түүхий эд",
        cn: "砖石-砖石原料"
    },
    "Керамикийн түүхий эд": {
        en: "Ceramic raw materials",
        mn: "Керамикийн түүхий эд",
        cn: "陶瓷原料"
    },
    "Цементний түүхий эд": {
        en: "Cement raw materials",
        mn: "Цементний түүхий эд",
        cn: "水泥原料"
    },
    "Хэвний түүхий эд": {
        en: "",
        mn: "Хэвний түүхий эд",
        cn: "模具原料"
    },
    "Перлит": {
        en: "Perlite",
        mn: "Перлит",
        cn: "珍珠岩"
    },
    "Шохойн чулуу": {
        en: "Limestone",
        mn: "Шохойн чулуу",
        cn: "石灰石"
    },
    "Эрдсийн будаг": {
        en: "Mineral paint",
        mn: "Эрдсийн будаг",
        cn: "矿物漆"
    },
    "Гөлтгөнө": {
        en: "Gypsum",
        mn: "Гөлтгөнө",
        cn: "石膏"
    },
    "Битум": {
        en: "Bitumen",
        mn: "Битум",
        cn: "沥青"
    },
    "Өнгөт ба үнэт чулуу": {
        en: "Colored and gemstones",
        mn: "Өнгөт ба үнэт чулуу",
        cn: "有色和宝石"
    },
    "Өнгөт чулуу": {
        en: "Colored stones",
        mn: "Өнгөт чулуу",
        cn: "有色石"
    },
    "Үнэт чулуу": {
        en: "Gem",
        mn: "Үнэт чулуу",
        cn: "宝石"
    },
    "Түгээмэл тархацтай": {
        en: "",
        mn: "Түгээмэл тархацтай",
        cn: "广泛"
    },
    "Барилгын чулуу": {
        en: "Building stone",
        mn: "Барилгын чулуу",
        cn: "建筑石材"
    },
    "Шавар": {
        en: "Clay",
        mn: "Шавар",
        cn: "黏土"
    },
    "Элс": {
        en: "Sand",
        mn: "Элс",
        cn: "沙"
    },
    "Үйлдвэр техникийн эрдэс": {
        en: "Industrial and technical minerals",
        mn: "Үйлдвэр техникийн эрдэс",
        cn: "工业和技术矿物"
    },
    "Болор": {
        en: "Crystal",
        mn: "Болор",
        cn: "水晶"
    },
    "Гялтагнуур": {
        en: "",
        mn: "Гялтагнуур",
        cn: "闪光"
    },
    "Офиокальцит": {
        en: "Ophiocalcite",
        mn: "Офиокальцит",
        cn: "蛇方解石"
    },
    "Хайлуур жонш": {
        en: "",
        mn: "Хайлуур жонш",
        cn: "融化琼什"
    },
    "Шаазан-ваарын түүхий эд": {
        en: "porcelain raw material",
        mn: "Шаазан-ваарын түүхий эд",
        cn: "瓷花瓶"
    },
    "Шилний түүхий эд": {
        en: "",
        mn: "Шилний түүхий эд",
        cn: "玻璃原料"
    },
    "Бари": {
        en: "Bari",
        mn: "Бари",
        cn: "巴里"
    },
    "Химийн үйлдвэрийн түүхий эд": {
        en: "",
        mn: "Химийн үйлдвэрийн түүхий эд",
        cn: "化工原料"
    },
    "Фосфор": {
        en: "Phosphorus",
        mn: "Фосфор",
        cn: "磷"
    },
    "Хүхэр": {
        en: "Sulfur",
        mn: "Хүхэр",
        cn: "硫"
    },
    "Бал чулуу": {
        en: "Graphite",
        mn: "Бал чулуу",
        cn: "石墨"
    },
    "Барагшун": {
        en: "Brag Zhun",
        mn: "Барагшун",
        cn: "喜来芝"
    },
    "Давс": {
        en: "Salt",
        mn: "Давс",
        cn: "盐"
    },
    "Исландын жонш": {
        en: "",
        mn: "Исландын жонш",
        cn: "冰岛琼什"
    },
    "Мөсөн шүү": {
        en: "",
        mn: "Мөсөн шүү",
        cn: ""
    },
    "Тальк": {
        en: "Talc",
        mn: "Тальк",
        cn: "滑石"
    },
    "Фосфорит": {
        en: "Phosphorite",
        mn: "Фосфорит",
        cn: "磷矿"
    },
    "Цеолит": {
        en: "Zeolite",
        mn: "Цеолит",
        cn: "沸石"
    },
    "Нефть": {
        en: "Oil",
        mn: "Нефть",
        cn: "油"
    },
    "Чулуун нүүрс": {
        en: "Coal",
        mn: "Чулуун нүүрс",
        cn: "石煤"
    },
    "Хүрэн нүүрс": {
        en: "Lignite",
        mn: "Хүрэн нүүрс",
        cn: "褐煤"
    },
    "Хүлэр": {
        en: "Peat",
        mn: "Хүлэр",
        cn: "泥炭"
    },
    "Занар": {
        en: "Shale",
        mn: "Занар",
        cn: "页岩"
    },
    "Хөнгөн": {
        en: "Light",
        mn: "Хөнгөн",
        cn: "光"
    },
    "Хүнд": {
        en: "Heavy",
        mn: "Хүнд",
        cn: "重的"
    },
    "Нэмэлт мэдээлэл": {
        en: "Additional information",
        mn: "Нэмэлт мэдээлэл",
        cn: "附加信息"
    },
    "Please fill in correctly": {
        en: "Please fill in correctly",
        mn: "Үнэн, зөв бөглөнө үү",
        cn: "请正确填写"
    },
    "Company name": {
        en: "Company name",
        mn: "Компанийн нэр",
        cn: "公司名称"
    },
    "Position": {
        en: "Position",
        mn: "Албан тушаал",
        cn: "职位"
    },
    "Save": {
        en: "Save",
        mn: "Хадгалах",
        cn: "节省"
    },
    "Frequently Asked Questions": {
        en: "Frequently Asked Questions",
        mn: "Түгээмэл асуултууд",
        cn: "经常问的问题",
    },
    "We sent email to your email address. Please confirm your email address.": {
        en: "We sent an email to your email address. Please confirm your email address",
        mn: "Бид таны имэйл хаяг руу имэйл илгээсэн. цахим хаягаа баталгаажуулна уу.",
        cn: "我们向您的电子邮件地址发送了一封电子邮件。 请确认您的电子邮件地址",
    },
    "Didn't get a email": {
        en: "Didn't get a email",
        mn: "Имэйл хүлээж аваагүй",
        cn: "没有收到电子邮件",
    },
    "Code": {
        en: "Code",
        mn: "Код",
        cn: "代码",
    },
    "Check password": {
        en: "Check password",
        mn: "Нууц үгээ шалгана уу",
        cn: "检查密码",
    },
    "Нэгжийн үнэ": {
        en: "Unit price",
        mn: "Үнэ ханшийн мэдээлэл",
        cn: "价格信息"
    },
    "Бүтээгдэхүүний үзүүлэлт": {
        en: "Product specifications",
        mn: "Бүтээгдэхүүний үзүүлэлт",
        cn: "产品规格"
    },
    "Загвар": {
        en: "Model",
        mn: "Загвар",
        cn: "模型"
    },
    "Үнийн хугацаа": {
        en: "Pricing period",
        mn: "Үнийн хугацаа",
        cn: "定价周期"
    },
    "Гарал угсаа": {
        en: "Origin",
        mn: "Гарал угсаа",
        cn: "原产"
    },
    "Дээж өгч чадах эсэх": {
        en: "",
        mn: "Дээж өгч чадах эсэх",
        cn: "可不可以提供样品"
    },
    "Захиалгын хамгийн бага хэмжээ": {
        en: "Minimum order size",
        mn: "Захиалгын хамгийн бага хэмжээ",
        cn: "最小订单量"
    },
    "Хүргэлтийн өдөр": {
        en: "Delivery date",
        mn: "Хүргэлтийн өдөр",
        cn: "交货日期"
    },
    "Нийлүүлэлтийн хүчин чадал": {
        en: "Supply capacity",
        mn: "Нийлүүлэлтийн хүчин чадал",
        cn: "供货能力"
    },
    "Борлуулалтын нөхцөл ба нэмэлт нөхцөл": {
        en: "Terms and Conditions of Sale and Additional Terms",
        mn: "Борлуулалтын нөхцөл ба нэмэлт нөхцөл",
        cn: "销售条款和条件以及附加条款"
    },
    "Савлах": {
        en: "Packing",
        mn: "Савлах",
        cn: "包装"
    },
    "Чанар/Аюулгүй байдлын гэрчилгээ": {
        en: "Quality/Safety Certificate",
        mn: "Чанар/Аюулгүй байдлын гэрчилгээ",
        cn: "质量/安全证书"
    },
    "Company": {
        en: "Company",
        mn: "Компани",
        cn: "公司"
    },
    "Name of contact": {
        en: "Name of contact",
        mn: "Харилцагчийн нэр",
        cn: "联系人姓名",
    },
    "Contact information": {
        en: "Contact information",
        mn: "Холбогдох мэдээлэл",
        cn: "联系信息",
    },
    "Detail": {
        en: "Details",
        mn: "Дэлгэрэнгүй",
        cn: "细节",
    },
    "Report": {
        en: "Report",
        mn: "Тайлан",
        cn: "报告",
    },
    "News": {
        en: "News",
        mn: "Мэдээ",
        cn: "新闻",
    },
    "Form": {
        en: "Form",
        mn: "Маягт",
        cn: "形式",
    },
    "Request form": {
        en: "Request for consulting services",
        mn: "Зөвлөх үйлчилгээний хүсэлт",
        cn: "申请咨询服务",
    },
    "Title": {
        en: "Title",
        mn: "Гарчиг",
        cn: "标题",
    },
    "Projects": {
        en: "Projects",
        mn: "Төслүүд",
        cn: "项目",
    },
    "Main content": {
        en: "Main content",
        mn: "Үндсэн агуулга",
        cn: "主要内容",
    },
    "content": {
        en: "content",
        mn: "агуулга",
        cn: "内容",
    },
    "I want to know": {
        en: "I want to know",
        mn: "Хүсэж буй мэдээлэл",
        cn: "我想知道",
    },
    "Pages": {
        en: "Pages",
        mn: "Хуудас",
        cn: "页",
    },
    "License": {
        en: "License",
        mn: "Лиценз",
        cn: "执照",
    },
    "минутын өмнө": {
        en: "minutes ago",
        mn: "минутын өмнө",
        cn: "分钟前",
    },
    "цагийн өмнө": {
        en: "hours ago",
        mn: "цагийн өмнө",
        cn: "几小时前",
    },
    "хоногийн өмнө": {
        en: "days ago",
        mn: "хоногийн өмнө",
        cn: "几天前",
    },
    "сарын өмнө": {
        en: "month ago",
        mn: "сарын өмнө",
        cn: "个月前",
    },
    "жилийн өмнө": {
        en: "year ago",
        mn: "жилийн өмнө",
        cn: "年前",
    },
    "Дөнгөж сая": {
        en: "Just now",
        mn: "Дөнгөж сая",
        cn: "现在",
    },
    "RECENT NEWS": {
        en: "Recent news",
        mn: "Сүүлийн мэдээ",
        cn: "最近的新闻",
    },
    "Categories": {
        en: "Categories",
        mn: "Ангилал",
        cn: "类别",
    },
    "Back": {
        en: "Back",
        mn: "Буцах",
        cn: "后退",
    },
    "Choose from map": {
        en: "Choose from map",
        mn: "Газрын зургаас сонгоно уу",
        cn: "从地图中选择"
    },
    "Number of cadaster": {
        en: "Number of cadasters",
        mn: "Кадастрын тоо",
        cn: "地籍数量",
    },
    "NEW METAL": {
        en: "NEW METAL",
        mn: "ШИНЭ МЕТАЛ",
        cn: "新金属",
    },
    "Date of detection": {
        en: "Date of detection",
        mn: "Илрүүлсэн огноо",
        cn: "出翔的日期"
    },
    "Current address": {
        en: "Current address",
        mn: "Одоогийн хаяг",
        cn: "目前的地址"
    },
    "Detail of an address": {
        en: "Detail of an address",
        mn: "Дэлгэрэнгүй хаяг",
        cn: "详细的地址"
    },
    "Origin": {
        en: "Origin",
        mn: "Гарал үүсэл",
        cn: "原产"
    },
    "Chemical element": {
        en: "Chemical element",
        mn: "Хиймийн элемент",
        cn: "化学元素"
    },
    "Classification": {
        en: "Classification",
        mn: "Ангилал",
        cn: "分类"
    },
    "Chemical composition": {
        en: "Chemical composition",
        mn: "Химийн найрлага",
        cn: "化学成分"
    },
    "Physical properties": {
        en: "Physical properties",
        mn: "Физик шинж, чанар",
        cn: "物理特性、质量"
    },
    "Substance composition": {
        en: "Substance composition",
        mn: "Бодисын найрлага",
        cn: "物质成分"
    },
    "Ingredients": {
        en: "Ingredients",
        mn: "Найрлага",
        cn: "成分"
    },
    "Root": {
        en: "",
        mn: "",
        cn: ""
    },
    "Нэршил": {
        en: "Nomenclature",
        mn: "Нэршил",
        cn: "命名法",
    },
    "Лиценз төрөл": {
        en: "License type",
        mn: "Лиценз төрөл",
        cn: "许可证类型",
    },
    "Санал": {
        en: "Offer",
        mn: "Санал",
        cn: "提议",
    },
    "Нөөцийн хэмжээ": {
        en: "Resources",
        mn: "Нөөцийн хэмжээ",
        cn: "资源量",
    },
    "Бүсээр": {
        en: "Location area",
        mn: "Байршлын бүс",
        cn: "位置区",
    },
    "Source": {
        en: "Source",
        mn: "Эх сурвалж",
        cn: "来源",
    },
    "Монгол улсын бүх уул уурхайн лиценз эзэмшигчдийн жагсаалт": {
        en: "",
        mn: "Монгол улсын бүх уул уурхайн лиценз эзэмшигчдийн жагсаалт",
        cn: "蒙古国所有矿业的许可证持有者名单"
    },
    "Total license": {
        en: "Total license",
        mn: "Лицензийн тоо",
        cn: "许可证总数",
    },
    "We provide reliable trading, investment, and consulting services for the mining industry. We will be your trusted partner.": {
        en: "We provide professional services for the mining product's trade and investment brokerage. We will be your trusted partner.",
        mn: "Бид уул уурхайн бүтээгдэхүүний худалдаа, хөрөнгө оруулалт зуучлалын чиглэлээр мэргэжлийн түвшинд үйлчилгээ үзүүлж байна. Бид таны найдвартай түнш байх болно.",
        cn: "我们为矿业产品的贸易和投资经纪提供专业服务。我将成为您值得信赖的合作伙伴。",
    },
    "Rows per page": {
        en: "Rows per page",
        mn: "Хуудсан харагдах мөрийн тоо",
        cn: "页面显示的行数",
    },
    "First": {
        en: "First",
        mn: "Эхний",
        cn: "首先",
    },
    "Prev": {
        en: "Previous",
        mn: "Өмнөх",
        cn: "上一篇",
    },
    "this is a required field.": {
        en: "Required field",
        mn: "Заавал бөглөнө үү",
        cn: "填写",
    },
    "email must be a valid email": {
        en: "Email must be a valid email",
        mn: "И-Мэйл хаяг зөв байх",
        cn: "电子邮件该正确",
    },
    "Passwords must match": {
        en: "Passwords must match",
        mn: "Нууц үг таарах ёстой",
        cn: "密码该符合",
    },
    "Password can only contain Latin letters." : {
        en: "Password can only contain Latin letters",
        mn: "Нууц үг зөвхөн латин үсэг агуулж болно",
        cn: "密码仅包含拉丁字母",
    },
    "Password is too short - should be 8 chars minimum.": {
        en: "Password is too short - should be 8 chars minimum",
        mn: "Нууц үг хэтэрхий богино байна - хамгийн багадаа 8 тэмдэгт байх ёстой",
        cn: "密码太短 - 必须至少 8 个字符",
    },
    "Purpose": {
        en: "Purpose",
        mn: "Зорилго",
        cn: "目的"
    },
    "Select purpose": {
        en: "Select purpose",
        mn: "Зорилгоо сонгох",
        cn: "选择目的",
    },
    "Та байгууллага мөн үү": {
        en: "Are you an organization?",
        mn: "Та байгууллага мөн үү?",
        cn: "你是一个组织吗？",
    },
    "We will be your trusted partner.": {
        en: "We will be your trusted partner.",
        mn: "Бид таны найдвартай түнш байх болно.",
        cn: "我将成为您值得信赖的合作伙伴。",
    },
    "CLOSING PRICE": {
        en: "Closing price",
        mn: "Хаалтын ханш",
        cn: "收盘价",
    },
    "STOCK CHART": {
        en: "Stock chart",
        mn: "Ханшийн график",
        cn: "股票走势图",
    },
    "Something is wrong": {
        en: "Something is wrong",
        mn: "Ямар нэг зүйл буруу байна",
        cn: "有問題"
    },
    "Success": {
        en: "Success",
        mn: "Амжилттай",
        cn: "成功"
    },
    "Loading": {
        en: "Loading",
        mn: "Уншиж байна",
        cn: "加載中"
    },
    "More": {
        mn: "Дэлгэрэнгүй",
        cn: "更多的",
        en: "More",
    },
    'Хэрэв өгөгдлийн өмнө "???" энэ тэмдэг байвал энэ нь таны үүсгэсэн хүсэлт гэсэн үг': {
        mn: 'Хэрэв өгөгдлийн өмнө "???" энэ тэмдэг байвал энэ нь таны үүсгэсэн хүсэлт гэсэн үг',
        cn: "如果数据前面有 “???” 此符号，则表示该请求是由您创建的",
        en: ""
    },
    "Воганы жагсаалт": {
        mn: "Воганы жагсаалт",
        en: "",
        cn: "货车的名单",
    },
    "Export": {
        mn: "Экспорт",
        cn: "出口",
        en: "Export",
    },
    "Refresh": {
        en: "Refresh",
        cn: "重新",
        mn: "Дахин ачааллах"
    },
    "танд шинэчлэх ??? эрх байна. Сүүлд ???-д шинэчилсэн": {
        mn: "танд шинэчлэх ??? эрх байна. Сүүлд ???-д шинэчилсэн",
        cn: "你有权利 ??? 更新。最后于 ??? 更新",
        en: ""
    },
    "№": {
        mn: "№",
        en: "№",
        cn: "№",
    },
    "Вагон дугаар": {
        mn: "Вагон дугаар",
        en: "Wagon no",
        cn: "车号",
    },
    "Илгээсэн өртөө": {
        mn: "Илгээсэн өртөө",
        en: "",
        cn: "发站",
    },
    "Илгээсэн огноо": {
        mn: "Илгээсэн огноо",
        en: "",
        cn: "发货日期",
    },
    "Хүрсэн өртөө": {
        mn: "Хүрсэн өртөө",
        en: "",
        cn: "到站",
    },
    "Хүлээн авсан огноо": {
        mn: "Хүлээн авсан огноо",
        en: "",
        cn: "收货日期",
    },
    "Ачааны нэр": {
        mn: "Ачааны нэр",
        en: "",
        cn: "货物名称",
    },
    "Ачаа хүлээн авагч": {
        mn: "Ачаа хүлээн авагч",
        en: "",
        cn: "收货人",
    },
    "Ачаа хүрэх өртөө": {
        mn: "Ачаа хүрэх өртөө",
        en: "",
        cn: "到站",
    },
    "Засварын хугацаа": {
        mn: "Засварын хугацаа",
        en: "",
        cn: "维修期间",
    },
    "List": {
        mn: "Жагсаалт",
        en: "List",
        cn: "列表",
    },
    "Order": {
        mn: "Захиалга",
        en: "Order",
        cn: "命令",
    },
    "Order list": {
        mn: "Захиалгын жагсаалт",
        en: "Order list",
        cn: "订单",
    },
    "Train": {
        mn: "Төмөр зам",
        cn: "铁路",
        en: "Railway",
    },
    "Ачаа": {
        mn: "Ачаа",
        en: "",
        cn: "货物",
    },
    "Нүүрс /кокс/": {
        mn: "Нүүрс /кокс/",
        en: "Coal /coking/",
        cn: "煤炭 /焦炭/",
    },
    "Нүүрс /эрчим/": {
        mn: "Нүүрс /эрчим/",
        en: "Coal /energy/",
        cn: "煤炭/能源/",
    },
    "Төмөр /баяжмал/": {
        mn: "Төмөр /баяжмал/",
        en: "",
        cn: "铁 /其精矿/",
    },
    "Төмөр /хүдэр/": {
        mn: "Төмөр /хүдэр/",
        en: "",
        cn: "铁 /矿石/",
    },
    "Жонш /хүдэр/": {
        mn: "Жонш /хүдэр/",
        en: "",
        cn: "琼什 /矿石/",
    },
    "Жонш /бал/": {
        mn: "Жонш /бал/",
        en: "",
        cn: "琼什 /石墨/",
    },
    "Ачааны төрөл": {
        mn: "Ачааны төрөл",
        cn: "货物类型",
        en: "",
    },
    "тонн": {
        mn: "тонн",
        cn: "吨",
        en: "tons",
    },
    "Choose": {
        mn: "Сонго",
        cn: "选择",
        en: "Choose",
    },
    "Вагоны тоо": {
        mn: "Вагоны тоо",
        cn: "车皮数",
        en: "",
    },
    "Нөөц /тонн/": {
        mn: "Нөөц /тонн/",
        cn: "资源/吨/",
        en: "Resource /tonn/",
    },
    "бэлэн байгаа эсэх": {
        mn: "бэлэн байгаа эсэх",
        cn: "可用性?",
        en: "Availability?",
    },
    "Ачилт хийх хугацаа": {
        mn: "Ачилт хийх хугацаа",
        cn: "装货期间",
        en: "",
    },
    "Үнийн санал": {
        mn: "Үнийн санал",
        cn: "价格意见",
        en: "",
    },
    "Давтамж": {
        mn: "Давтамж",
        cn: "频率",
        en: "",
    },
    "Өртөө": {
        mn: "Өртөө",
        cn: "货车站",
        en: "",
    },
    "Явах өртөө": {
        mn: "Явах өртөө",
        cn: "发站",
        en: "",
    },
    "Очих өртөө": {
        mn: "",
        cn: "",
        en: "",
    },
    "Салбар зам": {
        mn: "Салбар зам",
        cn: "岔路",
        en: "",
    },
    "Талбай": {
        mn: "Талбай",
        cn: "区域",
        en: "",
    },
    "Салбар зам / Талбай тайлбар": {
        mn: "Салбар зам / Талбай тайлбар",
        cn: "分路/区域说明",
        en: "",
    },
    "Талбайн тодорхойлолт": {
        mn: "Талбайн тодорхойлолт",
        cn: "区域说明",
        en: "",
    },
    "User name": {
        mn: "Хариуцах ажилтны нэр",
        cn: "联络人",
        en: "",
    },
    "Бичиг баримт": {
        mn: "Бичиг баримт",
        cn: "文件",
        en: "Documents",
    },
    "Лабораторын дүгнэлт": {
        mn: "Лабораторын дүгнэлт",
        cn: "实验室结果",
        en: "",
    },
    "Байгаа эсэх": {
        mn: "Байгаа эсэх",
        cn: "有没有",
        en: "",
    },
    "End time": {
        mn: "Дуусах хугацаа",
        cn: "到期日",
        en: "Expiry date",
    },
    "Attachment file": {
        mn: "Файл хавсаргах",
        cn: "附上文件",
        en: "Attach a file",
    },
    "Гэрээ": {
        mn: "Гэрээ",
        cn: "合同",
        en: "Contract",
    },
    "Уурхайгаас дундын Монгол компани хоорондын": {
        mn: "Уурхайгаас дундын Монгол компани хоорондын",
        cn: "从矿业与蒙古公司之间",
        en: "",
    },
    "Уурхайгаас дундын Хятад компани хоорондын": {
        mn: "Уурхайгаас дундын Хятад компани хоорондын",
        cn: "从矿业与中国公司之间",
        en: "",
    },
    "E-TAX болсон эсэх": {
        mn: "E-TAX болсон эсэх",
        cn: "是否电子税务",
        en: "",
    },
    "Татварын албаны баталгаажуулалт": {
        mn: "Татварын албаны баталгаажуулалт",
        cn: "税务机关确认",
        en: "",
    },
    "Invoice packing list": {
        mn: "",
        cn: "",
        en: "",
    },
    "Гурвалсан гэрээ татах": {
        mn: "Гурвалсан гэрээ татах",
        cn: "下载三方合同",
        en: "",
    },
    "Гарал үүслийн бичиг COO": {
        mn: "Гарал үүслийн бичиг COO",
        cn: "原产地信 COO",
        en: "",
    },
    "Тээврийн нөхцөл": {
        mn: "Тээврийн нөхцөл",
        cn: "运输条件",
        en: "",
    },
    "Харилцагч": {
        mn: "Харилцагч",
        cn: "客户",
        en: "",
    },
    "Байгууллага": {
        mn: "Байгууллага",
        cn: "单位",
        en: "Organization",
    },
    "Нэр": {
        mn: "Нэр",
        cn: "姓名",
        en: "Name",
    },
    "Phone number": {
        mn: "Утасны дугаар",
        cn: "电话号码",
        en: "Phone number",
    },
    "Мэйл": {
        mn: "Мэйл",
        cn: "邮件",
        en: "Mail",
    },
    "Нэмэлт тайлбар": {
        mn: "Нэмэлт тайлбар",
        cn: "附加说明",
        en: "Additional explanation",
    },
    "Системд амжилттай нэвтэрлээ": {
        mn: "Системд амжилттай нэвтэрлээ.",
        cn: "登录系统成功",
        en: "Login successfully.",
    },
    
    "Requests": {
        en: "Requests",
        mn: "Хүсэлтүүд",
        cn: "",
    },
    "Change email address": {
        en: "Change email address",
        mn: "Имэйл хаягаа өөрчлөх",
        cn: "更改电子邮件地址",
    },
    "Sent requests": {
        en: "Sent requests",
        mn: "Хүсэлт илгээсэн",
        cn: "请求已发送",
    },
    "Are you sure you want to change your email address? if so please continue": {
        en: "Are you sure you want to change your email address? if so please continue",
        mn: "Та имэйл хаягаа өөрчлөхийг хүсэж байна уу? хэрэв тийм бол үргэлжлүүлнэ үү",
        cn: "",
    },
    "Continue": {
        en: "Continue",
        mn: "Үргэлжлүүлэх",
        cn: "继续",
    },
    "Change password": {
        en: "Change password",
        mn: "Нууц үг солих",
        cn: "更改密码",
    },
    "Are you sure you want to change your password? if so please continue": {
        en: "Are you sure you want to change your password? if so please continue",
        mn: "Та нууц үгээ солихдоо итгэлтэй байна уу? хэрэв тийм бол үргэлжлүүлнэ үү",
        cn: "",
    },
    "We sent code to your email address.": {
        en: "We sent code to your email address",
        mn: "Бид таны имэйл хаягт код илгээсэн",
        cn: "",
    },
    "Didn't get a email?": {
        en: "Didn't get an email?",
        mn: "Имэйл ирээгүй юу?",
        cn: "",
    },
    "Your password has been changed": {
        en: "Your password has been changed",
        mn: "",
        cn: "",
    },
    "Your email has been changed": {
        en: "Your email has been changed",
        mn: "Таны нууц үг өөрчлөгдсөн байна",
        cn: "",
    },


    "Flash": {
        en: "Flash news",
        mn: "Шуурхай мэдээ",
        cn: "快讯",
    },

    // NOTE: Email address bolgoj solih
    "Email": {
        "en": "Email address",
        "mn": "Имэйл хаяг",
        "cn": "电邮位置"
    },
    "Send": {
        en: "Send",
        mn: "Илгээх",
        cn: "发送",
    },
    "Name": {
        en: "Name",
        mn: "Нэр",
        cn: "姓名"
    },
    "URL": {
        en: "URL",
        mn: "URL",
        cn: "网址",
    },
    "Status": {
        en: "Status",
        mn: "Төлөв",
        cn: "地位",
    },
    "Map": {
        en: "Map",
        mn: "Газрын зураг",
        cn: "地图",
    },

    "Сар бүр": {
        en: "",
        mn: "",
        cn: "",
    },
    "Өдөр бүр": {
        en: "",
        mn: "",
        cn: "",
    },
    "Ашиглалт": {
        en: "",
        mn: "",
        cn: "",
    },
    "Хайгуул": {
        en: "",
        mn: "",
        cn: "",
    },

    // 9-21
    "Таны эрх хүрэхгүй байна": {
        en: "",
        mn: "",
        cn: "",
    },
    "Planned Railway": {
        mn: "Төлөвлөж буй",
        cn: "规划中的铁路"
    },

    // 10-4
    "Воганы жагсаалт": {
        en: "",
        mn: "",
        cn: "",
    },
    "Хэвлэгдсэн огноо": {
        en: "",
        mn: "",
        cn: "",
    },
    "Багц": {
        en: "",
        mn: "",
        cn: "",
    },

    // 10-05
    "Дор хаяж нэг утга сонгоно уу": {
        en: "",
        mn: "",
        cn: "",
    },
    "1-ээс илүү утга сонгох ёстой": {
        en: "",
        mn: "",
        cn: "",
    },
    "Сонгоно уу": {
        en: "",
        mn: "",
        cn: "",
    },
    "Заавал эерэг утга байх ёстой.": {
        en: "",
        mn: "",
        cn: "",
    },
    "Хамгийн багадаа '???' байна": {
        en: "",
        mn: "",
        cn: "",
    },
    "Хамгийн ихдээ '???' байна": {
        en: "",
        mn: "",
        cn: "",
    },
    "И-мэйл бөглөнө үү.": {
        en: "",
        mn: "",
        cn: "",
    },
    "Урт нь '???' байх ёстой.": {
        en: "",
        mn: "",
        cn: "",
    },
    "Файл оруулна уу": {
        en: "",
        mn: "",
        cn: "",
    },
    "Эерэг утга оруулна уу": {
        en: "",
        mn: "",
        cn: "",
    },
    "Кодоо оруулна уу": {
        en: "",
        mn: "",
        cn: "",
    },

    // 10-11
    "1. Экспортод гаргах нүүрсний нээлттэй арилжааны видео танилцуулга": {
        en: "",
        mn: "",
        cn: "",
    },
    "2. Экспортод гаргах нүүрсний нээлттэй арилжаанд бүртгүүлэх видео заавар": {
        en: "",
        mn: "",
        cn: "",
    },
    "3. Экспортод гаргах нүүрсний арилжаанд оролцох видео заавар": {
        en: "",
        mn: "",
        cn: "",
    },
    "Заавар": {
        en: "",
        mn: "",
        cn: "",
    },
    "Журам, Гэрээ": {
        en: "",
        mn: "",
        cn: "",
    },
    "Date approved": {
        en: "",
        mn: "",
        cn: "",
    },
    "Download file": {
        en: "",
        mn: "",
        cn: "",
    },
    "Download": {
        en: "",
        mn: "",
        cn: "",
    },
    "Бөглөнө үү": {
        en: "",
        mn: "",
        cn: "",
    },

    // exchange
    "Үндсэн": {
        en: "",
        mn: "",
        cn: "",
    },
    "Заавар": {
        en: "",
        mn: "",
        cn: "",
    },
    "Notices": {
        en: "",
        mn: "",
        cn: "",
    },
    "Supply": {
        en: "",
        mn: "",
        cn: "",
    },
    "Огноо": {
        en: "",
        mn: "",
        cn: "",
    },
    "Бүгд": {
        en: "",
        mn: "",
        cn: "",
    },
    "№": {
        en: "",
        mn: "",
        cn: "",
    },
    "ХХК": {
        en: "",
        mn: "",
        cn: "",
    },
    "Төрөл": {
        en: "",
        mn: "",
        cn: "",
    },
    "Тоо ширхэг": {
        en: "",
        mn: "",
        cn: "",
    },
    "Үнэ": {
        en: "",
        mn: "",
        cn: "",
    },
    "Файл": {
        en: "",
        mn: "",
        cn: "",
    },
    "Үзэх": {
        en: "",
        mn: "",
        cn: "",
    },
    "Show all": {
        en: "",
        mn: "",
        cn: "",
    },
    "Зарсан": {
        en: "",
        mn: "",
        cn: "",
    },
    "А.Үнэ": {
        en: "",
        mn: "",
        cn: "",
    },
    "Ө": {
        en: "",
        mn: "",
        cn: "",
    },
    "Валют": {
        en: "",
        mn: "",
        cn: "",
    },
    "Жагсаалт": {
        en: "",
        mn: "",
        cn: "",
    },
    "Өнөөдөр": {
        en: "",
        mn: "",
        cn: "",
    },
}
