import reactStringReplace from 'react-string-replace';

import language from "."

export function changeLanguage(text, lang="", vars=[])
{

    if (!lang)
    {
        lang = window.localStorage.getItem("lang")
    }

    let templateText = language?.[text]?.[lang] || text
    if (vars.length)
    {
        templateText = reactStringReplace(
                            templateText,
                            '???',
                            (match, i) => {
                                return vars[Math.max((i - 1) / 2, 0)]
                            });
    }
    return templateText
}
